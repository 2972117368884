import React from "react";
import { Typography } from "@material-ui/core";

import { StandartTextCopyButton } from "components";
import strings from "helpers/strings";
import useAppContext from "../../../../../../contexts/AppContext";

interface IProps {
	apiKey?: string | null;
	facebookId?: string | null;
}
export const RobustaSdkModalContent = ({ apiKey, facebookId }: IProps) => {
	const { sharedConfig } = useAppContext();

	const sdkEndpoint = "https://github.com/espresso-pub/robusta#readme";

	const serverEndpoint = `${sharedConfig.endpoints.graphqlUseSSL ? "https" : "http"}://${
		sharedConfig.endpoints.graphqlHost
	}/api/v1/${apiKey || "{api-key}"}/stats-config/${facebookId || "{facebook-app-id}"}`;

	return (
		<>
			<Typography variant="body1">
				{strings.help_configurationRobustaSdkP1}
				<br />
				<a className="link" href={sdkEndpoint} target="_blank" rel="noreferrer">
					<i className="icon icon-link" />
					<b>{sdkEndpoint}</b>
				</a>
			</Typography>
			<ol className="MuiTypography-body1">
				<li>{strings.request_createRequestInfoStatsGuideStep1}</li>
				<li>
					{strings.request_createRequestInfoStatsGuideStep2}
					<Typography color="secondary">
						<StandartTextCopyButton valueToCopy={serverEndpoint} />
						<b>{serverEndpoint}</b>
					</Typography>
					{(!apiKey || !facebookId) && (
						<>
							<Typography variant="body1">{strings.where}:</Typography>
							<ul>
								{!apiKey && (
									<li>
										<b>{"{api-key}"}</b>
										{` - ${strings.request_createRequestInfoStatsGuideStep2ApiKey}`}
									</li>
								)}
								{!facebookId && (
									<li>
										<b>{"{facebook-app-id}"}</b>
										{` - ${strings.request_createRequestInfoStatsGuideStep2FacebookId}`}
									</li>
								)}
							</ul>
						</>
					)}
				</li>
				<li>{strings.request_createRequestInfoStatsGuideStep3}</li>
			</ol>
		</>
	);
};

import { Box, Typography } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { ECampaignType, ELoginRole, ERequestStatus, RequestForListFragment } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { TColor } from "../../components";
import useAppContext from "../../contexts/AppContext";
import moment from "moment";

export interface IRequestInfoProps {
	request?: RequestForListFragment | null;
}

export const RequestInfo = ({ request }: IRequestInfoProps) => {
	const { login } = useAppContext();
	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	if (!request) {
		return null;
	}

	let title = "";
	let text: string | undefined = request.Comment || strings.unknown;
	let secondaryText: string | undefined;
	let color: TColor = "info";
	let iconName = "comment";
	let invert: "invert" | undefined = undefined;

	if (request.Deleted) {
		title = strings.request_removedManagerInfo;
		color = "danger";
		text = undefined;
	} else {
		switch (request.Status) {
			case ERequestStatus.Returned:
				title = strings.request_infoReturnedTitle;
				secondaryText = strings.request_infoReturnedSecondaryText;
				color = "warning";
				invert = "invert";
				iconName = "rejected";
				break;
			case ERequestStatus.Validation:
			case ERequestStatus.Private:
				if (isManagerOrPublisher) {
					if (request.Comment) {
						title = strings.request_infoValidationAfterReturnTitle;
						color = "warning";
						iconName = "rejected";
					}
					break;
				}
				title = strings.request_infoValidationTitle;
				text = strings.request_infoValidationText;
				break;
			case ERequestStatus.Running:
				const metric = request.Metrics.find((m) => !m.AttachmentId);
				const dateOfEndTesting = metric?.StopTime
					? moment(metric.StopTime)
					: moment(request.CurrentStatusAt).add(4, "d");
				title = strings.request_infoRunningTitle;
				text = strings.standartFormat(strings.request_infoRunningTextFuture, {
					date: `${dateOfEndTesting.format("DD.MM.YYYY")}`,
				});
				break;
			case ERequestStatus.CampaignFinished:
				const currentStatusAt = moment.utc(request.CurrentStatusAt);
				const testFinishedRecently =
					request.CampaignType === ECampaignType.R1 &&
					request.Status === ERequestStatus.CampaignFinished &&
					moment.utc().add(-3, "days").isBefore(currentStatusAt);
				title = strings.standartFormat(strings.request_infoCampaignFinishedTitle, {
					date: `${currentStatusAt.local().from(moment())} (${currentStatusAt
						.local()
						.format("DD.MM.YYYY HH:mm")})`,
				});
				if (isManagerOrPublisher) {
					if (testFinishedRecently) {
						text = strings.standartFormat(strings.request_infoCampaignFinishedRecentlyManagerText, {
							date: currentStatusAt.clone().add(1, "day").local().format("DD.MM.YYYY"),
							time: currentStatusAt.clone().add(1, "day").local().format("HH:mm"),
						});
						secondaryText = strings.standartFormat(
							strings.request_infoCampaignFinishedRecentlyManagerSecondaryText,
							{
								date: currentStatusAt.clone().add(3, "days").local().format("DD.MM.YYYY"),
								time: currentStatusAt.clone().add(3, "days").local().format("HH:mm"),
							},
						);
						iconName = "attention";
						color = "warning";
						invert = "invert";
					} else {
						text = strings.request_infoCampaignFinishedManagerText;
					}
				} else {
					if (testFinishedRecently) {
						text = strings.standartFormat(strings.request_infoCampaignFinishedRecentlyText, {
							date: currentStatusAt.clone().add(1, "day").local().format("DD.MM.YYYY"),
						});
						secondaryText = strings.standartFormat(
							strings.request_infoCampaignFinishedRecentlySecondaryText,
							{
								date: currentStatusAt.clone().add(3, "days").local().format("DD.MM.YYYY"),
								time: currentStatusAt.clone().add(3, "days").local().format("HH:mm"),
							},
						);
					} else {
						text = strings.request_infoRunningTextPast;
					}
				}
				break;
			case ERequestStatus.RunFailed:
				title = strings.request_infoRunFailedTitle;
				color = "danger";
				break;
			case ERequestStatus.Rejected:
				title = strings.request_infoRejectedTitle;
				color = "warning";
				break;
			case ERequestStatus.Finished:
				title = strings.request_infoFinishedTitle;
				color = "success";
				break;
		}
	}

	const classes = classNames("request-info", color, invert);

	return (
		<Box className={classes}>
			<i className={`icon icon-${iconName}`} />
			<Box className={request.Deleted ? "deleted-root" : undefined}>
				<Typography className={request.Deleted ? "deleted-title" : undefined} variant="subtitle1">
					{title}
				</Typography>
				{text && <Typography>{text}</Typography>}
				{secondaryText && <Typography>{secondaryText}</Typography>}
			</Box>
		</Box>
	);
};

import { THelpSection } from "views/HelpView/types";
import { FbAppIdInvalid } from "./FbAppIdInvalid";
import { BaristaIdInvalid } from "./BaristaIdInvalid";
import { RobustaSdkFeatures } from "./RobustaSdkFeatures";
import { GetRobustaSdk } from "./GetRobustaSdk";
import { CheckRobustaSdk } from "./CheckRobustaSdk";
import { AppLinkError } from "./AppLinkError";
import { FbPublish } from "./FbPublish";
import { CheckFbSdk } from "./CheckFbSdk";

export const faq: THelpSection = {
	titleKey: "help_faqTitle",
	path: "faq",
	topics: [
		{ titleKey: "help_faqFbAppIdInvalidTitle", Content: FbAppIdInvalid, path: "fb-app-id-invalid" },
		{ titleKey: "help_faqBaristaIdInvalidTitle", Content: BaristaIdInvalid, path: "barista-id-invalid" },
		{
			titleKey: "help_faqRobustaSdkFeaturesTitle",
			Content: RobustaSdkFeatures,
			path: "robusta-sdk-features",
		},
		{ titleKey: "help_faqGetRobustaSdkTitle", Content: GetRobustaSdk, path: "get-robusta-sdk" },
		{ titleKey: "help_faqCheckRobustaSdkTitle", Content: CheckRobustaSdk, path: "check-robusta-sdk" },
		{ titleKey: "help_faqAppLinkErrorTitle", Content: AppLinkError, path: "app-link-error" },
		{ titleKey: "help_configurationFbPublish", Content: FbPublish, path: "fb-publish" },
		{ titleKey: "help_faqCheckFbSdkTitle", Content: CheckFbSdk, path: "check-fb-sdk" },
	],
};

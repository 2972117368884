import {
	ApolloClient,
	InMemoryCache,
	ApolloLink,
	concat,
	GraphQLRequest,
	FieldFunctionOptions,
	StoreObject,
} from "@apollo/client";
import { onError } from "@apollo/client/link/error";
import { RetryLink } from "@apollo/client/link/retry";
import OnError from "./OnError";
import { IConfig } from "@espresso/shared-config";
import { setContext } from "@apollo/client/link/context";
import { createUploadLink } from "apollo-upload-client";
import { customFetch } from "./uploadFetch";
import { ErrorData, SpendFullFragment, TenjinMetricsFullFragment } from "@espresso/protocol";
import _ from "lodash";
import moment from "moment";
import { getTenjinMetricsForEachDay } from "helpers/metrics";

export interface ICreateApolloClientParams {
	sharedConfig: IConfig;
	onErrorLogout: () => Promise<void>;
	getAuthToken: () => string | null;
	setErrorCode: (value: { code: string; data?: { [key: string]: string } } | null) => void;
}

const createApolloClient = (params: ICreateApolloClientParams) => {
	const endpointsConfig = params.sharedConfig.endpoints;
	const graphqlEndpoint = `${endpointsConfig.graphqlUseSSL ? "https" : "http"}://${endpointsConfig.graphqlHost}${
		endpointsConfig.graphqlEndpoint
	}`;

	const errorLink = onError(OnError({ logout: params.onErrorLogout, setErrorCode: params.setErrorCode }));

	const checkResponseError = new ApolloLink((operation, forward) => {
		return forward(operation).map((response) => {
			if (response.data) {
				for (const result of Object.values(response.data)) {
					if (result?.errorCode) {
						const error: { code: string; data?: { [key: string]: string } } = {
							code: result.errorCode as string,
						};
						if (result.errorData) {
							error.data = {};
							for (const ed of result.errorData as ErrorData[]) {
								error.data[ed.Key] = ed.Value;
							}
						}
						params.setErrorCode(error);
						break;
					}
				}
			}
			return response;
		});
	});

	/**
	 * Создается канал для хттп. Применяются с конца в начало, т.ч. RetryLink будет в конце и будет ретраить до конца
	 */
	const httpLink = concat(
		checkResponseError,
		concat(
			new RetryLink({ attempts: { max: Infinity } }),
			concat(
				errorLink,
				(createUploadLink({
					uri: graphqlEndpoint,
					fetch: customFetch,
				}) as unknown) as ApolloLink, // Types workaround: https://github.com/DefinitelyTyped/DefinitelyTyped/issues/47369
			),
		),
	);

	/**
	 * Мидлваря для добавления токена авторизации к каждому запросу, если токен есть
	 */
	const { getAuthToken } = params;
	const setAuthHeader = (_req: GraphQLRequest, { headers }: any) => {
		const token = getAuthToken();
		const authorizationHeader = token ? `Bearer ${token}` : undefined;

		if (authorizationHeader !== undefined) {
			return {
				headers: {
					...headers,
					authorization: authorizationHeader,
				},
			};
		}

		return;
	};
	const middlewareAuthLink = setContext(setAuthHeader);

	/**
	 * Мидлваря добавляется к созданному линку
	 */
	const httpLinkWithAuthToken = middlewareAuthLink.concat(httpLink);

	/**
	 * Разделение рассыки сабскрипшенов и не-сабскрипшенов между каналами
	 * Сабскрипшены идут в ws, остальное в http
	 */
	// const link = split(
	// 	({ query }) => {
	// 		const { kind, operation } = getMainDefinition(query) as any;
	// 		return kind === "OperationDefinition" && operation === "subscription";
	// 	},
	// 	wsLink,
	// 	httpLinkWithAuthToken
	// );
	const link = httpLinkWithAuthToken;

	const links = [link];

	const cache = new InMemoryCache({
		typePolicies: {
			Query: {
				fields: {
					/**
					 * Настройка поведения кеша.
					 * Чтобы в одном поле кеша можно было хранить все затраты для конкретной игры, параметры пагинации
					 * убраны из ключевых аргументов.
					 *
					 * Полученные данные о затратах замещают уже имеющиеся в кеше при совпадающем Id или добавляются
					 * в конец списка.
					 *
					 * Код взят из примеров в документации Apollo
					 */
					getManySpends: {
						keyArgs: ["input", ["GameId", "Deleted"]],
						merge: (existing: SpendFullFragment[], incoming: SpendFullFragment[], options) => {
							const merged: SpendFullFragment[] = mergeByIdentifyingProp(
								existing,
								incoming,
								"Id",
								options,
							);
							return merged.sort((a, b) => {
								if (a.Day > b.Day) return -1;
								if (a.Day < b.Day) return 1;
								if (a.CreatedAt && b.CreatedAt) {
									if (a.CreatedAt > b.CreatedAt) return -1;
									if (a.CreatedAt < b.CreatedAt) return 1;
								}
								return 0;
							});
						},
					},
					getManyRequests: {
						keyArgs: (args) => {
							if (args) {
								return JSON.stringify({ input: _.omit(args.input, "Pagination") }, null, 1);
							}
							return "null";
						},
						merge(existing, incoming, { args }) {
							const merged = existing ? existing.slice(0) : [];
							if (args?.input?.Pagination) {
								// Assume an offset of 0 if args.offset omitted.
								const {
									input: {
										Pagination: { Offset = 0, Limit = merged.length },
									},
								} = args;
								merged.splice(Offset, Limit, ...incoming);
							} else {
								// It's unusual (probably a mistake) for a paginated field not
								// to receive any arguments, so you might prefer to throw an
								// exception here, instead of recovering by appending incoming
								// onto the existing array.
								merged.push.apply(merged, incoming);
							}
							return merged;
						},
					},
					getGameTenjinMetrics: {
						keyArgs: ["id"],
						merge(existing: TenjinMetricsFullFragment[], incoming: TenjinMetricsFullFragment[], options) {
							const incomingMetrics = getTenjinMetricsForEachDay(
								moment(options.args?.from),
								moment(options.args?.to),
								incoming,
							);
							return mergeByIdentifyingProp(existing, incomingMetrics, "ReportDate", options);
						},
					},
				},
			},
		},
	});

	const client = new ApolloClient({
		link: ApolloLink.from(links),
		cache: cache,
		assumeImmutableResults: true,
		connectToDevTools: params.sharedConfig.devTools.apollo,
	});

	return client;
};

function mergeByIdentifyingProp<T extends StoreObject>(
	existing: T[],
	incoming: T[],
	propName: keyof T,
	options: FieldFunctionOptions,
) {
	const merged: T[] = existing ? existing.slice(0) : [];
	const propToIndex: Record<string, number> = Object.create(null);
	if (existing) {
		existing.forEach((item, index) => {
			propToIndex[(item[propName] as unknown) as string] = index;
		});
	}
	incoming.forEach((item) => {
		const index = propToIndex[(item[propName] as unknown) as string];
		if (typeof index === "number") {
			// Merge the new spend data with the existing spend data.
			merged[index] = (options.mergeObjects(merged[index], item) as unknown) as T;
		} else {
			// First time we've seen this spend in this array.
			propToIndex[(item[propName] as unknown) as string] = merged.length;
			merged.push(item);
		}
	});
	return merged;
}

export default createApolloClient;

import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";
import useAppContext from "contexts/AppContext";
import { InfoBox, StandartTextCopyButton } from "components";
import { ELang } from "@espresso/protocol/dist";

const imgSources: { [key in ELang]: string[] } = {
	Ru: [
		"/images/help/fbPublish/Ru0.png",
		"/images/help/fbPublish/Ru1.png",
		"/images/help/fbPublish/Ru2.png",
		"/images/help/fbPublish/Ru3.png",
		"/images/help/fbPublish/Ru4.png",
		"/images/help/fbPublish/Ru5.png",
		"/images/help/fbPublish/Ru6.png",
		"/images/help/fbPublish/Ru7.png",
	],
	En: [
		"/images/help/fbPublish/En0.png",
		"/images/help/fbPublish/En1.png",
		"/images/help/fbPublish/En2.png",
		"/images/help/fbPublish/En3.png",
		"/images/help/fbPublish/En4.png",
		"/images/help/fbPublish/En5.png",
		"/images/help/fbPublish/En6.png",
		"/images/help/fbPublish/En7.png",
	],
	Ch: [
		"/images/help/fbPublish/Ch0.png",
		"/images/help/fbPublish/Ch1.png",
		"/images/help/fbPublish/Ch2.png",
		"/images/help/fbPublish/Ch3.png",
		"/images/help/fbPublish/Ch4.png",
		"/images/help/fbPublish/Ch5.png",
		"/images/help/fbPublish/Ch6.png",
		"/images/help/fbPublish/Ch7.png",
	],
	Tr: [
		"/images/help/fbPublish/En0.png",
		"/images/help/fbPublish/En1.png",
		"/images/help/fbPublish/En2.png",
		"/images/help/fbPublish/En3.png",
		"/images/help/fbPublish/En4.png",
		"/images/help/fbPublish/En5.png",
		"/images/help/fbPublish/En6.png",
		"/images/help/fbPublish/En7.png",
	],
};

export const FbPublish = () => {
	const { sharedConfig, lang } = useAppContext();

	const fbUrl = "https://developers.facebook.com";
	const privacyLink = `${sharedConfig.endpoints.webUseSSL ? "https" : "http"}://${
		sharedConfig.endpoints.webHost
	}/privacy`;
	const dataDeletionLink = `${sharedConfig.endpoints.webUseSSL ? "https" : "http"}://${
		sharedConfig.endpoints.webHost
	}/data-deletion`;

	return (
		<>
			<Typography variant="body1">{strings.help_fbPublishDescription}</Typography>
			<ol className="MuiTypography-body1">
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep1, {
						myApps: <b>{strings.help_fbPublishGuideStep1MyApps}</b>,
						createApp: <b>{strings.help_fbPublishGuideStep1CreateApp}</b>,
						fbLink: (
							<a className="link" href={fbUrl} target="_blank" rel="noreferrer">
								<i className="icon icon-link" />
								<b>{fbUrl}</b>
							</a>
						),
					})}
					<img src={imgSources[lang][0]} alt="" />
				</li>
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep2, {
						integration: <b>{strings.help_fbPublishGuideStep2IntegrationSetup}</b>,
						next: <b>{strings.help_fbPublishGuideStep2Next}</b>,
					})}
					<img src={imgSources[lang][1]} alt="" />
				</li>
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep3, {
						games: <b>{strings.help_fbPublishGuideStep3Games}</b>,
						next: <b>{strings.help_fbPublishGuideStep3Next}</b>,
					})}
					<img src={imgSources[lang][2]} alt="" />
				</li>
				<li>
					{strings.help_fbPublishGuideStep4}
					<ul>
						<li>
							{strings.formatString(strings.help_fbPublishGuideStep4GameNameDesc, {
								gameName: <b>{strings.help_fbPublishGuideStep4GameName}</b>,
							})}
						</li>
						<li>
							{strings.formatString(strings.help_fbPublishGuideStep4EmailDesc, {
								email: <b>{strings.help_fbPublishGuideStep4Email}</b>,
							})}
						</li>
					</ul>
					<img src={imgSources[lang][3]} alt="" />
				</li>
				<li>{strings.help_fbPublishGuideStep5}</li>
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep6, {
						settings: <b>{strings.help_fbPublishGuideStep6Settings}</b>,
						main: <b>{strings.help_fbPublishGuideStep6Main}</b>,
					})}
					<ul>
						<li>{strings.help_fbPublishGuideStep6UrlPrivacy}</li>
						<li>{strings.help_fbPublishGuideStep6UrlDataDeletion}</li>
					</ul>
					<img src={imgSources[lang][4]} alt="" />
					<InfoBox icon="info-outline">
						<Typography variant="caption">{strings.help_fbPublishGuideStep6Example}</Typography>
						<ul>
							<li>
								<Typography variant="caption">
									{strings.formatString(strings.help_fbPublishGuideStep6ExampleUrlPrivacy, {
										privacyLink: (
											<>
												<b>{privacyLink}</b>
												<StandartTextCopyButton valueToCopy={privacyLink} size="small" />
											</>
										),
									})}
								</Typography>
							</li>
							<li>
								<Typography variant="caption">
									{strings.formatString(strings.help_fbPublishGuideStep6ExampleUrlDataDeletion, {
										dataDeletionLink: (
											<>
												<b>{dataDeletionLink}</b>
												<StandartTextCopyButton valueToCopy={dataDeletionLink} size="small" />
											</>
										),
									})}
								</Typography>
							</li>
						</ul>
					</InfoBox>
				</li>
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep7, {
						games: <b>{strings.help_fbPublishGuideStep3Games}</b>,
						hypercasual: <b>{strings.help_fbPublishGuideStep7Hypercasual}</b>,
					})}
				</li>
				<img src={imgSources[lang][5]} alt="" />
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep8, {
						save: <b>{strings.help_fbPublishGuideStep8Save}</b>,
					})}
					<img src={imgSources[lang][6]} alt="" />
				</li>
				<li>
					{strings.formatString(strings.help_fbPublishGuideStep9, {
						inDevelopent: <b>{strings.help_fbPublishGuideStep9InDevelopment}</b>,
						published: <b>{strings.help_fbPublishGuideStep9Published}</b>,
					})}
					<img src={imgSources[lang][7]} alt="" />
				</li>
			</ol>
		</>
	);
};

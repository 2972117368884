import React from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { SwitchBar, TSwitchBarProps } from "../common";

type TProps<T> = Pick<ControllerProps<React.ReactElement, Control>, "control" | "name" | "rules"> &
	Omit<TSwitchBarProps<T>, "value" | "onChange">;

export function StandartSwitchBarController<T>(props: TProps<T>): JSX.Element {
	const { name, control, rules, ...switchBarProps } = props;

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			render={({ onChange, value }) => (
				<SwitchBar {...switchBarProps} onItemsChange={(items) => onChange(items[0])} selectedItems={[value]} />
			)}
		/>
	);
}

import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";

export const R1 = () => {
	return (
		<>
			<Typography variant="body1">{strings.help_testingR1P1}</Typography>
			<ul className="MuiTypography-body1">
				<li>{strings.help_testingWeCollectLi1}</li>
				<li>{strings.help_testingWeCollectLi2}</li>
				<li>{strings.help_testingWeCollectLi3}</li>
			</ul>
			<Typography variant="body1">{strings.help_testingYouWillNeed}</Typography>
			<ol className="MuiTypography-body1">
				<li>{strings.help_testingGameRequirementsLi1}</li>
				<li>{strings.help_testingGameRequirementsLi2}</li>
				<li>{strings.help_testingGameRequirementsLi3}</li>
				<li>
					{strings.help_testingGameRequirementsLi5}
					<ul>
						<li>{strings.help_testingVideoRequirementsLi1}</li>
						<li>{strings.help_testingVideoRequirementsLi2}</li>
						<li>{strings.help_testingVideoRequirementsLi3}</li>
						<li>{strings.help_testingVideoRequirementsLi4}</li>
					</ul>
				</li>
			</ol>
			<Typography variant="body1">{strings.help_testingNameAndIcon}</Typography>
		</>
	);
};

import React, { useCallback, useMemo, useState } from "react";
import { Avatar, Grid, ButtonBase, ListItemIcon, Typography, Menu, MenuItem } from "@material-ui/core";
import { useLocation } from "react-router-dom";

import { EDeveloperRank, ELoginRole } from "@espresso/protocol";
import useAppContext from "../../../contexts/AppContext";
import strings from "../../../helpers/strings";

import { AppLink, LanguagePicker, StandartButton } from "components";

type TProps = {
	isDrawer?: boolean;
};

export default function HeaderLinks(props: TProps) {
	const { login, lang, setLang, setLogoutModalVisible } = useAppContext();
	const location = useLocation();

	const avatarLinkTo = useMemo(() => {
		switch (login?.Role) {
			case ELoginRole.User:
				return "/developer/edit";
			case ELoginRole.Publisher:
			case ELoginRole.Manager:
				return "/publisher";
			case ELoginRole.Unconfirmed:
				return "/unconfirmed";
			default:
				break;
		}
		return "/profile";
	}, [login?.Role]);

	const isProAvailable =
		login?.Developer?.Rank === EDeveloperRank.Trusted ||
		login?.Developer?.Rank === EDeveloperRank.ProViewAccess ||
		login?.Role === ELoginRole.Manager ||
		login?.Role === ELoginRole.Publisher;

	const getButtonVariantByLocation = useCallback(
		(pathname: string, strict: boolean = false) => {
			if (strict) {
				return location.pathname === pathname ? "contained" : "text";
			}
			return location.pathname.includes(pathname) ? "contained" : "text";
		},
		[location.pathname],
	);

	return (
		<Grid item className="button-container">
			{props.isDrawer && (
				<LanguagePicker
					disableUnderline
					disableSelectIcon
					withFlags
					value={lang}
					onChange={setLang}
					label=""
					fullWidth={false}
				/>
			)}
			<AppLink to="/">
				<StandartButton color="white" variant={getButtonVariantByLocation("/", true)}>
					{strings.about_title}
				</StandartButton>
			</AppLink>
			{login ? (
				<AppLink to="/profile">
					<StandartButton color="white" variant={getButtonVariantByLocation("/profile")}>
						{strings.request_list}
					</StandartButton>
				</AppLink>
			) : (
				<AppLink to="/request-express">
					<StandartButton color="white" variant={getButtonVariantByLocation("/request-express")}>
						{strings.request_title}
					</StandartButton>
				</AppLink>
			)}
			{login && isProAvailable && (
				<AppLink to="/pro">
					<StandartButton color="white" variant={getButtonVariantByLocation("/pro", true)}>
						{strings.proDashboard_link}
					</StandartButton>
				</AppLink>
			)}
			<AppLink to="/help" target="_blank">
				<StandartButton color="white" variant={getButtonVariantByLocation("/help")}>
					{strings.web_helpCenterTitle}
				</StandartButton>
			</AppLink>
			<ContactsButton showIcon={!props.isDrawer} />
			{login && <ProfileButton to={avatarLinkTo} showAvatar={!props.isDrawer} />}
			{!props.isDrawer && (
				<LanguagePicker
					disableUnderline
					disableSelectIcon
					withFlags
					value={lang}
					onChange={setLang}
					label=""
					fullWidth={false}
					color="white"
				/>
			)}
			{login ? (
				<StandartButton color="white" variant="text" onClick={() => setLogoutModalVisible(true)}>
					{strings.web_menuLogOut}
				</StandartButton>
			) : (
				<AppLink to="/signup">
					<StandartButton color="white" variant={getButtonVariantByLocation("/signup")}>
						{strings.web_menuLogIn}
					</StandartButton>
				</AppLink>
			)}
		</Grid>
	);
}

const ProfileButton = (props: { to: string; showAvatar: boolean }) => {
	const { to, showAvatar } = props;

	const location = useLocation();

	const buttonVariant = location.pathname.includes(to) ? "contained" : "text";

	return showAvatar ? (
		<Avatar
			variant="circle"
			className="profile-button"
			component={(avatarProps) => (
				<ButtonBase
					{...avatarProps}
					component={React.forwardRef<HTMLAnchorElement>((buttonBaseProps, ref) => (
						<AppLink {...buttonBaseProps} innerRef={ref} to={to} />
					))}
				/>
			)}
		/>
	) : (
		<AppLink to={to} className="profile-button">
			<StandartButton color="white" variant={buttonVariant}>
				{strings.web_menuMyProfile}
			</StandartButton>
		</AppLink>
	);
};

const ContactsButton = (props: { showIcon: boolean }) => {
	const { showIcon } = props;

	const {
		sharedConfig: { contacts },
	} = useAppContext();

	const [anchorEl, setAnchorEl] = useState<Element | null>(null);

	const handleClick = (event: React.MouseEvent) => {
		setAnchorEl(event.currentTarget);
	};

	const handleClose = () => {
		setAnchorEl(null);
	};

	const open = Boolean(anchorEl);
	return (
		<>
			<StandartButton className="contacts-button" variant="text" color="white" onClick={handleClick}>
				{showIcon ? <i className="icon icon-message" /> : <span>{strings.web_contacts}</span>}
			</StandartButton>
			<Menu
				className="contacts-popover-root"
				anchorEl={anchorEl}
				open={open}
				onClose={handleClose}
				MenuListProps={{ disablePadding: true }}
			>
				<MenuItem
					button
					component="a"
					href={`https://t.me/${contacts.tgUsername}`}
					target="_blank"
					rel="noreferrer"
				>
					<ListItemIcon>
						<i className="icon icon-telegram" />
					</ListItemIcon>
					<Typography variant="body2" color="textPrimary">
						{`@${contacts.tgUsername}`}
					</Typography>
				</MenuItem>
				<MenuItem button component="a" href={`mailto:${contacts.email}`} target="_blank" rel="noreferrer">
					<ListItemIcon>
						<i className="icon icon-e-mail" />
					</ListItemIcon>
					<Typography variant="body2" color="textPrimary">
						{contacts.email}
					</Typography>
				</MenuItem>
			</Menu>
		</>
	);
};

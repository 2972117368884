import React from "react";
import { Link, LinkProps, useLocation } from "react-router-dom";
import _ from "lodash";
import { smoothScroll } from "../../helpers/smoothScroll";

export const AppLink = (props: LinkProps) => {
	const spanProps = _.omit(props, "innerRef", "to");
	const location = useLocation();

	if (location.pathname === props.to) {
		return (
			<span {...spanProps} onClick={() => smoothScroll(document.documentElement, 0, 500, "scrollTop")}>
				{props.children}
			</span>
		);
	}
	return <Link {...props} />;
};

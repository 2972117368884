import React, { useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import {
	Box,
	CircularProgress,
	Dialog,
	DialogActions,
	DialogContent,
	DialogTitle,
	Fade,
	Grid,
	IconButton,
	InputAdornment,
	Table,
	TableBody,
	TableCell,
	TableFooter,
	TableHead,
	TablePagination,
	TableRow,
	Typography,
} from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import moment from "moment";
import { MutationUpdaterFn } from "@apollo/client";
import classNames from "classnames";

import {
	ELoginRole,
	EOrderDirection,
	GetManySpendsDocument,
	SpendFullFragment,
	UpsertSpendMutation,
	useGetManySpendsQuery,
	useUpsertSpendMutation,
} from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";
import useAppContext from "contexts/AppContext";
import strings from "helpers/strings";
import { getPaddingTableRows } from "helpers/table";
import { useLoadingSignal, useLoadingContext } from "contexts/LoadingContext";

import {
	StandartButton,
	PlaceholderView,
	StandartTextInputController,
	ConfirmModal,
	StayOrLeaveModal,
} from "components";
import { TLocationState } from "./types";

type TEditExpenseFormData = {
	date: moment.Moment;
	value: string;
	comment: string;
};

interface IExpenseColumn {
	key: keyof SpendFullFragment | "EditSpend";
	titleKey?: keyof TLocale;
	orderField?: keyof SpendFullFragment;
	orderDirection?: EOrderDirection;
	Cell: (props: {
		expense: SpendFullFragment;
		allowEdit: boolean;
		onClick?: (expense: SpendFullFragment) => void;
		onClickDelete?: (expense: SpendFullFragment) => void;
		deleting?: boolean;
	}) => JSX.Element;
}

const commonColumns: IExpenseColumn[] = [
	{
		key: "Day",
		titleKey: "date",
		Cell: (props) => (
			<TableCell onClick={() => props.onClick?.(props.expense)}>
				<Typography variant="body1">{moment(props.expense.Day).format("DD.MM.YYYY")}</Typography>
			</TableCell>
		),
	},
	{
		key: "Value",
		titleKey: "proDashboard_expenses",
		Cell: (props) => (
			<TableCell onClick={() => props.onClick?.(props.expense)}>
				<Typography variant="body1">{`$ ${props.expense.Value}`}</Typography>
			</TableCell>
		),
	},
	{
		key: "Comment",
		titleKey: "comment",
		Cell: (props) => (
			<TableCell className="comment-cell" onClick={() => props.onClick?.(props.expense)}>
				<Typography variant="body1">{props.expense.Comment}</Typography>
			</TableCell>
		),
	},
];

const managerColumns: IExpenseColumn[] = [
	...commonColumns,
	{
		key: "EditSpend",
		Cell: (props) => (
			<TableCell className="edit-spend-cell">
				{props.deleting ? (
					<CircularProgress size={30} />
				) : (
					<>
						<IconButton className="edit-button" onClick={() => props.onClick?.(props.expense)}>
							<i className="icon icon-icon-pencil" />
						</IconButton>
						<IconButton
							className="delete-button"
							onClick={() => props.onClickDelete?.(props.expense)}
							disabled={props.deleting}
						>
							<i className="icon icon-delit" />
						</IconButton>
					</>
				)}
			</TableCell>
		),
	},
];

export const ExpensesTable = (props: { gameId?: string; rowsPerPage: number; highlightedPeriod?: TLocationState }) => {
	const { gameId, rowsPerPage, highlightedPeriod } = props;

	const { login } = useAppContext();
	const { signalLoading } = useLoadingContext();

	//#region Internal State
	const [isEditDialogOpen, setIsEditDialogOpen] = useState(false);
	const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
	const [selectedExpense, setSelectedExpense] = useState<SpendFullFragment | null>(null);
	const [page, setPage] = useState(0);
	const [allFetched, setAllFetched] = useState(false);
	//#endregion

	//#region Data Fetching
	const { data: expensesData, loading, fetchMore, client } = useGetManySpendsQuery({
		fetchPolicy: "network-only",
		notifyOnNetworkStatusChange: true,
		skip: !gameId,
		variables: {
			input: {
				GameId: [gameId as string],
				Deleted: false,
				Pagination: {
					Limit: rowsPerPage * 2,
					Offset: 0,
				},
			},
		},
	});
	const expenses: SpendFullFragment[] = expensesData?.getManySpends ?? [];
	useLoadingSignal(loading);

	const [deleteExpense, { loading: deleting }] = useUpsertSpendMutation({
		update(cache, { data }) {
			cache.modify({
				fields: {
					getManySpends(existing: SpendFullFragment[]) {
						if (data?.upsertSpend.success) {
							const filtered = existing.filter((s) => s.Id !== data.upsertSpend.data?.Spend.Id);
							if (filtered.length && filtered.length === rowsPerPage * page) {
								setPage((prev) => prev - 1);
							}
							return filtered;
						}
						return existing;
					},
				},
			});
		},
		refetchQueries: [
			{
				query: GetManySpendsDocument,
				variables: {
					input: {
						GameId: [gameId],
						Deleted: false,
						Pagination: {
							Limit: rowsPerPage,
							Offset: Math.floor((expenses.length - 1) / rowsPerPage) * rowsPerPage,
						},
					},
				},
			},
		],
	});
	useLoadingSignal(deleting);

	useEffect(() => {
		return () => {
			client?.cache.evict({ fieldName: "getManySpends" });
			client?.cache.gc();
		};
	}, [client?.cache]);

	useEffect(() => {
		const totalExpenses = expensesData?.getManySpends?.length ?? 0;
		if (totalExpenses && totalExpenses <= rowsPerPage) {
			setAllFetched(true);
		}
	}, [expensesData, rowsPerPage]);
	//#endregion

	//#region Handlers
	const handleChangePage = async (_event: unknown, newPage: number) => {
		if (!fetchMore || !gameId) {
			return;
		}
		setPage(newPage);
		if (rowsPerPage * (newPage + 2) > expenses.length && !allFetched) {
			// Вероятно из-за ошибки в Apollo при вызове fetchMore не обновляется loading.
			// Поэтому сингализируем о загрузке вручную

			signalLoading("start");
			const { data } = await fetchMore({
				query: GetManySpendsDocument,
				variables: {
					input: {
						GameId: [gameId],
						Deleted: false,
						Pagination: { Limit: rowsPerPage, Offset: expenses.length },
					},
				},
			});
			signalLoading("finish");
			if ((data.getManySpends?.length ?? 0) < rowsPerPage) {
				setAllFetched(true);
			}
		}
	};

	const handleExpenseEdit = (expense: SpendFullFragment | null) => {
		setSelectedExpense(expense);
		setIsEditDialogOpen(true);
	};

	const handleDeleteExpenseClick = (expense: SpendFullFragment) => {
		setSelectedExpense(expense);
		setIsDeleteDialogOpen(true);
	};

	const handleDeleteExpense = () => {
		if (!selectedExpense) {
			return;
		}
		const { Day, Value, Id, GameId } = selectedExpense;
		deleteExpense({ variables: { data: { Day, Value, Id, GameId, Deleted: true } } });
	};
	//#endregion

	const expensesToShow = expenses.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage);

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	const expensesColumns = isManagerOrPublisher ? managerColumns : commonColumns;

	return (
		<>
			{expensesToShow.length === 0 && (
				<ExpensesTablePlaceholder
					loading={loading || expenses.length > 0}
					addExpense={() => handleExpenseEdit(null)}
					allowEdit={isManagerOrPublisher}
				/>
			)}
			<Fade in={expensesToShow.length > 0} appear>
				<Table>
					<TableHead>
						<TableRow>
							{expensesColumns.map((c) => (
								<TableCell key={c.key} className={c.key}>
									<Typography variant="subtitle1">{c.titleKey ? strings[c.titleKey] : ""}</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{expensesToShow.map((e, index) => (
							<ExpensesTableRow
								key={index}
								expense={e}
								expenseEdit={handleExpenseEdit}
								expenseDelete={handleDeleteExpenseClick}
								allowEdit={isManagerOrPublisher}
								columns={expensesColumns}
								deleting={deleting && selectedExpense?.Id === e.Id}
								highlightedPeriod={highlightedPeriod}
							/>
						))}
						{expensesToShow.length < rowsPerPage &&
							getPaddingTableRows(rowsPerPage - expensesToShow.length, expensesColumns.length)}
					</TableBody>
					<TableFooter>
						<ExpensesTablePaginationRow
							colSpan={expensesColumns.length}
							allFetched={allFetched}
							loadedCount={expenses.length}
							page={page}
							rowsPerPage={rowsPerPage}
							handleChangePage={handleChangePage}
							allowEdit={isManagerOrPublisher}
							addExpense={() => handleExpenseEdit(null)}
						/>
					</TableFooter>
				</Table>
			</Fade>
			<EditExpenseDialog
				open={isEditDialogOpen}
				onClose={() => {
					setIsEditDialogOpen(false);
					setSelectedExpense(null);
				}}
				expense={selectedExpense}
				gameId={gameId}
			/>
			<ConfirmModal
				visible={isDeleteDialogOpen}
				onClose={() => setIsDeleteDialogOpen(false)}
				onConfirm={handleDeleteExpense}
				title={strings.deletion}
				text={strings.proDashboard_deleteExpense}
				confirmButtonLabel={strings.delete}
				confirmButtonColor="danger"
			/>
		</>
	);
};

const ExpensesTableRow = (props: {
	expense: SpendFullFragment;
	expenseEdit: (expense: SpendFullFragment) => void;
	expenseDelete: (expense: SpendFullFragment) => void;
	allowEdit: boolean;
	columns: IExpenseColumn[];
	deleting?: boolean;
	highlightedPeriod?: TLocationState;
}) => {
	const { expense, expenseEdit, expenseDelete, allowEdit, columns, deleting, highlightedPeriod } = props;

	const isInteractive = !deleting && allowEdit;

	const isHighlighted =
		highlightedPeriod && moment(expense.Day).isBetween(highlightedPeriod.from, highlightedPeriod.to, "day", "[]");

	const classes = classNames({ interactive: isInteractive, highlighted: isHighlighted });

	return (
		<TableRow className={classes}>
			{columns.map((c) => (
				<c.Cell
					key={c.key}
					expense={expense}
					onClick={isInteractive ? expenseEdit : undefined}
					onClickDelete={isInteractive ? expenseDelete : undefined}
					allowEdit={isInteractive}
					deleting={deleting}
				/>
			))}
		</TableRow>
	);
};

const ExpensesTablePaginationRow = (props: {
	colSpan: number;
	allFetched: boolean;
	page: number;
	rowsPerPage: number;
	loadedCount: number;
	allowEdit: boolean;
	handleChangePage: (event: unknown, page: number) => void;
	addExpense: () => void;
}) => {
	const { colSpan, allFetched, page, rowsPerPage, loadedCount, handleChangePage, addExpense, allowEdit } = props;
	return (
		<TableRow>
			<TableCell colSpan={colSpan}>
				<Box className="table-actions">
					<TablePagination
						component="div"
						count={allFetched ? loadedCount : -1}
						page={page}
						rowsPerPage={rowsPerPage}
						rowsPerPageOptions={[]}
						onChangePage={handleChangePage}
						labelDisplayedRows={({ from, to, count }) => {
							if (count === -1) {
								return `${from}-${to} ${strings.fromPage} ${strings.morePage} ${loadedCount}`;
							}
							return `${from}-${to} ${strings.fromPage} ${count}`;
						}}
					/>
					{allowEdit && (
						<StandartButton
							color="secondary"
							startIcon={<i className="icon icon-add1" />}
							onClick={addExpense}
						>
							{strings.proDashboard_addExpenses}
						</StandartButton>
					)}
				</Box>
			</TableCell>
		</TableRow>
	);
};

const ExpensesTablePlaceholder = (props: { loading?: boolean; addExpense?: () => void; allowEdit?: boolean }) => {
	return (
		<PlaceholderView
			imageSrc="/images/placeholder.png"
			title={props.loading ? strings.loadingMessage : strings.proDashboard_expensesPlaceholderTitle}
			buttonProps={
				!props.loading && props.addExpense && props.allowEdit
					? {
							className: "button-width-medium",
							color: "success",
							onClick: props.addExpense,
							children: strings.proDashboard_addExpenses,
					  }
					: undefined
			}
		/>
	);
};

const EditExpenseDialog = (props: {
	open: boolean;
	onClose: () => void;
	expense: SpendFullFragment | null;
	gameId?: string;
}) => {
	const { open, onClose, expense, gameId } = props;

	const { setInfoSnackbarParams, showExitPrompt, setShowExitPrompt } = useAppContext();

	const [isConfirmOpen, setIsConfirmOpen] = useState(false);
	const [oldExitPrompt, setOldExitPrompt] = useState(showExitPrompt);

	const {
		control,
		handleSubmit,
		formState: { isDirty, isSubmitSuccessful, isSubmitting, errors },
		...form
	} = useForm<TEditExpenseFormData>({
		defaultValues: { date: moment(), value: "0", comment: "" },
	});

	useEffect(() => {
		if (open) {
			if (expense?.Id) {
				form.reset({
					date: moment(expense.Day),
					value: expense.Value.toString(),
					comment: expense.Comment ?? "",
				});
			}
			if (expense === null) {
				form.reset({ date: moment(), value: "0", comment: "" });
			}
			setOldExitPrompt(showExitPrompt);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [open, expense]);

	useEffect(() => {
		if (open) {
			setShowExitPrompt(isDirty && !isSubmitSuccessful);
		}
	}, [isDirty, isSubmitSuccessful, open, setShowExitPrompt]);

	const [upsertExpense, { loading: upserting }] = useUpsertSpendMutation({
		update: updateCachedExpenses(gameId),
	});
	useLoadingSignal(upserting);

	const onSubmit = async (data: TEditExpenseFormData) => {
		if (!gameId) {
			return;
		}
		await upsertExpense({
			variables: {
				data: {
					Id: expense?.Id,
					GameId: gameId,
					Day: data.date.format("YYYY-MM-DD"),
					Value: parseFloat(data.value),
					Comment: data.comment,
				},
			},
		});
		setInfoSnackbarParams({ open: true, message: strings.changesSaved });
		onClose();
		setShowExitPrompt(oldExitPrompt);
	};

	const handleClose = () => {
		if (isSubmitting) {
			return;
		}
		if (isDirty && !isSubmitSuccessful) {
			return setIsConfirmOpen(true);
		}
		onClose();
	};

	return (
		<>
			<Dialog
				className="edit-expense-dialog withCloseButton"
				open={open}
				onBackdropClick={handleClose}
				keepMounted
			>
				<IconButton className="closeButton" onClick={handleClose}>
					<i className="icon icon-close" />
				</IconButton>
				<DialogTitle disableTypography>
					<Typography variant="h5">{strings.edit}</Typography>
				</DialogTitle>
				<DialogContent>
					<Grid container component="form" id="edit-expense-form" onSubmit={handleSubmit(onSubmit)}>
						<Grid item xs={12} sm={6}>
							<Controller
								name="date"
								control={control}
								rules={{ required: strings.required }}
								render={({ ref, ...field }) => (
									<DatePicker
										required
										fullWidth
										{...field}
										inputRef={ref}
										disableToolbar
										disableFuture
										variant="inline"
										label={strings.date}
										format="DD.MM.YYYY"
										PopoverProps={{ className: "calendar-popover-root" }}
									/>
								)}
							/>
						</Grid>
						<Grid item xs={12} sm={6}>
							<StandartTextInputController
								required
								name="value"
								control={control}
								onlyNumbers
								allowFloat
								errorMessage={errors.value?.message}
								rules={{
									required: strings.required,
									min: {
										value: 0.01,
										message: strings.required,
									},
									max: {
										value: 999999.99,
										message: strings
											.formatString(strings.fieldErrorMaxValue, (999999.99).toString())
											.toString(),
									},
								}}
								label={strings.proDashboard_expensesSum}
								InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
							/>
						</Grid>
						<Grid item xs={12}>
							<StandartTextInputController
								name="comment"
								control={control}
								label={strings.comment}
								multiline
								errorMessage={errors.comment?.message}
								rules={{
									maxLength: {
										value: 150,
										message: strings
											.formatString(strings.fieldErrorMaxLength, (150).toString())
											.toString(),
									},
								}}
							/>
						</Grid>
						<Grid item xs={12}>
							<Typography className="required-fields-text">
								<span className="asterisk-error">*</span>
								<span className="required-info">{strings.standartFormat(strings.requiredFields)}</span>
							</Typography>
						</Grid>
					</Grid>
				</DialogContent>
				<DialogActions>
					<StandartButton
						form="edit-expense-form"
						type="submit"
						color="success"
						disabled={upserting || isSubmitSuccessful}
						startIcon={upserting && <CircularProgress color="inherit" size={16} />}
					>
						{strings.save}
					</StandartButton>
				</DialogActions>
			</Dialog>
			<StayOrLeaveModal
				visible={isConfirmOpen}
				onClose={() => {
					setIsConfirmOpen(false);
					setShowExitPrompt(oldExitPrompt);
				}}
				onConfirm={() => {
					setIsConfirmOpen(false);
					setShowExitPrompt(oldExitPrompt);
					onClose();
				}}
			/>
		</>
	);
};

const updateCachedExpenses = (gameId?: string): MutationUpdaterFn<UpsertSpendMutation> | undefined => {
	return gameId
		? (cache, { data }) => {
				cache.writeQuery({
					query: GetManySpendsDocument,
					variables: {
						input: { GameId: [gameId], Deleted: false },
					},
					data: { getManySpends: [data?.upsertSpend.data?.Spend] },
				});
		  }
		: undefined;
};

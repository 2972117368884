import { Box, CircularProgress } from "@material-ui/core";
import React from "react";
import { ECheckStatus } from "@espresso/protocol";

const statusVariants: { [key in ECheckStatus]: { iconName: string; iconClass: string } } = {
	NotChecked: { iconName: "analytics-statys", iconClass: "checking" },
	Checking: { iconName: "analytics-statys", iconClass: "checking" },
	Valid: { iconName: "analytics-acesses", iconClass: "valid" },
	NotValid: { iconName: "analytics-statys", iconClass: "not-valid" },
};

export interface IBadgeProps {
	status?: ECheckStatus;
	inProgress?: boolean;
}

export const StatusBadge = (props: IBadgeProps) => {
	const conf = props.status ? statusVariants[props.status] : { iconName: "circle", iconClass: "not-checked" };

	return (
		<Box className="status-container">
			{props.inProgress ? (
				<CircularProgress size={22} className="top" />
			) : (
				<i className={`icon icon-${conf.iconName} ${conf.iconClass}`} />
			)}
		</Box>
	);
};

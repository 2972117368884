import React, { useEffect, useRef, useState, useMemo } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import strings from "../../../../helpers/strings";
import { useStandartForm } from "../../../../helpers/useStandartForm";
import { StandartTextInputController, FullScreenPlaceholder, GoBackButton } from "../../../../components";
import { TCreateRequestFormProps } from "../types";
import { ECampaignType, ELoginRole, ERequestStatus, useCheckRequsetMutation, ECheckStatus } from "@espresso/protocol";
import { OtherRequestsList } from "../../OtherRequestsList";
import useAppContext from "../../../../contexts/AppContext";
import { smoothScroll } from "../../../../helpers/smoothScroll";
import { useAnalytics } from "helpers/ga";
import { CreateRequestButtons } from "../CreateRequestButtons";
import { RequestIdInfo } from "../../RequestIdInfo";
import { SdkWizard } from "./SdkWizard";
import { RequestInfo } from "../../RequestInfo";
import { useLoadingSignal } from "contexts/LoadingContext";

type TFormData = { FacebookId: string; InternalComment: string };

export const CreateRequestFormInfoFacebook = (props: TCreateRequestFormProps) => {
	const { request, submit, setCurrentStepStatus, refetch, handleClickRemove, handleRestoreRequest, loading } = props;
	const messagerIdFieldRef = useRef<HTMLDivElement>();

	const { login } = useAppContext();
	const { analyticsEvents } = useAnalytics();

	const form = useStandartForm<TFormData>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			FacebookId: request?.FacebookId || "",
			InternalComment: request?.InternalComment || "",
		},
	});
	const { handleSubmit, errors, control } = form;

	const [checkRequest, { loading: checking }] = useCheckRequsetMutation();
	useLoadingSignal(checking);

	const [isWizardCompleted, setIsWizardCompleted] = useState(false);

	useEffect(() => {
		if (!request) {
			return;
		}
		if (request.InternalComment && request.InternalComment !== form.getValues("InternalComment")) {
			form.setValue("InternalComment", request.InternalComment || "");
		}
		if (request.FacebookId && request.FacebookId !== form.getValues("FacebookId")) {
			form.setValue("FacebookId", request.FacebookId);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	useEffect(() => {
		if (errors.FacebookId?.message && messagerIdFieldRef.current) {
			smoothScroll(messagerIdFieldRef.current.ownerDocument.documentElement, 0, 200, "scrollTop");
			messagerIdFieldRef.current.focus({ preventScroll: true });
		}
	}, [errors.FacebookId?.message]);

	const isR1Request = request?.CampaignType === ECampaignType.R1;

	const back = () => {
		const backStatus = isR1Request ? ERequestStatus.SetSDK : ERequestStatus.Initial;

		setCurrentStepStatus(backStatus);
	};

	const next = async (data: TFormData) => {
		if (!request) {
			return;
		}
		await form.trigger("FacebookId");
		if (!errors.FacebookId) {
			const { FacebookId } = data;
			if (FacebookId !== request.FacebookId) {
				await submit({ FacebookId });
			}
		}
		const { data: checkData } = await checkRequest({
			variables: {
				data: {
					requestId: request.Id,
					checkFacebookId: true,
					checkAdvertId: true,
				},
			},
		});
		const updatedRequest = checkData?.checkRequest.data?.Request;

		if (
			updatedRequest?.FacebookIdStatus !== ECheckStatus.Valid ||
			updatedRequest.AdvertIdStatus !== ECheckStatus.Valid
		) {
			refetch?.();
			return;
		}

		const nextStatus = request?.Status === ERequestStatus.InfoFacebook ? ERequestStatus.New : request?.Status;

		await submit({
			...updatedRequest,
			Status: nextStatus,
		});
		setCurrentStepStatus(ERequestStatus.New);
		analyticsEvents.advanceRequestCreation(request, ERequestStatus.InfoFacebook, ERequestStatus.New);
	};

	const save = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const data = form.getValues();
		submit(data);
	};

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
	const disabledNext = useMemo(() => {
		if (!request) {
			return;
		}

		const statuses = [request.FacebookIdStatus, request.AdvertIdStatus];
		return (
			!isWizardCompleted || statuses.includes(ECheckStatus.NotChecked) || statuses.includes(ECheckStatus.NotValid)
		);
	}, [isWizardCompleted, request]);

	if (!request) {
		return (
			<>
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="request_emptyTitle" messageKey="request_emptyMessage" />
				</Grid>
			</>
		);
	}

	return (
		<form className="content" onSubmit={isManagerOrPublisher ? save : handleSubmit(next)}>
			<Grid container className="facebook-info-container">
				<Grid item xs={12} className="requestid-grid-root">
					<RequestIdInfo request={request} />
				</Grid>
				<Grid item xs={12}>
					<Box className="title-container">
						<GoBackButton to="/profile" />
						<Typography variant="h5">{strings.request_createRequestInfoFacebookTitle}</Typography>
					</Box>
					{request?.Status === ERequestStatus.Returned && !request.Deleted && (
						<RequestInfo request={request} />
					)}
				</Grid>
				<SdkWizard
					request={request}
					form={form}
					setCompleted={setIsWizardCompleted}
					refetch={refetch}
					submit={submit}
					loading={loading}
				/>
				{isManagerOrPublisher && (
					<Grid item md={12} className="MuiFormControl-marginNormal">
						<Typography variant="h5">{strings.request_internalComment}</Typography>
						<Box className="request-info">
							<StandartTextInputController
								control={control}
								name="InternalComment"
								label={strings.request_internalCommentLabel}
							/>
						</Box>
					</Grid>
				)}
				<OtherRequestsList request={request} />
				<CreateRequestButtons
					request={request}
					currentStepIndex={props.currentStepIndex}
					handleBackToStep={back}
					handleClickRemove={handleClickRemove}
					handleRestoreRequest={handleRestoreRequest}
					disabledNext={disabledNext}
					onNext={isManagerOrPublisher ? () => handleSubmit(next)() : undefined}
				/>
			</Grid>
		</form>
	);
};

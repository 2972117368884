import React from "react";
import { TableRow, TableCell, Avatar, Box, ButtonBase, ButtonBaseProps, Typography } from "@material-ui/core";
import classNames from "classnames";

import { AttachmentFullFragment } from "@espresso/protocol";
import { IVideoMetricsTableData, TVideoMetrics, TMetricParamsWithValue } from ".";
import { AttachmentStatus } from "components";

interface IProps {
	data: IVideoMetricsTableData;
	videoMetrics: Array<keyof TVideoMetrics>;
	openVideo: (video: AttachmentFullFragment) => void;
	isPriviliged: boolean;
	facebookAdBaseUrl?: string;
}

interface IVideoCellProps {
	video: AttachmentFullFragment;
	isPriviliged: boolean;
	fbAdUrl?: string | null;
	rejected?: boolean;
}

const VideoCell = ({ video, fbAdUrl, isPriviliged, rejected }: IVideoCellProps) => {
	const { FileName, PreviewUrl } = video;

	const clickAdUrl = (e: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => e.stopPropagation();

	return (
		<TableCell>
			<Box className="video-cell-content">
				{PreviewUrl && <Avatar variant="rounded" alt={FileName} src={PreviewUrl} />}
				<Box className="file-name-container">
					<Typography variant="subtitle2" className="text-ellipsis">
						{FileName}
					</Typography>
				</Box>
				{rejected && <AttachmentStatus status="FacebookRejected" />}
				{isPriviliged && fbAdUrl && (
					<a href={fbAdUrl} target="_blank" rel="noreferrer" onClick={clickAdUrl}>
						<i className="icon icon-link" />
					</a>
				)}
			</Box>
		</TableCell>
	);
};

const getMetricCellContent = (value?: number | null, unit?: string) => {
	if (!value) {
		return "--";
	}
	return !unit ? value.toString() : `${value}\u00a0${unit}`;
};

const MetricCell = ({ getGrade, value, unit }: Partial<TMetricParamsWithValue>) => {
	const checkValueResult = !!value && getGrade?.(value);
	const classes = classNames(checkValueResult);
	const content = getMetricCellContent(value, unit);

	return <TableCell className={classes}>{content}</TableCell>;
};

const ActionableRow = (props: ButtonBaseProps<"tr">) => {
	return <ButtonBase component="tr" disableTouchRipple disableRipple {...props} />;
};

export const VideoMetricsTableRow = ({
	data: { video, metrics, fbAdId, isVideoRejected },
	videoMetrics,
	facebookAdBaseUrl,
	openVideo,
	isPriviliged,
}: IProps) => {
	const fbAdUrl = fbAdId && facebookAdBaseUrl && `${facebookAdBaseUrl}&selected_ad_ids=${fbAdId}`;
	return (
		<TableRow hover component={ActionableRow} onClick={() => openVideo(video)}>
			<VideoCell video={video} fbAdUrl={fbAdUrl} isPriviliged={isPriviliged} rejected={isVideoRejected} />
			{videoMetrics.map((name) => (
				<MetricCell key={`metric-${name}`} {...metrics[name]} />
			))}
		</TableRow>
	);
};

import React from "react";
import { Step, StepConnector, Stepper } from "@material-ui/core";
import { RequestForListFragment } from "@espresso/protocol";

type TRequestItemStepperProps = {
	request: RequestForListFragment;
	activeStepIndex: number;
	stepsCount: number;
};

export const RequestItemStepper = (props: TRequestItemStepperProps) => {
	const { activeStepIndex, stepsCount } = props;

	return (
		<Stepper activeStep={activeStepIndex} connector={<StepConnector />}>
			{[...Array(stepsCount)].map((_, index) => (
				<Step key={index} />
			))}
		</Stepper>
	);
};

import React from "react";
import { TableRow, TableCell } from "@material-ui/core";

export const getPaddingTableRows = (numberOfRows: number, colSpan: number): JSX.Element[] => {
	const rows: JSX.Element[] = [];
	for (let i = 0; i < numberOfRows; i++) {
		rows.push(
			<TableRow key={`pad-row-${i}`}>
				<TableCell colSpan={colSpan} />
			</TableRow>,
		);
	}
	return rows;
};

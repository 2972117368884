import React from "react";
import { Control, Controller, ControllerProps } from "react-hook-form";
import { StandartTextInput, TStandartTextInputProps } from "../common";

type TProps = Pick<ControllerProps<React.ReactElement, Control>, "control" | "name" | "rules" | "defaultValue"> &
	Omit<TStandartTextInputProps, "value" | "onChange"> & {
		onlyNumbers?: boolean;
		allowFloat?: boolean;
	};

export function StandartTextInputController(props: TProps): JSX.Element {
	const { name, control, rules, defaultValue, onlyNumbers, allowFloat, ...textIputProps } = props;

	return (
		<Controller
			name={name}
			control={control}
			rules={rules}
			defaultValue={defaultValue}
			render={({ onChange, value }) => {
				const onChangeText = (text: string) => {
					if (onlyNumbers) {
						const filteredText = allowFloat
							? (text.replace(",", ".").match(/[0-9]+(\.[0-9]*)?/) ?? [""])[0]
							: text.replace(/[^\d]+/g, "");
						return onChange(filteredText);
					}
					return onChange(text);
				};

				return (
					<StandartTextInput
						{...textIputProps}
						error={!!textIputProps.errorMessage}
						value={value}
						onChange={onChangeText}
					/>
				);
			}}
		/>
	);
}

import React, { useState, useMemo } from "react";
import {
	TableContainer,
	Paper,
	Table,
	TableRow,
	TableHead,
	TableBody,
	TableSortLabel,
	TableCell,
} from "@material-ui/core";

import {
	AttachmentFullFragment,
	DeveloperRequestFragment,
	EAttachmentType,
	ECampaignType,
	EFileType,
	ELoginRole,
	useGetObjectAttachmentsQuery,
} from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";
import { getMetric, metricsConfig, getFbVideoStatus } from "helpers/metrics";
import strings from "helpers/strings";
import { VideoMetricsTableRow } from "./VideoMetricsTableRow";
import useAppContext from "contexts/AppContext";
import { GalleryPlayerDialog } from "components";
import { IVideoMetricsTableData, TVideoMetrics } from ".";

interface IProps {
	request: DeveloperRequestFragment;
}

type TColumnName = "video" | keyof TVideoMetrics;

const SortIcon = ({ className }: { className: string }) => {
	return <i className={`icon icon-arrow-3 ${className}`} />;
};

const metricLabels: Record<keyof TVideoMetrics, keyof TLocale> = {
	ctr: "request_metricCTR",
	ipm: "request_metricIPM",
	cr: "request_metricCR",
	impression: "request_metricImpression",
	install: "request_metricInstall",
	click: "request_metricClick",
};
const columnLabels: Record<TColumnName, keyof TLocale> = {
	video: "request_videoFileNameTitle",
	...metricLabels,
};

const videoMetrics: { [key in ECampaignType]: Array<keyof TVideoMetrics> } = {
	Ctr: ["ctr", "impression", "click"],
	Cpi: Object.keys(metricLabels) as Array<keyof TVideoMetrics>,
	R1: Object.keys(metricLabels) as Array<keyof TVideoMetrics>,
};

const getValuesToCompare = (a: IVideoMetricsTableData, b: IVideoMetricsTableData, orderBy: TColumnName) => {
	if (orderBy === "video") {
		return [a.video.FileName, b.video.FileName];
	}
	return [a.metrics[orderBy]?.value, b.metrics[orderBy]?.value];
};

const compare = (valA: string | number | null | undefined, valB: string | number | null | undefined, order: number) => {
	if (!valA || !valB) {
		if (valA) return -1;
		if (valB) return 1;
		return 0;
	}
	if (valA > valB) return -1 * order;
	if (valA < valB) return 1 * order;
	return 0;
};

const VideoMetricsTableComponent = ({ request }: IProps) => {
	const { lang, sharedConfig, login } = useAppContext();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const metricsParams = useMemo(() => metricsConfig(), [lang]);
	const [order, setOrder] = useState(1);
	const [orderBy, setOrderBy] = useState<TColumnName>("ctr");
	const [openedAttachment, setOpenedAttachment] = useState<AttachmentFullFragment>();

	const columns: TColumnName[] = useMemo(() => ["video", ...videoMetrics[request.CampaignType]], [
		request.CampaignType,
	]);

	const requestMetric = request.Metrics.find((m) => !m.AttachmentId);
	const fbCampaignId = requestMetric?.FBCampaignId;
	const adsetId = requestMetric?.FBAdsetId;
	const advertId = request.AdvertId || request.Publisher.AdvertId || sharedConfig.facebook.advertId;
	const facebookAdBaseUrl =
		fbCampaignId && adsetId
			? `https://business.facebook.com/adsmanager/manage/campaigns?act=${advertId}&filter_set=SEARCH_BY_CAMPAIGN_GROUP_ID-STRING%1EEQUAL%1E"${fbCampaignId}"&selected_campaign_ids=${fbCampaignId}&selected_adset_ids=${adsetId}`
			: undefined;

	const isPriviliged = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	const handleTableHeadClick = (columnName: TColumnName) => {
		columnName === orderBy ? toggleOrder() : setOrderBy(columnName);
	};
	const toggleOrder = () => {
		setOrder((prev) => prev * -1);
	};

	const openVideo = (video: AttachmentFullFragment) => {
		if (video.Url) {
			setOpenedAttachment(video);
		}
	};

	const { data } = useGetObjectAttachmentsQuery({
		variables: { objectId: request.Id, type: EAttachmentType.Request, fileType: EFileType.Video },
	});

	const rowElements = useMemo(() => {
		const rows =
			data?.getObjectAttachments.map(
				(video): IVideoMetricsTableData => {
					const videoMetric = request.Metrics.find((m) => m.AttachmentId === video.Id);
					const videoMetricsData: IVideoMetricsTableData = {
						video,
						fbAdId: videoMetric?.FBCampaignId,
						metrics: {},
						isVideoRejected: videoMetric ? getFbVideoStatus(videoMetric) === "FacebookRejected" : undefined,
					};
					videoMetrics[request.CampaignType].forEach(
						(name) =>
							(videoMetricsData.metrics[name] = {
								value: getMetric(name, request, video.Id),
								...metricsParams[name],
							}),
					);
					return videoMetricsData;
				},
			) || [];

		return rows
			.sort((a, b) => {
				const [valA, valB] = getValuesToCompare(a, b, orderBy);
				return compare(valA, valB, order);
			})
			.map((row, index) => (
				<VideoMetricsTableRow
					key={`video-${index}`}
					data={row}
					videoMetrics={videoMetrics[request.CampaignType]}
					facebookAdBaseUrl={facebookAdBaseUrl}
					openVideo={openVideo}
					isPriviliged={isPriviliged}
				/>
			));
	}, [data?.getObjectAttachments, facebookAdBaseUrl, isPriviliged, metricsParams, order, orderBy, request]);

	if (!rowElements) {
		return null;
	}

	return (
		<>
			<TableContainer component={Paper}>
				<Table className="video-metrics-table">
					<TableHead>
						<TableRow>
							{columns.map((name, idx) => (
								<TableCell key={name} className={name}>
									<TableSortLabel
										onClick={() => handleTableHeadClick(name)}
										direction={order < 0 ? "asc" : "desc"}
										active={orderBy === name}
										IconComponent={SortIcon}
									>
										{strings[columnLabels[name]]}
									</TableSortLabel>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>{rowElements}</TableBody>
				</Table>
			</TableContainer>
			<GalleryPlayerDialog
				attachment={openedAttachment}
				onClose={() => setOpenedAttachment(undefined)}
				open={!!openedAttachment}
				defaultGenre={request.Genre}
			/>
		</>
	);
};

export const VideoMetricsTable = React.memo(VideoMetricsTableComponent);

import { Box, Grid, Typography } from "@material-ui/core";
import React from "react";
import { StandartView, StandartButton, AppLink } from "../../components";
import useAppContext from "../../contexts/AppContext";

import strings from "../../helpers/strings";

export const PageNotFoundView = () => {
	const { login } = useAppContext();

	return (
		<StandartView title="">
			<Grid container className="not-found-view">
				<Grid item xs={12}>
					<Typography variant="h5">{strings.notFound_title}</Typography>
					<Box className="image-root">
						<img src="/images/404.png" alt="404" />
					</Box>
					<Box className="info-root">
						<Typography variant="body1">{strings.notFound_infoText}</Typography>
					</Box>
					<Box className="buttons-root">
						{login ? (
							<AppLink to="/profile">
								<StandartButton variant="contained" color="success">
									{strings.request_list}
								</StandartButton>
							</AppLink>
						) : (
							<>
								<AppLink to="/">
									<StandartButton variant="contained" color="success">
										{strings.web_mainPageTitle}
									</StandartButton>
								</AppLink>
								<AppLink to="/signup">
									<StandartButton variant="contained" color="secondary">
										{strings.web_menuLogIn}
									</StandartButton>
								</AppLink>
							</>
						)}
					</Box>
				</Grid>
			</Grid>
		</StandartView>
	);
};

import React, { useRef, useState, useEffect } from "react";
import { Box, CircularProgress, Typography } from "@material-ui/core";
import ReactCrop from "react-image-crop";
import "react-image-crop/dist/ReactCrop.css";
import ReactPlayer from "react-player";

import { AttachmentFullFragment } from "@espresso/protocol";
import strings from "helpers/strings";
import { VideoCropSlider } from "./VideoCropSlider";
import { VideoControlButtons } from "./VideoControlButtons";

interface IVideoCropProps {
	crop: ReactCrop.Crop;
	setCrop: (crop: ReactCrop.Crop, percentCrop: ReactCrop.PercentCrop) => void;
	timeCrop?: [number, number];
	setTimeCrop: React.Dispatch<React.SetStateAction<[number, number] | undefined>>;
	attachment: AttachmentFullFragment;
	onVideoDoneLoading?: (duration: number) => void;
	onSelectionChange: (selection: [number, number]) => void;
	isSelectionAtBoundaries?: boolean;
}

export const VideoCrop = (props: IVideoCropProps) => {
	const {
		attachment,
		crop,
		setCrop,
		timeCrop,
		setTimeCrop,
		onVideoDoneLoading,
		onSelectionChange,
		isSelectionAtBoundaries,
	} = props;
	const [duration, setDuration] = useState(0);
	const [start, end] = timeCrop || [0, duration];
	const [playing, setPlaying] = useState(true);
	const [currentTime, setCurrentTime] = useState(0);
	const [isVideoLoading, setIsVideoLoading] = useState(true);
	const playerRef = useRef<React.ElementRef<typeof ReactPlayer>>(null);

	const progressIntervalInSeconds = 0.1;

	const handlePlayPause = () => {
		if (currentTime >= end) {
			playerRef.current?.seekTo(start, "seconds");
		}
		setPlaying((prev) => !prev);
	};

	useEffect(() => {
		if (!playerRef.current) {
			return;
		}
		if (playerRef.current?.getCurrentTime() < start) {
			playerRef.current.seekTo(start, "seconds");
		}
		if (playerRef.current?.getCurrentTime() > end) {
			playerRef.current.seekTo(end, "seconds");
		}
	}, [start, end]);

	useEffect(() => {
		return () => setTimeCrop(undefined);
	}, [attachment, setTimeCrop]);

	const VideoComponent = (
		<Box>
			<ReactPlayer
				ref={playerRef}
				className="content"
				progressInterval={progressIntervalInSeconds * 1000}
				playing={playing}
				muted
				url={attachment.Url}
				width="fit-content"
				height="100%"
				onReady={(rp) => {
					const el = rp.getInternalPlayer() as HTMLVideoElement;
					const videoDuration = rp.getDuration();
					setDuration(videoDuration);
					el.dispatchEvent(new Event("medialoaded", { bubbles: true }));
					setIsVideoLoading(false);
					if (onVideoDoneLoading) {
						onVideoDoneLoading(videoDuration);
					}
				}}
				onProgress={({ playedSeconds }) => {
					if (playedSeconds <= start) {
						playerRef.current?.seekTo(start, "seconds");
					}
					if (playedSeconds + progressIntervalInSeconds >= (end || duration)) {
						setPlaying(false);
						playerRef.current?.seekTo(end || duration, "seconds");
					}
					setCurrentTime(playedSeconds);
				}}
			/>
			{isVideoLoading && (
				<Box className="loading-message">
					<CircularProgress size={24} />
					<Typography variant="body2">{strings.preparing}</Typography>
				</Box>
			)}
		</Box>
	);

	return (
		<Box className={`video-crop ${isSelectionAtBoundaries ? "selection-at-boundaries" : ""}`}>
			<ReactCrop
				locked
				src={attachment.Url}
				crop={crop}
				onChange={setCrop}
				renderComponent={VideoComponent}
				className={isVideoLoading ? "video-invisible" : undefined}
			/>
			{!isVideoLoading && (
				<Box className="video-controls-container">
					<VideoControlButtons
						onToStart={() => playerRef.current?.seekTo(start)}
						onPlayPause={handlePlayPause}
						onToEnd={() => playerRef.current?.seekTo(end)}
						playing={playing}
					/>
					<VideoCropSlider
						duration={duration}
						currentTime={currentTime}
						handleChangeCommited={setTimeCrop}
						handleChange={onSelectionChange}
					/>
				</Box>
			)}
		</Box>
	);
};

import React, { useEffect, useState } from "react";
import { Box, Slider, Typography, ValueLabelProps } from "@material-ui/core";

import useAppContext from "contexts/AppContext";

interface IProps {
	handleChangeCommited: React.Dispatch<React.SetStateAction<[number, number] | undefined>>;
	duration: number;
	currentTime: number;
	handleChange: (selection: [number, number]) => void;
}

const ThumbComponent = React.forwardRef<
	HTMLSpanElement,
	React.HTMLAttributes<HTMLSpanElement> & {
		children?: React.ReactNode;
		"data-index"?: number;
		"aria-valuemin"?: number;
		"aria-valuemax"?: number;
		"aria-valuenow"?: number;
	}
>((props, ref) => {
	return (
		<span {...props} ref={ref}>
			<i className="icon icon-left-parenthesis" />
			{props.children}
		</span>
	);
});

const ValueLabelComponent = (props: ValueLabelProps) => {
	const { children, value } = props;

	return React.cloneElement(
		children,
		{},
		<span className="value-label">
			<Typography variant="body2" component="span">
				{value}
			</Typography>
		</span>,
	);
};

export const VideoCropSlider = (props: IProps) => {
	const { duration, currentTime, handleChangeCommited, handleChange } = props;
	const [timeCrop, setTimeCrop] = useState<[number, number]>([0, duration]);
	const [start, end] = timeCrop;

	const {
		sharedConfig: {
			attachments: { videoMinLength, videoMaxLength },
		},
	} = useAppContext();

	useEffect(() => {
		const maxTimeCrop: [number, number] = [0, Math.min(duration, videoMaxLength)];
		handleChange(maxTimeCrop);
		handleChangeCommited(duration > videoMaxLength ? maxTimeCrop : undefined);
		setTimeCrop(maxTimeCrop);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [duration, handleChangeCommited, videoMaxLength]);

	const progress = Math.min(Math.round((currentTime / duration) * 10000) / 100, 100);
	const leftBracketProgress = Math.round((timeCrop[0] / duration) * 10000) / 100;
	const rigthBracketProgress = Math.round((timeCrop[1] / duration) * 10000) / 100;

	const formatSeconds = (val: number): string => {
		const minutes = Math.floor(val / 60)
			.toString()
			.padStart(2, "0");
		const seconds = Math.round(val % 60)
			.toString()
			.padStart(2, "0");
		return `${minutes}:${seconds}`;
	};
	return (
		<Box className="video-crop-slider">
			<Box className="progress-root">
				<span
					className="progress-indicator"
					style={{
						left: `clamp(${leftBracketProgress}% + 12px, ${progress}%, ${rigthBracketProgress}% - 12px)`,
					}}
					data-progress={`${formatSeconds(currentTime)} / ${formatSeconds(duration)}`}
				/>
			</Box>
			<Slider
				step={0.01}
				value={timeCrop}
				valueLabelFormat={formatSeconds}
				onChange={(event, value) => {
					if (
						event.type === "mousemove" ||
						!(event.target as HTMLSpanElement).classList.contains("MuiSlider-thumb")
					) {
						const [newStart, newEnd] = [...(value as number[])];
						const newDuration = newEnd - newStart;
						if (newDuration >= videoMinLength && newDuration <= videoMaxLength) {
							setTimeCrop([newStart, newEnd]);
							handleChange([newStart, newEnd]);
						}
					}
				}}
				onChangeCommitted={() => {
					const roundedTimeCrop: [number, number] | undefined =
						start > 0 || end < duration ? [Math.round(start), Math.round(end)] : undefined;
					setTimeCrop(roundedTimeCrop || [0, Math.min(duration, videoMaxLength)]);
					handleChangeCommited(roundedTimeCrop);
				}}
				max={duration}
				ThumbComponent={ThumbComponent}
				ValueLabelComponent={ValueLabelComponent}
				valueLabelDisplay="on"
			/>
		</Box>
	);
};

import { Grid, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { useParams, Redirect } from "react-router-dom";
import { StandartView, RequestList, FullScreenPlaceholder } from "../../components";
import useAppContext from "../../contexts/AppContext";

import { GetRequestsPresetsInput, ELoginRole, useGetOneDeveloperQuery } from "@espresso/protocol";
import withAuth from "../../helpers/withAuth";
import strings from "../../helpers/strings";
import { getDeveloperName } from "helpers/formatDeveloperName";
import { useLoadingSignal } from "contexts/LoadingContext";

interface IDeveloperRequestsParams {
	developerId?: string;
}

export const DeveloperRequestsView = withAuth(() => {
	const { login, requestsListParams, setRequestsListParams } = useAppContext();
	const params = useParams<IDeveloperRequestsParams>();
	const developerId = params.developerId;
	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
	const getRequestsQueryInput: GetRequestsPresetsInput = useMemo(() => ({ DeveloperId: developerId }), [developerId]);

	const { data, loading } = useGetOneDeveloperQuery({ variables: { id: developerId as string }, skip: !developerId });
	useLoadingSignal(loading);

	const developerName = data?.getOneDeveloper
		? getDeveloperName(data?.getOneDeveloper)
		: strings.developer_developerNameLabel;

	if (!login) {
		return (
			<StandartView disableNotifications title="">
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="developer_emptyTitle" messageKey="developer_emptyMessage" />
				</Grid>
			</StandartView>
		);
	}
	if (!isManagerOrPublisher) {
		return <Redirect to="/developer/edit" />;
	}
	return (
		<StandartView title="">
			<Grid container className="home-view developer-requests">
				<Grid item sm={12} className="title-container">
					<Typography variant="h5">{strings.request_developerRequests}</Typography>
					<Typography variant="subtitle2" className="developer-name">
						{developerName}
					</Typography>
				</Grid>
				<RequestList
					getRequestsQueryInput={getRequestsQueryInput}
					requestsListParams={requestsListParams}
					setRequestsListParams={setRequestsListParams}
					rememberLocation
					disableAllRequestsButton
				/>
			</Grid>
		</StandartView>
	);
});

import React, { useEffect, useState } from "react";
import { useFormContext } from "react-hook-form";
import { Box, Checkbox, CircularProgress, Dialog, FormControlLabel, IconButton } from "@material-ui/core";
import ReactPlayer from "react-player";
import useAppContext from "../../contexts/AppContext";
import { AttachmentFullFragment, EGenre, ELoginRole, useUpsertAttachmentDataMutation } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { GenreList, StandartButton } from "..";

interface IGalleryPlayerDialogProps {
	open: boolean;
	attachment?: AttachmentFullFragment;
	onClose: () => void;
	afterUpsert?: () => void;
	defaultGenre?: EGenre;
}
export const GalleryPlayerDialog = (props: IGalleryPlayerDialogProps) => {
	const { onClose, attachment, open, afterUpsert, defaultGenre = EGenre.Other } = props;

	const { login } = useAppContext();

	const form = useFormContext();
	const genreFromForm = form?.watch("Genre") as EGenre | undefined;

	const [upsertAttachmentData, { loading }] = useUpsertAttachmentDataMutation();
	const [selectedGenre, setSelectedGenre] = useState<[EGenre] | undefined>(
		attachment?.Genre ? [attachment.Genre] : undefined,
	);

	useEffect(() => {
		if (attachment?.Genre) {
			setSelectedGenre([attachment?.Genre]);
		} else {
			setSelectedGenre(undefined);
		}
	}, [attachment?.Genre]);

	if (!attachment) {
		return null;
	}

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	const initialGenre: [EGenre] = [genreFromForm ?? defaultGenre];

	const onChange = (genre: EGenre) => {
		setSelectedGenre([genre]);
	};

	const upsertAttachment = async () => {
		const res = await upsertAttachmentData({
			variables: {
				input: {
					Id: attachment.Id,
					FileName: attachment.FileName,
					FileType: attachment.FileType,
					Type: attachment.Type,
					Genre: selectedGenre?.[0] || null,
				},
			},
		});
		if (afterUpsert && res.data?.upsertAttachmentData.success) {
			afterUpsert();
		}
	};

	return (
		<Dialog
			onClose={onClose}
			className="gallery-dialog-player"
			open={open}
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			<Box className="player-container">
				<ReactPlayer url={attachment.Url} controls width="100%" height="100%" className="player" />
			</Box>
			{isManagerOrPublisher && (
				<Box className="controls-container">
					<FormControlLabel
						className="genre-checkbox"
						label={strings.genre_typicalLabel}
						control={
							<Checkbox
								checked={!!selectedGenre}
								color="secondary"
								icon={<i className="icon icon-Checkboxes" />}
								checkedIcon={<i className="icon icon-Checkboxhec" />}
								onChange={(e) =>
									e.target.checked ? setSelectedGenre(initialGenre) : setSelectedGenre(undefined)
								}
							/>
						}
					/>
					<GenreList
						className="MuiFormControl-marginNormal"
						disabled={!selectedGenre}
						labelKey="genre_label"
						selectedKeys={selectedGenre}
						onSelectedChange={(v) => onChange(v[0])}
					/>
					<StandartButton color="success" variant="contained" onClick={upsertAttachment} size="large">
						{loading && <CircularProgress color="inherit" size={20} />}
						{strings.save}
					</StandartButton>
				</Box>
			)}
			<IconButton onClick={onClose} className="close-button">
				<i className="icon icon-close" />
			</IconButton>
		</Dialog>
	);
};

import { Box, FormControl, Input, InputLabel, ListItemText, MenuItem, Select, Typography } from "@material-ui/core";
import React from "react";
import { ELang } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { ISwitchBarItem } from "./SwitchBar";
import { TLocale } from "@espresso/shared-config";
import { TColor } from "../types";

type TLang = ISwitchBarItem<ELang> & { flag: string; shortLabelKey: keyof TLocale; label: keyof TLocale };

export const languagesSwitchItems: Array<TLang> = [
	{ label: "ruLabel", shortLabelKey: "ru", value: ELang.Ru, flag: "/images/flags/russia.png" },
	{ label: "enLabel", shortLabelKey: "en", value: ELang.En, flag: "/images/flags/britain.png" },
	{ label: "chLabel", shortLabelKey: "ch", value: ELang.Ch, flag: "/images/flags/china.png" },
	{ label: "trLabel", shortLabelKey: "tr", value: ELang.Tr, flag: "/images/flags/turkey.png" },
];

const langsMap = languagesSwitchItems.reduce<{ [key in ELang]?: TLang }>((res, item) => {
	res[item.value] = item;
	return res;
}, {});

export interface ILanguagePickerProps {
	value: ELang | undefined;
	onChange?: (value: ELang) => void;
	disabled?: boolean;
	label?: string;
	fullWidth?: boolean;
	withFlags?: boolean;
	disableUnderline?: boolean;
	color?: TColor;
	disableSelectIcon?: boolean;
}

export const LanguagePicker = (props: ILanguagePickerProps) => {
	const {
		value,
		onChange,
		disabled,
		label = strings.developer_langLabel,
		fullWidth = true,
		withFlags,
		disableUnderline,
		color,
		disableSelectIcon,
	} = props;

	const renderValue = (selected: unknown) => {
		const item = langsMap[selected as ELang];

		if (!item) return strings.unknown;

		if (withFlags) {
			return (
				<Box className={color && `text-${color}`} display="flex" alignItems="center">
					<img src={item.flag} alt={strings[item.label]} />
					<Typography>{strings[item.shortLabelKey]}</Typography>
				</Box>
			);
		}

		return strings[item.label];
	};

	const handleChangeLang = (event: React.ChangeEvent<{ value: unknown }>) => {
		const newLang = event.target.value as ELang;
		onChange?.(newLang);
	};

	return (
		<FormControl className="lang-picker" disabled={disabled} fullWidth={fullWidth}>
			{!!label && <InputLabel color="secondary">{label}</InputLabel>}
			<Select
				value={value}
				onChange={handleChangeLang}
				input={<Input disableUnderline={disableUnderline} />}
				renderValue={renderValue}
				IconComponent={disableSelectIcon ? () => null : undefined}
			>
				{languagesSwitchItems.map((langItem) => (
					<MenuItem key={langItem.shortLabelKey} value={langItem.value}>
						<ListItemText
							className="lang-picker-item"
							primary={
								withFlags ? (
									<Box display="flex" alignItems="center">
										<img src={langItem.flag} alt={strings[langItem.label]} />
										<Typography className={color && `text-${color}`}>
											{`${strings[langItem.label]} (${strings[langItem.shortLabelKey]})`}
										</Typography>
									</Box>
								) : (
									strings[langItem.label]
								)
							}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

import React, { useCallback } from "react";
import classNames from "classnames";
import { TThemeColor } from "../../types";
import { Grid, Hidden, Typography } from "@material-ui/core";

type TCardProps = {
	className?: string;
	plain?: boolean;
	profile?: boolean;
	blog?: boolean;
	raised?: boolean;
	background?: boolean;
	pricing?: boolean;
	testimonial?: boolean;
	color?: TThemeColor;
	product?: boolean;
	signup?: boolean;
	inverted?: boolean;

	footerProps?: {
		className?: string;
		children: React.ReactNode;
	};
	headerProps?: {
		className?: string;
		children?: React.ReactNode;
		imageSrc?: string;
		contact?: boolean;
		noShadow?: boolean;
	};
	bodyProps?: {
		className?: string;
		formHorizontal?: boolean;
		title?: string;
		subtitle?: string;
	};
};

export function StandartCard(props: React.PropsWithChildren<TCardProps>) {
	const {
		className,
		plain,
		profile,
		blog,
		raised,
		background,
		pricing,
		color,
		product,
		testimonial,
		signup,
		footerProps,
		headerProps,
		bodyProps,
		inverted,
		children,
		...rest
	} = props;

	const cardClasses = classNames(
		"card",
		{
			plain,
			profile: profile || testimonial,
			blog,
			raised,
			background,
			pricingColor: (pricing && color !== undefined) || (pricing && background !== undefined),
		},
		color,
		{ pricing, product },
		className,
	);
	const cardHeaderClasses = classNames(
		"cardHeader",
		color,
		{
			plain,
			image: !!headerProps?.imageSrc,
			contact: headerProps?.contact,
			signup,
			noShadow: headerProps?.noShadow,
		},
		headerProps?.className,
	);
	const cardBodyClasses = classNames(
		"cardBody",
		{ background, plain, formHorizontal: bodyProps?.formHorizontal, pricing, signup, color },
		bodyProps?.className,
	);
	const cardFooterClasses = classNames(
		"cardFooter",
		{
			plain,
			cardFooterProfile: profile || testimonial,
			pricing,
			testimonial,
		},
		footerProps?.className,
	);

	const Header = useCallback(
		() => (
			<div className={cardHeaderClasses}>
				{!!headerProps?.imageSrc && (
					<>
						<img src={headerProps?.imageSrc} alt="..." />
						<div
							className="coloredShadow"
							style={{ backgroundImage: `url(${headerProps?.imageSrc})`, opacity: 1 }}
						/>
					</>
				)}
				{headerProps?.children}
			</div>
		),
		[cardHeaderClasses, headerProps?.children, headerProps?.imageSrc],
	);

	return (
		<div className={cardClasses} {...rest}>
			<Grid container justify="center">
				{!inverted && (
					<Grid item sm={12} md={5}>
						<Hidden smDown>
							<Header />
						</Hidden>
					</Grid>
				)}
				<Grid item sm={12} md={5}>
					<div className={cardBodyClasses}>
						{!!bodyProps?.title && (
							<Typography variant="h5" className="cardTitle">
								{bodyProps.title}
							</Typography>
						)}
						<Hidden mdUp>
							<Header />
						</Hidden>
						{!!bodyProps?.subtitle && (
							<Typography variant="subtitle2" className="description">
								{bodyProps.subtitle}
							</Typography>
						)}
						{children}
					</div>
				</Grid>
				{inverted && (
					<Grid item sm={12} md={5}>
						<Hidden smDown>
							<Header />
						</Hidden>
					</Grid>
				)}
				{footerProps && <div className={cardFooterClasses}>{footerProps?.children}</div>}
			</Grid>
		</div>
	);
}

import React, { useEffect, useMemo, useState } from "react";
import {
	Checkbox,
	FormControl,
	IconButton,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";
import { ERequestStatus, StatusPreset } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { StatusesColors } from "../../helpers/constants";

type TRequestItemStepperProps = {
	selectedStatuses?: StatusPreset[];
	onSelectedChange?: (selectedStatuses: StatusPreset[]) => void;
};

export const statusFilterItems = [
	{ Status: ERequestStatus.Initial, ...StatusesColors.Initial },
	{ Status: ERequestStatus.SetSDK, ...StatusesColors.SetSDK },
	{ Status: ERequestStatus.InfoFacebook, ...StatusesColors.InfoFacebook },
	{ Status: ERequestStatus.New, ...StatusesColors.New },
	{ Status: ERequestStatus.PreValidation, ...StatusesColors.PreValidation },
	{ Status: ERequestStatus.Returned, ...StatusesColors.Returned },
	{ Status: ERequestStatus.Validation, ...StatusesColors.Validation },
	{ Status: ERequestStatus.Running, ...StatusesColors.Running },
	{ Status: ERequestStatus.CampaignFinished, ...StatusesColors.CampaignFinished },
	{ Status: ERequestStatus.Finished, ...StatusesColors.Finished },
	{ Status: ERequestStatus.Rejected, ...StatusesColors.Rejected },
	{ Status: ERequestStatus.RunFailed, ...StatusesColors.RunFailed },
];

const statusFilterKeys = statusFilterItems.map((item) => item.Status);

export const StatusList = (props: TRequestItemStepperProps) => {
	const [selectedStatuses, setSelectedStatuses] = useState<StatusPreset[]>(props.selectedStatuses || []);

	// Отбор только тех статусов, которые присутствуют в statusFilterKeys
	const statusesList = useMemo(
		() =>
			selectedStatuses.reduce((result: Array<ERequestStatus>, item) => {
				if (statusFilterKeys.includes(item.Status)) {
					result.push(item.Status);
				}
				return result;
			}, []),
		[selectedStatuses],
	);

	useEffect(() => {
		setSelectedStatuses(props.selectedStatuses || []);
	}, [props.selectedStatuses]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const selected: StatusPreset[] = (event.target.value as ERequestStatus[]).map((item) => ({
			Status: item,
		}));
		setSelectedStatuses(selected);
		if (props.onSelectedChange) {
			props.onSelectedChange(selected);
		}
	};

	const handleClear = (event: React.SyntheticEvent) => {
		event.stopPropagation();
		setSelectedStatuses([]);
		if (props.onSelectedChange) {
			props.onSelectedChange([]);
		}
	};

	return (
		<FormControl className="status-filter" margin="dense" fullWidth>
			<InputLabel color="secondary">{strings.requestStatus_Label}</InputLabel>
			<Select
				color="secondary"
				MenuProps={{ className: "status-filter", variant: "menu" }}
				multiple
				value={statusesList}
				onChange={handleChange}
				input={
					<Input
						className="select-input"
						endAdornment={
							selectedStatuses.length ? (
								<IconButton
									onClick={handleClear}
									size="small"
									color="secondary"
									className="clear-button"
								>
									<i className="icon icon-close" />
								</IconButton>
							) : undefined
						}
					/>
				}
				renderValue={(selected) => {
					return (selected as ERequestStatus[])
						.map((selected) => strings[StatusesColors[selected].localeKey])
						.join(", ");
				}}
			>
				{statusFilterItems.map((conf) => (
					<MenuItem key={conf.Status} value={conf.Status}>
						<i className={`icon icon-${conf.icon} status ${conf.className}`} />
						<ListItemText primary={strings[conf.localeKey]} />
						<Checkbox
							checked={selectedStatuses.some((item) => item.Status === conf.Status)}
							color="default"
							icon={<i className="icon icon-Checkboxes" />}
							checkedIcon={<i className="icon icon-Checkboxhec" />}
							className={conf.className}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

import { TLocale } from "@espresso/shared-config";
import { IStandartButtonProps, StandartButton, TColor } from "../../components";
import { Card, CardActions, CardHeader, IconButton } from "@material-ui/core";
import React from "react";
import strings from "../../helpers/strings";

interface IAction extends Omit<IStandartButtonProps, "disableElevation" | "className"> {
	title: keyof TLocale;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export interface INotificationCardProps {
	key?: string;
	title?: string;
	titleKey?: keyof TLocale;
	message?: string;
	messageKey?: keyof TLocale;
	iconName?: string;
	variant?: TColor;
	actions?: IAction[];
	onClose?: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export const NotificationCard = (props: INotificationCardProps) => {
	const { onClose, title, titleKey, message, messageKey, iconName, actions, variant = "primary" } = props;

	return (
		<Card className={`notification-card-container bg-${variant}`}>
			<CardHeader
				avatar={
					iconName && (
						<div className="icon-container">
							<i className={`icon icon-${iconName}`} />
						</div>
					)
				}
				title={title || (titleKey && strings[titleKey])}
				subheader={message || (messageKey && strings[messageKey])}
			/>
			<CardActions disableSpacing>
				{onClose && (
					<IconButton className="close-button" onClick={onClose} size="small">
						<i className="icon icon-close" />
					</IconButton>
				)}
				{actions &&
					!!actions.length &&
					actions.map((action, index) => (
						<StandartButton
							disableElevation
							className={`text-color-${variant}`}
							key={`notif-action-${index}`}
							{...action}
							title={strings[action.title]}
						>
							{strings[action.title]}
						</StandartButton>
					))}
			</CardActions>
		</Card>
	);
};

import React from "react";
import { Switch, Route } from "react-router-dom";
import { LandingPlaceholder, LandingView } from "./views/LandingView";
import { PageNotFoundView } from "./views/PageNotFoundView";
import useAppContext from "./contexts/AppContext";
import { LoadingContextProvider } from "contexts/LoadingContext";

import { staticRoutes } from "./helpers/routes";

const Router = () => {
	const { sharedConfig, session } = useAppContext();
	return (
		<div>
			<Switch>
				{staticRoutes.map((route, i) => (
					<Route key={i} path={route.path}>
						<LoadingContextProvider>
							<route.component />
						</LoadingContextProvider>
					</Route>
				))}
				<Route exact path="/">
					<LoadingContextProvider>
						{(sharedConfig.endpoints.webHome === "landing" || session?.auth) && <LandingView />}
						{sharedConfig.endpoints.webHome === "placeholder" && !session?.auth && <LandingPlaceholder />}
					</LoadingContextProvider>
				</Route>
				<Route>
					<LoadingContextProvider>
						<PageNotFoundView />
					</LoadingContextProvider>
				</Route>
			</Switch>
		</div>
	);
};

export default Router;

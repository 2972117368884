import { checkVideoDimensionsValid, checkVideoLengthValid, TLocale } from "@espresso/shared-config";
import _ from "lodash";
import moment from "moment";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
	EAttachmentType,
	useUploadAttachmentMutation,
	useGetObjectAttachmentsLazyQuery,
	GetObjectAttachmentsQueryVariables,
	EFileType,
	AttachmentFullFragment,
	useGetManyVideoConvertTasksLazyQuery,
	ETaskStatus,
	ELoginRole,
	RequestMetricsFullFragment,
	GetOneRequestQuery,
	DeveloperRequestFragment,
} from "@espresso/protocol";
import strings from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import { AlertType, DropzoneAreaBase } from "material-ui-dropzone";
import { Box, Hidden, Icon, InputLabel, List, Typography } from "@material-ui/core";
import { humanFileSize } from "../../helpers/humanFileSize";
import classNames from "classnames";
import { GalleryPlayerDialog } from "./GalleryPlayerDialog";
import { GalleryAttachmentItem } from "./GalleryAttachmentItem";
import { GalleryCropDialog } from "./GalleryCropDialog";
import { StandartButton } from "../common";
import { ApolloQueryResult } from "@apollo/client";

export interface IGalleryProps {
	attachmentType: EAttachmentType;
	fileType: EFileType;
	disabled?: boolean;
	disableItemsActions?: boolean;
	objectId?: string;
	maxItems?: number;
	readonly?: boolean;
	hasDelete?: boolean;
	hasCreateAd?: boolean;
	onUpdateAttachments?: (attachments: AttachmentFullFragment[]) => void;
	onUpdateCropIds?: (value: string[] | undefined) => void;
	addFileTip?: keyof TLocale;
	label?: keyof TLocale;
	acceptedFiles?: string[];
	maxFileSize?: number;
	additionalRequirementsText?: keyof TLocale;
	forceUpdateTime?: Date;
	verticalItems?: boolean;
	simple?: boolean;
	required?: boolean;
	errorMessage?: string;
	onProcessingTasksVideo?: (loading: boolean) => void;
	onLoadingAttachmentsChange?: (loading: boolean) => void;
	requestMetrics?: RequestMetricsFullFragment[];
	refetchRequest?: () => Promise<ApolloQueryResult<GetOneRequestQuery>>;
	request?: Pick<DeveloperRequestFragment, "Status" | "CampaignType" | "Metrics" | "Genre"> | null;
}

const UploadIcon = (): React.ReactElement => {
	return (
		<Icon>
			<i className="icon icon-add1" />
		</Icon>
	);
};

export const Gallery = (props: IGalleryProps) => {
	const {
		attachmentType,
		fileType,
		disabled,
		disableItemsActions,
		objectId,
		maxItems,
		readonly,
		hasDelete,
		hasCreateAd,
		onUpdateAttachments,
		onUpdateCropIds,
		addFileTip,
		label,
		acceptedFiles,
		additionalRequirementsText,
		forceUpdateTime,
		verticalItems,
		simple,
		required,
		onProcessingTasksVideo,
		onLoadingAttachmentsChange,
		requestMetrics,
		refetchRequest,
		request,
	} = props;
	const [getObjectAttachments, { loading, data, refetch }] = useGetObjectAttachmentsLazyQuery({
		fetchPolicy: "no-cache",
	});
	const [getVideoTasks, { data: videoTasksData, loading: loadingVideoTasks }] = useGetManyVideoConvertTasksLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	const [uploadMutation] = useUploadAttachmentMutation();

	const { login, sharedConfig } = useAppContext();

	const uploadedIdsRef = useRef<Array<string>>([]);
	const prevVideoQueueIdsRef = useRef<Array<string>>([]);
	const variables = useRef<GetObjectAttachmentsQueryVariables>({
		objectId: "-1",
		type: attachmentType,
		fileType: fileType,
	});

	const [attachments, setAttachments] = useState<AttachmentFullFragment[]>([]);
	const [errorMessage, setErrorMessage] = useState<string>();
	const [openedAttachment, setOpenedAttachment] = useState<AttachmentFullFragment>();
	const [openedCropAttachment, setOpenedCropAttachment] = useState<AttachmentFullFragment>();
	const [cropAttachmentQueue, setCropAttachmentQueue] = useState<AttachmentFullFragment[]>([]);
	const [loadingVideoAttachments, setLoadingVideoAttachments] = useState<
		Map<string, { progress: number; fileName: string; abort?: () => void }>
	>(new Map());
	const [visibleCropAttachment, setVisibleCropAttachment] = useState(false);

	if (objectId) {
		variables.current.objectId = objectId;
	} else {
		variables.current.loginId = login?.Id;
	}

	useEffect(() => {
		if (data) {
			setAttachments(
				data.getObjectAttachments.slice().sort((a, b) => moment(a.CreatedAt).diff(moment(b.CreatedAt))),
			);

			const processingAttachments = data.getObjectAttachments.find((attach) => !attach.Url.length);

			if (processingAttachments) {
				let timeout = setTimeout(() => {
					refetchRequest && refetchRequest();
					getObjectAttachments({
						variables: { ...variables.current, ids: objectId ? undefined : uploadedIdsRef.current },
					});
				}, 10000);

				return () => {
					clearTimeout(timeout);
				};
			}
		}
		return;
	}, [data, getObjectAttachments, objectId, refetchRequest]);

	useEffect(() => {
		if (data) {
			data.getObjectAttachments.forEach((attach) => {
				if (loadingVideoAttachments.has(attach.Id)) {
					deleteLoadingAttachment(attach.Id);
				}
			});
		}
	}, [data, loadingVideoAttachments]);

	useEffect(() => {
		if (!login?.Id) {
			return;
		}
		setErrorMessage(undefined);
		getObjectAttachments({
			variables: variables.current,
		});
	}, [login?.Id, getObjectAttachments, objectId, forceUpdateTime]);

	useEffect(() => {
		if (!onProcessingTasksVideo) {
			return;
		}
		if (
			loadingVideoTasks ||
			(videoTasksData?.getManyVideoConvertTasks && videoTasksData.getManyVideoConvertTasks.length > 0)
		) {
			const hasProcessingTask = videoTasksData?.getManyVideoConvertTasks?.some(
				(item) => !item.Status || item.Status === ETaskStatus.Processing,
			);
			onProcessingTasksVideo(hasProcessingTask ?? true);
			return;
		}
		onProcessingTasksVideo(false);
	}, [onProcessingTasksVideo, videoTasksData, loadingVideoTasks]);

	useEffect(() => {
		if (!onLoadingAttachmentsChange) {
			return;
		}
		const processingAttachments = !!attachments.find((a) => !a.Url.length) || loadingVideoAttachments.size > 0;
		onLoadingAttachmentsChange(processingAttachments);
	}, [attachments, loadingVideoAttachments, onLoadingAttachmentsChange]);

	const setUploadProgress = (ev: ProgressEvent, key: string) => {
		setLoadingVideoAttachments((prev) => {
			const current = prev.get(key);
			if (!current) {
				return prev;
			}
			return new Map(prev).set(key, { ...current, progress: (ev.loaded / ev.total) * 100 });
		});
	};

	const deleteLoadingAttachment = (key: string) => {
		setLoadingVideoAttachments((prev) => {
			const newState = new Map(prev);
			newState.delete(key);
			return newState;
		});
	};

	const updateUploadedAttachmentId = (key: string, uploadedAttachmentId: string) => {
		setLoadingVideoAttachments((prev) => {
			const newState = new Map(prev);
			const uploadedAttachment = newState.get(key);
			if (uploadedAttachment) {
				newState.set(uploadedAttachmentId, uploadedAttachment);
			}
			newState.delete(key);
			return newState;
		});
	};

	const uploadFile = useCallback(
		async (file?: File) => {
			if (!file) {
				return;
			}

			if (!file.name) {
				return;
			}

			const key = `${file.name}|${Date.now()}`;
			setLoadingVideoAttachments((prev) => new Map(prev).set(key, { progress: 0, fileName: file.name }));

			const resUpload = await uploadMutation({
				variables: {
					file: {
						File: file,
						FileName: file.name,
						FileType: fileType,
						Type: attachmentType,
						ObjectId: objectId || "-1",
					},
				},
				context: {
					fetchOptions: {
						useUpload: true,
						onProgress: (ev: ProgressEvent) => setUploadProgress(ev, key),
						onAbortPossible: (abortHandler: () => void) => {
							setLoadingVideoAttachments((prev) => {
								const current = prev.get(key);
								if (!current) {
									return prev;
								}
								return new Map(prev).set(key, {
									...current,
									abort: () => {
										deleteLoadingAttachment(key);
										abortHandler();
									},
								});
							});
						},
					},
				},
			});

			const success = resUpload.data?.uploadAttachment.success;
			const attachmentId = resUpload.data?.uploadAttachment.data?.Attachment.Id;

			if (!success) {
				deleteLoadingAttachment(key);
				if (!simple) {
					return;
				}
			}

			if (attachmentId) {
				uploadedIdsRef.current = [...uploadedIdsRef.current, attachmentId];
				updateUploadedAttachmentId(key, attachmentId);
			}

			getObjectAttachments({
				variables: {
					...variables.current,
					ids: objectId ? undefined : uploadedIdsRef.current,
				},
			});
		},
		[uploadMutation, fileType, attachmentType, objectId, getObjectAttachments, simple],
	);

	useEffect(() => {
		if (!attachments || loading) {
			return;
		}
		onUpdateAttachments?.(attachments);

		if (!uploadedIdsRef.current.length) {
			uploadedIdsRef.current = attachments.map((a) => a.Id);
		}

		if (!simple) {
			onProcessingTasksVideo?.(true);
			getVideoTasks({ variables: { id: uploadedIdsRef.current } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [attachments, loading]);

	useEffect(() => {
		if (!videoTasksData || loadingVideoTasks || simple) {
			return;
		}

		const fbProcessing = requestMetrics?.find(
			(m) => (!m.FBCampaignId && m.Status === ETaskStatus.Processing) || (m.AttachmentId && !m.FBStatus),
		);

		const ids = videoTasksData.getManyVideoConvertTasks
			?.filter((item) => item.Status == null || item.Status === ETaskStatus.Processing)
			.map((item) => item.Id);
		onUpdateCropIds?.(ids);

		const converted = videoTasksData.getManyVideoConvertTasks?.filter(
			(item) => item.Status === ETaskStatus.Done && item.ConvertedId,
		);
		if (converted) {
			let needFetch = false;
			for (const task of converted) {
				const found = uploadedIdsRef.current.findIndex((v) => v === task.Id);
				if (found >= 0 && task.ConvertedId) {
					uploadedIdsRef.current[found] = task.ConvertedId;
					needFetch = true;
				}
			}
			if (needFetch) {
				getObjectAttachments({
					variables: { ...variables.current, ids: objectId ? undefined : uploadedIdsRef.current },
				});
			}
		}

		if (_.isEmpty(ids) && !fbProcessing) {
			return;
		}

		let timeout = setTimeout(() => {
			refetchRequest && refetchRequest();
			getObjectAttachments({
				variables: { ...variables.current, ids: objectId ? undefined : uploadedIdsRef.current },
			});
		}, 10000);

		return () => {
			clearTimeout(timeout);
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getObjectAttachments, simple, videoTasksData, loadingVideoTasks, requestMetrics]);

	const setMessage = (message: string, type: AlertType) => {
		if (type === "error") {
			setErrorMessage(message);
		} else {
			setErrorMessage(undefined);
		}
	};

	const openFull = (attachment: AttachmentFullFragment) => {
		if (attachment.Url) {
			setOpenedAttachment(attachment);
		}
	};

	const closeFull = () => {
		setOpenedAttachment(undefined);
	};

	const setCropAttachment = (attachment?: AttachmentFullFragment) => {
		setOpenedCropAttachment(attachment);
	};

	const afterDelete = (id: string) => {
		setAttachments((prev) => prev.filter((a) => a.Id !== id));
		getObjectAttachments({
			variables: {
				...variables.current,
				ids: objectId ? undefined : uploadedIdsRef.current,
			},
		});

		uploadedIdsRef.current = uploadedIdsRef.current.filter((uploadedId) => uploadedId !== id);

		if (visibleCropAttachment) {
			setVisibleCropAttachment((prevState) => !prevState);
		}
	};

	const openCrop = (attachment?: AttachmentFullFragment) => {
		if (visibleCropAttachment) {
			setVisibleCropAttachment((prevState) => !prevState);
		}
		setCropAttachment(attachment);
	};

	useEffect(() => {
		if (openedCropAttachment || _.isEmpty(cropAttachmentQueue)) {
			return;
		}

		setCropAttachmentQueue((prevState) => {
			const nextCrop = prevState[0];
			const newState = prevState.slice(1);
			setOpenedCropAttachment(nextCrop);
			prevVideoQueueIdsRef.current.push(nextCrop.Id);
			return newState;
		});
	}, [cropAttachmentQueue, openedCropAttachment]);

	const attachmentsToCrop = useMemo(
		() =>
			attachments.filter(
				(attachment) =>
					attachment &&
					(login?.Role === ELoginRole.User ? attachment.LoginId === login?.Id : true) &&
					attachment.FileType === EFileType.Video &&
					attachment.Metadata?.Height &&
					attachment.Metadata.Width &&
					attachment.Metadata.Length &&
					(!checkVideoDimensionsValid(attachment.Metadata.Height, attachment.Metadata.Width) ||
						!checkVideoLengthValid(attachment.Metadata.Length)) &&
					!videoTasksData?.getManyVideoConvertTasks?.some((task) => task.Id === attachment.Id),
			) || [],
		[attachments, videoTasksData?.getManyVideoConvertTasks, login],
	);

	useEffect(() => {
		if (
			simple ||
			!visibleCropAttachment ||
			!attachmentsToCrop.length ||
			!openedCropAttachment ||
			!prevVideoQueueIdsRef.current.length
		) {
			return;
		}

		const newAttachmentsToQueue = attachmentsToCrop.filter(
			({ Id }) =>
				cropAttachmentQueue.every((attInQueue) => attInQueue.Id !== Id) &&
				prevVideoQueueIdsRef.current.every((prevVideoId) => prevVideoId !== Id),
		);
		if (!newAttachmentsToQueue.length) {
			return;
		}

		setCropAttachmentQueue((prev) => {
			const presentAttachments = prev.filter(({ Id }) => attachmentsToCrop.some((a) => a.Id === Id));
			return [...presentAttachments, ...newAttachmentsToQueue];
		});
	}, [attachmentsToCrop, cropAttachmentQueue, openedCropAttachment, simple, visibleCropAttachment]);

	useEffect(() => {
		return () => {
			if (prevVideoQueueIdsRef.current.length > 0) {
				prevVideoQueueIdsRef.current = [];
			}
		};
	}, []);

	const attachmentsMetrics = useMemo(() => {
		if (!requestMetrics) {
			return;
		}
		const metricsMap: Map<string, RequestMetricsFullFragment> = new Map();
		for (const metric of requestMetrics) {
			if (metric.AttachmentId) {
				metricsMap.set(metric.AttachmentId, metric);
			}
		}
		return metricsMap;
	}, [requestMetrics]);

	const clickCropAttachments = () => {
		setVisibleCropAttachment(true);
		setCropAttachmentQueue(attachmentsToCrop);
	};

	const itemsCount = attachments.length + loadingVideoAttachments.size;
	const placeholderClasses = classNames("gallery-placeholder", { vertical: verticalItems });

	if (itemsCount === 0 && readonly) {
		if (simple) {
			return (
				<div className="icon-placeholder">
					<i className="icon icon-puzzle" />
					<Typography align="center" variant="caption">
						{strings.iconEmpty}
					</Typography>
				</div>
			);
		}
		return (
			<Hidden smDown>
				<div className={placeholderClasses}>
					<Typography align="center">{strings.videoEmpty}</Typography>
				</div>
			</Hidden>
		);
	}
	const maxFileSize =
		(props.maxFileSize && Math.min(sharedConfig.attachments.maxFileSize, props.maxFileSize)) ||
		sharedConfig.attachments.maxFileSize;

	const requirementsMessage = [`${strings.costRangeEnd} ${humanFileSize(maxFileSize)}`];
	additionalRequirementsText && requirementsMessage.push(strings[additionalRequirementsText]);

	const getDropRejectMessage = (rejectedFile: File, acceptedFiles: string[], maxFileSize: number) => {
		let message: string[] = [];
		const rejectedDividedType = rejectedFile.type.split("/");
		const hasSameFileType = acceptedFiles.some((itemType) => {
			const acceptedDividedType = itemType.split("/");
			const isSameType = acceptedDividedType[0] === rejectedDividedType[0];

			if (acceptedDividedType[1] === "*") {
				return isSameType;
			}
			return isSameType && acceptedDividedType[1] === rejectedDividedType[1];
		});

		if (!hasSameFileType) {
			message.push(strings.addFileErrorType);
		}
		if (rejectedFile.size > maxFileSize) {
			message.push(
				strings.standartFormat(strings.addFileErrorSize, {
					fSize: humanFileSize(rejectedFile.size),
					maxSize: humanFileSize(maxFileSize),
				}),
			);
		}
		return message.join(". ");
	};

	const getFileLimitExceedMessage = () => {
		return strings.standartFormat(strings.addFileErrorLimit, {
			maxFiles: sharedConfig.request.maxVideos.toString(),
		});
	};

	const galleryClasses = classNames("gallery", { simple, vertical: verticalItems });
	const listItemDropzoneClasses = classNames("MuiListItem-root", "MuiListItem-gutters", {
		"Mui-error": !!errorMessage,
		simpleDropzone: simple,
		disabledDropzone: disabled,
	});

	return (
		<Box className={galleryClasses}>
			{label && (
				<InputLabel required={required} className="label-text">
					{maxItems ? strings.standartFormat(strings[label], { max: maxItems.toString() }) : strings[label]}
				</InputLabel>
			)}
			{!!additionalRequirementsText && (
				<Box className="requirements-message" display="flex" alignItems="center">
					<i className="icon icon-info1" />
					<Typography variant="body2" color="secondary">
						{`${strings.addFileRequirements}: ${requirementsMessage.join(", ")}.`}
					</Typography>
				</Box>
			)}
			<List>
				{attachments.map((attachment) => (
					<GalleryAttachmentItem
						disabled={disabled || disableItemsActions}
						simple={simple}
						key={attachment.Id}
						attachment={attachment}
						afterDelete={afterDelete}
						openFull={openFull}
						openCrop={openCrop}
						readonly={readonly || (login?.Role === ELoginRole.User && attachment.LoginId !== login?.Id)}
						hasDelete={hasDelete}
						task={videoTasksData?.getManyVideoConvertTasks?.find((item) => item.Id === attachment.Id)}
						attachmentMetrics={attachmentsMetrics?.get(attachment.Id)}
						hasCreateAd={hasCreateAd}
						request={request}
					/>
				))}
				{Array.from(loadingVideoAttachments.entries()).map(([attachment, st]) => (
					<GalleryAttachmentItem
						key={attachment}
						simple={simple}
						readonly={readonly}
						progress={st.progress}
						fileName={st.fileName}
						onAbortClick={st.abort}
					/>
				))}
				{!readonly && (!maxItems || itemsCount < maxItems) && (
					<DropzoneAreaBase
						fileObjects={[]}
						classes={{ root: listItemDropzoneClasses }}
						acceptedFiles={acceptedFiles}
						dropzoneText={strings[addFileTip || "addFile"]}
						clearOnUnmount
						onAdd={(files) => {
							setErrorMessage(undefined);
							files.forEach((f) => uploadFile(f.file));
						}}
						filesLimit={maxItems ? maxItems - itemsCount : 1}
						maxFileSize={maxFileSize}
						showAlerts={false}
						showPreviewsInDropzone={false}
						Icon={UploadIcon as any}
						onAlert={setMessage}
						getFileLimitExceedMessage={getFileLimitExceedMessage}
						getDropRejectMessage={getDropRejectMessage}
						dropzoneProps={{
							disabled,
						}}
						disableRejectionFeedback
					/>
				)}
			</List>
			{!readonly && errorMessage && (
				<Typography color="error" variant="caption">
					{errorMessage}
				</Typography>
			)}
			{!readonly && !!props.errorMessage && (
				<Typography color="error" variant="caption">
					{props.errorMessage}
				</Typography>
			)}
			{!readonly && !_.isEmpty(attachmentsToCrop) && (
				<Box marginTop="20px" display="flex" justifyContent="center">
					<StandartButton
						disabled={disabled}
						className="button-width-medium"
						color="warning"
						onClick={clickCropAttachments}
					>
						{strings.request_cropVideoButton}
					</StandartButton>
				</Box>
			)}
			<GalleryPlayerDialog
				attachment={openedAttachment}
				onClose={closeFull}
				open={!!openedAttachment}
				afterUpsert={refetch}
				defaultGenre={request?.Genre}
			/>
			<GalleryCropDialog
				refetchAttachments={() => {
					onProcessingTasksVideo?.(true);
					getVideoTasks({ variables: { id: uploadedIdsRef.current } });
				}}
				attachment={openedCropAttachment}
				onClose={setCropAttachment}
				open={!!openedCropAttachment}
			/>
		</Box>
	);
};

import React, { useMemo } from "react";
import { RequestList, TRequestListProps, StandartButton, StandartAccordion } from "../../components";
import strings from "../../helpers/strings";
import { ELoginRole, RequestFullFragment } from "@espresso/protocol";
import { Box, Typography } from "@material-ui/core";
import useAppContext from "../../contexts/AppContext";
import { formatDeveloperName } from "../../helpers/formatDeveloperName";

type TOtherRequestsListProps = TRequestListProps & {
	request: RequestFullFragment | null | undefined;
};

const MAX_REQUESTS_PER_PAGE = 10;

export const OtherRequestsList = (props: TOtherRequestsListProps) => {
	const { request, ...listProps } = props;
	const { login } = useAppContext();

	const developerNameText = useMemo(() => formatDeveloperName(request), [request]);
	const { developerRequestsQueryInput, hiddenRequestsIds } = useMemo(() => {
		if (!request) {
			return {};
		}
		return {
			developerRequestsQueryInput: {
				DeveloperId: request.Developer.Id,
			},
			hiddenRequestsIds: [request.Id],
		};
	}, [request]);

	const developerProfileLink =
		request?.Developer.Id === login?.Developer?.Id ? "/developer/edit" : `/developer-${request?.Developer.Id}`;

	if (login?.Role !== ELoginRole.Manager && login?.Role !== ELoginRole.Publisher) {
		return null;
	}

	return (
		<StandartAccordion titleKey="request_developerInfo" className="developer-requests-accordion">
			<Box className="developer-info">
				<Box className="name-container">
					<Typography
						variant="subtitle2"
						color="textSecondary"
					>{`${strings.developer_developerNameLabel}:`}</Typography>
					{request && (
						<Typography variant="subtitle2" className="developer-name text-ellipsis">
							{developerNameText}
						</Typography>
					)}
				</Box>
				{request && (
					<Box className="actions-container">
						<a href={developerProfileLink} target="_blank" rel="noreferrer">
							<StandartButton variant="contained" color="secondary">
								{strings.developer_profileTitle}
							</StandartButton>
						</a>
					</Box>
				)}
			</Box>
			<RequestList
				maxRowsPerPage={MAX_REQUESTS_PER_PAGE}
				withTableHeader={false}
				getRequestsQueryInput={developerRequestsQueryInput}
				hiddenRequestsIds={hiddenRequestsIds}
				hideFilters
				{...listProps}
			/>
		</StandartAccordion>
	);
};

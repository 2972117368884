import React from "react";
import { StandartView } from "../components";
import { Typography } from "@material-ui/core";

export const TermsView = () => {
	return (
		<StandartView disableNotifications hideHeadMenu hideFooterMenu title="">
			<TermsViewContent />
		</StandartView>
	);
};

export const TermsViewContent = () => {
	return (
		<div className="doc-view">
			<Typography variant="h3">Dromaxco Solutions Ltd TERMS OF USE</Typography>
			<Typography variant="h5">Last updated: March 4, 2021</Typography>
			<Typography variant="body1">
				The terms of this agreement (<b>"Terms of use"</b> or <b>"Terms"</b>)govern the relationship between
				You(hereinafter“You”) andDromaxco Solutions Ltd(hereinafter <b>"Dromaxco Solutions"</b> or
				<b>"We"</b>) regarding Your use of Dromaxco Solution games and related services, which include
				applications for mobile devices and Dromaxco Solution branded websites. In this agreement, "Dromaxco
				Solution" means 13 Kypranoros str., EVI BUILDING, 2nd Floor, Office 201, 1061 Nicosia, Republic of
				Cyprus.
			</Typography>
			<ol>
				<li className="header">
					<Typography variant="h5">GENERAL PROVISIONS</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							These Terms of use shall apply for using all the games, mobile applications, game portals,
							game forums, chat rooms of the games and other game services published and/or provided by by
							Dromaxco Solution on any web-site (including but not limited to espresso-pub.com), platform
							and/or application store (hereinafter collectively referred to as the “Game Services”).
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Your use of any Game Services is governed by these Terms of use, as well as other agreements
							and terms in respect of certain Game Service (if any).
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Please note that the Game Services may offer services and software of the partners of
							Dromaxco Solution, the use of which shall be governed by separate agreements with these
							partners of Dromaxco Solution.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Regulation of Your use of Game Services designed for mobile devices displayed on the
							showcase at Dromaxco Solution is defined by documents contained in the respective
							application stores. The description of the certain Game Services in the application store
							may contain a reference to these Terms of use, which means that Your use of that Game
							Services is governed by these Terms of use.
						</Typography>
						<Typography variant="body1">
							<b>
								Please read carefully the contents of these Terms and documents referred to in
								sub-paragraphs 1.1. to 1.4.
							</b>
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Your use of the Games Services at the beginning of gameplay in any game, posting a message
							in the forum/game chat, as well as downloading any software that is offered for download on
							the website of the respective game, is a proof of your unconditional consent to the terms of
							the above mentioned documents.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							By registering at the Game Services You confirm that Your age is sufficient for such
							registration under the applicable laws and/or You have all necessary consents (e.g. parental
							consents) in full compliance with the applicable laws, including for purposes of data
							protection laws, otherwise the registration at the Game Services and using them is
							prohibited.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Main Terms and Definitions Used in this Terms ofuse</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							<b>Services</b> shall mean the projects, games and/or applications of the Website
							Administration or the Website Administration Partner (Service of the Administration Partner)
							posted on the Website and access to which is provided through the Website, the mobile
							version of the Website, and certain applications or the websites of the Administration
							Partners.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Website/Services Access</b> shall mean the access to the functional options of the
							Website and certain Services of the Website which the User obtains after registration
							procedure (authorization on the Website/Service). The Website as well as any Website Service
							shall be accessed with a login and password.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Website/Service Administration</b> (the <b>"Administration"</b>) shall mean Dromaxco
							Solution registered at: 13 Kypranoros str., EVI BUILDING, 2nd Floor, Office 201, 1061
							Nicosia, Republic of Cyprus.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Administration Partner</b> shall mean the individual (individual entrepreneur) or legal
							entity developing or operating the project, game and/or application (Administration Partner
							Service). Relations between the User and the Administration Partner occurring in the course
							of using the Administration Partner Service by the User shall be subject to regulation by
							separate agreements.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Website/Service User</b> (the <b>"User"</b>) shall mean a person who has reached a
							certain age which allows, in accordance with the applicable laws, being fully liable for
							his/her own actions (fully capable) and having obtained the Website/Service access by
							registration and agreed to other agreements and rules regulating the procedure for using of
							certain Services.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>User's Account</b> (the <b>"Account"</b>) shall mean the User's profile created when
							registering the User with the Website/creating the User's accounting record, which allows
							the User to obtain access to the Website and/or its Service and identifies him/her as a
							unique User of the Website. Under a certain Service there may be required creation of a
							separate account. The procedure for creation thereof is regulated by the agreements for the
							relevant Services.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Virtual Currency</b> shall mean a conventional unit accrued and displayed by the
							Website/relevant Service Administration on the Personal Account of the User within the
							framework of the Website or Service as a result of its acquisition (in accordance with the
							internal rate of the Website), the making by the User of certain actions or obtained by the
							latter within the framework of implementation by the Website/Service of the programs focused
							on attraction (creation of interest) of the users to the Website and/or its certain
							Services.
						</Typography>
						<Typography variant="body1">
							Virtual currency may not be a means of payment orintended for settlements with third
							parties. Use of the virtual currency is possible only within the framework of the Website or
							the Servicesthe User acquires them in, for the purpose of theUser obtaining an Additional
							Function Set orexchange to other intangible (virtual) values providedfor by the function set
							of the relevantService.
						</Typography>
						<Typography variant="body1">
							Due to its immaterial nature, limited ways of useand conditions of provision, the virtual
							currencydoes not have reverse money value and shall not besubject to reconversion into
							monetary unitsand return.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Additional Function Set</b> shall mean additional functional(software) options of
							theWebsite/Services, the right to use of which may beprovided to the User under the
							conditions ofseparate agreements to the Services, for consideration,the amount and
							conditions of which aredetermined in the agreements for certain Servicesand on the pages of
							the relevant sections ofsuch Services, as well as other conditions determinedby the
							Administration.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							<b>Personal Account</b> shall mean the display to the User(available only to a certain User)
							ofhis/her current virtual account state in the privatesector of the interface of the
							Website/relevantService - the quantity of the virtual currency ofthe Website/Service accrued
							by theAdministration in accordance with the internal rateof the Website/relevant Service.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">LIMITED LICENSE</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							The Game Services shall be used in accordance with the terms and agreements outlined on the
							web pages/websites dedicated to the relevant Game Services (hereinafter the{" "}
							<b>“Special conditions”</b>). If there are no Special conditions governing the use of a
							Game, the provisions of these Terms of use shall be applied
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							From the moment of registration of a Main Account or downloading the certain part of the
							Game Services, depending on what happened earlier, Dromaxco Solution grants You a
							non-exclusive, non-transferable to third parties, limited license to use the Game Services,
							including all subsequent amendments and improvements (if any) for personal non-commercial
							purposes by obtaining access to the published by Dromaxco Solution, as well as downloading
							and installing the client portion of the Game Services (if any).
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Subject to compliance with the terms and conditions of these Terms, You are entitled to:
							<ul>
								<li>
									<Typography variant="body1">
										participate in the gameplay, create a game character (depending on the Game
										Services) purchase in-game currency, exchange in-game currency for in-game
										valuables, and subscribe to the Game Services;
									</Typography>
								</li>
								<li>
									<Typography variant="body1">
										reproduce the client portion of the Game Services in the memory of Your device
										by downloading and installing it on Your device in order to participate in the
										Game Services.
									</Typography>
								</li>
								<li>
									<Typography variant="body1">
										stream, record and publish video content of the gameplay of the Game Services
										for commercial and non-commercial reasons. For avoidance of doubt, You are
										entitled to include advertisements in such streaming/recorded video content and
										otherwise monetize such video content by means allowed by corresponding
										streaming or video sharing websites.
									</Typography>
								</li>
							</ul>
						</Typography>
					</li>
					<li>
						<Typography variant="body1">You are not permitted to:</Typography>
						<ul>
							<li>
								<Typography variant="body1">
									modify, adapt, decompile, disassemble, change the Game Services or any of its
									components;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									distribute for commercial or non-commercial purposes the client portion of the Game
									Services or its copies, either through the distribution of physical media with it,
									or through its posting on the Internet for downloading by certain persons or the
									general public;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									translate the Game Services into other languages, or create other derivative works
									from the Game Services;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									distribute for commercial purposes any element of the Game Services, its copies or
									screenshots;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									attempt to circumvent any security measures adopted in the Game Services, including
									blocking access by IP-address;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									try to obtain the in-game currency, and/or in-game valuables fraudulently or in
									other ways not provided for by the usual gameplay process and conditions of
									purchasing the in-game currency and exchanging in-game currency for in-game
									valuables;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									distribute for commercial or non-commercial purposes the in-game currency and/or
									in-game valuables outside the framework of the gameplay process, transfer the right
									to use the Game Services for commercial or non-commercial purposes to third parties,
									including through the transfer of a game character or a login and password required
									to access the Main Account;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									use the Game Services in other ways, not provided for by these Terms and beyond the
									usual gameplay process.
								</Typography>
							</li>
						</ul>
					</li>
					<li>
						<Typography variant="body1">
							All rights not expressly granted to You under these Terms shall be reserved by Dromaxco
							Solution.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">GAME CURRENCY USE. GAME VALUABLESCONSUMPTION</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							If You wish, You can purchase proposed in the Game currency (in-game currency), which can be
							used by You solely under the conditions, specified below. All fees payable for in-game
							currency are non-refundable.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							The in-game currency, although it has a price at the time of purchase, has no monetary value
							and is not subject to monetary evaluation, is not a means of payment and serves a sole
							purpose so that You can exchange it for consumed in-game valuables. The in-game currency
							cannot be exchanged for cash or other valuables, except for in-game valuables in the usual
							course of the game process, even if it was not used by You while You had the opportunity.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							In-game currency is purchased by You by paying for the appropriate amount of the in-game
							currency by one of the payment methods, provided for each respective Game Services/on the
							corresponding website of the Game Services. The charge of the in-game currency to Your
							in-game account shall be made as soon as possible, however, due to circumstances beyond the
							control of Dromaxco Solution, there may be delays in the provision of payment information
							systems about Your payments. Please note that Dromaxco Solution will not credit the in-game
							currency to Your in-game account until Dromaxco Solution has received a confirmation of
							receipt of Your payment. In case of no crediting of Game currency within 24 hours from the
							time of payment, please contact our customer service –&nbsp;
							<a className="link" href="mailto://stores@espresso-pub.com">
								<i className="icon icon-link" />
								<b>stores@espresso-pub.com</b>
							</a>
							. The moment of crediting the in-game currency and the rights of its use in the Game
							Services by the provided ways shall be considered the moment of reflection on Your in-game
							account, established under the relevant Game Services, of the monetary inflow to the account
							of Dromaxco Solution.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You can exchange in-game currency for in-game valuables that are available at a particular
							time. And thus You acknowledge that this is the only operation You can perform with the
							in-game currency. For the purposes of the present Terms, You acknowledge that in-game
							valuables also refers to Bonus Codes that provide the possibility of obtaining bonus gifts
							in the Game Services.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							The purchase of in-game currency and the exchange of in-game currency for in-game valuables
							shall be made by You at Your own risk. Dromaxco Solution do not guarantee, that (i) the
							in-game valuables desired by You will be available at the time of crediting the in-game
							currency to Your in-game account, (ii) You can use the in-game valuables for an indefinite
							or desired period, (iii) You can exchange in-game currency for specific or any in-game
							valuables for an indefinite or desired period , (iv) the characteristics or the intended use
							of the in-game valuables will be unchanged for the duration of the operation or the Game
							Services or will meet Your expectations or views.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							By purchasing in-game currency, as well as exchanging in-game currency for in-game
							valuables, You realize that (i) Your access to the Game Services may be terminated according
							to these Terms, and/or (ii) the Game Services may cease to exist at any time for any reason,
							and this does not give You reason to require from Dromaxco Solution refund of the fees paid
							for the in-game currency.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							The moment that the in-game valuables are allotted is the moment that the in-game currency
							is credited to and reflected in the in-game account.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You are prohibited to sublicense, sell or attempt to sell the in-game valuables for money,
							or exchange the in-game valuables for value of any kind outside of the Game Services. Any
							such transfer or attempted transfer is prohibited and void, and will subject Your access to
							the Game Services to termination.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">CODE OF CONDUCT</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							Using the Game Services, You agree to observe this Code of conduct.
						</Typography>
						<Typography variant="body1">
							(A) Conditions of virtual communication with other users in the game forums, in game chats
						</Typography>
						<Typography variant="body1">
							When placing any materials, data, comments, messages, information, as well as links to them
							(hereinafter in this section the "Content") in forums, chats, other virtual spaces designed
							for communication, You agree not to:
						</Typography>
						<ul>
							<li>
								<Typography variant="body1">
									transfer or publish erotic or pornographic Content,
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									transfer or publish any Content that, in the opinion of Dromaxco Solution, is
									offensive to other users and can equally do harm to business reputation or moral
									harm to third parties,
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									transfer or publish illegal, harmful, dangerous, or obscene Content, as well as
									Content that contains information discriminating against other persons on the basis
									of gender, race, nationality, religious ideologies, sexual orientation and other
									grounds,
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									transfer or publish the Content directed on advertising or attracting attention to
									any goods and services,
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									transfer or publish the Content that contains personal information or confidential
									data of any person,
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									use "bots" or other automated techniques for collection of information about users,
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									disturb, threaten, pursue, confuse or cause stress, impose communication, attention
									or otherwise cause discomfort to other users.
								</Typography>
							</li>
						</ul>
						<Typography variant="body1">
							(B) Terms of choosing the name of the game character, the name of the clan, group, user name
							at the game forum
						</Typography>
						<Typography variant="body1">
							The Users are prohibited from using as the name of the game character, name of the game clan
							or other group, as well as the name of the User at the forum the following designations:
						</Typography>
						<ul>
							<li>
								<Typography variant="body1">
									offensive or coarse terms, inciting ethnic discord and discrimination, words and
									phrases which include curses and expletives in any language, executed with letters
									of any alphabet;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									proper names and other words and phrases used in religions or cults, which may cause
									insult of the feelings of believers (use in the name of the game clans and the game
									groups such common religious concepts (except for proper names) as "paradise",
									"hell", "angel", "devil", "voodoo", etc. is not prohibited. For example, the clan
									name of the group like "Team of devils" or "Voodoo dolls" corresponds to these
									Terms);
								</Typography>
							</li>
							<li>
								<Typography variant="body1">names of historical figures and politicians;</Typography>
							</li>
							<li>
								<Typography variant="body1">
									words and phrases that are directly or indirectly related to drugs, ways of their
									preparation, use and acquisition;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									words and phrases which can mislead other users as to that the user, registered
									under such name, is a representative of Dromaxco Solution or otherwise has direct or
									indirect relation to it, or has any rights of administration of the Game Services;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">unpronounceable letter combinations;</Typography>
							</li>
							<li>
								<Typography variant="body1">
									words and phrases that contain advertisement of goods and services, including any
									domain names and trademarks;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									words and phrases that violate the rights of third parties (including but not
									limited to intellectual property rights) or the requirements of applicable law.
								</Typography>
							</li>
						</ul>
						<Typography variant="body1">(C) Terms of the Game Services</Typography>
						<Typography variant="body1">
							Terms of the Game Services that determine an order of participation in the game process,
							interaction with the computer (PvE) or real players (PvP), may be set for each concrete Game
							Services on the page, devoted to the corresponding Game Services.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">LIABILITY FOR VIOLATION OF THESE TERMS OF USE</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							Unless other is established by the Terms of the Game Services, Dromaxco Solution shall have
							the right to apply to You, in case of violation of these Terms and/or Terms of the Game
							Services, the following types of sanction depending on the extent of the violation committed
							by You on the game process and other users:
						</Typography>
						<ul>
							<li>
								<Typography variant="body1">
									issuance of a warning in any form, including via e-mail;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									suspension of access to the Game Services for a definite or indefinite term;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									nrestriction of use of the game chats and forums for a definite or indefinite term;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									restriction of a part of the game functionality or possibilities of the game
									character for a definite or indefinite term;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									forced renaming of the game character, community of users or the game object;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									withdrawal of the in-game currency and/or the in-game valuables;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									restriction, suspension or termination of access to Your game character;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									a change in the values of achievements and parameters of development of Your game
									character;
								</Typography>
							</li>{" "}
							<li>
								<Typography variant="body1">
									removal of the Game character without possibility of restoration.
								</Typography>
							</li>
						</ul>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">WARRANTY DISCLAIMER</Typography>
					<Typography variant="body1">
						UNLESS OTHERWISE EXPRESSLY PROVIDED BY THE SPECIAL AGREEMENT, REGULATING USE OF A PARTICULAR
						GAME SERVICE, THE GAME SERVICES WILL FUNCTION BY THE PRINCIPLE "AS IS", WHICH MEANS WITHOUT
						QUALITY WARRANTIES AND SUITABILITY FOR ANY OBVIOUS OR IMPLIED PURPOSES. TO THE MAXIMUM EXTENT
						ALLOWED BY APPLICABLE LAW, DROMAXCO SOLUTION HEREBY WAIVES ANY EXPRESSED OR IMPLIED WARRANTIES
						THAT THE GAME SERVICES WILL BE UNINTERRUPTED OR ERROR-FREE, WILL CORRESPOND TO INDIVIDUAL
						REPRESENTATIONS AND EXPECTATIONS OF USERS. THUS, DROMAXCO SOLUTION WILL TAKE ALL WELL GROUNDED
						COMMERCIALLY REASONABLE MEASURES FOR ENSURING OPERABILITY OF THE DROMAXCO SOLUTION GAME
						SERVICES.&nbsp;
						<a href="https://my.com" target="_blank" rel="noreferrer">
							<i className="icon icon-link" />
							<b>MY.COM</b>
						</a>
						&nbsp;SHALL HAVE THE RIGHT (AND IT IS AN ESSENTIAL CONDITION OF YOUR USE OF THE DROMAXCO
						SOLUTION GAME SERVICES) AT ANY TIME TO SUSPEND OR STOP FUNCTIONING AND/OR ACCESS TO THE DROMAXCO
						SOLUTION GAME SERVICES, FOR ALL OR SOME USERS. TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW,
						DROMAXCO SOLUTION DISCLAIMS ANY OBVIOUS OR IMPLIED WARRANTIES OF ACCURACY, RELEVANCE,
						HARMLESSNESS, ABSENCE OF VIRUSES, FAULTLESSNESS, LEGALITY AND/OR RELIABILITY OF INFORMATION,
						DATA, MATERIALS, AS WELL AS GAMES PROVIDED BY DROMAXCO SOLUTION, OTHER USERS OF DROMAXCO
						SOLUTION. DROMAXCO SOLUTION DOES NOT WARRANT THAT CHARACTERISTICS OF YOUR PERSONAL COMPUTER OR
						OTHER DEVICE ARE SUFFICIENT TO USE DROMAXCO SOLUTION GAME SERVICES. PLEASE LEARN IN ADVANCE THE
						SYSTEM REQUIREMENTS SHOWN IN PARTICULAR GAME SERVICES TO YOUR COMPUTER DEVICE.
					</Typography>
				</li>
				<li className="header">
					<Typography variant="h5">LIMITATION OF LIABILITY</Typography>
					<Typography variant="body1">
						TO THE MAXIMUM EXTENT ALLOWED BY APPLICABLE LAW, DROMAXCO SOLUTION SHALL NOT BE LIABLE FOR
						DIRECT OR INDIRECT DAMAGES, LOST PROFIT, LOSS AND/OR DESTRUCTION OF DATA, LOSS/TERMINATION/,
						LOSS BY THE USER OF ACHIEVEMENTS OR PROGRESS IN THE GAME, LOSS/DESTRUCTION/CHANGE OF THE
						INFORMATION, PLACED BY THE USER AT FORUMS OR CHATS OF THE GAME. IN ANY CASE THE LIABILITY OF
						DROMAXCO SOLUTION TO YOU IS LIMITED TO COMPENSATION OF REAL DAMAGE, WHICH WILL NOT EXCEED 100
						EUROS. LOST PROFIT SHALL NOT BE COMPENSATED TO YOU UNDER ANY CIRCUMSTANCES. DROMAXCO SOLUTION
						SHALL NOT BE RESPONSIBLE FOR DIRECT OR INDIRECT LOSS TO YOU OR OTHER THIRD PARTIES CAUSED AS A
						RESULT OF (A) USE OR IMPOSSIBILITY OF USE OF ANY SERVICES AND/OR GAMES OF DROMAXCO SOLUTION, (B)
						UNAUTHORIZED ACCESS OF ANY THIRD PARTIES TO PERSONAL INFORMATION OF THE USER, YOUR INTRA GAME
						ACCOUNT IN THE GAME, AND (C) STATEMENTS OR BEHAVIOR OF ANY THIRD PARTY IN THE GAME, AT FORUMS,
						IN GAME CHATS, IN COMMENTS ON THE GAMES. DROMAXCO SOLUTION WEBSITE. DROMAXCO SOLUTION SHALL NOT
						BE LIABLE FOR ANY DELAY OR FAILURE TO PERFORM RESULTING FROM CAUSES OUTSIDE THE REASONABLE
						CONTROL OF DROMAXCO SOLUTION, INCLUDING WITHOUT LIMITATION ANY FAILURE TO PERFORM HEREUNDER DUE
						TO UNFORESEEN CIRCUMSTANCES OR CAUSE BEYOND DROMAXCO SOLUTION’S CONTROL SUCH AS ACTS OF GOD,
						WAR, TERRORISM, RIOTS, EMBARGOES, ACTS OF CIVIL OR MILITARY AUTHORITIES, FIRE, FLOODS,
						ACCIDENTS, NETWORK INFRASTRUCTURE FAILURES, STRIKES, OR SHORTAGES OF TRANSPORTATION FACILITIES,
						FUEL, ENERGY, LABOR OR MATERIALS.
					</Typography>
				</li>
				<li className="header">
					<Typography variant="h5">DATA AND INFORMATION SECURITY</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							You provide Dromaxco Solutions data at the creation as well as any other information placed
							in the Game Service at Your own risk. Though Dromaxco Solutions undertakes all efforts for
							ensuring confidentiality of those data which are not intended for public viewing, it is
							necessary to consider that no security measures are perfect and they can be bypassed.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Dromaxco Solutions cannot control the actions of other users with whom You want to share
							Your profile and information. Therefore Dromaxco Solutions cannot warrant that any content
							that You post on the Games Services will not be available for viewing by unauthorized
							persons.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Dromaxco Solutions shall not be responsible for any detours undertaken on the Dromaxco
							Solutions Games websites and in the Games of security measures.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You understand and acknowledge that, even after removal of data and content
							provided/arranged by You, these data or content may remain visible in the cache or web
							archives, as well as in the results of search engines, and may also be available to other
							persons, if other users have copied and stored Your data or content.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You hereby acknowledge that all comments, messages, information, data, content, placed by
							You in the Game Services are likely to be available for viewing, copying by third parties.
							You are responsible for the texts, files, images, photos, videos, sounds, works of music,
							copyrighted works and other material and information etc. placed and published by You on the
							net (hereinafter referred to as "published information"), that You make available in the
							Game Services or exchange with other users. We neither appropriate these contents nor do we
							agree to them. We have no control over the information placed in the Game Services by users.
							We do not check these contents before they are published. Insofar as we have knowledge of
							illegal contents, or receive such knowledge, we will delete it immediately. We do not accept
							any liability for these contents, nor, in particular, do we accept liability for
							correctness, completeness or integrity.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">MISCELLANEOUS</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							Dromaxco Solutions reserves the right to assign its rights and obligations arising out of
							these Terms to any third party at its discretion, by notifying You by posting the new
							edition of these Terms. Your rights and obligations arising out of these Terms shall be
							considered transferred to the assignee of Dromaxco Solutions at the moment of the respective
							notification.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Your rights and responsibilities are personal and non- transferable.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Dromaxco Solutions has the right at any time at its discretion without further notice to You
							to change the functionality and the content of the Game services.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Any claim You may have to Dromaxco Solutions in connection with the use of Game Services
							shall be made within one year from the moment You knew or should have known about the
							violation of Your rights; otherwise presenting a claim is prohibited.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							In order to improve its services, Dromaxco Solutions reserves its right to make in the Games
							automatically (without an additional request for Your consent) updates and changes. In order
							to ensure the effectiveness of these updates and changes, and the possibility of extending
							the use of the Games, You hereby consent to the introduction of automatic updates and
							changes.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You hereby acknowledge that all rights to intellectual property to the Game Services belong
							to Dromaxco Solutions and its licensors.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Dromaxco Solutions reserves the right at any time, without notice to You and without stating
							any reasons, to suspend access to and the possibility to use the Game Services without
							compensation to You of any costs, losses or remuneration, including in the case of any,
							including single, violation of the terms of these Terms, the Terms of the Game Services or
							other applicable terms and agreements.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Both parties have an ordinary right to terminate these Terms of use at any time with
							immediate effect. Ordinary termination does not require any reasons to be given. In
							particular, but not exclusively, we have the right to terminate these Terms of use with You,
							if You violates laws, or breaching these Terms of use, Code of Conduct, the particular Game
							Services rules / Game Services for a period of four weeks.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You have the right at any time without notice to Dromaxco Solutions and without explanation
							to terminate the present Terms in unilateral extrajudicial order by discontinuing the use of
							the Account.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							In the event that You, in accordance with the laws of Your state, are prohibited to use
							sections or services of the espresso-pub.com website, computer online games, or there are
							other legal restrictions, including restrictions on the age of admission to such software,
							You do not have the right to use the Game Services. In such a case, You will be solely
							responsible for the use of the Game Services or the relevant sections and services of the
							espresso-pub.com website on the territory of Your state in violation of the local
							legislation
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							These Terms shall be governed and construed by the law of England and Wales. All disputes
							arising in connection with these Terms are subject to mandatory pre-trial settlement by the
							Parties, and in case of failure to reach agreement during the pre-trial settlement of the
							dispute – in the court having jurisdiction at the location of Dromaxco Solutions.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							These Terms can be modified by Dromaxco Solutions without any prior notice. Any changes to
							these Terms, implemented by Dromaxco Solutions unilaterally, shall enter into force on the
							day following the date of publication of such changes on the website at &nbsp;
							<a
								className="link"
								href="https://espresso-pub.com/terms-of-use"
								target="_blank"
								rel="noreferrer"
							>
								<i className="icon icon-link" />
								<b>https://espresso-pub.com/terms-of-use</b>
							</a>
							. You agree to independently verify these Terms for changes. Failure by You to familiarize
							yourself with these Terms and/or the modified version of the Terms is not a reason for
							non-performance by You of Your obligations and failure by You to comply with the
							restrictions imposed by these Terms.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You hereby understand, accept and agree that any Game may contain audio and / or video
							effects, which under certain circumstances can cause in people who are prone to epileptic or
							other disorders of nervous character, worsening of these conditions, and You hereby
							guarantee that You do not suffer such disorders, or You will not use a Game. You accept and
							agree that regular long-term (continuous) usage of the personal computer can cause various
							complications of physical condition, including sight easing, scoliosis, various forms of
							neuroses and other negative effects on the body. You hereby warrant that You will use any
							Game only for a reasonable time, with breaks for rest and that You will take other measures
							prescribed by Your doctor.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							The invalidity of one or more provisions of these Terms, recognized in the established
							order, which came into force by a court decision, does not entail for the parties the
							invalidity of the Terms as a whole. In the event that one or more provisions of the Terms in
							due course become invalid, the Parties agree to fulfill the obligations of these Terms as
							closely as possible to those implied by the parties at the conclusion and/or agreeing of
							changes in the Terms.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							On matters related to the execution of the Terms of Use, please contact the Licensor at its
							location: 13 Kypranoros str., EVI BUILDING, 2nd Floor, Office 201, 1061 Nicosia, Republic of
							Cyprus, or at email stores@espresso-pub.com. Only the English version of this document has
							legal effect. Any translations of this document to other languages are provided for your
							convenience only.
						</Typography>
					</li>
				</ol>
			</ol>
		</div>
	);
};

import React, { useEffect } from "react";
import { FullScreenPlaceholder, StandartView } from "../../components";
import { UpsertDeveloperForm } from "./UpsertDeveloperForm";
import { Grid } from "@material-ui/core";
import { useGetMyDeveloperLazyQuery, useGetOneDeveloperLazyQuery } from "@espresso/protocol";
import useAppContext from "../../contexts/AppContext";
import { useParams } from "react-router-dom";
import withAuth from "../../helpers/withAuth";
import { useLoadingSignal } from "contexts/LoadingContext";

interface IDeveloperParams {
	developerId: string;
}

export const DeveloperEdit = withAuth(() => {
	const params = useParams<IDeveloperParams>();
	const { login } = useAppContext();

	const [getMyDeveloper, { data: dataMyDeveloper, loading: loadingMyDeveloper }] = useGetMyDeveloperLazyQuery();
	useLoadingSignal(loadingMyDeveloper);

	const [getDeveloper, { data: dataDeveloper, loading: loadingDeveloper }] = useGetOneDeveloperLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	useLoadingSignal(loadingDeveloper);

	useEffect(() => {
		if (params.developerId) {
			getDeveloper({ variables: { id: params.developerId } });
			return;
		}
		getMyDeveloper();
	}, [getDeveloper, getMyDeveloper, login, params.developerId]);

	const developer = dataMyDeveloper?.getMyDeveloper || dataDeveloper?.getOneDeveloper;

	if (!developer && (loadingMyDeveloper || loadingDeveloper)) {
		return (
			<StandartView disableNotifications title="">
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="developer_emptyTitle" messageKey="developer_emptyMessage" />
				</Grid>
			</StandartView>
		);
	}

	return (
		<StandartView disableNotifications title="">
			<UpsertDeveloperForm developer={developer} />
		</StandartView>
	);
});

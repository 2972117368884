import React from "react";
import { StandartView } from "../components";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

const tableContent = [
	{
		info: (
			<Typography variant="body1">
				Data you provide for registering in the Services including your email and/or mobile phone number
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to manage and administer the Services provided to you. We use this
					data to enable us to fulfill our obligations to you as part of the Services (e.g. in cases where you
					request restoration of your account).
				</Typography>
				<Typography variant="body1">See section 8.3 of this Privacy Policy for more information.</Typography>
				<Typography variant="body1">
					We also use this information in order to provide you with updates and information on our and
					selected third parties' products and services we think you may be interested in.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
				<Typography variant="body1">Performance of our contract with you</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Where necessary, a copy of your identity or another document containing your name, surname, photograph,
				number of the principal identity document of you or your representative, payment details and another
				additional data, with that you may provide us, including through our support service.We may take
				additional verification steps where we consider reasonable in order to verify your account.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this data in order to identify you, verify your account and prevent abuse and infringements
					of your or other persons' rights.For example, we use this information to verify your identity if you
					lose your credentials and wish to access your account with us.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Additional data you provide on a dedicated Services profile page via including your first and last name,
				nickname, gender, date of birth, avatar picture and language preferences.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to provide our Services to you, to manage and administer Services
					and as additional information to verify your account to prevent abuse and infringements of your or
					other persons' rights.
				</Typography>
				<Typography variant="body1">
					We also use this information in order to provide you with updates and information on our and
					selected third parties' products and services we think you may be interested in.We use this
					information in order to tailor and improve the adverts that are presented on the Site and measure
					the effectiveness of these advertisements.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
				<Typography variant="body1">Performance of our contract with you</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Additional data received when you access the Services, including information regarding technical
				devices, technical interaction with the Service such as your IP-address, time of registration in the
				Service, device ids, country and language settings, device model and operating system used, your
				installed apps, type of browser, your Internet provider and/or phone network operator, network type,
				screen resolution, RAM size and your browsing behavior.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use your data for internal review in order to constantly improve the content of our Services and
					web pages, optimizing your user experience, to understand any errors you may encounter when using
					the Services, to notify you of changes to the Services and to personalise the use of our Services.We
					use this information in order to tailor and improve the adverts that are presented on the Site and
					measure the effectiveness of these advertisements.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Information that is automatically received at the time of access to the Services with the use of
				cookies.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					Please see our cookies policy which sets out the types of cookies we use and what we use these
					cookies for. We use this information in order to tailor and improve the adverts that are presented
					on the Site and measure the effectiveness of these advertisements.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Consent</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Information that is created by you while using the Services (including information that you post in any
				game forums and/or chat rooms). Depending on the place of posting (chat room or forum) this information
				can be available to some or all other users of our Services.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to manage and administer the Services including providing our
					services to you.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">
					Legitimate interests, which inter alia, include the processing of manifestly made public by you data
				</Typography>
				<Typography variant="body1">Performance of our contract with you</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Information that is created by you while placing requests to our Services support.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to verify your identity and to fulfil your support request.
				</Typography>
				<Typography variant="body1">
					We may also use this data in order to investigate any complaints on your behalf and to provide you
					with a more efficient service.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
				<Typography variant="body1">Performance of our contract with you</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Information that is received as the result of your behavioral actions when using the Services (including
				your game actions and achievements, badges). This information can be available to other users of our
				Services (e.g. on Leaderboards).
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to manage and administer the Services including providing our
					Services to you.
				</Typography>
				<Typography variant="body1">
					We may also use this data in order to tailor and improve the adverts that are presented to you and
					measure the Legitimate interests effectiveness of these advertisements.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Information that is received about you as the result of other users’ actions on the Services (in
				particular, information posted in chat rooms and forums by other users).
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to manage and administer the Services including providing our
					Services to you.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
			</>
		),
	},
	{
		info: (
			<Typography variant="body1">
				Data collected via third parties, including your social network ids, application store ids, nickname,
				email and friends list, when you register in our Services via your social or application store accounts
				and/or connect your social account to our Services.
			</Typography>
		),
		purpose: (
			<>
				<Typography variant="body1">
					We use this information in order to manage and administer the Services provided to you.
				</Typography>
				<Typography variant="body1">
					We use this information for certain social functions of our Services, such as to show you who of
					your friends play the same game as you.
				</Typography>
				<Typography variant="body1">
					We also use this information in order to provide you with updates and information on our and
					selected third parties' products and services we think you may be interested in.
				</Typography>
			</>
		),
		basis: (
			<>
				<Typography variant="body1">Legitimate interests</Typography>
				<Typography variant="body1">Performance of our contract with you</Typography>
			</>
		),
	},
];

export const PrivacyView = () => {
	return (
		<StandartView disableNotifications hideHeadMenu hideFooterMenu title="">
			<PrivacyViewContent />
		</StandartView>
	);
};

export const PrivacyViewContent = () => {
	return (
		<div className="doc-view">
			<Typography variant="h3">Dromaxco Solutions Ltd PRIVACY POLICY</Typography>
			<Typography variant="h5">Last updated: March 4, 2021</Typography>

			<ol>
				<li className="header">
					<Typography variant="h5">Who we are</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							We are Dromaxco Solutions Ltd located at 13 Kypranoros str., EVI BUILDING, 2nd Floor, Office
							201, 1061 Nicosia, Republic of Cyprus.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							YThis Privacy Policy sets out how we collect and use your personal information when you use
							the mobile, online and downloadable products and services ("Services") offered by Dromaxco
							Solutions Ltd and its subsidiaries at{" "}
							<a className="link" href="https://espresso-pub.com/" target="_blank" rel="noreferrer">
								<i className="icon icon-link" />
								<b>https://espresso-pub.com/</b>
							</a>{" "}
							and the choices available to you in connection with our use of your personal information
							(the Privacy Policy).This Privacy Policy should be read alongside, and in addition to, our
							terms and conditions at{" "}
							<a
								className="link"
								href="https://espresso-pub.com/terms-of-use"
								target="_blank"
								rel="noreferrer"
							>
								<i className="icon icon-link" />
								<b>https://espresso-pub.com/terms-of-use</b>
							</a>
							. In case of any contradictions between this Privacy Policy and the Terms, this Privacy
							Policy will prevail.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">This Privacy Policy</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							By making available the Services we, acting reasonably and in good faith, believe that you:
						</Typography>
						<ul>
							<li>
								<Typography variant="body1">
									have all necessary rights to register on and use the Services;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									provide true information about yourself to the extent necessary for use of the
									Services;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									understand that by the posting your personal information you have manifestly made
									this information public, and this information may become available to other Site
									users and internet users, be copied and disseminated by them;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">
									understand that some types of information transferred by you to other Service users
									cannot be deleted by you or us;
								</Typography>
							</li>
							<li>
								<Typography variant="body1">are aware of and accept this Privacy Policy.</Typography>
							</li>
						</ul>
					</li>
					<li>
						<Typography variant="body1">
							We do not check the user information received from you, except where such a check is
							necessary in order for us to fulfill our obligations to you.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							This Privacy Policy applies only to the European Union-based users. If you are not a
							European Union-based user, please refer to the Privacy Policy applicable in the relevant
							jurisdiction.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Information we collect about you</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							In order to implement the agreement between you and us, and provide you with access to the
							use of the Services, we will improve, develop and implement new features to our Services,
							and enhance the available Services functionality. To achieve these objectives, and in
							compliance with applicable laws, we will collect, store, aggregate, organise, extract,
							compare, use, and supplement your data (hereinafter “processing”). We will also receive and
							pass this data, and our automatically processed analyses of this data to our affiliates and
							partners as set out in the table below and section 4 of this Privacy Policy.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							We set out in more detail the information we collect when you use our Services, why we
							collect and process it and the legal bases below.
						</Typography>
						<Table size="small">
							<TableHead>
								<TableRow>
									<TableCell>#</TableCell>
									<TableCell>Information Collected</TableCell>
									<TableCell>Purpose</TableCell>
									<TableCell>Legal Basis</TableCell>
								</TableRow>
							</TableHead>
							<TableBody>
								{tableContent.map((content, index) => (
									<TableRow key={index}>
										<TableCell>
											<Typography variant="body1">{index + 1}</Typography>
										</TableCell>
										<TableCell>{content.info}</TableCell>
										<TableCell>{content.purpose}</TableCell>
										<TableCell>{content.basis}</TableCell>
									</TableRow>
								))}
							</TableBody>
						</Table>
					</li>
					<li>
						<Typography variant="body1">
							Our legitimate interests include (1) maintaining and administering the Services; (2)
							providing the Services to you; (3) improving the content of the Services and web pages; (4)
							processing of the data that was manifestly made public by you; (5) ensuring your account is
							adequately protected; and (6) compliance with any contractual, legal or regulatory
							obligations under any applicable law.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							As part of maintaining and administering the Services we use the information to analyse user
							activity and ensure that rules and terms of use for the Services are not violated.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Your personal information may also be processed if it is required by a law enforcement or
							regulatory authority, body or agency or in the defence or exercise of legal claims.We will
							not delete personal information if it is relevant to an investigation or a dispute.It will
							continue to be stored until those issues are fully resolved and/or during the term that is
							required and/or permissible under applicable/relevant law.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You may withdraw your consent to sending you marketing information by amending your privacy
							settings of your account.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Please note, if you do not want us to process sensitive and special categories of data about
							you (including data relating to your health, racial or ethnic origin, political opinion,
							religious or philosophical beliefs, sex life, and your sexual orientation) you should take
							care not to post this information or share this data on the Site.Once you have provided this
							data it will be accessible by other site users and it becomes difficult for us to remove
							this data.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Please note, if you withdraw your consent to processing or you do not provide the data that
							we require in order to maintain and administer the Services, you may not be able to access
							the Services or register with the web pages.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							If we intend to further process your data for any other purpose to those set out in this
							Privacy Policy, we shall provide you with details of this further purpose before we commence
							processing.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Data sharing</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							Your username can be available to all of the Service users. We take technical and
							organizational measures to ensure that your data is safe. Please note that by posting your
							personal information you have manifestly made this information public, and this may become
							available to other Service users and internet users and be copied and/ or disseminated by
							such users. Once this data is transferred by you to other users you will not be able to
							delete this data
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Sometimes we may need to share your data with a third party in order to provide our Services
							to you or to administer the Services, for example if you choose to share your data across
							other social media platforms.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							We may also share your data with our third party contractors and application developers
							provided these third parties assume confidentiality obligations regarding your personal data
							collected by your use of the applications they offer. The developers use the information
							provided to them in order to provide you with additional services. Data will only be shared
							with these developers with your consent. You can authorise developers to access your
							information via our products.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Our ad management and recommendation system is designed so that your information will not be
							shared directly with our third party advertisers. An advertiser or maker of a recommendation
							can only choose to target advertisements to groups of users falling within criteria such as
							age, gender or location, or to target communities according to type, e.g. cars or fashion.
							If you fall within one of the target groups you will receive an advert or recommendation.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							An advertiser or maker of recommendations may also choose to upload a list of emails, phone
							numbers and identities to our systems so that we (but not the adviser or maker of
							recommendations) can check for user matches. They will see the number of matches but not the
							matches themselves.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							If you participate in public tournaments we may have the obligation to disclose your
							personal information to tax authorities. We also may publish your data as part of the
							tournament score tables on our and third party websites.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							Dromaxco Solutions Ltd or our selected third party partners with our permission, using their
							own ad servers, can show advertisements to you.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Privacy Settings</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							The Services may contain links to sites operated by third parties. We are not responsible
							for your data privacy when you access these links or engage with third party services and
							you should ensure you review the relevant third party's privacy statement which will govern
							your data privacy rights.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							We bear no liability for the actions of third parties which, as the result of your use of
							the internet or the Services, obtain access to your information in accordance with the
							confidentiality level selected by you.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							We bear no liability for the consequences of use of the information which, due to the
							Services nature, is available to any internet user. We ask you to take a responsible
							approach to the scope of their information posted on the Site.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">International Transfers</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							We may transfer and maintain on our servers or databases some of your personal information
							outside the European Economic Area (EEA) including in Russia.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							The countries to which we transfer your data may not have the same data protection laws as
							your jurisdiction. We take reasonable cyber security measures and/or put in place the
							Standard Contractual Clauses (e.g. Model Clauses, Data Processing Agreement/Addendum) to
							ensure your data is adequately protected.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Retention Periods</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							We will retain your personal information for as long as required to perform the purposes for
							which the data was collected depending on the legal basis for which that data was obtained
							and/or whether additional legal/regulatory obligations mandate that we retain your personal
							information during the term that is required and/or permissible under applicable/relevant
							law.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You may delete your personal data by removing the data from your account; alternatively, you
							can delete your account.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							We may remove your account, or the information you post as provided by the Terms.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Your Rights</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							You have the following rights, in certain circumstances, in relation to your personal
							information:(a) Right to access your personal information.(b) Right to rectify your personal
							information: you can request that we update, block or delete your personal data, if the data
							is incomplete, outdated, incorrect, unlawfully received or no longer relevant for the
							purpose of processing.(c) Right to restrict the use of your personal information.(d) Right
							to request that your personal information is erased.(e) Right to object to processing of
							your personal information.(f) Right to data portability (in certain specific
							circumstances).(g) Right not to be subject to an automated decision.(h) Right to lodge a
							complaint with a supervisory authority.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							You also have a right to independently remove personal information on your account and make
							changes and corrections to your information, provided that such changes and corrections
							contain up-to-date and true information. You can also view an overview of the information we
							hold about you.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							If you would like to exercise these rights, please contact Support Service at&nbsp;
							<a className="link" href="mailto://stores@espresso-pub.com">
								<i className="icon icon-link" />
								<b>stores@espresso-pub.com</b>
							</a>{" "}
							or send your request to us, in writing to 13 Kypranoros str., EVI BUILDING, 2nd Floor,
							Office 201, 1061 Nicosia, Republic of Cyprus. We will aim to respond to you within 30 days
							from receipt of request. We will need to verify your identity before we are able to disclose
							any personal data to you.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Security Measures</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							We take technical, organizational and legal measures, including, where suitable, encryption,
							to ensure that your personal data are protected from unauthorized or accidental access,
							deletion, modification, blocking, copying and dissemination.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							DAccess to the Services is authorized using your login (e-mail address or mobile phone
							number) and password. You are responsible for keeping this information confidential. You
							should not share your credentials with third parties and we recommend you take measures to
							ensure this information is kept confidential.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							If you forget your login details, you can request us to send you an SMS or email, which will
							contain a restoration code.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							To reduce the probability of third parties gaining unauthorized access, if you login to your
							account from an unusual place or after several failed attempts to provide valid login
							details, we may block entry to your account. You will then need to contact Service support
							and provide certain additional information to verify your credentials and gain access to
							your account.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Changes to this Policy</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							From time to time, we may change and/or update this Privacy Policy. If this Privacy Policy
							changes in any way, we will post an updated version on this page. We will store the previous
							versions of this Privacy Policy in our documentation achieve. We recommend you regularly
							review this page to ensure that you are always aware of our information practices and any
							changes to such.
						</Typography>
					</li>
				</ol>
				<li className="header">
					<Typography variant="h5">Contact Us</Typography>
				</li>
				<ol>
					<li>
						<Typography variant="body1">
							If you have any questions, please send your inquiries to Service support at&nbsp;
							<a className="link" href="mailto://stores@espresso-pub.com">
								<i className="icon icon-link" />
								<b>stores@espresso-pub.com</b>
							</a>{" "}
							or send your request to us, in writing to 13 Kypranoros str., EVI BUILDING, 2nd Floor,
							Office 201, 1061 Nicosia, Republic of Cyprus. So we can deal with your enquiry effectively,
							please quote this Privacy Policy. We will aim to respond to you within 30 days from receipt
							of request.
						</Typography>
					</li>
					<li>
						<Typography variant="body1">
							All correspondence received by us from you (written or electronic inquiries) is classified
							as restricted-access information and may not be disclosed without your written consent. The
							personal data and other information about you may not be used without your consent for any
							purpose other than for response to the inquiry, except as expressly provided by law.
						</Typography>
					</li>
				</ol>
			</ol>
		</div>
	);
};

import React from "react";
import { Box } from "@material-ui/core";
interface IGameIconProps {
	iconUrl?: string | null;
	tikTokId?: string | null;
}

export const GameIcon = ({ iconUrl, tikTokId }: IGameIconProps) => {
	return (
		<Box className="game-icon">
			{iconUrl ? <img src={iconUrl} alt="" /> : <i className="icon icon-puzzle" />}
			{tikTokId && <i className="icon icon-tt" />}
		</Box>
	);
};

import React, { useCallback } from "react";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import { StandartButton, TColor } from "./";
import strings from "helpers/strings";

type TBaseModalProps = {
	visible: boolean;
	text?: string;
	onClose: () => void;
	onConfirm: () => void;
	closeOnConfirm?: boolean;
};

type TConfirmModalProps = TBaseModalProps & {
	title: string;
	confirmButtonLabel: string;
	confirmButtonColor?: TColor;
	discardButtonLabel?: string;
	discardButtonColor?: TColor;
};

export const ConfirmModal = (props: TConfirmModalProps) => {
	const {
		visible,
		onClose,
		onConfirm,
		title,
		text,
		confirmButtonLabel,
		discardButtonLabel,
		confirmButtonColor = "success",
		discardButtonColor = "danger",
		closeOnConfirm = true,
	} = props;

	const onClickConfirmButton = useCallback(() => {
		onConfirm();
		closeOnConfirm && onClose();
	}, [closeOnConfirm, onClose, onConfirm]);

	return (
		<Dialog className="withCloseButton" open={visible} onBackdropClick={onClose}>
			{!discardButtonLabel && (
				<IconButton className="closeButton" onClick={onClose}>
					<i className="icon icon-close" />
				</IconButton>
			)}
			<DialogTitle disableTypography>
				<Typography variant="h5" align="center">
					{title}
				</Typography>
			</DialogTitle>
			<DialogContent className="confirm-content">
				<Typography variant="body1">{text}</Typography>
				<Box className="button-container">
					<StandartButton
						className="dialog-button confirm"
						color={confirmButtonColor}
						variant="contained"
						onClick={onClickConfirmButton}
					>
						{confirmButtonLabel}
					</StandartButton>
					{discardButtonLabel && (
						<StandartButton
							className="dialog-button discard"
							color={discardButtonColor}
							variant="contained"
							onClick={onClose}
						>
							{discardButtonLabel}
						</StandartButton>
					)}
				</Box>
			</DialogContent>
		</Dialog>
	);
};

export const StayOrLeaveModal = (props: TBaseModalProps) => {
	return (
		<ConfirmModal
			title={strings.web_closeWithoutSavingTitle}
			text={strings.web_closeWithoutSaving}
			confirmButtonLabel={strings.web_leave}
			confirmButtonColor="danger"
			discardButtonColor="success"
			discardButtonLabel={strings.web_stay}
			{...props}
		/>
	);
};

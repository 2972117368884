import React, { useMemo } from "react";

import { Box, Chip, Icon, TableCell, Tooltip, Typography } from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";
import { RequestForListFragment, ERequestOrderField, ECampaignType } from "@espresso/protocol";
import { TLocale, formatNumber } from "@espresso/shared-config";
import strings from "../../helpers/strings";
import {
	StatusesColors,
	ctrRequestSteps,
	cpiRequestSteps,
	r1RequestSteps,
	GENRE_LIST_ITEMS,
} from "../../helpers/constants";
import { getMetric, metricsConfig, TMetricName } from "../../helpers/metrics";
import { RequestItemStepper } from "./RequestItemStepper";
import { AppLink } from "../common";
import useAppContext from "../../contexts/AppContext";
import { formatDeveloperName } from "../../helpers/formatDeveloperName";
import { useLocation } from "react-router";

export interface IColumn {
	key: "Name" | "CreatedDate" | "UpdatedDate" | "MT" | "CPI" | "R1" | "CTR" | "Actions";
	localeKey?: keyof TLocale;
	sortKey?: ERequestOrderField;
	Component: (props: TCellProps) => JSX.Element;
}

type TCellProps = {
	column: IColumn;
	row: RequestForListFragment;
	rememberLocation?: boolean;
};

type TMetricCellProps = TCellProps & {
	metricName: TMetricName;
};

type TDateCellProps = TCellProps & {
	date: string | null | undefined;
};

interface ICampaignTypeIconProps {
	iconName: string;
	backdropName: string;
}

const CampaignTypeIcon = (props: ICampaignTypeIconProps) => {
	return (
		<Box className="campaign-type-icon-container">
			<Icon className="backdrop">
				<i className={`icon icon-${props.backdropName}`} />
			</Icon>
			<Icon className="fill">
				<i className={`icon icon-${props.iconName}`} />
			</Icon>
		</Box>
	);
};
const campaignTypeIcons: { [key in ECampaignType]: ICampaignTypeIconProps } = {
	[ECampaignType.Cpi]: { iconName: "gamepad-2", backdropName: "gamepad-1" },
	[ECampaignType.Ctr]: { iconName: "video-2", backdropName: "video-1" },
	[ECampaignType.R1]: { iconName: "coffee-2", backdropName: "coffee-1" },
};

const BaseCell = ({
	row,
	column,
	className,
	children,
	rememberLocation,
}: React.PropsWithChildren<TCellProps & { className: string }>) => {
	const { pathname } = useLocation();
	return (
		<TableCell key={column.key} className={className}>
			<AppLink
				to={{
					pathname: `/request-${row.Id}`,
					state: {
						prevPathname: rememberLocation ? pathname : undefined,
					},
				}}
			>
				{children}
			</AppLink>
		</TableCell>
	);
};

const NameCell = (props: TCellProps) => {
	const developerNameText = useMemo(() => formatDeveloperName(props.row), [props.row]);
	const requestSteps =
		props.row.CampaignType === ECampaignType.Ctr
			? ctrRequestSteps
			: props.row.CampaignType === ECampaignType.Cpi
			? cpiRequestSteps
			: r1RequestSteps;
	const activeStepIndex = useMemo(
		() => requestSteps.findIndex((item) => item?.statuses.includes(props.row.Status)),
		[requestSteps, props.row.Status],
	);

	return (
		<BaseCell {...props} className="name-cell">
			<Box display="flex" flexDirection="row" alignItems="center" flexGrow={1}>
				<CampaignTypeIcon {...campaignTypeIcons[props.row.CampaignType]} />
				{props.row.IconPreviewUrl ? (
					<img src={props.row.IconPreviewUrl} className="app-icon" alt={props.row.AppName || undefined} />
				) : (
					<Icon className="appNameIcon" fontSize="large">
						<i className="icon icon-puzzle" />
					</Icon>
				)}
				<Tooltip
					title={strings.formatString(
						strings.requestStatus_Step,
						...[
							activeStepIndex + 1,
							requestSteps.length,
							strings[StatusesColors[props.row.Status].localeKey],
						],
					)}
					placement="top"
				>
					<Box display="flex" flexDirection="column" flexGrow={1}>
						<Box display="flex" justifyContent="space-between" alignItems="center" flexGrow={1}>
							<Box className="appName-container" display="flex" alignItems="center" flexGrow={1}>
								<i
									className={`icon icon-${StatusesColors[props.row.Status].icon} status ${
										StatusesColors[props.row.Status].className
									}`}
								/>
								<Typography className="appName" variant="body1" color="textPrimary">
									{props.row.AppName || strings.request_appNameEmpty}
								</Typography>
							</Box>
							<Typography color="textSecondary">#{props.row.Id}</Typography>
						</Box>
						<Box className="request-info-container">
							<Box className="teamName-and-stepper">
								<Box className="teamName-root">
									<Typography className="text-ellipsis" variant="body2" color="textSecondary">
										{developerNameText}
									</Typography>
								</Box>
								<RequestItemStepper
									request={props.row}
									activeStepIndex={activeStepIndex}
									stepsCount={requestSteps.length}
								/>
							</Box>
							<Box className="genre-chip">
								<Chip label={strings[GENRE_LIST_ITEMS[props.row.Genre].label]} size="small" />
							</Box>
						</Box>
					</Box>
				</Tooltip>
			</Box>
		</BaseCell>
	);
};

const MetricCell = ({ metricName, ...baseCellProps }: TMetricCellProps) => {
	const { lang } = useAppContext();

	const metricValue = getMetric(metricName, baseCellProps.row);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const metrics = useMemo(() => metricsConfig(), [lang]);

	const conf = metrics[metricName];
	const checkValueResult = metricValue != null && conf.getGrade(metricValue);

	let metricText = "—";

	if (metricValue != null) {
		metricText = formatNumber(metricValue.toFixed(2), conf.unit);
	}

	const metricClassnames = classNames("metric-text", checkValueResult);

	return (
		<BaseCell {...baseCellProps} className="metric-cell">
			<Typography className={metricClassnames} variant="subtitle1">
				{metricText}
			</Typography>
		</BaseCell>
	);
};

const DateCell = ({ date, ...baseCellProps }: TDateCellProps) => (
	<BaseCell {...baseCellProps} className="date-cell">
		<Typography color="textPrimary" variant="body1">
			{moment(date).format("DD.MM.YY")}
		</Typography>
	</BaseCell>
);

const ActionsCell = (props: TCellProps) => {
	return (
		<BaseCell {...props} className="actions-cell">
			<i className="icon icon-arrow" />
		</BaseCell>
	);
};

export const columns: Array<IColumn> = [
	{ key: "Name", localeKey: "developerDashboard_Name", sortKey: ERequestOrderField.AppName, Component: NameCell },
	{
		key: "CreatedDate",
		localeKey: "developerDashboard_CreatedDate",
		sortKey: ERequestOrderField.CreatedAt,
		Component: (props) => <DateCell date={props.row.CreatedAt} {...props} />,
	},
	{
		key: "UpdatedDate",
		localeKey: "developerDashboard_UpdatedDate",
		sortKey: ERequestOrderField.CurrentStatusAt,
		Component: (props) => <DateCell date={props.row.CurrentStatusAt} {...props} />,
	},
	{
		key: "CPI",
		localeKey: "developerDashboard_CPI",
		sortKey: ERequestOrderField.CPI,
		Component: (props) => <MetricCell metricName="cpi" {...props} />,
	},
	{
		key: "R1",
		localeKey: "developerDashboard_R1",
		sortKey: ERequestOrderField.R1,
		Component: (props) => <MetricCell metricName="r1" {...props} />,
	},
	{
		key: "MT",
		localeKey: "developerDashboard_MT",
		sortKey: ERequestOrderField.MT,
		Component: (props) => <MetricCell metricName="mt" {...props} />,
	},
	{
		key: "CTR",
		localeKey: "developerDashboard_CTR",
		sortKey: ERequestOrderField.CTR,
		Component: (props) => <MetricCell metricName="ctr" {...props} />,
	},
	{ key: "Actions", Component: ActionsCell },
];

import React, { Fragment, useEffect } from "react";
import { StandartButton } from "../common";
import {
	Box,
	Checkbox,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	IconButton,
	ListItemText,
	MenuItem,
	Slider,
	Typography,
} from "@material-ui/core";
import strings from "../../helpers/strings";
import { ERequestStatus } from "@espresso/protocol";
import { declDays } from "@espresso/shared-config";
import { useStandartForm } from "../../helpers/useStandartForm";
import { Controller } from "react-hook-form";
import { statusFilterItems } from "./StatusList";

type TPresetsSettingsDialogProps = {
	open: boolean;
	close: () => void;
	presetName: string;
	onSavePresets: (slidersConfig: { [key in ERequestStatus]?: number }) => void;
	slidersConfig: { [key in ERequestStatus]?: number };
};

export const PresetsSettingsDialog = (props: TPresetsSettingsDialogProps) => {
	const { onSavePresets, open, close, presetName } = props;
	const [slidersConfig, setSlidersConfig] = React.useState(props.slidersConfig);

	useEffect(() => {
		setSlidersConfig(props.slidersConfig);
	}, [props.slidersConfig]);

	const onSave = (data: { [key in ERequestStatus]?: number | null }) => {
		const newData: { [key in ERequestStatus]?: number } = {};

		Object.entries(data).forEach(([key, value]) => {
			if (value != null) {
				newData[key as ERequestStatus] = value;
			}
		});

		onSavePresets(newData);
		close();
	};

	const form = useStandartForm<{ [key in ERequestStatus]?: number | null }>({
		mode: "onBlur",
		reValidateMode: "onChange",
	});

	const { handleSubmit, control, reset } = form;

	return (
		<Dialog
			className="withCloseButton presets-dialog"
			open={open}
			onClose={close}
			TransitionProps={{
				onExited: () => {
					reset();
					setSlidersConfig(props.slidersConfig);
				},
			}}
		>
			<IconButton className="closeButton" onClick={close}>
				<i className="icon icon-close" />
			</IconButton>
			<DialogTitle disableTypography>
				<Typography variant="h5" align="center">
					{strings.formatString(strings.requestsPresets_dialogTitle, presetName)}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(onSave)}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<Typography variant="body1">{strings.requestsPresets_dialogText}</Typography>
						<Grid container className="MuiMenu-list multi-columns">
							{statusFilterItems.map((conf) => {
								const checked = slidersConfig[conf.Status] != null;

								return (
									<Fragment key={conf.Status}>
										<Grid item xs={12} md={6}>
											<MenuItem
												className={checked ? "Mui-selected" : undefined}
												key={conf.Status}
												value={conf.Status}
												onClick={() => {
													const newValue = checked ? null : 0;
													setSlidersConfig((prev) => ({
														...prev,
														[conf.Status]: newValue,
													}));
													form.setValue(conf.Status, newValue);
												}}
											>
												<i className={`icon icon-${conf.icon} status ${conf.className}`} />
												<ListItemText primary={strings[conf.localeKey]} />
												<Checkbox
													checked={checked}
													color="default"
													icon={<i className="icon icon-Checkboxes" />}
													checkedIcon={<i className="icon icon-Checkboxhec" />}
													className={conf.className}
												/>
											</MenuItem>
										</Grid>
										<Grid item xs={12} md={6}>
											<Controller
												name={conf.Status}
												control={control}
												defaultValue={slidersConfig[conf.Status] ?? null}
												render={({ onChange, value }) => (
													<Slider
														marks
														value={value}
														min={0}
														max={7}
														valueLabelDisplay="on"
														valueLabelFormat={(value) =>
															value === 0
																? strings.requestsPresets_zeroDays
																: `${value} ${strings[declDays(value)]}`
														}
														onChange={(_event, value) => onChange(value)}
														disabled={!checked}
													/>
												)}
											/>
										</Grid>
									</Fragment>
								);
							})}
						</Grid>
						<Box display="flex" alignItems="center" alignSelf="flex-start">
							<i className="icon icon-info" />
							<Typography variant="body2" color="secondary">
								{strings.requestsPresets_dialogInfo}
							</Typography>
						</Box>
						<StandartButton color="success" variant="contained" type="submit">
							{strings.save}
						</StandartButton>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
};

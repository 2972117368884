import React, { useRef } from "react";
import { TLocale } from "@espresso/shared-config";
import strings from "../../helpers/strings";
import { Control, DeepMap, FieldError } from "react-hook-form";
import { EMessagerType, messageTypes, SwitchBar, StandartTextInputController } from "../../components";

export type TExpressRequestFormData = {
	name: string;
	messagerId: string;
};

export interface IExpressRequestFormProps {
	control: Control<TExpressRequestFormData>;
	errors: DeepMap<TExpressRequestFormData, FieldError>;
	messagerTypeActive: EMessagerType;
	setMessagerTypeActive: React.Dispatch<React.SetStateAction<EMessagerType>>;
}

const MessangerIdLabels: { [key: string]: keyof TLocale } = {
	[EMessagerType.Telegram]: "developer_telegramId",
	[EMessagerType.Skype]: "developer_skypeId",
	[EMessagerType.WhatsApp]: "developer_whatsAppId",
};
export const ExpressRequestForm = (props: IExpressRequestFormProps) => {
	const { control, errors, messagerTypeActive, setMessagerTypeActive } = props;

	const messagerIdFieldRef = useRef<HTMLDivElement>();

	return (
		<div className="express-register-form">
			<StandartTextInputController
				required
				name="name"
				control={control}
				rules={{
					required: strings.required,
				}}
				errorMessage={errors.name?.message}
				label={strings.clientName}
				margin="normal"
				placeholder={strings.namePlaceholder}
			/>
			<SwitchBar
				items={messageTypes}
				onItemsChange={(items) => {
					if (messagerTypeActive !== items[0]) {
						setMessagerTypeActive(items[0]);
						messagerIdFieldRef.current?.focus();
					}
				}}
				selectedItems={[messagerTypeActive]}
				required
				labelKey="messanger"
				margin="normal"
				buttonColor="secondary"
			/>
			<StandartTextInputController
				inputRef={messagerIdFieldRef}
				name="messagerId"
				control={control}
				rules={{
					required: strings.required,
				}}
				errorMessage={errors.messagerId?.message}
				label={strings[MessangerIdLabels[messagerTypeActive]]}
			/>
		</div>
	);
};

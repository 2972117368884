import React from "react";
import { Pagination } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react/swiper-react.js";
import { Typography } from "@material-ui/core";
import strings from "helpers/strings";

import { ReactComponent as EvaluationStep } from "scss/images/offer/evaluation.svg";
import { ReactComponent as DevelopmentStep } from "scss/images/offer/development.svg";
import { ReactComponent as ConfirmationStep } from "scss/images/offer/confirmation.svg";
import { ReactComponent as LaunchStep } from "scss/images/offer/launch.svg";

import "swiper/swiper.scss";
import "swiper/modules/pagination/pagination.scss";

export const OfferInfoGraphic = () => {
	return (
		<>
			<Typography variant="body2">{"(version 1.1 as of February 17, 2021)"}</Typography>
			<Typography variant="body1">{strings.help_docsOfferSummaryDescription}</Typography>
			<Swiper
				className="info-graphic-slider"
				modules={[Pagination]}
				pagination={{ clickable: true }}
				slidesPerView={1}
				centeredSlides
				centeredSlidesBounds
				resizeObserver={false}
				spaceBetween={50}
			>
				<SwiperSlide>
					<EvaluationStep className="slide-step" />
				</SwiperSlide>
				<SwiperSlide>
					<DevelopmentStep className="slide-step" />
				</SwiperSlide>
				<SwiperSlide>
					<ConfirmationStep className="slide-step" />
				</SwiperSlide>
				<SwiperSlide>
					<LaunchStep className="slide-step" />
				</SwiperSlide>
			</Swiper>
			<Typography variant="body1">
				{strings.help_docsOfferSummaryMoreInfo}
				<a className="link" href="/help/docs/publishing-offer-full" target="_blank" rel="noreferrer">
					<i className="icon icon-link" />
					<b>{strings.help_docsOfferFullTitle}</b>
				</a>
			</Typography>
		</>
	);
};

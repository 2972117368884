import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";

export const RobustaSdkFeatures = () => {
	return (
		<>
			<Typography variant="body1">{strings.help_faqRobustaSdkFeaturesP1}</Typography>
			<ul className="MuiTypography-body1">
				<li>{strings.help_faqRobustaSdkFeaturesLi1}</li>
				<li>{strings.help_faqRobustaSdkFeaturesLi2}</li>
				<li>{strings.help_faqRobustaSdkFeaturesLi3}</li>
			</ul>
			<Typography variant="body1">
				{strings.help_faqRobustaSdkFeaturesP2}
				<br />
				<a className="link" href="/help/configuration/robusta-sdk">
					<i className="icon icon-link" />
					<b>{strings.help_configurationRobustaSdkTitle}</b>
				</a>
			</Typography>
		</>
	);
};

import {
	Checkbox,
	FormControl,
	IconButton,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import { EGenre } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { TColor } from "../types";
import { TLocale } from "@espresso/shared-config";
import { GENRE_LIST_ITEMS } from "../../helpers/constants";
import { AppLink } from "components";
import classNames from "classnames";

export interface IGenreListProps {
	selectedKeys?: EGenre[];
	onSelectedChange?: (value: EGenre[]) => void;
	disabled?: boolean;
	labelKey?: keyof TLocale;
	fullWidth?: boolean;
	color?: TColor;
	multiple?: boolean;
	className?: string;
	disableInfoButton?: boolean;
}

export const GenreList = (props: IGenreListProps) => {
	const [selectedKeys, setSelectedKeys] = useState<EGenre[]>(props.selectedKeys || []);

	useEffect(() => {
		setSelectedKeys(props.selectedKeys || []);
	}, [props.selectedKeys]);

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const selectedKeys = props.multiple ? (event.target.value as EGenre[]) : [event.target.value as EGenre];
		setSelectedKeys(selectedKeys);
		if (props.onSelectedChange) {
			props.onSelectedChange(selectedKeys);
		}
	};

	const handleClear = (event: React.SyntheticEvent) => {
		event.stopPropagation();
		setSelectedKeys([]);
		if (props.onSelectedChange) {
			props.onSelectedChange([]);
		}
	};

	const rootClasses = classNames("genre-list", props.className);
	const inputClasses = classNames("select-input", { "with-info-button": !props.disableInfoButton });

	return (
		<FormControl className={rootClasses} margin="none" fullWidth={props.fullWidth} disabled={props.disabled}>
			{props.labelKey && <InputLabel color="secondary">{strings[props.labelKey]}</InputLabel>}
			<Select
				color="secondary"
				MenuProps={{ variant: "menu" }}
				multiple={props.multiple}
				value={props.multiple || selectedKeys.length ? selectedKeys : ""}
				onChange={handleChange}
				input={
					<Input
						className={inputClasses}
						endAdornment={
							props.multiple && selectedKeys.length ? (
								<IconButton
									onClick={handleClear}
									size="small"
									color="secondary"
									className="clear-button"
								>
									<i className="icon icon-close" />
								</IconButton>
							) : undefined
						}
					/>
				}
				renderValue={(selected) => {
					if (!(selected as Array<any>)[0]) {
						return undefined;
					}
					if (props.multiple) {
						return (selected as EGenre[]).map((v) => strings[GENRE_LIST_ITEMS[v].label]).join(", ");
					} else {
						return strings[GENRE_LIST_ITEMS[selected as EGenre].label];
					}
				}}
			>
				{Object.values(GENRE_LIST_ITEMS).map((genreItem) => (
					<MenuItem key={genreItem.value} value={genreItem.value}>
						<ListItemText primary={strings[genreItem.label]} />
						{props.multiple && (
							<Checkbox
								checked={selectedKeys.includes(genreItem.value)}
								color="secondary"
								icon={<i className="icon icon-Checkboxes" />}
								checkedIcon={<i className="icon icon-Checkboxhec" />}
							/>
						)}
					</MenuItem>
				))}
			</Select>
			{!props.disableInfoButton && (
				<AppLink to="/help/configuration/genres-showcase" target="_blank" rel="noreferrer">
					<IconButton size="small" color="secondary" className="info-button">
						<i className="icon icon-info-outline" />
					</IconButton>
				</AppLink>
			)}
		</FormControl>
	);
};

import { TCreateRequestFormProps } from "./types";
import {
	EAttachmentType,
	ECampaignType,
	ECheckStatus,
	EFileType,
	ELoginRole,
	ERequestStatus,
	useGetObjectAttachmentsQuery,
	AttachmentFullFragment,
	EDeveloperPlatform,
	EGenre,
} from "@espresso/protocol";
import { RequestCheckStatus, IRequestCheckStatusProps } from "..";
import { OtherRequestsList } from "../OtherRequestsList";
import { checkPrivateGameRequest } from "@espresso/shared-config";
import useAppContext from "../../../contexts/AppContext";
import { CreateRequestButtons } from "./CreateRequestButtons";
import { useStandartForm } from "../../../helpers/useStandartForm";
import {
	Gallery,
	StandartTextField,
	StandartTextInputController,
	AppLink,
	GoBackButton,
	ConfirmModal,
} from "../../../components";
import { formatUrlApp } from "../../../helpers/formatUrlApp";
import React, { memo, ReactNode, useEffect, useLayoutEffect, useMemo, useState } from "react";
import strings from "../../../helpers/strings";
import { ListItem, ListItemText, Box, Typography, Grid, List } from "@material-ui/core";
import { formatDeveloperName } from "../../../helpers/formatDeveloperName";
import { useAnalytics } from "helpers/ga";
import { RequestIdInfo } from "../RequestIdInfo";
import { analyticsStatus, validateAttachments } from "../../../helpers/request";
import { TCheckField } from "../RequestCheckStatus";
import { RequestInfo } from "../RequestInfo";

type TFormData = { InternalComment: string };

type TPreValidationListItemProps = IRequestCheckStatusProps & {
	title: string;
	disabled?: boolean;
	onClick?: () => void;
	children?: ReactNode;
};

const PreValidationListItem = memo((props: TPreValidationListItemProps) => {
	const { disabled, title, onClick, children, ...checkStatusProps } = props;

	return (
		<ListItem
			disabled={disabled}
			button
			onClick={onClick}
			className={
				checkStatusProps.className
					? `prevalidation-item-root ${checkStatusProps.className}`
					: "prevalidation-item-root"
			}
			dense
		>
			{(title || children) && (
				<>
					<ListItemText
						primary={
							<Box className="title-row">
								<RequestCheckStatus {...checkStatusProps}>{title}</RequestCheckStatus>
								<i className="icon icon-arrow" />
							</Box>
						}
						secondary={children}
						secondaryTypographyProps={{ component: "div" }}
					/>
				</>
			)}
		</ListItem>
	);
});

export const CreateRequestFormPreValidation = (props: TCreateRequestFormProps) => {
	const { request, submit, setCurrentStepStatus, handleClickRemove, handleRestoreRequest } = props;
	const { login, sharedConfig } = useAppContext();
	const { analyticsEvents } = useAnalytics();
	const isPrivate = checkPrivateGameRequest(request);

	const [loadingAttachment, setLoadingAttachment] = useState(false);
	const [loadingTasksVideo, setLoadingTasksVideo] = useState(false);
	const [isConfirmDialogOpen, setIsConfirmDialogOpen] = useState(false);

	const { data, refetch, loading: loadingObjectAttachments } = useGetObjectAttachmentsQuery({
		variables: { objectId: request?.Id || "", type: EAttachmentType.Request },
		notifyOnNetworkStatusChange: true,
	});

	useLayoutEffect(() => {
		refetch();
	}, [refetch]);

	const form = useStandartForm<TFormData>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: { InternalComment: request?.InternalComment || "" },
	});
	const { handleSubmit, control } = form;

	useEffect(() => {
		if (!request) {
			return;
		}
		if (request.InternalComment && request.InternalComment !== form.getValues("InternalComment")) {
			form.setValue("InternalComment", request.InternalComment || "");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	const [videosCount, iconsCount, attachmentsError] = useMemo(() => {
		let videos = 0;
		let icons = 0;
		let error = undefined;
		data?.getObjectAttachments.forEach((item) => {
			switch (item.FileType) {
				case EFileType.Icon:
					icons++;
					break;
				case EFileType.Video:
					videos++;
					break;
				default:
					break;
			}
		});

		if (data?.getObjectAttachments) {
			error = validateAttachments(data.getObjectAttachments);
		}
		return [videos, icons, error];
	}, [data]);

	const appNameText = request?.AppName || "Default App Name";

	const { urlText, urlString } = useMemo(() => formatUrlApp(request), [request]);
	const developerNameText = useMemo(() => formatDeveloperName(request), [request]);

	const back = () => setCurrentStepStatus(isPrivate ? ERequestStatus.Private : ERequestStatus.New);
	const backToInfoEdit = () => setCurrentStepStatus(isPrivate ? ERequestStatus.Private : ERequestStatus.New);

	const next = (data: TFormData) => {
		submit({ ...data, Status: ERequestStatus.Validation });
		setCurrentStepStatus(ERequestStatus.Validation);
		analyticsEvents.sendRequestToManager(request);
	};

	const save = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const data = form.getValues();
		submit(data);
	};

	const handleDeveloperFormSubmit = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		setIsConfirmDialogOpen(true);
	};

	const updateAttachments = (newAttachments: AttachmentFullFragment[]) => {
		const attachmentNotUrl = newAttachments.find((item) => !item.Url);
		setLoadingAttachment(!!attachmentNotUrl);

		if (!attachmentNotUrl) {
			refetch();
		}
	};

	const appLinkStatus = useMemo(() => {
		switch (request?.Platform) {
			case EDeveloperPlatform.Android:
				return request?.GooglePlayUrlStatus;
			case EDeveloperPlatform.Ios:
				return request?.AppStoreUrlStatus;
			default:
				return ECheckStatus.NotValid;
		}
	}, [request]);

	const videosStatus = useMemo(() => {
		if (attachmentsError) {
			return ECheckStatus.NotValid;
		}
		return videosCount >= sharedConfig.request.recommendedVideos ? ECheckStatus.Valid : ECheckStatus.Checking;
	}, [attachmentsError, sharedConfig.request.recommendedVideos, videosCount]);

	const disableSubmit = useMemo(() => {
		if (videosStatus === ECheckStatus.NotValid) return true;
		if (isPrivate) return false;
		if (!request?.AppStoreUrl && !request?.GooglePlayUrl) return true;
		if (request?.FacebookIdStatus !== ECheckStatus.Valid) return true;
		if (request?.AdvertIdStatus !== ECheckStatus.Valid) return true;
		if (appLinkStatus !== ECheckStatus.Valid) return true;
		return false;
	}, [
		appLinkStatus,
		videosStatus,
		isPrivate,
		request?.AdvertIdStatus,
		request?.AppStoreUrl,
		request?.FacebookIdStatus,
		request?.GooglePlayUrl,
	]);
	const disabledNext = loadingObjectAttachments || loadingAttachment || loadingTasksVideo || disableSubmit;

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
	const isR1Request = request?.CampaignType === ECampaignType.R1;

	const getAppLinkField = (): TCheckField => {
		if (request?.GooglePlayUrl) {
			return "GooglePlayUrlStatus";
		}
		if (request?.AppStoreUrl) {
			return "AppStoreUrlStatus";
		}
		return "AppLink";
	};

	return (
		<form className="content" onSubmit={isManagerOrPublisher ? save : handleDeveloperFormSubmit}>
			<Grid container className="pre-validation-container">
				<Grid item xs={12} className="requestid-grid-root">
					<RequestIdInfo request={request} />
				</Grid>
				<Grid item xs={12}>
					<Box className="title-container">
						<GoBackButton to="/profile" />
						<Typography variant="h5">{strings.request_createRequestPreValidationTitle}</Typography>
					</Box>
					{request?.Status === ERequestStatus.Returned && !request.Deleted && (
						<RequestInfo request={request} />
					)}
				</Grid>
				<Grid item xs={12}>
					<Box className="app-name-container">
						<Typography title={appNameText} className="text-ellipsis" variant="h5">
							{appNameText}
						</Typography>
					</Box>
				</Grid>
				<Grid container className="multi-columns" direction="row">
					<Grid item md={2} xs={12} className="item-no-indent">
						<List component="nav" className="gallery-container" disablePadding>
							<PreValidationListItem
								hasData
								onClick={backToInfoEdit}
								status={iconsCount > 0 ? ECheckStatus.Valid : ECheckStatus.Checking}
								field="AppIcon"
								title={strings.icon}
							>
								<Gallery
									simple
									verticalItems
									readonly={request?.Status !== ERequestStatus.New}
									attachmentType={EAttachmentType.Request}
									fileType={EFileType.Icon}
									objectId={request?.Id}
									maxItems={1}
									acceptedFiles={["image/*"]}
									maxFileSize={5242880}
								/>
							</PreValidationListItem>
						</List>
					</Grid>
					{(!isPrivate || request) && (
						<Grid item md={6} xs={12}>
							<List component="nav" disablePadding>
								<PreValidationListItem
									className="genre-item-root"
									field="Genre"
									hasData
									title={strings.genre_label}
									onClick={backToInfoEdit}
									status={
										request?.Genre === EGenre.Other ? ECheckStatus.NotChecked : ECheckStatus.Valid
									}
								>
									<StandartTextField value={request?.Genre} margin="none" />
								</PreValidationListItem>
								{!isPrivate && (
									<PreValidationListItem
										hasData
										title={strings.request_createRequestPreValidationAppLink}
										onClick={() => setCurrentStepStatus(ERequestStatus.New)}
										status={appLinkStatus || undefined}
										field={getAppLinkField()}
									>
										<StandartTextField value={urlText} urlString={urlString} margin="none" />
									</PreValidationListItem>
								)}
							</List>
							{request && (
								<Box className="developer-link-root">
									<Typography className="MuiInputLabel-shrink">
										{strings.developer_developerNameLabel}
									</Typography>
									<AppLink
										className="url-text"
										to={
											request.Developer.Id === login?.Developer?.Id
												? "/developer/edit"
												: `/developer-${request.Developer.Id}`
										}
									>
										<Typography className="status-text" variant="body1" color="inherit">
											{developerNameText}
										</Typography>
									</AppLink>
								</Box>
							)}
						</Grid>
					)}
					{!isPrivate && (
						<Grid item md={4} sm={12} className="item-no-indent">
							<List component="nav" disablePadding className="padded-list">
								<PreValidationListItem
									hasData
									title={strings.request_facebookAppId}
									onClick={() => setCurrentStepStatus(ERequestStatus.InfoFacebook)}
									status={request?.FacebookIdStatus}
									field="FacebookIdStatus"
								/>
								<PreValidationListItem
									hasData
									title={strings.request_advertId}
									onClick={() => setCurrentStepStatus(ERequestStatus.InfoFacebook)}
									status={request?.AdvertIdStatus}
									field="AdvertIdStatus"
								/>
								{isR1Request && (
									<PreValidationListItem
										hasData
										title={strings.request_checkAnalyticsAccess}
										onClick={() => setCurrentStepStatus(ERequestStatus.InfoFacebook)}
										status={request ? analyticsStatus(request) : undefined}
										customExtraData={{ lastStatsDate: request?.LastStatsAt }}
										field="FacebookAnalyticsStatus"
									/>
								)}
							</List>
						</Grid>
					)}
				</Grid>
				<Grid container className="multi-columns">
					<Grid item sm={12}>
						<List component="nav" className="gallery-container" disablePadding>
							<PreValidationListItem
								hasData
								onClick={backToInfoEdit}
								title={strings.standartFormat(strings.request_createRequestPreValidationVideos, {
									current: videosCount.toString(),
									max: sharedConfig.request.maxVideos.toString(),
								})}
								status={videosStatus}
								field="CampaignVideos"
								customExtraData={{
									videosCount: videosCount.toString(),
								}}
							>
								<Gallery
									readonly
									verticalItems
									attachmentType={EAttachmentType.Request}
									fileType={EFileType.Video}
									maxItems={sharedConfig.request.maxVideos}
									acceptedFiles={["video/mp4", "video/x-matroska", "video/quicktime"]}
									objectId={request?.Id}
									addFileTip="request_addVideo"
									onUpdateAttachments={updateAttachments}
									onProcessingTasksVideo={setLoadingTasksVideo}
									request={request}
								/>
							</PreValidationListItem>
						</List>
					</Grid>
				</Grid>
				{isManagerOrPublisher && (
					<Grid item md={12} className="MuiFormControl-marginNormal">
						<Typography variant="h5">{strings.request_internalComment}</Typography>
						<Box className="request-info">
							<StandartTextInputController
								control={control}
								name="InternalComment"
								label={strings.request_internalCommentLabel}
							/>
						</Box>
					</Grid>
				)}
				<OtherRequestsList request={request} />
				<ConfirmModal
					visible={isConfirmDialogOpen}
					onClose={() => setIsConfirmDialogOpen(false)}
					onConfirm={handleSubmit(next)}
					title={strings.request_creationReadyToSendDialogTitle}
					text={strings.request_creationReadyToSendDialogText}
					confirmButtonLabel={strings.send}
				/>
				<CreateRequestButtons
					request={request}
					currentStepIndex={props.currentStepIndex}
					handleBackToStep={back}
					handleClickRemove={handleClickRemove}
					handleRestoreRequest={handleRestoreRequest}
					onNext={isManagerOrPublisher ? () => handleSubmit(next)() : undefined}
					disabledNext={disabledNext}
					nextLabelKey={!isManagerOrPublisher ? "request_creationSubmit" : undefined}
				/>
			</Grid>
		</form>
	);
};

import React, { useState } from "react";
import { Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";

import { TLocale } from "@espresso/shared-config";
import strings from "helpers/strings";

interface IInfoButtonProps {
	children: React.ReactNode;
	dialogSummaryKey?: keyof TLocale;
	disabled?: boolean;
}

interface IInfoDialogProps {
	isOpen: boolean;
	onClose: () => void;
	summary?: string;
}

export const InfoDialog = (props: React.PropsWithChildren<IInfoDialogProps>) => {
	const { isOpen, onClose, summary, children } = props;

	return (
		<Dialog className="withCloseButton info-dialog" open={isOpen} onClose={onClose} onBackdropClick={onClose}>
			<IconButton className="closeButton" onClick={onClose}>
				<i className="icon icon-close" />
			</IconButton>
			<DialogTitle className={!summary ? "no-padding" : undefined} disableTypography>
				{summary && <Typography variant="body2">{summary}</Typography>}
			</DialogTitle>
			<DialogContent>{children}</DialogContent>
		</Dialog>
	);
};

export const InfoButtonWithDialog = (props: IInfoButtonProps) => {
	const { dialogSummaryKey, children, disabled } = props;
	const [isDialogOpen, setIsDialogOpen] = useState(false);
	return (
		<>
			<IconButton size="small" color="secondary" onClick={() => setIsDialogOpen(true)} disabled={disabled}>
				<i className="icon icon-info-outline" />
			</IconButton>
			<InfoDialog
				isOpen={isDialogOpen}
				onClose={() => setIsDialogOpen(false)}
				summary={dialogSummaryKey ? strings[dialogSummaryKey] : undefined}
			>
				{children}
			</InfoDialog>
		</>
	);
};

import React, { useEffect } from "react";
import { Redirect, useParams } from "react-router-dom";
import useAppContext from "../../contexts/AppContext";

export const PromoView = () => {
	const { session, setPromoKey } = useAppContext();
	const params = useParams<{ promoId: string } | undefined>();
	const promoId = params?.promoId;

	useEffect(() => {
		if (!promoId || session?.data?.loginId) {
			return;
		}
		setPromoKey(promoId);
	}, [session?.data?.loginId, promoId, setPromoKey]);

	return session ? (
		<Redirect
			to={{
				pathname: "/profile",
			}}
		/>
	) : (
		<Redirect
			to={{
				pathname: "/request-express",
			}}
		/>
	);
};

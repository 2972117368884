import React, { useEffect, useCallback, useMemo, useState } from "react";
import { Box, ButtonGroup, Paper, Typography } from "@material-ui/core";
import "firebase/auth";
import * as firebase from "firebase/app";
import { ELoginRole, useSignUpMutation } from "@espresso/protocol";
import useAppContext from "../contexts/AppContext";
import { authStatusCode, regExps, TLocale } from "@espresso/shared-config";
import strings from "../helpers/strings";
import { StandartTextInputController } from "./form";
import { StandartButton } from "./common";
import { IBaseSignUpState } from "./types";
import { SocialAuth } from "./common/SocialAuth";
import { useHistory, useLocation } from "react-router-dom";
import { EmailLinkSendedModal } from "../views/EmailLinkSendedModal";
import { useStandartForm } from "../helpers/useStandartForm";
import { Maybe } from "graphql/jsutils/Maybe";
import { IAuthLocation } from "../helpers/withAuth";
import { useAnalytics } from "helpers/ga";
import { useLoadingSignal } from "contexts/LoadingContext";

interface ISignUpState extends IBaseSignUpState {
	formType: "authorization" | "registation" | "forgotPassword";
	modalVisible: boolean;
}

export interface ISignUpFormProps {
	authDefaultMessageKey?: keyof TLocale;
	authCodeSendedKey?: keyof TLocale;
}

export interface IAuthLocationState extends IAuthLocation {
	email?: string;
	isUserExists?: boolean;
}

type TSignUpFormData = {
	email: string;
	password: string;
	passwordAgain: string;
};

export const SignUpForm = (props: ISignUpFormProps) => {
	const location = useLocation<Maybe<IAuthLocationState>>();
	const history = useHistory();
	const { analyticsEvents } = useAnalytics();
	const redirectRoute = location.state?.successRedirect;
	const isUserExists = location.state?.isUserExists;

	const form = useStandartForm<TSignUpFormData>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			email: location.state?.email || "",
			password: "",
			passwordAgain: "",
		},
	});
	const {
		handleSubmit,
		errors,
		control,
		formState: { isSubmitting },
	} = form;
	useLoadingSignal(isSubmitting);

	const [signUpState, setSignUpState] = useState<ISignUpState>({
		formType: "authorization",
		loading: false,
		error: false,
		localeKey: undefined,
		modalVisible: false,
	});
	useLoadingSignal(signUpState.loading);

	const [signUp, { data, loading }] = useSignUpMutation();
	useLoadingSignal(loading);

	const { session, setSession, firebaseApp, lang, getErrorCode, promoKey } = useAppContext();

	const firebaseAppAuth = useMemo<firebase.auth.Auth>(() => firebaseApp.auth(), [firebaseApp]);

	useEffect(() => {
		if (data?.signUp.data && data.signUp.data.Login.Role !== ELoginRole.Unconfirmed) {
			const token = data.signUp.data.token;
			const id = data.signUp.data.authId;

			if (session?.auth?.id !== id || session.auth.token !== token) {
				setSession({ auth: { token, id } });
			}
		}
	}, [data, session, setSession]);

	const signInWithEmailAndPassword = useCallback(
		async (email: string, password: string) => {
			try {
				const fbResult = await firebaseAppAuth.signInWithEmailAndPassword(email, password);
				if (!fbResult.user) {
					return;
				}
				const authToken = await fbResult.user.getIdToken();
				await signUp({
					variables: { data: { FirebaseToken: authToken, Lang: lang, PromoKey: promoKey } },
				});

				analyticsEvents.login("e-mail");

				const route = redirectRoute || "/profile";
				history.push(route, { isUserExists });
			} catch (ex) {
				console.log(ex);
				const localeKey: keyof TLocale = (ex.code && authStatusCode.get(ex.code)?.localeKey) || "auth_unknown";

				if (ex.code === "auth/user-not-found") {
					form.setError("email", { message: strings[localeKey] });
					setSignUpState((prevState) => ({ ...prevState, loading: false }));
					return;
				}
				if (ex.code === "auth/wrong-password") {
					form.setError("password", { message: strings[localeKey] });
					setSignUpState((prevState) => ({ ...prevState, loading: false }));
					return;
				}

				setSignUpState((prevState) => ({ ...prevState, localeKey, error: true, loading: false }));
			}
		},
		[firebaseAppAuth, signUp, lang, promoKey, analyticsEvents, redirectRoute, history, isUserExists, form],
	);

	const submit = useCallback(
		async (data: TSignUpFormData) => {
			switch (signUpState.formType) {
				case "authorization":
					await signInWithEmailAndPassword(data.email, data.password);
					break;
				case "registation":
					await signUp({ variables: { data: { Email: data.email, Lang: lang } } });
					setSignUpState((prevState) => ({ ...prevState, modalVisible: !getErrorCode() }));

					if (!getErrorCode()) {
						analyticsEvents.signup("e-mail");
					}

					break;
				case "forgotPassword":
					await firebaseAppAuth
						.sendPasswordResetEmail(data.email)
						.then(() => {
							setSignUpState((prevState) => ({
								...prevState,
								formType: "authorization",
								localeKey: "auth_forgotPasswordSended",
								error: false,
								loading: false,
							}));
						})
						.catch((ex: firebase.auth.Error) => {
							console.log(ex);
							const localeKey: keyof TLocale =
								(ex.code && authStatusCode.get(ex.code)?.localeKey) || "auth_unknown";
							setSignUpState((prevState) => ({
								...prevState,
								localeKey,
								error: true,
								loading: false,
							}));
						});

					break;
				default:
					break;
			}
		},
		[
			signUpState.formType,
			signInWithEmailAndPassword,
			signUp,
			lang,
			getErrorCode,
			firebaseAppAuth,
			analyticsEvents,
		],
	);

	const submitButtonText = useMemo(() => {
		switch (signUpState.formType) {
			case "authorization":
				return strings.web_menuLogIn;
			case "registation":
				return strings.auth_register;
			case "forgotPassword":
				return strings.auth_forgotPasswordSend;
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [signUpState.formType, lang]);

	return (
		<form noValidate onSubmit={handleSubmit(submit)}>
			<Paper className="signup-view" square elevation={0}>
				{signUpState.formType !== "forgotPassword" && (
					<Box display="flex" flexDirection="column" alignItems="center">
						<ButtonGroup color="secondary" disabled={signUpState.loading}>
							<StandartButton
								variant={signUpState.formType === "authorization" ? "contained" : "outlined"}
								onClick={() =>
									setSignUpState((prevState) => ({
										...prevState,
										formType: "authorization",
										localeKey: undefined,
										error: false,
										loading: false,
									}))
								}
							>
								{strings.auth_title}
							</StandartButton>
							<StandartButton
								variant={signUpState.formType === "registation" ? "contained" : "outlined"}
								onClick={() =>
									setSignUpState((prevState) => ({
										...prevState,
										formType: "registation",
										localeKey: undefined,
										error: false,
										loading: false,
									}))
								}
							>
								{strings.auth_register}
							</StandartButton>
						</ButtonGroup>
						<SocialAuth<ISignUpState> setSignUpState={setSignUpState} loading={signUpState.loading} />
					</Box>
				)}
				<Box className={signUpState.error ? "error-text" : undefined}>
					{signUpState.localeKey ? strings[signUpState.localeKey] : ""}
				</Box>
				<StandartTextInputController
					required
					name="email"
					control={control}
					rules={{
						required: strings.required,
						pattern: {
							value: regExps.email,
							message: strings.wrongEmail,
						},
					}}
					errorMessage={errors.email?.message}
					label={strings.email}
					pattern={regExps.email}
					margin="normal"
					placeholder="email@example.com"
					autoFocus
					disabled={signUpState.loading}
				/>
				{/* {signUpState.formType !== "forgotPassword" && ( */}
				{signUpState.formType === "authorization" && (
					<StandartTextInputController
						required
						name="password"
						type="password"
						control={control}
						rules={{
							required: strings.required,
							// pattern:
							// 	signUpState.formType === "registation"
							// 		? {
							// 				value: regExps.password,
							// 				message: strings.auth_passwordPattern,
							// 		  }
							// 		: undefined,
						}}
						errorMessage={errors.password?.message}
						label={strings.auth_password}
						margin="normal"
						disabled={signUpState.loading}
					/>
				)}
				{/* {signUpState.formType === "registation" && (
					<StandartTextInputController
						name="passwordAgain"
						type="password"
						control={control}
						rules={{
							required: strings.required,
							validate: (value: string): string | undefined => {
								if (value !== form.getValues("password")) return strings.auth_passwordsNotEqual;
								return;
							},
						}}
						errorMessage={errors.passwordAgain?.message}
						label={strings.auth_passwordAgain}
						pattern={regExps.password}
						margin="dense"
					/>
				)} */}
				<Box width="100%" marginTop="20px">
					<Typography className="required-fields-text">
						<span className="asterisk-error">*</span>
						<span className="required-info">{strings.standartFormat(strings.requiredFields)}</span>
					</Typography>
					<Box display="flex" flexDirection="column" alignItems="center">
						<StandartButton
							disabled={signUpState.loading || isSubmitting}
							className="button-width-medium"
							type="submit"
							color="success"
						>
							{submitButtonText}
						</StandartButton>
						{signUpState.formType === "forgotPassword" && (
							<StandartButton
								className="button-width-medium"
								onClick={() =>
									setSignUpState((prevState) => ({
										...prevState,
										formType: "authorization",
										localeKey: undefined,
										error: false,
										loading: false,
									}))
								}
								variant="text"
								color="success"
							>
								{strings.cancel}
							</StandartButton>
						)}
						{signUpState.formType === "authorization" && (
							<>
								<StandartButton
									disabled={signUpState.loading}
									className="button-width-medium"
									onClick={() =>
										setSignUpState((prevState) => ({
											...prevState,
											formType: "forgotPassword",
											localeKey: "auth_forgotPasswordDesc",
											error: false,
											loading: false,
										}))
									}
									variant="text"
									color="success"
								>
									{strings.auth_forgotPassword}
								</StandartButton>
							</>
						)}
					</Box>
				</Box>
				<EmailLinkSendedModal
					visible={signUpState.modalVisible}
					setVisible={(value) => setSignUpState((prevState) => ({ ...prevState, modalVisible: value }))}
					text1={strings.auth_verifyEmailModalText1Register}
					text2={strings.auth_verifyEmailModalText2Register}
					onButtonClick={() => {
						setSignUpState((prevState) => ({
							...prevState,
							modalVisible: false,
							formType: "authorization",
						}));
					}}
				/>
			</Paper>
		</form>
	);
};

import { ru, en, ch, tr, TLocale } from "@espresso/shared-config";
import LocalizedStrings from "react-localization";
import { ELang } from "@espresso/protocol";

class StandartLocalizedStrings extends LocalizedStrings<TLocale> {
	/**
	 * standartFormat
	 * Заменяет все {nbps} на неразрывные пробелы
	 */
	public standartFormat(value: string, args?: { [key: string]: string }) {
		let formattedResult = this.formatString(value, { nbsp: "\u00a0", ...args });
		return Array.isArray(formattedResult) ? formattedResult.join("") : formattedResult;
	}

	public capitalize(value: string) {
		return value.charAt(0).toUpperCase() + value.slice(1);
	}
}

const strings = new StandartLocalizedStrings({
	ru,
	en,
	ch,
	tr,
});

export const langs: { [key: string]: ELang } = {
	en: ELang.En,
	"zh-cn": ELang.Ch,
	ch: ELang.Ch,
	zh: ELang.Ch,
	ru: ELang.Ru,
	uk: ELang.Ru,
	be: ELang.Ru,
	kk: ELang.Ru,
	ky: ELang.Ru,
	tr: ELang.Tr,
};

export default strings;

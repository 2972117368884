import { ButtonGroup, FormControl, FormHelperText, FormLabel, Tooltip } from "@material-ui/core";
import React, { useEffect, useState } from "react";
import classNames from "classnames";
import { StandartButton } from "..";
import strings from "../../../helpers/strings";
import { TSwitchBarProps } from "./types";
import _ from "lodash";

export function SwitchBar<ItemT = any>(props: TSwitchBarProps<ItemT> & { round?: boolean }): JSX.Element {
	const {
		labelKey,
		items,
		onItemsChange,
		multiSelect,
		selectedIndex,
		selectedItems,
		errorKey,
		round,
		buttonColor,
		...controlProps
	} = props;

	const getItemsIndexes = (selectedItems: ItemT[] | undefined) => {
		if (selectedItems) {
			const selectedIdx: number[] = [];
			for (const selectedItem of selectedItems) {
				const idx = items.findIndex((item) => item.value === selectedItem);
				if (idx > -1) {
					selectedIdx.push(idx);
				}
			}
			return selectedIdx;
		}
		return [];
	};

	const selectedInit = selectedItems ? getItemsIndexes(selectedItems) : selectedIndex || (multiSelect ? [] : [0]);

	const [selected, setSelected] = useState<number[]>(selectedInit.sort());

	const updateValues = (newIndex: number) => {
		if (multiSelect) {
			setSelected((prevState) => {
				const idx = prevState.indexOf(newIndex);
				const isAlreadySelected = idx > -1;
				if (isAlreadySelected) {
					prevState.splice(idx, 1);
					return [...prevState];
				} else {
					return [...prevState, newIndex].sort();
				}
			});
		} else {
			setSelected([newIndex]);
		}
	};

	useEffect(() => {
		onItemsChange && onItemsChange(selected.map((index) => items[index].value));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, selected]);

	const formControlClasses = classNames("buttons-group-formControl", controlProps.className, {
		"horizontal-switch": !controlProps.fullWidth,
	});

	useEffect(() => {
		if (selectedItems) {
			const selectedIdx: number[] = getItemsIndexes(selectedItems);
			setSelected((prevState) => {
				if (!_.isEqual(prevState, selectedIdx.sort())) {
					return selectedIdx;
				} else {
					return prevState;
				}
			});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [items, selectedItems]);

	return (
		<FormControl {...controlProps} className={formControlClasses}>
			{labelKey && (
				<FormLabel component="legend" className="MuiSwitchBarLabel">
					{strings[labelKey]}
				</FormLabel>
			)}
			<ButtonGroup
				disabled={controlProps.disabled}
				className={round ? "round" : undefined}
				fullWidth={controlProps.fullWidth}
			>
				{items.map((item, idx) => (
					<Tooltip
						key={`sw-tt-${idx}`}
						title={item.tooltipKey ? strings[item.tooltipKey] : ""}
						placement="top"
					>
						<StandartButton
							disabled={item.disabled}
							color={buttonColor}
							key={`sw-${idx}`}
							variant={selected.indexOf(idx) > -1 ? "contained" : "outlined"}
							onClick={() => updateValues(idx)}
							size={controlProps.size}
							startIcon={item.icon && <i className={`icon icon-${item.icon}`} />}
						>
							{item.label &&
								(item.label_params
									? strings.standartFormat(strings[item.label], item.label_params)
									: strings[item.label])}
						</StandartButton>
					</Tooltip>
				))}
			</ButtonGroup>
			{errorKey && <FormHelperText>{errorKey}</FormHelperText>}
		</FormControl>
	);
}

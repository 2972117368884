import React, { useEffect, useRef, useState } from "react";
import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import strings from "../../../helpers/strings";
import {
	AttachmentFullFragment,
	EAttachmentType,
	EFileType,
	ELoginRole,
	ERequestStatus,
	EDeveloperPlatform,
	useClearDraftAttachmentsMutation,
	useGetStoreInfoMutation,
	useUpdateAppLinksMutation,
	ECheckStatus,
	EGenre,
} from "@espresso/protocol";
import { TCreateRequestFormProps } from "./types";
import { useStandartForm } from "../../../helpers/useStandartForm";
import {
	StandartButton,
	Gallery,
	StandartTextInputController,
	SwitchBar,
	StandartTextField,
	ISwitchBarItem,
	GoBackButton,
	InfoButtonWithDialog,
	GenreList,
	StayOrLeaveModal,
} from "../../../components";
import { Controller, FieldError, useWatch, FormProvider } from "react-hook-form";
import { OtherRequestsList } from "../OtherRequestsList";
import _ from "lodash";
import useAppContext from "../../../contexts/AppContext";
import { CreateRequestButtons } from "./CreateRequestButtons";
import { INPUT_MAX_LENGTH_APP_NAME } from "../../../helpers/constants";
import { useAnalytics } from "helpers/ga";
import { RequestIdInfo } from "../RequestIdInfo";
import { validateAttachments } from "../../../helpers/request";
import { RequestCheckStatus, TCheckField } from "../RequestCheckStatus";
import { AppLinkError } from "views/HelpView/sections/faq/AppLinkError";
import { RequestInfo } from "../RequestInfo";
import { Prompt, useHistory } from "react-router-dom";

type TFormData = {
	AppName: string;
	attachments: AttachmentFullFragment[];
	InternalComment: string;
	Platform: EDeveloperPlatform | null;
	Genre: EGenre | null;
	icon: AttachmentFullFragment | null;
};

const platformSwitchItems: ISwitchBarItem<EDeveloperPlatform>[] = [
	{ value: EDeveloperPlatform.Ios, icon: "apple", tooltipKey: "request_createRequestPlatformTooltipIos" },
	{ value: EDeveloperPlatform.Android, icon: "android", tooltipKey: "request_createRequestPlatformTooltipAndroid" },
];

const IconAndNameInfo = () => {
	return (
		<>
			<Typography>{strings.help_infoIconAndNameP1}</Typography>
			<Typography>{strings.help_infoIconAndNameP2}</Typography>
		</>
	);
};

export const CreateRequestFormNew = (props: TCreateRequestFormProps) => {
	const { request, submit, setCurrentStepStatus, refetch, handleClickRemove, handleRestoreRequest } = props;
	const attachmentsProcessingRef = useRef<string[]>();

	const { login, sharedConfig, showExitPrompt, setShowExitPrompt } = useAppContext();
	const history = useHistory();
	const { analyticsEvents } = useAnalytics();

	const [getStoreInfo] = useGetStoreInfoMutation();
	const [updateStoreLinks, { loading: updatingStoreLinks }] = useUpdateAppLinksMutation();
	const [forceUpdateIcon, setForceUpdateIcon] = useState<Date>();
	const [loadingStoreInfo, setLoadingStoreInfo] = useState(false);
	const [loadingAttachment, setLoadingAttachment] = useState(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const [clearAttachments] = useClearDraftAttachmentsMutation({
		variables: { type: EAttachmentType.Request },
	});

	useEffect(() => {
		clearAttachments();
	}, [clearAttachments]);

	useEffect(() => {
		setShowExitPrompt(loadingAttachment);
	}, [loadingAttachment, setShowExitPrompt]);

	const form = useStandartForm<TFormData>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: {
			AppName: request?.AppName || "",
			icon: null,
			attachments: [],
			InternalComment: "",
			Platform: request?.Platform || null,
			Genre: request?.Genre || null,
		},
	});
	const { handleSubmit, errors, control } = form;
	const watchPlatform = useWatch({ name: "Platform", control });
	const watchIcon = useWatch({ name: "icon", control });
	const watchAppName = useWatch<string>({ name: "AppName", control });

	useEffect(() => {
		if (!request) {
			return;
		}
		if (request.AppName !== form.getValues("AppName")) {
			form.setValue("AppName", request.AppName || "");
		}
		if (request.InternalComment && request.InternalComment !== form.getValues("InternalComment")) {
			form.setValue("InternalComment", request.InternalComment || "");
		}
		if (!request.Platform && (request.GooglePlayUrl || request.AppStoreUrl)) {
			form.setValue("Platform", request?.GooglePlayUrl ? EDeveloperPlatform.Android : EDeveloperPlatform.Ios);
		}
		if (request.Platform && request.Platform !== form.getValues("Platform")) {
			form.setValue("Platform", request.Platform);
		}
		if (request.Genre && request.Genre !== form.getValues("Genre")) {
			form.setValue("Genre", request.Genre);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	const attachmentsError = ((errors.attachments as unknown) as FieldError | undefined)?.message;
	const appNameError = errors.AppName?.message;
	const formState = control.formStateRef.current;

	useEffect(() => {
		const appNameValue = form.getValues("AppName");
		const attachmentsValue = form.getValues("attachments");

		if (appNameError && appNameValue) {
			form.clearErrors("AppName");
		}
		if (!attachmentsError || attachmentsValue?.length || formState.isSubmitted) {
			return;
		}
		form.clearErrors("attachments");
	}, [appNameError, attachmentsError, formState.isSubmitted, form]);

	const updateFromStore = async () => {
		if (loadingStoreInfo) {
			return;
		}

		const data: TFormData = form.getValues();

		setLoadingStoreInfo(true);

		void submit(
			{
				AppName: data.AppName,
				AttachmentId: data.attachments.map((a) => a.Id),
				Platform: data.Platform,
			},
			async (requestId) => {
				await getStoreInfo({ variables: { id: requestId } });
				refetch?.(requestId);
				setForceUpdateIcon(new Date());
				setLoadingStoreInfo(false);
			},
		);
	};

	const storeLink = watchPlatform === EDeveloperPlatform.Android ? request?.GooglePlayUrl : request?.AppStoreUrl;

	const storeLinkStatus = (() => {
		if (
			request?.AppStoreUrlStatus === ECheckStatus.NotChecked &&
			request.GooglePlayUrlStatus === ECheckStatus.NotChecked
		) {
			return ECheckStatus.NotValid;
		}
		return watchPlatform === EDeveloperPlatform.Android ? request?.GooglePlayUrlStatus : request?.AppStoreUrlStatus;
	})();

	const storeLinkStatusField: TCheckField =
		watchPlatform === EDeveloperPlatform.Android ? "GooglePlayUrlStatus" : "AppStoreUrlStatus";

	const iconAndNameStatusField: TCheckField = !watchAppName ? "AppName" : "AppIcon";
	const iconAndNameStatus: ECheckStatus = (() => {
		if (iconAndNameStatusField === "AppIcon") {
			return watchIcon ? ECheckStatus.Valid : ECheckStatus.Checking;
		}
		return !watchAppName?.length ? ECheckStatus.NotValid : ECheckStatus.Valid;
	})();

	useEffect(() => {
		if (!request?.GooglePlayUrl && !request?.AppStoreUrl) {
			return;
		}
		if (storeLinkStatus !== ECheckStatus.Valid) {
			return;
		}
		if (
			request?.Status === ERequestStatus.New ||
			request?.Status === ERequestStatus.PreValidation ||
			request?.Status === ERequestStatus.Returned
		) {
			updateFromStore();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request?.GooglePlayUrl, request?.AppStoreUrl, request?.Status, watchPlatform]);

	const back = () => setCurrentStepStatus(ERequestStatus.InfoFacebook);
	const handleBackClick = () => {
		if (showExitPrompt) {
			return setIsConfirmOpen(true);
		}
		back();
	};

	const next = (data: TFormData) => {
		submit({
			...data,
			Status: request?.Status === ERequestStatus.New ? ERequestStatus.PreValidation : request?.Status,
			AttachmentId: data.attachments.map((a) => a.Id),
		});
		setCurrentStepStatus(ERequestStatus.PreValidation);
		analyticsEvents.advanceRequestCreation(request, ERequestStatus.New, ERequestStatus.PreValidation);
	};

	const save = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const data = form.getValues();

		submit({
			...data,
			AttachmentId: data.attachments.map((a) => a.Id),
		});
	};

	const onUpdateAttachments = (newAttachments: AttachmentFullFragment[]) =>
		form.setValue("attachments", newAttachments, { shouldValidate: true });

	const onUpdateIcon = (newAttachments: AttachmentFullFragment[]) => {
		form.setValue("icon", newAttachments[0]);
	};

	const onUpdateCropIds = (ids: string[] | undefined) => {
		attachmentsProcessingRef.current = ids;
		if (control.isFormDirty("attachments") || !_.isEmpty(ids)) {
			form.trigger("attachments");
		}
	};

	const handlePlatformSwitch = (items: EDeveloperPlatform[]) => {
		form.setValue("Platform", items[0]);
	};

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
	const disabledNext = !!errors.attachments || loadingAttachment || loadingStoreInfo;
	const isStoreScrapeDisabled =
		!storeLinkStatus || storeLinkStatus === ECheckStatus.NotValid || loadingStoreInfo || !!request?.Deleted;

	return (
		<FormProvider {...form}>
			<form noValidate className="content" onSubmit={isManagerOrPublisher ? save : handleSubmit(next)}>
				<Grid container>
					<Grid item xs={12} className="requestid-grid-root">
						<RequestIdInfo request={request} />
					</Grid>
					<Grid item xs={12}>
						<Box className="title-container">
							<GoBackButton to="/profile" />
							<Typography variant="h5">{strings.request_createRequestNewTitle}</Typography>
						</Box>
						{request?.Status === ERequestStatus.Returned && !request.Deleted && (
							<RequestInfo request={request} />
						)}
					</Grid>
					<Grid item xs={12} className="block-container first-block">
						<Box className="block-header">
							<RequestCheckStatus
								field={
									request?.GooglePlayUrl || request?.AppStoreUrl ? storeLinkStatusField : "AppLink"
								}
								status={storeLinkStatus || undefined}
							>
								{strings.request_storeLink}
							</RequestCheckStatus>
							<InfoButtonWithDialog>
								<AppLinkError />
							</InfoButtonWithDialog>
						</Box>
						<Box className="store-link block-content">
							<Box className="store-link-info-container">
								<StandartTextField urlString={storeLink || ""} value={storeLink || ""} />
								<Typography variant="body2">{strings.request_createRequestAppLinkText}</Typography>
							</Box>
							<Box className="store-link-buttons-container">
								<Controller
									name="Platform"
									control={control}
									render={({ value }) =>
										request?.GooglePlayUrl && request.AppStoreUrl ? (
											<SwitchBar
												disabled={loadingStoreInfo || updatingStoreLinks}
												items={platformSwitchItems}
												onItemsChange={handlePlatformSwitch}
												selectedItems={[value]}
												buttonColor="secondary"
												className="platform-switch"
											/>
										) : (
											<></>
										)
									}
								/>
								<StandartButton
									color="warning"
									onClick={async () => {
										if (!request?.Id) {
											return;
										}
										await updateStoreLinks({ variables: { requestId: request?.Id } });
										refetch?.();
									}}
									disabled={updatingStoreLinks || loadingStoreInfo || !!request?.Deleted}
								>
									{strings.update}
								</StandartButton>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12} className="genre-select">
						<Controller
							name="Genre"
							control={control}
							render={({ onChange, value }) => (
								<GenreList
									fullWidth
									labelKey="genre_label"
									selectedKeys={[value]}
									onSelectedChange={(v) => onChange(v[0])}
								/>
							)}
						/>
					</Grid>
					<Grid item xs={12} className="block-container">
						<Box className="block-header">
							<RequestCheckStatus field={iconAndNameStatusField} status={iconAndNameStatus}>
								{strings.iconAndName}
							</RequestCheckStatus>
							<InfoButtonWithDialog>
								<IconAndNameInfo />
							</InfoButtonWithDialog>
						</Box>
						<Box className="icon-and-name block-content">
							<Controller
								name="icon"
								control={control}
								render={() => (
									<Gallery
										simple
										verticalItems
										disabled={!!request?.Deleted || loadingStoreInfo}
										attachmentType={EAttachmentType.Request}
										fileType={EFileType.Icon}
										maxItems={1}
										acceptedFiles={["image/*"]}
										addFileTip="request_addIcon"
										maxFileSize={5242880}
										objectId={request?.Id}
										forceUpdateTime={forceUpdateIcon}
										onLoadingAttachmentsChange={setLoadingAttachment}
										onUpdateAttachments={onUpdateIcon}
									/>
								)}
							/>
							<Box className="fullwidth-content">
								<StandartTextInputController
									required
									disabled={!!request?.Deleted || loadingStoreInfo}
									name="AppName"
									control={control}
									rules={{
										required: strings.required,
									}}
									inputProps={{
										maxLength: INPUT_MAX_LENGTH_APP_NAME,
									}}
									errorMessage={appNameError}
									label={strings.request_appName}
									placeholder={strings.request_appNamePlaceholder}
									margin="normal"
								/>
								<StandartButton
									color="secondary"
									disabled={isStoreScrapeDisabled}
									startIcon={
										loadingStoreInfo ? (
											<CircularProgress size={24} />
										) : (
											<i className="icon icon-cloud" />
										)
									}
									onClick={updateFromStore}
								>
									{strings.request_getFromStore}
								</StandartButton>
							</Box>
						</Box>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="attachments"
							control={control}
							rules={{
								required: strings.required,
								validate: (attachments) =>
									validateAttachments(attachments, attachmentsProcessingRef.current),
							}}
							render={() => (
								<Box>
									<Gallery
										required
										verticalItems
										hasDelete
										disabled={!!request?.Deleted}
										attachmentType={EAttachmentType.Request}
										fileType={EFileType.Video}
										maxItems={sharedConfig.request.maxVideos}
										acceptedFiles={["video/mp4", "video/x-matroska", "video/quicktime"]}
										onUpdateAttachments={onUpdateAttachments}
										onUpdateCropIds={onUpdateCropIds}
										onLoadingAttachmentsChange={setLoadingAttachment}
										objectId={request?.Id}
										additionalRequirementsText="request_videoRequirements"
										addFileTip="request_addVideo"
										label="request_videoLabel"
										errorMessage={attachmentsError}
										request={request}
									/>
								</Box>
							)}
						/>
						{isManagerOrPublisher && (
							<Grid item xs={12} className="MuiFormControl-marginNormal">
								<Typography variant="h5">{strings.request_internalComment}</Typography>
								<Box className="request-info">
									<StandartTextInputController
										control={control}
										name="InternalComment"
										label={strings.request_internalCommentLabel}
									/>
								</Box>
							</Grid>
						)}
						<Box marginTop={5}>
							<Typography className="required-fields-text">
								<span className="asterisk-error">*</span>
								<span className="required-info">{strings.standartFormat(strings.requiredFields)}</span>
							</Typography>
						</Box>
					</Grid>
					<OtherRequestsList request={request} />
					<CreateRequestButtons
						request={request}
						disabledSave={loadingAttachment}
						disabledNext={disabledNext}
						currentStepIndex={props.currentStepIndex}
						handleBackToStep={handleBackClick}
						handleClickRemove={handleClickRemove}
						handleRestoreRequest={handleRestoreRequest}
						onNext={isManagerOrPublisher ? () => handleSubmit(next)() : undefined}
					/>
				</Grid>
				<Prompt
					when={showExitPrompt}
					message={(locationNext, historyAction) => {
						return JSON.stringify({ historyAction, locationNext, currentLocation: history.location });
					}}
				/>
				<StayOrLeaveModal
					visible={isConfirmOpen}
					onClose={() => {
						setIsConfirmOpen(false);
					}}
					onConfirm={() => {
						setIsConfirmOpen(false);
						back();
					}}
				/>
			</form>
		</FormProvider>
	);
};

import React from "react";
import { StandartInfoBlock, StandartView } from "../../components";
import strings from "../../helpers/strings";

export const UnconfirmedView = () => {
	return (
		<StandartView disableNotifications pageBreakpoints={{ sm: 12, md: 8, lg: 6, xl: 4 }} title="">
			<StandartInfoBlock
				title={strings.developer_ProfileUnconfirmedTitle}
				text={strings.developer_ProfileUnconfirmedText}
			/>
		</StandartView>
	);
};

import { THelpSection } from "views/HelpView/types";
import { FbAppId } from "./FbAppId";
import { BaristaId } from "./BaristaId";
import { RobustaSdk } from "./RobustaSdk";
import { NonUnityGames } from "./NonUnityGames";
import { GenresShowcase } from "./GenresShowcase";

export const configuration: THelpSection = {
	titleKey: "help_configurationTitle",
	path: "configuration",
	topics: [
		{ titleKey: "help_configurationFbAppIdTitle", Content: FbAppId, path: "fb-app-id" },
		{ titleKey: "help_configurationBaristaIdTitle", Content: BaristaId, path: "barista-id" },
		{ titleKey: "help_configurationRobustaSdkTitle", Content: RobustaSdk, path: "robusta-sdk" },
		{ titleKey: "help_configurationNonUnityGamesTitle", Content: NonUnityGames, path: "non-unity-games" },
		{ titleKey: "help_configurationGenresShowcaseTitle", Content: GenresShowcase, path: "genres-showcase" },
	],
};

import React, { useCallback, useEffect } from "react";
import strings from "../../helpers/strings";
import { useLogInMutation } from "@espresso/protocol";
import { Grid } from "@material-ui/core";
import useAppContext from "../../contexts/AppContext";
import { regExps } from "@espresso/shared-config";
import { StandartButton, StandartTextInputController } from "../../components";
import { useHistory } from "react-router-dom";
import { useStandartForm } from "../../helpers/useStandartForm";
import { useLoadingSignal } from "contexts/LoadingContext";

type TLogInFormData = {
	Email: string;
	Password: string;
};

export const LoginForm = () => {
	const { session, setSession, setLogin, clearSession } = useAppContext();
	const history = useHistory();

	const [logIn, { data, loading }] = useLogInMutation();
	useLoadingSignal(loading);

	useEffect(() => {
		if (data?.logIn.data) {
			const token = data.logIn.data.token;
			const id = data.logIn.data.authId;

			if (session?.auth?.id !== id || session?.auth?.token !== token) {
				setSession({ auth: { token, id } });
				setLogin(data.logIn.data.Login);
			}
			history.push("/profile");
		}
	}, [data, history, session, setLogin, setSession]);

	const form = useStandartForm<TLogInFormData>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			Email: "",
			Password: "",
		},
	});
	const { handleSubmit, errors, control } = form;

	const submit = useCallback(
		async (data: TLogInFormData) => {
			try {
				await clearSession(false);
				await logIn({ variables: { data } });
			} catch (ex) {
				console.log(ex);
			}
		},
		[clearSession, logIn],
	);

	return (
		<Grid item md={12}>
			<form onSubmit={handleSubmit(submit)}>
				<StandartTextInputController
					name="Email"
					fullWidth
					control={control}
					rules={{
						required: strings.required,
						pattern: {
							value: regExps.email,
							message: strings.wrongEmail,
						},
					}}
					errorMessage={errors.Email?.message}
					label={strings.email}
					pattern={regExps.email}
					margin="normal"
					placeholder="email@example.com"
					autoFocus
				/>
				<StandartTextInputController
					name="Password"
					type="password"
					fullWidth
					control={control}
					rules={{
						required: strings.required,
					}}
					errorMessage={errors.Password?.message}
					label={strings.auth_password}
					margin="normal"
				/>
				<StandartButton fullWidth type="submit" color="success" size="large">
					{strings.web_menuLogIn}
				</StandartButton>
			</form>
		</Grid>
	);
};

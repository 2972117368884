import { GameForStatFragment, SpendForStatFragment, TenjinMetricsForStatFragment } from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";

export type TGameMetricNames =
	| keyof Omit<TenjinMetricsForStatFragment, "__typename" | "Id" | "ReportDate">
	| keyof Omit<SpendForStatFragment, "__typename" | "Id">;

type TGameMetricParams = {
	titleKey: keyof TLocale;
	target?: string;
	check?: (value: number) => boolean;
	unit: string;
	info?: string;
	format?: (value: number) => string;
};

export const getGameMetric = (game: GameForStatFragment, metricName: TGameMetricNames) => {
	const metric =
		game.TenjinMetrics[0][metricName as keyof TenjinMetricsForStatFragment] ||
		game.Spend[0][metricName as keyof SpendForStatFragment];

	return metric?.toString() ? parseFloat(metric.toString()) : null;
};

export const gameMetricsConfig: { [key in TGameMetricNames]: TGameMetricParams } = {
	Spend: {
		titleKey: "proDashboard_gameMetricSpend",
		unit: "$",
	},
	Value: {
		titleKey: "proDashboard_gameSpendValue",
		unit: "$",
	},
	TotalSpend: {
		titleKey: "proDashboard_gameMetricTotalSpend",
		unit: "$",
	},
	AdRevenue: {
		titleKey: "proDashboard_gameMetricAdRevenue",
		unit: "$",
	},
	Roas: {
		titleKey: "proDashboard_gameMetricRoas",
		unit: "%",
		check: (value: number) => value > 100,
	},
	RoasD120: {
		titleKey: "proDashboard_gameMetricRoasD120",
		unit: "%",
		check: (value: number) => value > 100,
	},
	RetentionD1: {
		titleKey: "proDashboard_gameMetricRetentionD1",
		unit: "%",
		check: (value: number) => value > 30,
	},
	RetentionD3: {
		titleKey: "proDashboard_gameMetricRetentionD3",
		unit: "%",
	},
	RetentionD7: {
		titleKey: "proDashboard_gameMetricRetentionD7",
		unit: "%",
	},
	UsersD0: {
		titleKey: "proDashboard_gameMetricUsersD0",
		unit: "",
	},
	UsersD1: {
		titleKey: "proDashboard_gameMetricUsersD1",
		unit: "",
	},
	UsersD3: {
		titleKey: "proDashboard_gameMetricUsersD3",
		unit: "",
	},
	UsersD7: {
		titleKey: "proDashboard_gameMetricUsersD7",
		unit: "",
	},
	Income: {
		titleKey: "proDashboard_gameMetricIncome",
		unit: "$",
		check: (value: number) => value > 0,
	},
};

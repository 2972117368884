import React from "react";
import { useRouteMatch } from "react-router-dom";

import { AppLink } from "components";
import { HelpPage } from "./HelpPage";
import strings from "helpers/strings";
import { THelpSection } from "./types";

export const SectionPage = (props: { section: THelpSection }) => {
	const { section } = props;

	const { url } = useRouteMatch();

	return (
		<HelpPage titleKey={section.titleKey}>
			<ul className="topics-list MuiTypography-body1">
				{section.topics.map((topic, index) => (
					<AppLink key={`topic-${index}`} to={`${url}/${topic.path}`}>
						<li>{strings[topic.titleKey]}</li>
					</AppLink>
				))}
			</ul>
		</HelpPage>
	);
};

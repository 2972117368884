import React from "react";
import { Typography } from "@material-ui/core";
import { PARALLAX_LANDING_MEDIA_CONTENT } from "../../helpers/constants";

const email = "publishing@espresso-pub.com";

export const LandingPlaceholder = () => {
	return (
		<div className="app-container">
			<div
				className="landing-placeholder filter"
				id="videoContainer"
				style={{
					backgroundImage: "url(" + PARALLAX_LANDING_MEDIA_CONTENT.image + ")",
				}}
			>
				<div className="video filter">
					<video autoPlay loop muted playsInline>
						<source src={PARALLAX_LANDING_MEDIA_CONTENT.video.h264} type="video/mp4" />
						<source src={PARALLAX_LANDING_MEDIA_CONTENT.video.h265} type="video/mp4" />
						<source src={PARALLAX_LANDING_MEDIA_CONTENT.video.vp8} type="video/webm" />
					</video>
				</div>

				<div className="content">
					<div></div>
					<div className="container">
						<div className="top-circle"></div>
						<div className="logo">
							<img className="image" src="/logo180.png" alt="logo" />
							<img className="name" src="/images/logo-name.png" alt="logo" />
						</div>
						<div className="email-container">
							<Typography variant="h4" className="text">
								Something awesome <br />
								is coming soon!
							</Typography>
							<Typography variant="subtitle2" className="text">
								Contact us
							</Typography>
							<a className="url-text" href={`mailto:${email}`} target="_blank" rel="noreferrer">
								<Typography variant="h5" className="text">
									{email}
								</Typography>
							</a>
						</div>
					</div>
					<div className="footer">
						<Typography className="copyrights">
							All right reserved, Dromaxco Solutions Ltd © 2021
						</Typography>
						<img className="bottom-circle" src="/images/rings.png" alt="rings" />
					</div>
				</div>
			</div>
		</div>
	);
};

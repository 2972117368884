import { ECampaignType, EDeveloperPlatform } from "@espresso/protocol";
import { EMessagerType, ISwitchBarItem } from "./types";

export const messageTypes: ISwitchBarItem<EMessagerType>[] = [
	{ label: "skype", value: EMessagerType.Skype },
	{ label: "telegram", value: EMessagerType.Telegram },
	{ label: "whatsApp", value: EMessagerType.WhatsApp },
];

export const booleanSwitchItems: ISwitchBarItem<boolean>[] = [
	{ label: "yes", value: true },
	{ label: "no", value: false },
];

export const platformSwitchItems: ISwitchBarItem<EDeveloperPlatform>[] = [
	{ label: "android", value: EDeveloperPlatform.Android },
	{ label: "ios", value: EDeveloperPlatform.Ios },
	{ label: "bothPlatform", value: EDeveloperPlatform.Both },
];

export const campaignTypeSwitchItems: ISwitchBarItem<ECampaignType>[] = [
	{ label: "request_metricCTR", value: ECampaignType.Ctr },
	{ label: "request_metricCPI", value: ECampaignType.Cpi },
];

export const requestPrivateItems: ISwitchBarItem<ECampaignType>[] = [
	{ label: "request_metricCPI", value: ECampaignType.Cpi },
	{ label: "request_metricCTR", value: ECampaignType.Ctr },
	{ label: "request_metricR1", value: ECampaignType.R1 },
];

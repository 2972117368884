import React from "react";
import { Switch, Route, useRouteMatch } from "react-router-dom";

import { PageNotFoundView } from "views/PageNotFoundView";

import { ContentsPage } from "./ContentsPage";
import { SectionPage } from "./SectionPage";
import { TopicPage } from "./TopicPage";
import { helpSections } from "./sections";

export const HelpView = () => {
	const { path } = useRouteMatch();

	return (
		<Switch>
			{helpSections.map((section, sidx) =>
				section.topics.map((topic, tidx) => (
					<Route exact key={`top-${sidx}-${tidx}`} path={`${path}/${section.path}/${topic.path}`}>
						<TopicPage topic={topic} />
					</Route>
				)),
			)}
			{helpSections.map((section, idx) => (
				<Route exact key={`sec-${idx}`} path={`${path}/${section.path}`}>
					<SectionPage section={section} />
				</Route>
			))}
			<Route exact path={path}>
				<ContentsPage />
			</Route>
			<Route>
				<PageNotFoundView />
			</Route>
		</Switch>
	);
};

import { Box, CircularProgress, Grid, Paper, Typography } from "@material-ui/core";
import React, { useCallback, useEffect } from "react";
import {
	useGetMyPublisherQuery,
	useUpsertPublisherMutation,
	defaultUpsertPublisherInput,
	UpsertPublisherInput,
	useGetQueueTasksCountQuery,
	ELoginRole,
} from "@espresso/protocol";
import {
	FullScreenPlaceholder,
	StandartButton,
	StandartTextInputController,
	StandartView,
	StatusBadge,
} from "../../components";
import strings from "../../helpers/strings";
import { useStandartForm } from "../../helpers/useStandartForm";
import useAppContext from "../../contexts/AppContext";
import { mergeKeepShape } from "@espresso/shared-config/dist";
import { Controller } from "react-hook-form";
import { MonitoringView } from "./MonitoringView";
import { Prompt, useHistory } from "react-router-dom";
import { LoginForm } from "./LoginForm";
import { useLoadingSignal } from "contexts/LoadingContext";

export const PublisherEditView = () => {
	const { session, login, setInfoSnackbarParams, showExitPrompt, setShowExitPrompt } = useAppContext();
	const history = useHistory();

	const { data, loading: loadingPublisher } = useGetMyPublisherQuery();
	const { data: tasksCountData, loading: loadingTasksCount } = useGetQueueTasksCountQuery();

	const loading = loadingPublisher || loadingTasksCount;
	useLoadingSignal(loading);

	const [upsertPublisher, { loading: upserting }] = useUpsertPublisherMutation();
	useLoadingSignal(upserting);

	const form = useStandartForm<UpsertPublisherInput>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: {
			Id: "",
			Name: "",
			AdvertId: "",
			FacebookAccountId: "",
			FacebookToken: "",
		},
	});

	useEffect(() => {
		return () => {
			setShowExitPrompt(false);
		};
	}, [setShowExitPrompt]);

	useEffect(() => {
		setShowExitPrompt(form.formState.isDirty);
	}, [form.formState.isDirty, setShowExitPrompt]);

	useEffect(() => {
		return () => {
			setInfoSnackbarParams({ open: false, message: strings.changesSaved });
		};
	}, [setInfoSnackbarParams]);

	useEffect(() => {
		if (!data?.getMyPublisher || !tasksCountData || loading) {
			return;
		}
		const publisher = data.getMyPublisher;
		form.setValue("Id", publisher.Id || "");
		form.setValue("Name", publisher.Name);
		form.setValue("AdvertId", publisher.AdvertId || "");
		form.setValue("FacebookAccountId", publisher.FacebookAccountId || "");
		form.setValue("FacebookToken", publisher.FacebookToken || "");
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [data?.getMyPublisher, loading, tasksCountData]);

	const { handleSubmit, errors, control, reset } = form;

	const submit = useCallback(
		async (data: UpsertPublisherInput) => {
			const upsertPublisherInput = mergeKeepShape(defaultUpsertPublisherInput, {
				Id: data.Id || undefined,
				Name: data.Name,
				AdvertId: data.AdvertId || undefined,
				FacebookAccountId: data.FacebookAccountId || undefined,
				FacebookToken: data.FacebookToken || undefined,
			});
			const res = await upsertPublisher({ variables: { data: upsertPublisherInput } });

			if (res.errors || !res.data?.upsertPublisher.success) {
				return;
			}

			setInfoSnackbarParams({ open: true, message: strings.changesSaved });
			reset({ ...data });
		},
		[reset, setInfoSnackbarParams, upsertPublisher],
	);

	if ((!login && session) || ((!data || !tasksCountData) && loading)) {
		return (
			<StandartView disableNotifications title={strings.publisher_ProfileTitle}>
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="developer_emptyTitle" />
				</Grid>
			</StandartView>
		);
	}

	if (login?.Role !== ELoginRole.Publisher) {
		return (
			<StandartView
				disableNotifications
				title={strings.publisher_authTitle}
				pageBreakpoints={{ xs: 12, sm: 8, md: 6 }}
				backgroundImage="/images/signin_background.png"
			>
				<Grid container className="publisher-login-view">
					<LoginForm />
				</Grid>
			</StandartView>
		);
	}

	const clickRemovedRequests = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		history.push("/deleted-requests");
	};

	const clickDeveloperEdit = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		history.push("/developer/edit");
	};

	return (
		<StandartView disableNotifications title={strings.publisher_ProfileTitle}>
			<form className="publisher-view" onSubmit={handleSubmit(submit)}>
				<Grid container spacing={4}>
					<Grid item md={6} sm={12} className="publisher-settings-container">
						<Typography variant="subtitle1">{strings.publisher_settings}</Typography>
						<Controller name="Id" control={control} render={(_props) => <></>} />
						<StandartTextInputController
							name="Name"
							control={control}
							rules={{
								required: strings.required,
							}}
							errorMessage={errors.Name?.message}
							label={strings.publisher_Name}
							margin="normal"
						/>
						<Box className="status-row">
							<StandartTextInputController
								name="AdvertId"
								control={control}
								errorMessage={errors.AdvertId?.message}
								label={strings.publisher_AdvertId}
								margin="normal"
							/>
							<StatusBadge status={data?.getMyPublisher?.AdvertIdStatus} />
						</Box>
						<Box className="status-row">
							<StandartTextInputController
								name="FacebookAccountId"
								control={control}
								errorMessage={errors.FacebookAccountId?.message}
								label={strings.publisher_FacebookAccountId}
								margin="normal"
							/>
							<StatusBadge status={data?.getMyPublisher?.FacebookAccountIdStatus} />
						</Box>
						<Box className="status-row">
							<StandartTextInputController
								name="FacebookToken"
								control={control}
								errorMessage={errors.FacebookToken?.message}
								label={strings.publisher_FacebookToken}
								margin="normal"
							/>
							<StatusBadge status={data?.getMyPublisher?.FacebookTokenStatus} />
						</Box>
					</Grid>
					<MonitoringView tasksCountData={tasksCountData?.getQueueTasksCount} />
					<Grid item md={6} xs={12}>
						<Paper className="removed-requests">
							<Typography variant="subtitle2">{strings.request_removedListTitle}</Typography>
							<StandartButton
								variant="contained"
								color="secondary"
								onClick={clickRemovedRequests}
								href="/deleted-requests"
							>
								{strings.developerDashboard_Show}
							</StandartButton>
						</Paper>
					</Grid>
					<Grid item md={6} xs={12}>
						<Paper className="removed-requests">
							<Typography variant="subtitle2">
								{strings.developer_profileTitle} <b>{login?.Developer?.TeamName || ""}</b>
							</Typography>
							<StandartButton
								variant="contained"
								color="secondary"
								onClick={clickDeveloperEdit}
								href="/developer/edit"
							>
								{strings.editAction}
							</StandartButton>
						</Paper>
					</Grid>
				</Grid>
				<Box className="MuiFormControl-marginNormal">
					<StandartButton className="button-width-medium" color="success" type="submit">
						{loading && <CircularProgress color="inherit" size={20} />}
						{strings.save}
					</StandartButton>
				</Box>
				<Prompt
					when={showExitPrompt}
					message={(locationNext, historyAction) => {
						return JSON.stringify({ historyAction, locationNext, currentLocation: history.location });
					}}
				/>
			</form>
		</StandartView>
	);
};

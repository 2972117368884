import {
	AttachmentFullFragment,
	ECampaignType,
	ECheckStatus,
	EFileType,
	ERequestStatus,
	RequestFullFragment,
	RequestMetricsFullFragment,
	UpsertRequestInput,
	useGetRequestHistoryLazyQuery,
} from "@espresso/protocol";
import moment from "moment";
import { checkVideoDimensionsValid, checkVideoLengthValid, loadConfig, TLocale } from "@espresso/shared-config";
import strings from "./strings";
import { useEffect, useState } from "react";
import { ctrRequestSteps, cpiRequestSteps, r1RequestSteps } from "helpers/constants";

const sharedConfig = loadConfig(process.env.REACT_APP_CHANNEL || "local");

export type TStatusHistory = { [key in ERequestStatus]?: string | null };

export interface IRequestCampaignTypeItem {
	iconName: string;
	titleKey: keyof TLocale;
	tooltipInfoKey: keyof TLocale;
	listKeys?: Array<keyof TLocale>;
}
export interface IRequestCampaignTypeConfig {
	itemProps: IRequestCampaignTypeItem;
	input: UpsertRequestInput;
	nextStepStatus: ERequestStatus;
}
const finishedStatuses: ERequestStatus[] = [
	ERequestStatus.CampaignFinished,
	ERequestStatus.Finished,
	ERequestStatus.RunFailed,
	ERequestStatus.Rejected,
];

const campaignTypeStepsMap: { [key in ECampaignType]: { labelkey: keyof TLocale; statuses: ERequestStatus[] }[] } = {
	Ctr: ctrRequestSteps,
	Cpi: cpiRequestSteps,
	R1: r1RequestSteps,
};

export const isRequestRunningOrFinished = (request: Pick<RequestFullFragment, "Status">) => {
	return request && (request.Status === ERequestStatus.Running || finishedStatuses.includes(request.Status));
};

export const analyticsStatus = (request: Pick<RequestFullFragment, "LastStatsAt">) => {
	if (!request.LastStatsAt) {
		return ECheckStatus.NotValid;
	}
	const lastStatsAt = moment.utc(request.LastStatsAt).local();
	if (lastStatsAt.isBefore(moment().add(-sharedConfig.request.appStatsThresholds[1], "hour"))) {
		return ECheckStatus.NotValid;
	}
	if (lastStatsAt.isBefore(moment().add(-sharedConfig.request.appStatsThresholds[0], "hour"))) {
		return ECheckStatus.Checking;
	}
	return ECheckStatus.Valid;
};

export const validateAttachments = (
	attachments: AttachmentFullFragment[],
	processingAttachments?: string[],
	metrics?: RequestMetricsFullFragment[],
): string | undefined => {
	let hasVideoAttach = false;

	for (const attachment of attachments) {
		if (attachment.FileType === EFileType.Video) {
			hasVideoAttach = true;

			if (attachment.Url && !attachment.PreviewUrl) {
				return strings.request_cropFailedStatusText;
			}

			if (
				attachment.Metadata?.Height &&
				attachment.Metadata.Width &&
				attachment.Metadata.Length &&
				(!checkVideoDimensionsValid(attachment.Metadata.Height, attachment.Metadata.Width) ||
					!checkVideoLengthValid(attachment.Metadata.Length)) &&
				!processingAttachments?.some((item) => item === attachment.Id)
			) {
				return strings.request_wrongVideoDimensions;
			}

			if (metrics) {
				const metric = metrics.find((m) => m.AttachmentId === attachment.Id);
				if (!metric?.FBCampaignId) {
					return strings.request_notAllVideoHaveAd;
				}
			}
		}
	}
	if (hasVideoAttach) {
		return;
	}
	return strings.standartFormat(strings.request_needVideo, {
		max: sharedConfig.request.maxVideos.toString(),
	});
};

export const useRequestStatusHistory = (
	request?: Pick<RequestFullFragment, "Id" | "Status" | "CreatedAt" | "CampaignType"> | null,
) => {
	const [statusHistory, setStatusHistory] = useState<TStatusHistory>();
	const [getHistory, { data }] = useGetRequestHistoryLazyQuery({ fetchPolicy: "cache-and-network" });

	useEffect(() => {
		if (!request) {
			return;
		}
		getHistory({
			variables: { input: { ObjectId: request?.Id, FieldName: ["Status"] } },
		});
	}, [getHistory, request]);

	useEffect(() => {
		if (!request) {
			return;
		}
		if (!data) {
			return;
		}

		const latestStepIndex = campaignTypeStepsMap[request.CampaignType].reduce<number>((latestIdx, step, curIdx) => {
			if (
				step.statuses.some((status) => data.getRequestHistory.some((item) => status === item.NewValue)) ||
				step.statuses.includes(request.Status)
			) {
				return curIdx;
			}
			return latestIdx;
		}, 0);

		const newHistory = data.getRequestHistory.reduce<TStatusHistory>(
			(res, item) => {
				if (item.NewValue && !res[item.NewValue as ERequestStatus]) {
					res[item.NewValue as ERequestStatus] = item.CreatedAt;
				}
				return res;
			},
			{ Initial: request.CreatedAt },
		);

		const prevStatusesMap = campaignTypeStepsMap[request.CampaignType].reduce<
			{ [key in ERequestStatus]?: ERequestStatus[] }
		>((result, currentStep, idx, steps) => {
			const { statuses: curStepStatuses } = currentStep;
			const prevStatuses: ERequestStatus[] = steps[idx - 1]?.statuses ?? [];

			curStepStatuses.forEach((s) => {
				result[s] = prevStatuses;
			});
			return result;
		}, {});

		for (let i = 0; i < latestStepIndex; i++) {
			const step = campaignTypeStepsMap[request.CampaignType][i];
			step.statuses.forEach((s) => {
				if (newHistory[s]) {
					return;
				}
				newHistory[s] = prevStatusesMap[s]
					?.map((prevStatus) => newHistory[prevStatus])
					.sort()
					.reverse()[0];
			});
		}
		setStatusHistory(newHistory);
	}, [data, request]);

	return statusHistory;
};

export const requestTypeConfig: Array<IRequestCampaignTypeConfig> = [
	{
		itemProps: {
			iconName: "game_underconstruction",
			titleKey: "request_titlePrivateGame",
			tooltipInfoKey: "request_tooltipInfoPrivateGame",
			listKeys: ["request_infoListGame1"],
		},
		input: {
			Status: ERequestStatus.Private,
			CampaignType: ECampaignType.Ctr,
			FacebookId: null,
			AppStoreUrl: null,
			GooglePlayUrl: null,
			Platform: null,
		},
		nextStepStatus: ERequestStatus.Private,
	},
	{
		itemProps: {
			iconName: "game_ready",
			titleKey: "request_titlePublicGame",
			tooltipInfoKey: "request_tooltipInfoPublicGame",
			listKeys: ["request_infoListGame0", "request_infoListGame1", "request_infoListGame2"],
		},
		input: { Status: ERequestStatus.InfoFacebook, CampaignType: ECampaignType.Cpi },
		nextStepStatus: ERequestStatus.InfoFacebook,
	},
	{
		itemProps: {
			iconName: "sdk",
			titleKey: "request_titleR1Test",
			tooltipInfoKey: "request_tooltipInfoR1Test",
			listKeys: [
				"request_infoListGame0",
				"request_infoListGame1",
				"request_infoListGame2",
				"request_infoListGame3",
			],
		},
		input: { Status: ERequestStatus.SetSDK, CampaignType: ECampaignType.R1 },
		nextStepStatus: ERequestStatus.SetSDK,
	},
];

import React from "react";
import { Box, IconButton } from "@material-ui/core";

export const VideoControlButtons = (props: {
	onToStart: () => void;
	onToEnd: () => void;
	onPlayPause: () => void;
	playing: boolean;
}) => {
	const { onToStart, onToEnd, onPlayPause, playing } = props;

	const playIconClass = playing ? "icon-pause" : "icon-play-1";

	return (
		<Box className="button-group">
			<IconButton onClick={onToStart}>
				<i className="icon icon-next" />
			</IconButton>
			<IconButton onClick={onPlayPause}>
				<i className={`icon ${playIconClass}`} />
			</IconButton>
			<IconButton onClick={onToEnd}>
				<i className="icon icon-previous" />
			</IconButton>
		</Box>
	);
};

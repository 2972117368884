import React from "react";

import useAppContext from "contexts/AppContext";
import { SetupSdkManualModalContent } from "views/RequestView/CreateRequest/CreateRequestFormInfoFacebook/SdkWizard/ModalContent";

export const NonUnityGames = () => {
	const { sharedConfig, login } = useAppContext();
	const apiKey = login?.Developer?.ApiKey || "{api-key}";

	const manualStatsEndpoint = `${sharedConfig.endpoints.graphqlUseSSL ? "https" : "http"}://${
		sharedConfig.endpoints.graphqlHost
	}/api/v1/${apiKey}/event/{facebook-app-id}`;

	return (
		<SetupSdkManualModalContent
			manualStatsEndpoint={manualStatsEndpoint}
			apiKey={apiKey}
			facebookId="{facebook-app-id}"
		/>
	);
};

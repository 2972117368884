import { formatNumber } from "@espresso/shared-config";
import { Collapse, TableCell, TableRow, Tooltip } from "@material-ui/core";
import classNames from "classnames";
import React, { useState } from "react";
import { DeveloperRequestFragment } from "@espresso/protocol";
import { TMetricName, TMetricParams } from "../../helpers/metrics";
import { MetricsTableExpandBlock } from "./MetricsTableExpandBlock/MetricsTableExpandBlock";

export interface IMetricsTableRowProps extends TMetricParams {
	request: DeveloperRequestFragment;
	metricName?: TMetricName;
	mainRow?: boolean;
	detailsRow?: boolean;
	additionalRow?: boolean;
	value: number | null | undefined;
}

export const MetricsTableRow = React.memo((props: IMetricsTableRowProps) => {
	const {
		title,
		value,
		target,
		getGrade,
		unit,
		info,
		mainRow,
		detailsRow,
		additionalRow,
		request,
		metricName,
		warnShow,
		warn,
		format,
	} = props;
	const [descriptionVisible, setDescriptionVisible] = useState(false);
	const checkValueResult = !!value && getGrade(value);
	const warnResult = !!warnShow && warnShow(request);
	const rowClassNames = classNames(
		{
			mainRow,
			detailsRow,
			additionalRow,
			warning: warnResult,
		},
		checkValueResult,
	);
	const descriptionClassNames = classNames("description", { hidden: !descriptionVisible });

	let textValue = "—";

	if (value != null) {
		textValue = format?.(value) || formatNumber(value.toFixed(2), unit);
	}

	return (
		<>
			<TableRow className={rowClassNames} onClick={() => info && setDescriptionVisible((prev) => !prev)}>
				<TableCell className="cell-fill" />
				<TableCell className="cell-title">{title}</TableCell>
				<TableCell className="cell-value">
					{warnResult && warn ? (
						<Tooltip title={warn} placement="bottom">
							<div>
								<i className="icon icon-attention" />
								<span>{textValue}</span>
							</div>
						</Tooltip>
					) : (
						<div>
							<span>{textValue}</span>
						</div>
					)}
				</TableCell>
				<TableCell className="cell-info">{target ? `${target} ${unit}` : ""}</TableCell>
				<TableCell className="cell-icon">
					{info && (
						<div>
							<i className="icon icon-info-outline" />
						</div>
					)}
				</TableCell>
			</TableRow>
			<TableRow className={descriptionClassNames}>
				<TableCell colSpan={5}>
					<Collapse in={descriptionVisible} timeout="auto" unmountOnExit>
						<MetricsTableExpandBlock
							info={info}
							visible={descriptionVisible}
							metricName={metricName}
							request={request}
						/>
					</Collapse>
				</TableCell>
			</TableRow>
		</>
	);
});

import React, { useEffect, useMemo, useState } from "react";
import { Box, CircularProgress, Dialog, IconButton, Typography } from "@material-ui/core";
import ReactCrop from "react-image-crop";
import strings from "helpers/strings";
import { AttachmentFullFragment, useInsertVideoConvertTaskMutation } from "@espresso/protocol";
import { StandartButton } from "components";
import useAppContext from "contexts/AppContext";
import { VideoCrop } from "./VideoCrop";

interface IGalleryCropDialogProps {
	open: boolean;
	attachment: AttachmentFullFragment | null | undefined;
	onClose: () => void;
	refetchAttachments: () => void;
}

export const GalleryCropDialog = (props: IGalleryCropDialogProps) => {
	const { onClose, attachment, open, refetchAttachments } = props;

	const {
		sharedConfig: {
			attachments: { videoMaxHeight, videoMaxWidth, videoMinLength, videoMaxLength },
		},
	} = useAppContext();

	const cropAspect = videoMaxWidth / videoMaxHeight;

	const [cropMutation, { loading }] = useInsertVideoConvertTaskMutation();

	const initialCrop: ReactCrop.Crop = useMemo(() => {
		const cropConfig: ReactCrop.Crop = { unit: "%", aspect: cropAspect };

		if (attachment?.Metadata?.Height && attachment.Metadata?.Width) {
			const imageAspect = attachment.Metadata.Width / attachment.Metadata.Height;

			if (imageAspect < cropAspect) {
				cropConfig.width = 100;
				cropConfig.y = 50 * (1 - imageAspect / cropAspect);
			} else {
				cropConfig.height = 100;
				cropConfig.x = 50 * (1 - cropAspect / imageAspect);
			}
		}
		return cropConfig;
	}, [attachment, cropAspect]);

	const [crop, setCrop] = useState<ReactCrop.Crop>(initialCrop);
	const [timeCrop, setTimeCrop] = useState<[number, number] | undefined>();
	const [selectedFragmentDuration, setSelectedFragmentDuration] = useState(0);

	useEffect(() => {
		setCrop(initialCrop);
		setSelectedFragmentDuration(0);
	}, [initialCrop, attachment]);

	const handleSelectionChange = React.useCallback(
		([start, end]: [number, number]) => {
			const duration = Math.round(end - start);
			if (duration !== selectedFragmentDuration) {
				setSelectedFragmentDuration(duration);
			}
		},
		[selectedFragmentDuration],
	);

	if (!attachment?.Url) {
		return null;
	}

	const doneCrop = async () => {
		if (
			!crop.height ||
			!crop.width ||
			!attachment?.Metadata?.Height ||
			!attachment.Metadata.Width ||
			crop.x == null ||
			crop.y == null
		) {
			return;
		}

		const result = await cropMutation({
			variables: {
				data: {
					Id: attachment.Id,
					DeltaX: Math.floor((crop.x * attachment.Metadata.Height) / crop.height),
					DeltaY: Math.floor((crop.y * attachment.Metadata.Width) / crop.width),
					StartSeconds: timeCrop && timeCrop[0],
					EndSeconds: timeCrop && timeCrop[1],
				},
			},
		});
		if (result.data?.insertVideoConvertTask.success) {
			refetchAttachments();
			onClose();
		}
	};

	const isVideoCropAvailable =
		attachment.Metadata?.Width !== videoMaxWidth || attachment.Metadata.Height !== videoMaxHeight;
	const isSelectedFragmentMinOrMaxLength =
		selectedFragmentDuration === videoMinLength || selectedFragmentDuration === videoMaxLength;
	const selectedFragmentDurationString = `${Math.round(selectedFragmentDuration)} ${strings.sec}`;
	const videoDimensionsString = `${attachment.Metadata?.Width || ""}x${attachment.Metadata?.Height || ""}px`;

	return (
		<Dialog onClose={() => onClose()} className="gallery-dialog-crop" open={open}>
			<Typography variant="h5">{strings.request_cropVideoTitle}</Typography>
			<Box className="content">
				<Typography paragraph>
					{strings.formatString(
						strings.request_cropVideoDimensions,
						<b className={isVideoCropAvailable ? "text-danger" : "text-success"}>
							{videoDimensionsString}
						</b>,
					)}
				</Typography>
				{isVideoCropAvailable ? (
					<Typography color="secondary" variant="body2">
						{strings.formatString(
							strings.request_cropVideoDimensionsNewSize,
							videoMaxWidth,
							videoMaxHeight,
						)}
					</Typography>
				) : (
					<Typography color="textSecondary" variant="body2">
						{strings.request_cropVideoDimensionsSameSize}
					</Typography>
				)}
				<VideoCrop
					attachment={attachment}
					crop={crop}
					setCrop={setCrop}
					timeCrop={timeCrop}
					setTimeCrop={setTimeCrop}
					onSelectionChange={handleSelectionChange}
					isSelectionAtBoundaries={isSelectedFragmentMinOrMaxLength}
				/>
				<Typography paragraph>
					{strings.formatString(
						strings.request_cropVideoLength,
						<b className={isSelectedFragmentMinOrMaxLength ? "text-warning" : "text-info-2"}>
							{selectedFragmentDurationString}
						</b>,
					)}
				</Typography>
				<Typography
					className={isSelectedFragmentMinOrMaxLength ? "text-warning" : ""}
					color="secondary"
					variant="body2"
				>
					{strings.formatString(
						strings.request_cropVideoLengthInfo,
						videoMinLength.toString(),
						videoMaxLength.toString(),
					)}
				</Typography>
				<Box className="requirements-message" display="flex" alignItems="center">
					<i className="icon icon-info" />
					<Typography variant="body2" color="secondary">
						{strings.request_cropVideoInfo}
					</Typography>
				</Box>
			</Box>

			<StandartButton color="success" onClick={doneCrop}>
				{loading && <CircularProgress color="inherit" size={16} />}
				{strings.save}
			</StandartButton>
			<IconButton onClick={() => onClose()} className="close-button">
				<i className="icon icon-close" />
			</IconButton>
		</Dialog>
	);
};

import React, { useEffect, useState } from "react";
import { Avatar, Box, Icon, IconButton, Typography } from "@material-ui/core";

import { AttachmentFullFragment, EFileType, EGenre, useGetAttachmentsByGenreQuery } from "@espresso/protocol";
import strings from "helpers/strings";
import { useLoadingSignal } from "../../../../contexts/LoadingContext";
import { GENRE_LIST_ITEMS } from "../../../../helpers/constants";
import { GalleryPlayerDialog, StandartAccordion } from "../../../../components";

export const GenresShowcase = () => {
	const { data, loading, refetch } = useGetAttachmentsByGenreQuery({ fetchPolicy: "cache-first" });

	const [genresGallery, setGenresGallery] = useState<{ [key in EGenre]?: AttachmentFullFragment[] }>({});
	const [openedAttachment, setOpenedAttachment] = useState<AttachmentFullFragment>();

	useLoadingSignal(loading);

	useEffect(() => {
		if (!data) {
			return;
		}
		const newGenresGallery: { [key in EGenre]?: AttachmentFullFragment[] } = {};
		for (const attachment of data.getAttachmentsByGenre) {
			if (!attachment.Genre) {
				continue;
			}
			if (!newGenresGallery[attachment.Genre]) {
				newGenresGallery[attachment.Genre] = [attachment];
			} else {
				newGenresGallery[attachment.Genre]?.push(attachment);
			}
		}
		setGenresGallery(newGenresGallery);
	}, [data]);

	const openFull = (attachment: AttachmentFullFragment) => {
		setOpenedAttachment(attachment);
	};

	const closeFull = () => {
		setOpenedAttachment(undefined);
	};

	return (
		<>
			{Object.values(GENRE_LIST_ITEMS).map((conf, genreIdx) => (
				<StandartAccordion key={genreIdx} titleKey={conf.label} className="genre-container">
					{conf.description && <Typography variant="body1">{strings[conf.description]}</Typography>}
					{conf.typicalGame && conf.typicalStudio && (
						<Typography variant="body1">
							{strings.formatString(strings.genre_typicalGame, {
								game: (
									<a className="link" href={conf.typicalGameUrl} target="_blank" rel="noreferrer">
										<i className="icon icon-link" />
										<b>{conf.typicalGame}</b>
									</a>
								),
								studio: <b>{conf.typicalStudio}</b>,
							})}
						</Typography>
					)}
					{genresGallery[conf.value as EGenre]?.length && (
						<>
							<Typography variant="body1" color="textSecondary">
								<b>{strings.help_configurationGenresShowcaseVideos}</b>
							</Typography>
							<Box className="previews">
								{genresGallery[conf.value as EGenre]?.map((attachment, attachIdx) =>
									attachment.PreviewUrl ? (
										<Box key={attachIdx} className="preview-container">
											<Avatar alt={attachment.FileName} src={attachment.PreviewUrl} />
											<IconButton
												onClick={() =>
													attachment?.FileType === EFileType.Video && openFull(attachment)
												}
											>
												<i className="icon icon-play" />
											</IconButton>
										</Box>
									) : (
										<Icon key={attachIdx}>
											<i className="icon icon-file_apk" />
										</Icon>
									),
								)}
							</Box>
						</>
					)}
				</StandartAccordion>
			))}
			<GalleryPlayerDialog
				attachment={openedAttachment}
				onClose={closeFull}
				open={!!openedAttachment}
				afterUpsert={refetch}
			/>
		</>
	);
};

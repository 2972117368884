import React from "react";
import { Box, Typography } from "@material-ui/core";

type TProps = {
	title?: string;
	subtitle?: string;
	name?: string | null;
	text?: string;
	children?: React.ReactNode;
};

export const StandartInfoBlock = (props: TProps) => {
	const { title, subtitle, name, text, children } = props;

	return (
		<Box className="standart-info-block">
			<div className={children ? "texts-margin" : undefined}>
				{!!title && (
					<Typography align="center" variant="h5">
						{title}
					</Typography>
				)}
				{!!subtitle && <Typography align="center">{subtitle}</Typography>}
				{!!name && (
					<Typography align="center" variant="subtitle1">
						{name}
					</Typography>
				)}
				{!!text && <Typography>{text}</Typography>}
			</div>
			{children}
		</Box>
	);
};

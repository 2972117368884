import React from "react";

import strings from "helpers/strings";
import useAppContext from "contexts/AppContext";
import { ELang } from "@espresso/protocol";

export const FacebookIdModalContent = () => {
	const imgSources: { [key in ELang]: string[] } = {
		Ru: ["/images/facebookAppIdRu0.png", "/images/facebookAppIdRu1.png", "/images/facebookAppIdRu2.png"],
		En: ["/images/facebookAppIdEn0.png", "/images/facebookAppIdEn1.png", "/images/facebookAppIdEn2.png"],
		Ch: ["/images/facebookAppIdCh0.png", "/images/facebookAppIdCh1.png", "/images/facebookAppIdCh2.png"],
		Tr: ["/images/facebookAppIdEn0.png", "/images/facebookAppIdEn1.png", "/images/facebookAppIdEn2.png"],
	};

	const { lang } = useAppContext();

	return (
		<ol className="MuiTypography-body1">
			<li>
				{strings.formatString(
					strings.request_createRequestInfoFacebookAccordionAppIdDetails1,
					<a className="link" href="https://developers.facebook.com/" target="_blank" rel="noreferrer">
						<i className="icon icon-link" />
						<b>{strings.request_createRequestInfoFacebookAccordionAppIdDetails1Bold}</b>
					</a>,
				)}
			</li>
			<li>
				{strings.formatString(
					strings.request_createRequestInfoFacebookAccordionAppIdDetails2,
					<b>{strings.request_createRequestInfoFacebookAccordionAppIdDetails2Bold}</b>,
				)}
			</li>
			<li>
				{strings.formatString(
					strings.request_createRequestInfoFacebookAccordionAppIdDetails3,
					<b>{strings.request_createRequestInfoFacebookAccordionAppIdDetails3Bold}</b>,
				)}
			</li>
			<div className="img-wrap">
				<img src={imgSources[lang][0]} alt="" />
			</div>
			<li>
				{strings.formatString(
					strings.request_createRequestInfoFacebookAccordionAppIdDetails4,
					<b>{strings.request_createRequestInfoFacebookAccordionAppIdDetails4Bold}</b>,
				)}
			</li>
			<div className="img-wrap">
				<img src={imgSources[lang][2]} alt="" />
			</div>
		</ol>
	);
};

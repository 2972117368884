import React, { useCallback, useEffect, useMemo, useState } from "react";
import moment from "moment";

import {
	AreaChart,
	Area,
	CartesianGrid,
	ResponsiveContainer,
	XAxis,
	YAxis,
	ReferenceArea,
	BarChart,
	Bar,
	Tooltip,
	TooltipProps,
} from "recharts";
import { Box, Grid, MenuItem, Select, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import classNames from "classnames";

import {
	DeveloperRequestFragment,
	RequestMetricsFullFragment,
	RobustaDailyMetricsFullFragment,
	RobustaLevelMetricsFullFragment,
	useGetRobustaStatQuery,
} from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";
import { getDaysBetweenDates } from "helpers/time";
import strings from "helpers/strings";
import { IStandartButtonProps, PlaceholderView, StandartAccordion, TColor } from "components";
import { TStatusHistory, useRequestStatusHistory } from "helpers/request";

interface IStatsSummaryItemProps {
	labelKey: keyof TLocale;
	value: number;
	valueToMeet?: number;
	unit?: string;
	color?: TColor;
}

const levelsOptions = [5, 10, 30, 50];

const RETENTION_WAITING_LENGTH = 3;
const DEFAULT_TEST_LENGTH = 4;

export const RobustaStatsBlock = (props: { request: DeveloperRequestFragment }) => {
	const { request } = props;
	const metric = request.Metrics.find((m) => !m.AttachmentId);
	const statusHistory = useRequestStatusHistory(request);

	//#region InternalState
	const [from, setFrom] = useState(getDefaultReportPeriodStart());
	const [to, setTo] = useState(getDefaultReportPeriodEnd());
	const [levels, setLevels] = useState(5);

	useEffect(() => {
		setFrom(getDefaultReportPeriodStart(metric, statusHistory));
		setTo(getDefaultReportPeriodEnd(metric, statusHistory));
	}, [metric, statusHistory]);
	//#endregion

	//#region Data Fetching
	const { data, loading } = useGetRobustaStatQuery({
		skip: !request.FacebookId,
		variables: {
			input: {
				AppId: request.FacebookId as string,
				From: from.toISOString(),
				To: to.endOf("day").toISOString(),
			},
		},
		fetchPolicy: "cache-and-network",
	});
	//#endregion

	//#region Helpers
	const maxLevel =
		data?.getRobustaLevelsStat.reduce((max, current) => (current.Level > max ? current.Level : max), 0) ?? 0;

	const levelsToShow = getLevelsToShow(levels, maxLevel);

	const dailyStats = padDailyStatsForEachDay(from, to, data?.getRobustaDailyStat);
	const levelStats = getRelativeLevelStatsForEachLevel(levelsToShow, data?.getRobustaLevelsStat);

	const isRetentionInDailyStats = useMemo(
		() =>
			!!data?.getRobustaDailyStat.length &&
			data.getRobustaDailyStat.some((stat) => stat.RetentionD1 || stat.RetentionD3 || stat.RetentionD7),
		[data?.getRobustaDailyStat],
	);

	const statsSummaryConfig: IStatsSummaryItemProps[] = useMemo(
		() => [
			{
				labelKey: "request_metricInstall",
				value: data?.getRobustaLevelsStat.reduce((total, current) => total + current.Users, 0) ?? 0,
			},
			{
				labelKey: "request_robustaStatsR1Label",
				value: getTotalRetention("RetentionD1", data?.getRobustaDailyStat) * 100,
				valueToMeet: 30,
				unit: "%",
				color: "new",
			},
			{
				labelKey: "request_robustaStatsR3Label",
				value: getTotalRetention("RetentionD3", data?.getRobustaDailyStat) * 100,
				unit: "%",
				color: "warning",
			},
			{
				labelKey: "request_robustaStatsR7Label",
				value: getTotalRetention("RetentionD7", data?.getRobustaDailyStat) * 100,
				unit: "%",
				color: "primary",
			},
		],
		[data?.getRobustaDailyStat, data?.getRobustaLevelsStat],
	);

	const formatLevelString = useCallback(
		(level: number) => strings.formatString(strings.levelN, level.toString()) as string,
		[],
	);

	const testStartDate = getTestPeriodStart(metric, statusHistory);
	const testEndDate = getTestPeriodEnd(metric, statusHistory);
	//#endregion

	return (
		<StandartAccordion titleKey="request_robustaStatsBlockHeader" className="robusta-stats-accordion">
			<Typography variant="subtitle1" className="period-header">
				{strings.request_robustaStatsPeriodHeader}
			</Typography>
			<Box className="pickers-and-summary-container">
				<Box className="pickers-container">
					<DatePicker
						autoOk
						disableToolbar
						maxDateMessage=" "
						minDateMessage=" "
						maxDate={to}
						variant="inline"
						value={from}
						onChange={(value) => {
							if (value) {
								setFrom(value);
							}
						}}
						label={strings.proDashboard_reportPeriodFrom}
						format="DD/MM/YYYY"
						PopoverProps={{ className: "calendar-popover-root" }}
					/>
					<DatePicker
						autoOk
						disableToolbar
						maxDateMessage=" "
						minDateMessage=" "
						minDate={from}
						maxDate={moment.max(
							moment.utc().endOf("day"),
							getDefaultReportPeriodEnd(metric, statusHistory),
						)}
						variant="inline"
						value={to}
						onChange={(value) => {
							if (value) {
								setTo(value);
							}
						}}
						label={strings.proDashboard_reportPeriodTo}
						format="DD/MM/YYYY"
						PopoverProps={{ className: "calendar-popover-root" }}
					/>
				</Box>
				<Box className="summary-container">
					<Box className="summary">
						{statsSummaryConfig.map((config, index) => (
							<StatsSummaryItem key={index} {...config} />
						))}
					</Box>
					<Typography variant="body2">{strings.request_robustaStatsDisclaimer}</Typography>
				</Box>
			</Box>
			<Box className="graph-container retention">
				<Typography variant="subtitle1" className="graph-header">
					{strings.formatString(
						strings.request_robustaStatsRetentionHeader,
						from.format("DD.MM.YY"),
						to.format("DD.MM.YY"),
					)}
				</Typography>
				<Box className="graph">
					{isRetentionInDailyStats && !loading ? (
						<ResponsiveContainer height={285} minWidth={500}>
							<AreaChart data={dailyStats} margin={{ right: 50 }}>
								<defs>
									<linearGradient id="colorR1" x1="0" x2="0" y1="0" y2="1">
										<stop offset="3%" stopColor="#b3e7ff" />
										<stop offset="95%" stopColor="#FFFFFF" />
									</linearGradient>
									<linearGradient id="colorR3" x1="0" x2="0" y1="0" y2="1">
										<stop offset="3%" stopColor="#ffe6b3" />
										<stop offset="95%" stopColor="#FFFFFF" />
									</linearGradient>
									<linearGradient id="colorR7" x1="0" x2="0" y1="0" y2="1">
										<stop offset="3%" stopColor="#a1e6a1" />
										<stop offset="95%" stopColor="#FFFFFF" />
									</linearGradient>
									<linearGradient id="colorDuration" x1="0" x2="0" y1="0" y2="1">
										<stop offset="3%" stopColor="#5F3887" />
										<stop offset="95%" stopColor="#FFFFFF" />
									</linearGradient>
								</defs>
								{testStartDate && testEndDate && (
									<ReferenceArea
										x1={moment.max(from, testStartDate).toISOString()}
										x2={moment.min(to.startOf("day"), testEndDate).toISOString()}
										fill="url(#colorDuration)"
										fillOpacity={0.2}
									/>
								)}
								<CartesianGrid stroke="#ccc" strokeDasharray="3 3" />
								<Area
									dataKey="RetentionD1"
									stroke="#5E5CE6"
									fillOpacity={1}
									fill="url(#colorR1)"
									activeDot={{ stroke: "#5E5CE6", strokeWidth: 4, fill: "#FFFFFF", r: 5 }}
								/>
								<Area
									dataKey="RetentionD3"
									stroke="#d39f36"
									fillOpacity={1}
									fill="url(#colorR3)"
									activeDot={{ stroke: "#d39f36", strokeWidth: 4, fill: "#FFFFFF", r: 5 }}
								/>
								<Area
									dataKey="RetentionD7"
									stroke="#09814A"
									fillOpacity={1}
									fill="url(#colorR7)"
									activeDot={{ stroke: "#09814A", strokeWidth: 4, fill: "#FFFFFF", r: 5 }}
								/>
								<XAxis
									dataKey="CreatedAt"
									tickFormatter={(v) => moment(v).format("DD.MM.YY")}
									minTickGap={30}
									tickMargin={10}
								/>
								<YAxis
									domain={[0, 1.25]}
									tickFormatter={(v) => (v === 0.5 || v === 1 ? `${(v * 100).toFixed(0)}%` : "")}
									ticks={[0.25, 0.5, 0.75, 1]}
								/>
								<Tooltip content={<DailyStatsTooltip />} />
							</AreaChart>
						</ResponsiveContainer>
					) : (
						<PlaceholderView
							imageSrc="/images/data-placeholder.png"
							to="https://github.com/espresso-pub/robusta"
							text={getPlaceholderText(loading, "retention")}
							buttonProps={getPlaceholderButtonProps(loading)}
						/>
					)}
				</Box>
			</Box>
			<Grid container className="graph-container levels">
				<Grid item xs={12} className="graph-header">
					<Typography variant="subtitle1">
						{strings.formatString(
							strings.request_robustaStatsLevelsHeader,
							from.format("DD.MM.YY"),
							to.format("DD.MM.YY"),
						)}
					</Typography>
					{!!data?.getRobustaLevelsStat.length && !loading && maxLevel > 0 && (
						<Box className="select-container">
							<Typography variant="subtitle2">{strings.constraint}</Typography>
							<Select
								value={levels}
								onChange={(e) => setLevels(e.target.value as number)}
								color="secondary"
							>
								<MenuItem value={0}>{strings.no.toLowerCase()}</MenuItem>
								{levelsOptions.map((option) => (
									<MenuItem key={option} value={option}>
										{strings.formatString(getLevelsString(option), option.toString())}
									</MenuItem>
								))}
							</Select>
						</Box>
					)}
				</Grid>
				<Grid item xs={12} className="graph">
					{data?.getRobustaLevelsStat.length && !loading && maxLevel > 0 ? (
						<ResponsiveContainer height={levelsToShow * 60} minWidth={500}>
							<BarChart data={levelStats} layout="vertical" barSize={30}>
								<XAxis type="number" domain={[0, 1]} hide />
								<Bar
									yAxisId={1}
									dataKey="Users"
									fill="#5E5CE6"
									background={{ fill: "#ebebeb", radius: 6 }}
									isAnimationActive={false}
									radius={6}
								/>
								<YAxis
									orientation="left"
									type="category"
									dataKey="Level"
									axisLine={false}
									tickLine={false}
									yAxisId={1}
									tickFormatter={formatLevelString}
									width={120}
									tickMargin={20}
								/>
								<YAxis
									orientation="right"
									type="category"
									dataKey="Users"
									axisLine={false}
									tickLine={false}
									yAxisId={2}
									tickFormatter={getUsersPercentageString}
								/>
							</BarChart>
						</ResponsiveContainer>
					) : (
						<PlaceholderView
							imageSrc="/images/data-placeholder.png"
							text={getPlaceholderText(loading, "users")}
							to="https://github.com/espresso-pub/robusta"
							buttonProps={getPlaceholderButtonProps(loading)}
						/>
					)}
				</Grid>
			</Grid>
		</StandartAccordion>
	);
};

const StatsSummaryItem = (props: IStatsSummaryItemProps) => {
	const { labelKey, value, valueToMeet, unit, color } = props;

	const formattedValue = value % 1 !== 0 ? value.toFixed(1) : value;
	const valueString = formattedValue !== 0 ? formattedValue.toString() : "—";

	const checkResult = value > 0 && valueToMeet !== undefined ? value > valueToMeet : undefined;

	const classes = classNames("stats-summary-item", color, {
		positive: checkResult,
		negative: !(checkResult ?? true),
	});

	return (
		<Box className={classes}>
			<Box className="label-container">
				<Typography variant="caption" component="p">
					{`${strings.formatString(strings[labelKey], " ")} *`}
				</Typography>
			</Box>
			<Box className="value-container">
				<Typography variant="subtitle1" className="value">
					{valueString}
				</Typography>
				{unit && (
					<Typography variant="subtitle1" className="unit">
						{unit}
					</Typography>
				)}
			</Box>
		</Box>
	);
};

const DailyStatsTooltip = (props: TooltipProps<number, "RetentionD1" | "RetentionD3" | "RetentionD7">) => {
	const { active, payload, label } = props;
	return active ? (
		<div className="daily-stats-tooltip">
			{payload && (
				<>
					<Typography variant="body1" className="R1">{`R1=${(
						(payload[0].payload as RobustaDailyMetricsFullFragment).RetentionD1 * 100
					).toFixed(0)}%`}</Typography>
					<Typography variant="body1" className="R3">{`R3=${(
						(payload[1].payload as RobustaDailyMetricsFullFragment).RetentionD3 * 100
					).toFixed(0)}%`}</Typography>
					<Typography variant="body1" className="R7">{`R7=${(
						(payload[2].payload as RobustaDailyMetricsFullFragment).RetentionD7 * 100
					).toFixed(0)}%`}</Typography>
				</>
			)}
			<Typography variant="caption">
				{strings.formatString(strings.asOf, moment(label).format("DD.MM.YY"))}
			</Typography>
		</div>
	) : null;
};

const getTestPeriodEnd = (requestMetric?: RequestMetricsFullFragment, statusHistory?: TStatusHistory) => {
	const StartTime = requestMetric && requestMetric.StartTime;
	const StopTime = requestMetric && requestMetric.StopTime;
	const Running = statusHistory && statusHistory.Running;
	const CampaignFinished = statusHistory && statusHistory.CampaignFinished;

	if (StopTime) {
		return moment.utc(StopTime).startOf("day");
	}

	if (CampaignFinished) {
		return moment.utc(CampaignFinished).startOf("day");
	}

	if (StartTime) {
		return moment.utc(StartTime).startOf("day").add(DEFAULT_TEST_LENGTH, "days");
	}

	if (Running) {
		return moment.utc(Running).startOf("day").add(DEFAULT_TEST_LENGTH, "days");
	}

	return null;
};

const getTestPeriodStart = (requestMetric?: RequestMetricsFullFragment, statusHistory?: TStatusHistory) => {
	const StartTime = requestMetric && requestMetric.StartTime;
	const Running = statusHistory && statusHistory.Running;

	if (StartTime) {
		return moment.utc(StartTime).startOf("day");
	}

	if (Running) {
		return moment.utc(Running).startOf("day");
	}

	return null;
};

const getUsersPercentageString = (value: number) => {
	return `${Math.round(value * 100)} %`;
};

const getLevelsToShow = (levels: number, maxLevel: number) => {
	return (levels || maxLevel) > 5 ? levels || maxLevel : 5;
};

const getDefaultReportPeriodStart = (requestMetric?: RequestMetricsFullFragment, statusHistory?: TStatusHistory) => {
	return getTestPeriodStart(requestMetric, statusHistory) ?? moment.utc().startOf("day");
};
const getDefaultReportPeriodEnd = (requestMetric?: RequestMetricsFullFragment, statusHistory?: TStatusHistory) => {
	const testEndDate = getTestPeriodEnd(requestMetric, statusHistory);
	if (testEndDate) {
		return testEndDate.endOf("day").add(RETENTION_WAITING_LENGTH, "days");
	}

	const testStartDate = getTestPeriodStart(requestMetric, statusHistory);
	if (testStartDate) {
		return testStartDate.endOf("day").add(DEFAULT_TEST_LENGTH + RETENTION_WAITING_LENGTH, "days");
	}

	return moment
		.utc()
		.endOf("day")
		.add(DEFAULT_TEST_LENGTH + RETENTION_WAITING_LENGTH, "days");
};

const getPlaceholderButtonProps = (loading: boolean): IStandartButtonProps | undefined => {
	return !loading ? { color: "success", children: strings.request_robustaStatsShowHelpText } : undefined;
};

const getPlaceholderText = (loading: boolean, variant: "retention" | "users"): string => {
	const message =
		variant === "retention"
			? strings.request_robustaStatsRetentionPlaceholderText
			: strings.request_robustaStatsLevelsPlaceholderText;
	return loading ? strings.loadingMessage : (strings.formatString(message, <br />) as string);
};

const getTotalRetention = (
	kind: "RetentionD1" | "RetentionD3" | "RetentionD7",
	stats?: RobustaDailyMetricsFullFragment[],
) => {
	if (!stats) {
		return 0;
	}

	let totalUsers = 0;
	let totalRetention = 0;

	for (const stat of stats) {
		totalUsers += stat.Users;
		totalRetention += stat[kind];
	}

	return totalUsers > 0 ? totalRetention / totalUsers : 0;
};

const getLevelsString = (levels: number) => {
	const options = [strings.nLevels, strings.nLevelsAlt, strings.nLevels];
	return declOfNum(levels, options);
};

/**
 * Возвращает вариант склонения в зависимости от переданного числа.
 *
 * Варианты: единственное число + именительный падеж, единственное число + родительный падеж,
 * множественное число + родительный падеж
 *
 * Пример: ["уровень", "уровня", "уровней"]
 */
const declOfNum = (number: number, words: string[]) => {
	const optionIndex = number % 10 < 5 ? Math.abs(number) % 10 : 5;
	return words[number % 100 > 4 && number % 100 < 20 ? 2 : [2, 0, 1, 1, 1, 2][optionIndex]];
};

const getRelativeLevelStatsForEachLevel = (amtOfLevels: number, stats: RobustaLevelMetricsFullFragment[] = []) => {
	const statsForEachLevel: RobustaLevelMetricsFullFragment[] = [];
	const totalUsers = stats.reduce((total, current) => (current.Level > 0 ? total + current.Users : total), 0) || 1;

	const maxLevel = Math.max(
		stats.reduce((max, current) => (current.Level > max ? current.Level : max), 0) ?? 0,
		amtOfLevels,
	);
	for (let curLevel = maxLevel; curLevel > 0; curLevel--) {
		const curLevelStats = stats.find((s) => s.Level === curLevel) ?? {
			Id: `no-data-${curLevel}`,
			Level: curLevel,
			Users: 0,
		};
		const nextLevelUsers = statsForEachLevel[0]?.Users ?? 0;
		const relativeLevelStats = { ...curLevelStats, Users: curLevelStats.Users + nextLevelUsers };
		statsForEachLevel.unshift(relativeLevelStats);
	}
	return statsForEachLevel
		.map((s) => {
			return { ...s, Users: s.Users / totalUsers };
		})
		.slice(0, amtOfLevels);
};

const padDailyStatsForEachDay = (
	from: moment.Moment,
	to: moment.Moment,
	stats: RobustaDailyMetricsFullFragment[] = [],
): RobustaDailyMetricsFullFragment[] => {
	const days = getDaysBetweenDates(from, to);
	return days.map((d, index) => {
		const found = stats.find((s) => s.CreatedAt === d.toISOString());
		return {
			Id: found?.Id || `no-data-${index}`,
			CreatedAt: d.toISOString(),
			Users: found?.Users || 0,
			RetentionD1: found?.Users ? found.RetentionD1 / found.Users : 0,
			RetentionD3: found?.Users ? found.RetentionD3 / found.Users : 0,
			RetentionD7: found?.Users ? found.RetentionD7 / found.Users : 0,
			MedianSession: found?.MedianSession || 0,
			MedianTimeSpent: found?.MedianTimeSpent || 0,
		};
	});
};

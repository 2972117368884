import React from "react";
import {
	Accordion,
	AccordionDetails,
	AccordionDetailsProps,
	AccordionProps,
	AccordionSummary,
	AccordionSummaryProps,
	Divider,
	Typography,
} from "@material-ui/core";
import classNames from "classnames";

import { TLocale } from "@espresso/shared-config";
import strings from "helpers/strings";

interface IProps extends AccordionProps {
	titleKey: keyof TLocale;
	summaryProps?: AccordionSummaryProps;
	detailsProps?: AccordionDetailsProps;
}

export const StandartAccordion = (props: IProps) => {
	const { className, summaryProps, children, detailsProps, titleKey, ...rest } = props;

	const classes = classNames("standart-accordion", className);
	return (
		<Accordion {...rest} className={classes}>
			<AccordionSummary
				expandIcon={<i className={`icon icon-arrow-3`} />}
				IconButtonProps={{ edge: false }}
				{...summaryProps}
			>
				<Typography variant="h5">{strings[titleKey]}</Typography>
				<Divider />
				<Typography variant="body2" className="standart-accordion-hint">
					{strings.web_pressToExpand}
				</Typography>
			</AccordionSummary>
			<AccordionDetails {...detailsProps}>{children}</AccordionDetails>
		</Accordion>
	);
};

import React from "react";
import { DeveloperRequestFragment } from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";
import { Box } from "@material-ui/core";
import strings from "../../../helpers/strings";
import { StandartButton } from "../../../components";
import { Maybe } from "graphql/jsutils/Maybe";

interface ICreateRequestButtonsProps {
	request?: Maybe<DeveloperRequestFragment>;
	disabledSave?: boolean;
	disabledNext?: boolean;
	showNext?: boolean;
	currentStepIndex?: number;
	handleBackToStep?: () => void;
	handleClickRemove?: () => void;
	handleRestoreRequest?: () => Promise<void>;
	onNext?: () => void;
	nextLabelKey?: keyof TLocale;
}

export const CreateRequestButtons = React.memo((props: ICreateRequestButtonsProps) => {
	const {
		request,
		disabledSave,
		disabledNext,
		currentStepIndex = 0,
		handleBackToStep,
		handleClickRemove,
		handleRestoreRequest,
		onNext,
		showNext = true,
		nextLabelKey = "request_creationNext",
	} = props;

	if (!request?.Id) {
		return null;
	}

	if (request.Deleted) {
		return (
			<Box className="buttons-container">
				<StandartButton
					color="success"
					onClick={handleRestoreRequest}
					size="large"
					variant="outlined"
					startIcon={<i className="icon icon-file-5" />}
				>
					{strings.request_restoreTitle}
				</StandartButton>
			</Box>
		);
	}

	const textBackToStep = currentStepIndex
		? strings.standartFormat(strings.request_creationStep, { step: currentStepIndex.toString() })
		: strings.request_creationBack;

	return (
		<Box className="buttons-container">
			{currentStepIndex > 1 ? (
				<Box>
					<StandartButton
						className="button-width-medium"
						color="secondary"
						variant="outlined"
						size="large"
						onClick={handleBackToStep}
					>
						{textBackToStep}
					</StandartButton>
					<StandartButton onClick={handleClickRemove} color="danger" variant="outlined" size="large" justIcon>
						<i className="icon icon-delit" />
					</StandartButton>
				</Box>
			) : (
				<Box>
					<StandartButton
						onClick={handleClickRemove}
						color="danger"
						variant="outlined"
						size="large"
						className="button-width-medium"
						startIcon={<i className="icon icon-delit" />}
					>
						{strings.delete}
					</StandartButton>
				</Box>
			)}

			{onNext ? (
				<>
					<StandartButton
						className="button-width-medium"
						type="submit"
						color="secondary"
						variant="contained"
						size="large"
						disabled={disabledSave}
					>
						{strings.save}
					</StandartButton>
					{showNext && (
						<StandartButton
							className="button-width-medium"
							color="success"
							variant="contained"
							size="large"
							disabled={disabledNext}
							onClick={onNext}
						>
							{strings[nextLabelKey]}
						</StandartButton>
					)}
				</>
			) : (
				showNext && (
					<StandartButton
						className="button-width-medium"
						type="submit"
						color="success"
						variant="contained"
						size="large"
						disabled={disabledNext}
					>
						{strings[nextLabelKey]}
					</StandartButton>
				)
			)}
		</Box>
	);
});

import { Grid, GridProps, Icon, IconButton, Paper, Snackbar, Typography } from "@material-ui/core";
import React, { useCallback, useEffect, useState, useRef } from "react";
import strings from "../../../helpers/strings";
import useAppContext from "../../../contexts/AppContext";
import { errorDictionary } from "@espresso/shared-config";
import { INotificationCardProps, NotificationCard, Parallax } from "../";
import Header from "./Header";
import classNames from "classnames";
import Footer from "./Footer";
import FooterLinks from "./FooterLinks";
import { useLocation } from "react-router-dom";
import { ConfirmModal } from "../../ConfirmModal";
import { AgreementDialog } from "./AgreementDialog";
import _ from "lodash";
import { useLoadingContext } from "contexts/LoadingContext";
import { ELoginRole, useGetFbAdAccountInfoLazyQuery } from "@espresso/protocol";

interface IStandartViewProps {
	title?: string;
	rightIcon?: string;
	onRightButtonClick?: () => void;
	rightButtonDisabled?: boolean;
	disableNotifications?: boolean;
	disableScrollToTop?: boolean;
	parallax?: {
		image?: string;
		video?: {
			h264?: string;
			h265?: string;
			vp8?: string;
		};
		content?: React.ReactElement;
		containerClass?: string;
		changeColorOnScroll?: number;
	};
	backgroundImage?: string;
	pageBreakpoints?: Pick<GridProps, "xs" | "sm" | "md" | "lg" | "xl">;
	hideHeadMenu?: boolean;
	hideFooterMenu?: boolean;
	fullWidth?: boolean;
}

const ERROR_SNACKBAR_EXIT_MS = 250;

export const StandartView = (props: React.PropsWithChildren<IStandartViewProps>) => {
	const {
		getErrorCode,
		updateErrorCode,
		notifications,
		logoutModalVisible,
		setLogoutModalVisible,
		clearSession,
		infoSnackbarParams,
		setInfoSnackbarParams,
		isAgreementDialogOpen,
		unshiftNotification,
		login,
	} = useAppContext();

	const { loading } = useLoadingContext();

	const [getFbAdAccountInfo, { data }] = useGetFbAdAccountInfoLazyQuery({ fetchPolicy: "cache-and-network" });
	const errorSnackbarIdRef = useRef<number>();
	const [error, setError] = useState<string>();
	const [highPriorityNotifications, setHighPriorityNotifications] = useState<boolean>(false);
	const location = useLocation();

	const errorCode = getErrorCode();

	if (!error && errorCode) {
		const errorKey = (errorDictionary[errorCode.code] || errorDictionary.common_1).localeKey;
		const errorText = strings.standartFormat(strings[errorKey], errorCode?.data);
		setError(errorText);
	}

	const closeError = useCallback(
		(event?: React.SyntheticEvent, reason?: string) => {
			if (reason === "clickaway") {
				return;
			}

			updateErrorCode(undefined);
			errorSnackbarIdRef.current = _.delay(() => setError(undefined), ERROR_SNACKBAR_EXIT_MS);
		},
		[updateErrorCode],
	);

	useEffect(() => {
		return () => {
			if (errorSnackbarIdRef.current) {
				clearTimeout(errorSnackbarIdRef.current);
				errorSnackbarIdRef.current = undefined;
			}
		};
	}, []);

	useEffect(() => {
		if (errorCode) {
			closeError();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [closeError, location.pathname]);

	useEffect(() => {
		if (!props.disableScrollToTop) {
			window.scrollTo(0, 0);
		}
	}, [props.disableScrollToTop]);

	useEffect(() => {
		if (login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher) {
			getFbAdAccountInfo();
		}
	}, [getFbAdAccountInfo, login?.Role]);

	useEffect(() => {
		if (data?.getFbAdAccountInfo.length) {
			const disabledAcc = data.getFbAdAccountInfo.find((acc) => !acc.Enabled);
			if (!disabledAcc) {
				return;
			}
			const disabledAdAccountNotification: INotificationCardProps = {
				key: "disabledAdAccount",
				title: strings.standartFormat(strings.notification_disabledAdAccountTitle, {
					ad_acc_id: disabledAcc.Id,
				}),
				messageKey: "notification_disabledAdAccountMessage",
				variant: "danger",
				iconName: "error",
				actions: [
					{
						title: "notification_disabledAdAccountActionText",
						onClick: () => {
							window.open(
								`https://business.facebook.com/ads/manager/account_settings/information/?act=${disabledAcc.Id}`,
								"_blank",
							);
						},
						variant: "contained",
						color: "white",
					},
				],
			};
			unshiftNotification(disabledAdAccountNotification);
			setHighPriorityNotifications(true);
		}
	}, [data?.getFbAdAccountInfo, unshiftNotification]);

	const containerClasses = classNames("app-container", { "image-background": !!props.backgroundImage });

	const showNotifications = (!props.disableNotifications || highPriorityNotifications) && !!notifications.length;

	return (
		<Grid
			container
			className={containerClasses}
			alignItems="center"
			style={props.backgroundImage ? { backgroundImage: `url(${props.backgroundImage})` } : undefined}
		>
			<Header
				brand="web_pageTitle"
				fixed
				color={props.parallax ? "transparent" : "secondary"}
				changeColorOnScroll={
					props.parallax && {
						height: props.parallax.changeColorOnScroll || 400,
						color: "secondary",
					}
				}
				loading={loading}
				hideMenu={props.hideHeadMenu}
			/>
			{props.parallax && (
				<Parallax
					image={props.parallax.image}
					video={props.parallax.video}
					className={props.parallax.containerClass}
					filter
				>
					{props.parallax.content && <div className="container">{props.parallax.content}</div>}
				</Parallax>
			)}

			<Grid item xs={12} {...props.pageBreakpoints} className="full-width">
				<Paper
					className={classNames("page-content main mainRaised", {
						margin: !props.parallax,
						container: !props.fullWidth,
					})}
				>
					{showNotifications && <NotificationCard {...notifications[0]} />}
					{!!props.title && (
						<Typography className="page-content-title" align="center" variant="h5">
							{props.title}
						</Typography>
					)}
					{props.children}
				</Paper>
			</Grid>
			<Footer theme={!!props.backgroundImage ? "white" : undefined} hideMenu={props.hideFooterMenu}>
				<FooterLinks />
			</Footer>
			<Snackbar
				className="error-snackbar"
				open={!!errorCode}
				onClose={closeError}
				autoHideDuration={20000}
				transitionDuration={{ exit: ERROR_SNACKBAR_EXIT_MS }}
				message={error || strings.serverError_common_1}
				action={
					<IconButton size="small" aria-label="close" color="inherit" onClick={closeError}>
						<i className="icon icon-close" />
					</IconButton>
				}
			/>
			<Snackbar
				anchorOrigin={{
					vertical: "bottom",
					horizontal: "left",
				}}
				autoHideDuration={3000}
				onClose={() => setInfoSnackbarParams({ open: false })}
				action={
					<IconButton
						size="small"
						aria-label="close"
						color="inherit"
						onClick={() => setInfoSnackbarParams({ open: false })}
					>
						<Icon fontSize="small">
							<i className="icon icon-close" />
						</Icon>
					</IconButton>
				}
				{...infoSnackbarParams}
			/>
			<ConfirmModal
				visible={logoutModalVisible}
				title={strings.web_logOutTitle}
				onClose={() => setLogoutModalVisible(false)}
				onConfirm={clearSession}
				text={strings.web_logOutText}
				confirmButtonLabel={strings.web_menuLogOut}
			/>
			<AgreementDialog open={isAgreementDialogOpen} />
		</Grid>
	);
};

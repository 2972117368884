import React, { useCallback, useEffect, useMemo, useState } from "react";
import {
	EMessagerType,
	IBaseSignUpState,
	SocialAuth,
	StandartButton,
	StandartTextInputController,
	StandartView,
	IAuthLocationState,
} from "../../components";
import strings from "../../helpers/strings";
import { useAnalytics } from "helpers/ga";
import { Grid, Typography, Box } from "@material-ui/core";
import { EmailLinkSendedModal } from "../EmailLinkSendedModal";
import { EnterPasswordModal } from "./EnterPasswordModal";
import {
	CheckUserExistsDocument,
	CheckUserExistsQuery,
	defaultUpsertDeveloperInput,
	defaultUpsertRequestInput,
	ECampaignType,
	ERequestStatus,
	useSignUpMutation,
	useUpsertDeveloperMutation,
	useUpsertRequestMutation,
} from "@espresso/protocol";
import useAppContext from "../../contexts/AppContext";
import moment from "moment";
import { authStatusCode, mergeKeepShape, regExps, TLocale } from "@espresso/shared-config";
import { useStandartForm } from "../../helpers/useStandartForm";
import { Prompt, Redirect, useHistory } from "react-router-dom";
import { ApolloQueryResult, useApolloClient } from "@apollo/client";
import { ExpressFormAppInfo, TExpressFormAppInfoData } from "./ExpressFormAppInfo";
import { ExpressRequestForm, TExpressRequestFormData } from "./ExpressRequestForm";
import * as firebase from "firebase/app";
import { useLoadingSignal } from "contexts/LoadingContext";

export type TExpressRegisterFormData = TExpressRequestFormData &
	TExpressFormAppInfoData & {
		email: string;
	};

export type TEnterPasswordModalState = {
	visible: boolean;
	password?: string;
	loading?: boolean;
	errorMessage?: string;
};
export const ExpressRegisterView = () => {
	const {
		session,
		setSession,
		firebaseApp,
		lang,
		getErrorCode,
		promoKey,
		showExitPrompt,
		setShowExitPrompt,
	} = useAppContext();
	const { analyticsEvents } = useAnalytics();
	const history = useHistory();

	const firebaseAppAuth = useMemo<firebase.auth.Auth>(() => firebaseApp.auth(), [firebaseApp]);

	const [messagerTypeActive, setMessagerTypeActive] = useState<EMessagerType>(EMessagerType.Telegram);
	const [emailSendedModalVisible, setEmailSendedModalVisible] = useState(false);
	const [enterPasswordModalState, setEnterPasswordModalState] = useState<TEnterPasswordModalState>({
		visible: false,
	});
	const [authLocationState, setAuthLocationState] = useState<IAuthLocationState>();
	const [loadingAttachment, setLoadingAttachment] = useState(false);
	const [isUserExists, setIsUserExists] = useState(true);
	const [loadingTasksVideo, setLoadingTasksVideo] = useState(false);

	const [signUp, { loading: loadingSignUp }] = useSignUpMutation();
	useLoadingSignal(loadingSignUp);

	const [upsertDeveloper, { loading: loadingDeveloper }] = useUpsertDeveloperMutation();
	useLoadingSignal(loadingDeveloper);

	const [upsertRequest, { loading: loadingRequest, data: upsertRequestData }] = useUpsertRequestMutation();
	useLoadingSignal(loadingRequest);

	const apolloClient = useApolloClient();

	const form = useStandartForm<TExpressRegisterFormData>({
		mode: "onBlur",
		reValidateMode: "onChange",
		shouldUnregister: false,
		defaultValues: {
			email: "",
			name: "",
			messagerId: "",
			appName: "",
			attachments: [],
			campaignType: ECampaignType.Cpi,
		},
	});
	const { handleSubmit, errors, control } = form;

	const [signUpState, setSignUpState] = useState<IBaseSignUpState>({
		loading: false,
		error: false,
		localeKey: undefined,
	});

	const submit = useCallback(
		async (data: TExpressRegisterFormData) => {
			try {
				setSignUpState((prevState) => ({ ...prevState, localeKey: undefined, error: false, loading: true }));
				let authToken: string | undefined;
				let hasUser = isUserExists;
				const password = enterPasswordModalState.password;

				if (!password) {
					const checkUserExistsResult: ApolloQueryResult<
						CheckUserExistsQuery | undefined
					> = await apolloClient.query({
						query: CheckUserExistsDocument,
						variables: { email: data.email },
						fetchPolicy: "no-cache",
					});
					hasUser = !!checkUserExistsResult.data?.checkUserExists;
					setIsUserExists(hasUser);

					if (hasUser) {
						setSignUpState((prevState) => ({ ...prevState, loading: false }));
						setEnterPasswordModalState({ visible: true });
						return;
					}
				} else {
					setEnterPasswordModalState((prev) => ({ ...prev, password: "" }));
					const fbResult = await firebaseAppAuth.signInWithEmailAndPassword(data.email, password);
					if (!fbResult.user) {
						setSignUpState((prevState) => ({
							...prevState,
							localeKey: "auth_unknown",
							error: true,
							loading: false,
						}));
						return;
					}
					authToken = await fbResult.user.getIdToken();
				}

				const signUpResult = await signUp({
					variables: {
						data: {
							FirebaseToken: authToken,
							Email: !authToken ? data.email : undefined,
							Lang: lang,
							PromoKey: promoKey,
						},
					},
				});
				const token = signUpResult?.data?.signUp.data?.token;
				const developer = signUpResult.data?.signUp.data?.Login.Developer;

				if (!token) {
					const errorCode = getErrorCode();
					if (errorCode?.code === "auth_2") {
						setEnterPasswordModalState({ visible: true });
						return;
					}
					setSignUpState((prevState) => ({
						...prevState,
						localeKey: "auth_unknown",
						error: true,
						loading: false,
					}));
					return;
				}

				const upsertDeveloperInput = mergeKeepShape(defaultUpsertDeveloperInput, {
					...developer,
					ContactEmail: data.email,
					ContactName: data.name,
					TimezoneDelta: moment().utcOffset(),
					TelegramId: messagerTypeActive === EMessagerType.Telegram ? data.messagerId : undefined,
					SkypeId: messagerTypeActive === EMessagerType.Skype ? data.messagerId : undefined,
					WhatsAppId: messagerTypeActive === EMessagerType.WhatsApp ? data.messagerId : undefined,
					Lang: lang,
				});
				await upsertDeveloper({
					variables: { data: upsertDeveloperInput },
					context: { headers: { Authorization: `Bearer ${token}` } },
				});

				const isPublicGame = data.campaignType === ECampaignType.Cpi;
				const attachmentIds = data.attachments?.map((item) => item.Id);

				const upsertRequestInput = mergeKeepShape(defaultUpsertRequestInput, {
					AppName: !isPublicGame ? data.appName : undefined,
					CampaignType: data.campaignType,
					Status: isPublicGame ? ERequestStatus.InfoFacebook : ERequestStatus.Validation,
					AttachmentId: attachmentIds,
				});

				const upsertRequestResult = await upsertRequest({
					variables: { data: upsertRequestInput },
					context: { headers: { Authorization: `Bearer ${token}` } },
				});
				const upsertedRequestId = upsertRequestResult.data?.upsertRequest.data?.Request.Id ?? "";

				setSignUpState((prevState) => ({
					...prevState,
					localeKey: undefined,
					error: false,
					loading: false,
				}));
				form.reset();

				analyticsEvents.createRequestOfType(data.campaignType, "express");
				if (data.campaignType === ECampaignType.Ctr) {
					analyticsEvents.sendRequestToManager({ CampaignType: ECampaignType.Ctr, Id: upsertedRequestId });
				}

				if (password) {
					const id = signUpResult.data?.signUp.data?.authId;

					if (session?.auth?.id !== id || session?.auth?.token !== token) {
						setSession({ auth: { token, id } });
					}

					analyticsEvents.login("e-mail");
				} else {
					setEmailSendedModalVisible(true);
					setAuthLocationState({ email: data.email, isUserExists: hasUser });
					analyticsEvents.signup("e-mail");
				}
			} catch (ex) {
				console.log(ex);
				const localeKey: keyof TLocale = (ex.code && authStatusCode.get(ex.code)?.localeKey) || "auth_unknown";

				if (ex.code === "auth/wrong-password") {
					setEnterPasswordModalState({
						visible: true,
						errorMessage: strings[localeKey],
						loading: false,
						password: "",
					});
					setSignUpState((prevState) => ({ ...prevState, loading: false }));
					return;
				}

				if (ex.code === "auth/too-many-requests") {
					setEnterPasswordModalState((prev) => ({
						...prev,
						errorMessage: strings[localeKey],
						loading: false,
						password: "",
					}));
				}
				setSignUpState((prevState) => ({ ...prevState, localeKey, error: true, loading: false }));
			}
		},
		[
			apolloClient,
			enterPasswordModalState.password,
			firebaseAppAuth,
			form,
			getErrorCode,
			lang,
			promoKey,
			isUserExists,
			messagerTypeActive,
			session?.auth?.id,
			session?.auth?.token,
			setSession,
			signUp,
			upsertDeveloper,
			upsertRequest,
			analyticsEvents,
		],
	);

	useEffect(() => {
		return () => {
			setShowExitPrompt(false);
		};
	}, [setShowExitPrompt]);

	useEffect(() => {
		if (!enterPasswordModalState.password) {
			return;
		}
		handleSubmit(submit)();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [enterPasswordModalState.password]);

	useEffect(() => {
		setShowExitPrompt(loadingAttachment || form.formState.isDirty);
	}, [form.formState.isDirty, loadingAttachment, setShowExitPrompt]);

	const getModalParams = () => {
		if (!form.formState.isDirty) {
			return;
		}
		return {
			title: strings.auth_title,
			text: strings.auth_signInExpressRequestDesc,
		};
	};

	if (session) {
		const upsertedRequestId = upsertRequestData?.upsertRequest.data?.Request.Id;
		const gameName = upsertRequestData?.upsertRequest.data?.Request.AppName;
		if (upsertedRequestId && !gameName) {
			return <Redirect to={{ pathname: `/request-${upsertedRequestId}`, state: { isUserExists } }} />;
		}
		return (
			<Redirect
				to={{
					pathname: "/profile",
					state: {
						isUserExists,
					},
				}}
			/>
		);
	}
	return (
		<StandartView
			disableNotifications
			title={strings.request_toPublic}
			backgroundImage="/images/express-reg-bg.png"
		>
			<form noValidate className="express-register-view" onSubmit={handleSubmit(submit)}>
				<Grid container justify="center" spacing={5}>
					<Grid item xs={12}>
						<SocialAuth
							setSignUpState={setSignUpState}
							loading={signUpState.loading}
							getModalParams={getModalParams}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="subtitle1">{strings.request_expressPromo1}</Typography>
						<StandartTextInputController
							required
							name="email"
							control={control}
							rules={{
								required: strings.required,
								pattern: {
									value: regExps.email,
									message: strings.wrongEmail,
								},
							}}
							errorMessage={errors.email?.message}
							label={strings.clientEmail}
							pattern={regExps.email}
							margin="normal"
							placeholder="email@example.com"
							autoFocus
						/>
						<ExpressRequestForm
							control={control}
							errors={errors}
							messagerTypeActive={messagerTypeActive}
							setMessagerTypeActive={setMessagerTypeActive}
						/>
					</Grid>
					<Grid item xs={12}>
						<Typography variant="subtitle1">{strings.request_expressPromo2}</Typography>
						<ExpressFormAppInfo
							form={form}
							signUpState={signUpState}
							onLoadingAttachmentsChange={setLoadingAttachment}
							onProcessingTasksVideo={setLoadingTasksVideo}
						/>
					</Grid>
					<EmailLinkSendedModal
						visible={emailSendedModalVisible}
						setVisible={setEmailSendedModalVisible}
						locationState={authLocationState}
					/>
				</Grid>
				<Box display="flex" flexDirection="column" width="100%">
					<Typography className="required-fields-text">
						<span className="asterisk-error">*</span>
						<span className="required-info">{strings.standartFormat(strings.requiredFields)}</span>
					</Typography>
					<StandartButton
						className="button-width-medium submitButton"
						type="submit"
						color="success"
						size="large"
						disabled={loadingAttachment || loadingTasksVideo}
					>
						{strings.request_next}
					</StandartButton>
				</Box>
				<Prompt
					when={showExitPrompt}
					message={(locationNext, historyAction) => {
						return JSON.stringify({ historyAction, locationNext, currentLocation: history.location });
					}}
				/>
			</form>
			<EnterPasswordModal
				visible={enterPasswordModalState.visible}
				setEnterPasswordModalState={setEnterPasswordModalState}
				errorMessage={enterPasswordModalState.errorMessage}
				loading={enterPasswordModalState.loading}
			/>
		</StandartView>
	);
};

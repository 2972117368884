import React, { useMemo, memo, useCallback } from "react";

import { TableCell, Typography } from "@material-ui/core";
import { EOrderDirection, ERequestOrderField, EOrderNullPosition } from "@espresso/protocol";
import { IColumn } from "./RequestListCells";
import strings from "../../helpers/strings";
import classNames from "classnames";
import { IRequestsListSortParam } from "../../contexts/AppContext";

interface ITableRowProps {
	column: IColumn;
	selectedSort: IRequestsListSortParam;
	handleChangeColumnSort: (column: IRequestsListSortParam) => void;
}

type TSortKeys = {
	[key in ERequestOrderField]: {
		[key in EOrderDirection]: EOrderNullPosition;
	};
};

const sorts: TSortKeys = {
	[ERequestOrderField.CreatedAt]: {
		[EOrderDirection.ASC]: EOrderNullPosition.LAST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.CurrentStatusAt]: {
		[EOrderDirection.ASC]: EOrderNullPosition.LAST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.Developer]: {
		[EOrderDirection.ASC]: EOrderNullPosition.FIRST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.AppName]: {
		[EOrderDirection.ASC]: EOrderNullPosition.FIRST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.CTR]: {
		[EOrderDirection.ASC]: EOrderNullPosition.LAST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.CPI]: {
		[EOrderDirection.ASC]: EOrderNullPosition.LAST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.R1]: {
		[EOrderDirection.ASC]: EOrderNullPosition.LAST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
	[ERequestOrderField.MT]: {
		[EOrderDirection.ASC]: EOrderNullPosition.LAST,
		[EOrderDirection.DESC]: EOrderNullPosition.LAST,
	},
};

const RequestListTableHeadCell = memo((props: ITableRowProps) => {
	const { column, selectedSort, handleChangeColumnSort } = props;
	const sortKey = column.sortKey;
	const directionSort = useMemo(() => {
		if (selectedSort.field !== sortKey) {
			return;
		}
		return selectedSort.order;
	}, [selectedSort, sortKey]);
	const headCellContent = classNames("head-cell-content", sortKey ? "sorted" : null);

	const clickTableCell = useCallback(() => {
		if (!sortKey) {
			return;
		}

		const sortItem = sorts[sortKey];
		const order =
			selectedSort.field !== sortKey
				? EOrderDirection.DESC
				: selectedSort.order === EOrderDirection.DESC
				? EOrderDirection.ASC
				: EOrderDirection.DESC;
		const nullsPosition = sortItem[order];

		handleChangeColumnSort({
			order,
			nullsPosition,
			field: sortKey,
		});
	}, [sortKey, selectedSort, handleChangeColumnSort]);

	return (
		<TableCell key={column.key} onClick={clickTableCell}>
			<div className={headCellContent}>
				<Typography className="text" variant="subtitle1">
					{column.localeKey ? strings[column.localeKey] : ""}
				</Typography>
				{directionSort &&
					(directionSort === EOrderDirection.DESC ? (
						<i className="icon icon-arrow-3 desc-sort-icon" />
					) : (
						<i className="icon icon-arrow-3 asc-sort-icon" />
					))}
			</div>
		</TableCell>
	);
});

export default RequestListTableHeadCell;

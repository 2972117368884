import { THelpSection } from "views/HelpView/types";
import { Ctr } from "./Ctr";
import { Cpi } from "./Cpi";
import { R1 } from "./R1";

export const testing: THelpSection = {
	titleKey: "help_testingTitle",
	path: "testing",
	topics: [
		{ titleKey: "help_testingCtrTitle", Content: Ctr, path: "ctr" },
		{ titleKey: "help_testingCpiTitle", Content: Cpi, path: "cpi" },
		{ titleKey: "help_testingR1Title", Content: R1, path: "r1" },
	],
};

import {
	ECampaignType,
	EFbEffectiveStatus,
	ETaskStatus,
	RequestForListFragment,
	RequestMetricsFullFragment,
	TenjinMetricsFullFragment,
} from "@espresso/protocol";
import moment from "moment";
import strings from "helpers/strings";
import { getDaysBetweenDates } from "helpers/time";

export type TMetricName =
	| "cost"
	| "impression"
	| "click"
	| "install"
	| "cpi"
	| "r1"
	| "r7"
	| "mt"
	| "cpm"
	| "ctr"
	| "ir"
	| "ipm"
	| "msl"
	| "cr";

export type TMetricParams = {
	title: string;
	target?: string;
	unit: string;
	info?: string;
	format?: (value: number) => string;
	warn?: string;
	warnShow?: (request: Pick<RequestForListFragment, "Metrics">) => boolean;
	getGrade: (value: number) => TMetricGrade;
};

type TMetricGrade = "positive" | "intermediate" | "negative" | "neutral";

export const getMetric = (
	metricName: TMetricName,
	request: Pick<RequestForListFragment, "CampaignType" | "Metrics">,
	attachmentId?: string,
) => {
	const metric = attachmentId
		? request.Metrics.find((m) => m.AttachmentId === attachmentId)
		: request.Metrics.find((m) => !m.AttachmentId);

	switch (metricName) {
		case "cost":
			return metric?.Cost;
		case "r7":
			return metric?.RetentionD7 && Math.round(metric?.RetentionD7 * 10000) / 100;
		case "impression":
			return metric?.Impressions;
		case "click":
			return metric?.Clicks;
		case "install":
			return metric?.Installs;
		case "cpi":
			return request.CampaignType !== ECampaignType.Ctr
				? metric?.Cost && metric?.Installs && Math.round((metric?.Cost / metric?.Installs) * 100) / 100
				: null;
		case "r1":
			return request.CampaignType === ECampaignType.R1
				? metric?.RetentionD1 && Math.round(metric?.RetentionD1 * 10000) / 100
				: null;
		case "mt":
			return request.CampaignType === ECampaignType.R1
				? metric?.MedianTimeSpent && Math.round((metric?.MedianTimeSpent / 60) * 100) / 100
				: null;
		case "cpm":
			return (
				metric?.Cost && metric?.Impressions && Math.round((metric?.Cost / metric?.Impressions) * 100000) / 100
			);
		case "ctr":
			return (
				metric?.Clicks &&
				metric?.Impressions &&
				Math.round((metric?.Clicks / metric?.Impressions) * 10000) / 100
			);
		case "ir":
			return metric?.Installs && metric?.Clicks && Math.round((metric?.Installs / metric?.Clicks) * 10000) / 100;
		case "ipm":
			return (
				metric?.Installs &&
				metric?.Impressions &&
				Math.round((metric?.Installs / metric?.Impressions) * 100000) / 100
			);
		case "msl":
			return metric?.MedianSession && Math.round((metric?.MedianSession / 60) * 100) / 100;
		case "cr":
			return metric?.Clicks && metric?.Installs && Math.round((metric?.Installs / metric?.Clicks) * 10000) / 100;
	}
};

export const metricsConfig: () => { [key in TMetricName]: TMetricParams } = () => ({
	cost: {
		title: strings.request_metricCost,
		unit: "$",
		getGrade: createMetricGradingFn(),
	},
	impression: {
		title: strings.request_metricImpression,
		unit: "",
		format: (value) => value.toString(),
		getGrade: createMetricGradingFn(),
	},
	click: {
		title: strings.request_metricClick,
		unit: "",
		format: (value) => value.toString(),
		getGrade: createMetricGradingFn(),
	},
	install: {
		title: strings.request_metricInstall,
		unit: "",
		format: (value) => value.toString(),
		getGrade: createMetricGradingFn(),
	},
	cpi: {
		title: strings.request_metricCPI,
		target: "< 0.4",
		getGrade: createMetricGradingFn(["<0.4", "positive"], ["<0.45", "intermediate"], [">0.45", "negative"]),
		unit: "$",
		info: strings.request_metricCommentCPI,
	},
	r1: {
		title: strings.request_metricR1,
		target: "> 30",
		getGrade: createMetricGradingFn([">30", "positive"], [">25", "intermediate"], ["<25", "negative"]),
		unit: "%",
		info: strings.request_metricCommentR1,
		warn: strings.request_metricWarnR1,
		warnShow: (request) => {
			const metric = request.Metrics.find((m) => !m.AttachmentId);
			if (metric?.Installs && metric.RetentionD1 != null) {
				return metric.Installs < 25;
			} else {
				return false;
			}
		},
	},
	r7: {
		title: strings.request_metricR7,
		unit: "%",
		getGrade: createMetricGradingFn(),
	},
	mt: {
		title: strings.request_metricMT,
		target: "> 5",
		getGrade: createMetricGradingFn([">5", "positive"], [">4", "intermediate"], ["<4", "negative"]),
		unit: strings.min,
		info: strings.request_metricCommentMT,
	},
	cpm: {
		title: strings.request_metricCPM,
		target: "< 40",
		getGrade: createMetricGradingFn(["<40", "positive"], ["<45", "intermediate"], [">45", "negative"]),
		unit: "$",
		info: strings.request_metricCommentCPM,
	},
	ctr: {
		title: strings.request_metricCTR,
		target: "> 4",
		getGrade: createMetricGradingFn([">4", "positive"], [">3.5", "intermediate"], ["<3.5", "negative"]),
		unit: "%",
		info: strings.request_metricCommentCTR,
	},
	ir: {
		title: strings.request_metricIR,
		target: "> 70",
		getGrade: createMetricGradingFn([">70", "positive"], [">75", "intermediate"], ["<75", "negative"]),
		unit: "%",
		info: strings.request_metricCommentIR,
	},
	ipm: {
		title: strings.request_metricIPM,
		target: "> 40",
		getGrade: createMetricGradingFn([">40", "positive"], [">35", "intermediate"], ["<35", "negative"]),
		unit: "",
		info: strings.request_metricCommentIPM,
	},
	msl: {
		title: strings.request_metricMSL,
		target: "> 10",
		getGrade: createMetricGradingFn([">10", "positive"], [">9", "intermediate"], ["<9", "negative"]),
		unit: strings.min,
		info: strings.request_metricCommentMSL,
	},
	cr: {
		title: strings.request_metricCR,
		unit: "%",
		getGrade: createMetricGradingFn(),
	},
});

export const getFbVideoStatus = (videoMetric: RequestMetricsFullFragment) => {
	if (videoMetric.Status === ETaskStatus.Processing) {
		return "FacebookProcessing";
	} else if (videoMetric.FBCampaignId) {
		switch (videoMetric.FBStatus) {
			case EFbEffectiveStatus.DISAPPROVED:
			case EFbEffectiveStatus.WITH_ISSUES:
				return "FacebookRejected";
			case EFbEffectiveStatus.IN_PROCESS:
			case EFbEffectiveStatus.PENDING_REVIEW:
			case EFbEffectiveStatus.PENDING_BILLING_INFO:
				return "FacebookReviewing";
			case null:
				return "FacebookProcessing";
		}
		return "FacebookAccepted";
	} else if (videoMetric.Status === ETaskStatus.Failed) {
		return "FacebookRejected";
	}
	return null;
};

export const getTenjinMetricsForEachDay = (
	from: moment.Moment,
	to: moment.Moment,
	stats: TenjinMetricsFullFragment[] = [],
): TenjinMetricsFullFragment[] => {
	const weekDays = getDaysBetweenDates(from, to);

	return weekDays.map(
		(d) =>
			stats.find((s) => s.ReportDate === d.toISOString()) ?? {
				__typename: "TenjinMetrics",
				Id: `tj-no-metric-${d.toISOString()}`,
				UpdatedAt: moment().toISOString(),
				CreatedAt: moment().toISOString(),
				ReportDate: d.toISOString(),
				Spend: null,
				TotalSpend: null,
				AdRevenue: null,
				RoasD120: null,
				Roas: null,
				Income: null,
				UsersD0: null,
				UsersD1: null,
				UsersD3: null,
				UsersD7: null,
				RetentionD1: null,
				RetentionD3: null,
				RetentionD7: null,
			},
	);
};

/**
 * Создает функцию для оценки значения метрики
 * @param conditions Массив условий для оцеки метрики. Условия должны идти по порядку. Условия содержат в себе требуемый
 *  проходной порог и соответствующее значение результата оценки
 * @returns Функция оценки значения метрики. Сравнивает значение метрики с пороговыми и возвращает результат оценки
 * для первого успешного сравнения
 * @example
 * const getGrade = createMetricGradingFn( [["<1.2", "positive"],  ["<5.45", "intermediate"],  [">5.45", "negative"]] );
 * getGrade(1.2); // "intermediate"
 */
const createMetricGradingFn = (
	...conditions: [`${">" | "<"}${number}`, TMetricGrade][]
): ((value: number) => TMetricGrade) => {
	if (conditions.length === 0) {
		return () => "neutral";
	}
	return (value) => {
		let result: TMetricGrade = "neutral";
		for (const [threshold, evaluationResult] of conditions) {
			const checkPassed = checkThreshold(value, threshold);
			if (checkPassed === null) {
				console.warn(
					"%cОшибка в описании пороговых значений!",
					"font-weight: bold;",
					"Значение должно начинаться с > или <",
				);
				continue;
			}
			if (checkPassed) {
				result = evaluationResult;
				break;
			}
		}
		return result;
	};
};

const checkThreshold = (value: number, threshold: `${">" | "<"}${number}`) => {
	switch (threshold[0]) {
		case ">": {
			return value > +threshold.substring(1);
		}
		case "<": {
			return value < +threshold.substring(1);
		}
		default: {
			return null;
		}
	}
};

import React, { useCallback, useEffect, useState, useRef } from "react";
import {
	booleanSwitchItems,
	EMessagerType,
	platformSwitchItems,
	StandartButton,
	StandartTextInputController,
	StandartSwitchBarController,
	LanguagePicker,
	StandartTextCopyButton,
} from "../../components";
import {
	defaultUpsertDeveloperInput,
	DeveloperFullFragment,
	EDeveloperPlatform,
	ELang,
	GetMyDeveloperDocument,
	GetMyLoginDocument,
	useUpsertDeveloperMutation,
} from "@espresso/protocol";
import { Box, CircularProgress, Grid, Typography, Paper } from "@material-ui/core";
import strings from "../../helpers/strings";
import { mergeKeepShape, regExps } from "@espresso/shared-config";
import moment from "moment";
import useAppContext from "../../contexts/AppContext";
import { useStandartForm } from "../../helpers/useStandartForm";
import { Controller } from "react-hook-form";
import { UpdateApiKey } from "./UpdateApiKey";
import { Prompt, useHistory } from "react-router-dom";
import _ from "lodash";
import { useLoadingSignal } from "contexts/LoadingContext";

type TProps = {
	developer?: DeveloperFullFragment | null;
};

export type TUpsertDeveloperFormData = {
	ContactName: string;
	ContactSurname: string;
	ContactEmail: string;
	messanger: EMessagerType;
	TelegramId: string;
	WhatsAppId: string;
	SkypeId: string;
	TeamName: string;
	TeamSize: number;
	TeamLocation: string;
	Portfolio: string;
	Engine: string;
	PrevPublishers: boolean;
	PrevSdk: boolean;
	Platform: EDeveloperPlatform;
	Lang: ELang;
};

export const UpsertDeveloperForm = (props: TProps) => {
	const { developer } = props;
	const history = useHistory();

	const { setLang, setInfoSnackbarParams, showExitPrompt, setShowExitPrompt } = useAppContext();

	const submitCountDelayIdRef = useRef<number>();
	const [submitCountState, setSubmitCountState] = useState(0);

	const [upsertDeveloper, { loading }] = useUpsertDeveloperMutation({
		refetchQueries: [{ query: GetMyDeveloperDocument }, { query: GetMyLoginDocument }],
	});
	useLoadingSignal(loading);

	useEffect(() => {
		return () => {
			setShowExitPrompt(false);
		};
	}, [setShowExitPrompt]);

	useEffect(() => {
		return () => {
			setInfoSnackbarParams({ open: false, message: strings.changesSaved });
		};
	}, [setInfoSnackbarParams]);

	const form = useStandartForm<TUpsertDeveloperFormData>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: getDefaultFormValues(developer),
	});
	const {
		handleSubmit,
		errors,
		control,
		formState: { isDirty, submitCount },
		reset,
	} = form;

	useEffect(() => {
		if (!developer) {
			return;
		}
		form.reset(getDefaultFormValues(developer));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [developer]);

	useEffect(() => {
		if (!errors || submitCount === 0) {
			return;
		}

		setSubmitCountState(submitCount);

		submitCountDelayIdRef.current = _.delay(() => {
			setSubmitCountState(0);
		}, 0);

		return () => {
			if (submitCountDelayIdRef.current) {
				clearTimeout(submitCountDelayIdRef.current);
				submitCountDelayIdRef.current = undefined;
			}
		};
	}, [errors, submitCount]);

	useEffect(() => {
		setShowExitPrompt(isDirty);
	}, [isDirty, setShowExitPrompt]);

	const submit = useCallback(
		async (data: TUpsertDeveloperFormData) => {
			const upsertDeveloperInput = mergeKeepShape(defaultUpsertDeveloperInput, {
				...developer,
				...data,
				TeamSize: parseInt(`${data.TeamSize}`, 10) || developer?.TeamSize,
				TimezoneDelta: moment().utcOffset(),
			});
			const res = await upsertDeveloper({ variables: { data: upsertDeveloperInput } });

			if (res.errors || !res.data?.upsertDeveloper.success) {
				return;
			}

			setLang(data.Lang);
			setInfoSnackbarParams({ open: true, message: strings.changesSaved });
			reset({ ...data });
		},
		[developer, reset, setInfoSnackbarParams, setLang, upsertDeveloper],
	);

	const clickRemovedRequests = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
		e.preventDefault();
		history.push("/deleted-requests");
	};

	const nameScrollByAllow = submitCountState > 0 && !!errors.ContactName?.message;
	const emailScrollByAllow = submitCountState > 0 && !!errors.ContactEmail?.message;
	const teamNameScrollByAllow = submitCountState > 0 && !!errors.TeamName?.message;
	const teamSizeScrollByAllow = submitCountState > 0 && !!errors.TeamSize?.message;

	return (
		<form noValidate className="upsert-developer-form" onSubmit={handleSubmit(submit)}>
			<Grid container spacing={5}>
				<Grid item md={6} sm={12}>
					<Controller
						name="Lang"
						control={control}
						error={!!errors.Lang?.message}
						render={({ value, onChange }) => <LanguagePicker value={value} onChange={onChange} />}
					/>
					<Typography className="developer-contacts-title" variant="h5">
						{strings.developer_contactsDeveloper}
					</Typography>
					<StandartTextInputController
						required
						name="ContactName"
						hasScrollByAllow={nameScrollByAllow}
						control={control}
						rules={{
							required: strings.required,
						}}
						errorMessage={errors.ContactName?.message}
						label={strings.developer_contactNameLabel}
						placeholder={strings.developer_contactNamePlaceholder}
						margin="normal"
					/>
					<StandartTextInputController
						name="ContactSurname"
						control={control}
						errorMessage={errors.ContactSurname?.message}
						label={strings.developer_contactSurnameLabel}
						placeholder={strings.developer_contactSurnamePlaceholder}
						margin="normal"
					/>
					<StandartTextInputController
						required
						name="ContactEmail"
						hasScrollByAllow={emailScrollByAllow}
						control={control}
						rules={{
							required: strings.required,
							pattern: {
								value: regExps.email,
								message: strings.wrongEmail,
							},
						}}
						errorMessage={errors.ContactEmail?.message}
						label={strings.developer_contactEmailLabel}
						pattern={regExps.email}
						margin="normal"
						placeholder={strings.developer_contactEmailPlaceholder}
					/>
					<Typography className="inner-title" variant="h6">
						{strings.messanger}
						<span className="MuiFormLabel-asterisk">*</span>
					</Typography>
					<StandartTextInputController
						name="TelegramId"
						control={control}
						rules={{
							validate: (value: string | null) => {
								const { SkypeId, WhatsAppId } = form.getValues();
								if (!value && !WhatsAppId && !SkypeId) return strings.developer_messangerRequired;
								return;
							},
						}}
						errorMessage={errors.TelegramId?.message}
						label={strings.developer_telegramId}
						margin="normal"
					/>
					<StandartTextInputController
						name="WhatsAppId"
						control={control}
						rules={{
							validate: (value: string | null) => {
								const { TelegramId, SkypeId } = form.getValues();
								if (!TelegramId && !value && !SkypeId) return strings.developer_messangerRequired;
								return;
							},
						}}
						errorMessage={errors.WhatsAppId?.message}
						label={strings.developer_whatsAppId}
						margin="normal"
					/>
					<StandartTextInputController
						name="SkypeId"
						control={control}
						rules={{
							validate: (value: string | null) => {
								const { TelegramId, WhatsAppId } = form.getValues();
								if (!TelegramId && !WhatsAppId && !value) return strings.developer_messangerRequired;
								return;
							},
						}}
						errorMessage={errors.SkypeId?.message}
						label={strings.developer_skypeId}
						margin="normal"
					/>
				</Grid>
				<Grid item md={6} sm={12}>
					<Typography variant="h5">{strings.developer_contactsTeam}</Typography>
					<StandartTextInputController
						required
						name="TeamName"
						hasScrollByAllow={teamNameScrollByAllow}
						control={control}
						errorMessage={errors.TeamName?.message}
						label={strings.developer_studioNameLabel}
						placeholder={strings.developer_studioNamePlaceholder}
						margin="normal"
						rules={{
							required: strings.required,
						}}
					/>
					<StandartTextInputController
						name="TeamLocation"
						control={control}
						errorMessage={errors.TeamLocation?.message}
						label={strings.developer_countryLabel}
						placeholder={strings.developer_countryPlaceholder}
						margin="normal"
					/>
					<StandartTextInputController
						name="Portfolio"
						control={control}
						errorMessage={errors.Portfolio?.message}
						label={strings.developer_portfolioLinkLabel}
						placeholder={"URL//*"}
						margin="normal"
					/>
					<StandartTextInputController
						name="Engine"
						control={control}
						errorMessage={errors.Engine?.message}
						label={strings.developer_technologiesLabel}
						placeholder={strings.developer_technologiesPlaceholder}
						margin="normal"
					/>
					<StandartTextInputController
						required
						onlyNumbers
						name="TeamSize"
						hasScrollByAllow={teamSizeScrollByAllow}
						control={control}
						errorMessage={errors.TeamSize?.message}
						label={strings.developer_teamSizeLabel}
						placeholder={strings.developer_teamSizePlaceholder}
						margin="normal"
						rules={{
							required: strings.required,
							min: {
								value: 1,
								message: strings.required,
							},
						}}
					/>
					<StandartSwitchBarController
						name="PrevPublishers"
						control={control}
						items={booleanSwitchItems}
						labelKey="developer_expPublisherLabel"
						margin="normal"
						error={!!errors.PrevPublishers?.message}
						buttonColor="secondary"
					/>
					<StandartSwitchBarController
						name="PrevSdk"
						control={control}
						items={booleanSwitchItems}
						labelKey="developer_expSdkLabel"
						margin="normal"
						error={!!errors.PrevSdk?.message}
						buttonColor="secondary"
					/>
					<StandartSwitchBarController
						name="Platform"
						control={control}
						items={platformSwitchItems}
						labelKey="developer_platformLabel"
						margin="normal"
						error={!!errors.Platform?.message}
						buttonColor="secondary"
					/>
				</Grid>
				<Grid item xs={12} className="action-grid-root">
					<UpdateApiKey developer={developer} />
				</Grid>
				<Grid item xs={12} className="action-grid-root">
					<Paper className="action-root">
						<Box className="info-root">
							<i className="icon icon-delit" />
							<Typography variant="subtitle2">{strings.request_removedListTitle}</Typography>
						</Box>
						<StandartButton variant="contained" onClick={clickRemovedRequests} color="secondary">
							{strings.developerDashboard_Show}
						</StandartButton>
					</Paper>
				</Grid>
				<ProfileLinkBlock developer={props.developer} />
			</Grid>
			<Box width="100%">
				<Typography className="required-fields-text">
					<span className="asterisk-error">*</span>
					<span className="required-info">{strings.standartFormat(strings.requiredFields)}</span>
				</Typography>
				<Box display="flex" flex={1} flexDirection="column" alignItems="center">
					<StandartButton
						color="success"
						className="submit-button button-width-medium"
						fullWidth
						type="submit"
						disabled={loading}
					>
						{loading && <CircularProgress className="progress" color="inherit" size={20} />}
						{strings.save}
					</StandartButton>
					<Typography className="info-message" align="center" color="textSecondary">
						{strings.developer_infoMessage}
					</Typography>
				</Box>
			</Box>
			<Prompt
				when={showExitPrompt}
				message={(locationNext, historyAction) => {
					return JSON.stringify({ historyAction, locationNext, currentLocation: history.location });
				}}
			/>
		</form>
	);
};

const getDefaultFormValues = (
	developer?: DeveloperFullFragment | null,
): Omit<TUpsertDeveloperFormData, "messanger"> => {
	return {
		ContactName: developer?.ContactName || "",
		ContactSurname: developer?.ContactSurname || "",
		ContactEmail: developer?.ContactEmail || "",
		TelegramId: developer?.TelegramId || "",
		WhatsAppId: developer?.WhatsAppId || "",
		SkypeId: developer?.SkypeId || "",
		TeamName: developer?.TeamName || "",
		TeamSize: developer?.TeamSize || 0,
		TeamLocation: developer?.TeamLocation || "",
		Portfolio: developer?.Portfolio || "",
		Engine: developer?.Engine || "",
		PrevPublishers: developer?.PrevPublishers || false,
		PrevSdk: developer?.PrevSdk || false,
		Platform: developer?.Platform || EDeveloperPlatform.Android,
		Lang: developer?.Login.Lang || ELang.Ru,
	};
};

const ProfileLinkBlock = (props: { developer?: DeveloperFullFragment | null }) => {
	const { developer } = props;
	const {
		sharedConfig: { endpoints },
	} = useAppContext();

	const protocol = endpoints.webUseSSL ? "https://" : "http://";

	const profileLink = `${protocol}${endpoints.webHost}/developer-${developer?.Id ?? ""}`;
	return (
		<Grid item xs={12} className="action-grid-root">
			<Paper className="profile-link">
				<Typography variant="subtitle2">
					{strings.developer_profileLink}{" "}
					<Typography variant="subtitle1" color="secondary" component="span">
						{profileLink}
						<StandartTextCopyButton valueToCopy={profileLink} color="secondary" />
					</Typography>
				</Typography>
				<Box className="info-root">
					<i className="icon icon-info1" />
					<Typography variant="body2">{strings.developer_profileLinkInfo}</Typography>
				</Box>
			</Paper>
		</Grid>
	);
};

import { Box, Tooltip } from "@material-ui/core";
import React from "react";
import { ECheckStatus } from "@espresso/protocol";
import strings from "../../helpers/strings";
import classNames from "classnames";
import { StatusBadge } from "../../components";
import useAppContext from "../../contexts/AppContext";
import moment from "moment";

export type TCheckField =
	| "AdvertIdStatus"
	| "FacebookIdStatus"
	| "FacebookTokenStatus"
	| "FacebookAnalyticsStatus"
	| "FacebookCampaignStatus"
	| "AppLink"
	| "AppName"
	| "AppIcon"
	| "CampaignVideos"
	| "GooglePlayUrlStatus"
	| "AppStoreUrlStatus"
	| "Genre";

export interface IRequestCheckStatusProps {
	field?: TCheckField;
	hasData?: boolean;
	status?: ECheckStatus;
	customExtraData?: {
		videosCount?: string;
		lastStatsDate?: string | null;
	};
	className?: string;
	inProgress?: boolean;
}

export const RequestCheckStatus = (props: React.PropsWithChildren<IRequestCheckStatusProps>) => {
	const { field, hasData = true, status, customExtraData, className, children, inProgress } = props;
	const { sharedConfig } = useAppContext();

	const tooltipsConfig: {
		[key in ECheckStatus]?: { [key: string]: { text: string | string[]; list?: string[] } };
	} = {
		NotChecked: {
			AdvertIdStatus: {
				text: strings.request_facebookAdIdUpdates,
			},
			FacebookIdStatus: {
				text: strings.request_facebookAppIdUpdates,
			},
			FacebookAnalyticsStatus: {
				text: strings.request_facebookAdminIdUpdates,
			},
			FacebookTokenStatus: {
				text: strings.request_infoUpdates,
			},
			FacebookCampaignStatus: {
				text: strings.request_infoUpdates,
			},
			Genre: {
				text: strings.request_checkGenreWarning,
			},
		},
		Checking: {
			AdvertIdStatus: {
				text: strings.request_facebookAdIdUpdates,
			},
			FacebookIdStatus: {
				text: strings.request_facebookAppIdUpdates,
			},
			FacebookAnalyticsStatus: {
				text: customExtraData?.lastStatsDate
					? strings.standartFormat(strings.request_actualDataInfo, {
							updatedAt: moment.utc(customExtraData?.lastStatsDate).local().format("DD-MM-YYYY HH:mm"),
					  })
					: strings.request_facebookAdminIdUpdates,
			},
			FacebookTokenStatus: {
				text: strings.request_infoUpdates,
			},
			FacebookCampaignStatus: {
				text: strings.request_infoUpdates,
			},
			AppIcon: {
				text: strings.request_checkAppIconWarning,
			},
			CampaignVideos: {
				text: strings.formatString(
					strings.request_checkCampaignVideosWarning,
					customExtraData?.videosCount || "",
					sharedConfig.request.maxVideos.toString(),
					sharedConfig.request.recommendedVideos.toString(),
				),
			},
		},
		NotValid: {
			AdvertIdStatus: {
				text: strings.request_advIdNotCorrect,
			},
			FacebookIdStatus: {
				text: [strings.request_appIdNotCorrect, strings.request_appIdNotCorrectList0],
			},
			FacebookAnalyticsStatus: {
				text: customExtraData?.lastStatsDate
					? strings.standartFormat(strings.request_actualDataInfo, {
							updatedAt: moment.utc(customExtraData?.lastStatsDate).local().format("DD-MM-YYYY HH:mm"),
					  })
					: strings.request_facebookAnalyticsNotCorrect,
			},
			FacebookTokenStatus: {
				text: strings.request_facebookAccessTokenNotCorrect,
			},
			FacebookCampaignStatus: {
				text: strings.request_facebookCampaignNotCorrect,
			},
			GooglePlayUrlStatus: {
				text: strings.request_googlePlayUrlNotCorrect,
			},
			AppStoreUrlStatus: {
				text: strings.request_appStoreUrlNotCorrect,
			},
			AppLink: {
				text: strings.request_noAppLink,
			},
			AppName: {
				text: strings.request_checkAppNameWarning,
			},
			CampaignVideos: {
				text:
					customExtraData?.videosCount !== "0"
						? strings.request_wrongVideoDimensions
						: strings.formatString(strings.request_noVideos, sharedConfig.request.maxVideos.toString()),
			},
		},
		Valid: {
			FacebookAnalyticsStatus: {
				text: strings.standartFormat(strings.request_actualDataInfo, {
					updatedAt: moment.utc(customExtraData?.lastStatsDate).local().format("DD-MM-YYYY HH:mm"),
				}),
			},
		},
	};

	const tooltipField = hasData && field && status ? tooltipsConfig?.[status]?.[field] : undefined;
	const tooltipTitle = tooltipField?.text;
	const tooltipList = tooltipField?.list;

	const statusClassNames = classNames("status-row", field, className);

	return (
		<Box className={statusClassNames}>
			<Box className="content">{children}</Box>
			{status &&
				(!tooltipTitle ? (
					<StatusBadge inProgress={inProgress} status={hasData ? status : undefined} />
				) : (
					<Tooltip
						title={
							<>
								{Array.isArray(tooltipTitle) ? (
									tooltipTitle.map((item, index) => <p key={`t-${index}`}>{item}</p>)
								) : (
									<p>{tooltipTitle}</p>
								)}
								{tooltipList && (
									<ul>
										{tooltipList.map((item, index) => (
											<li key={index}>{item}</li>
										))}
									</ul>
								)}
							</>
						}
						placement="top"
					>
						<div>
							<StatusBadge inProgress={inProgress} status={hasData ? status : undefined} />
						</div>
					</Tooltip>
				))}
		</Box>
	);
};

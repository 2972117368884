import { SignUp } from "../views/SignUp";
import { HomeView } from "../views/HomeView";
import { CreateRequest } from "../views/RequestView/CreateRequest";
import { DeveloperView, DeveloperEdit } from "../views/DeveloperView";
import { ExpressRegisterView } from "../views/ExpressRegisterView";
import { LandingPlaceholder, CtrLandingView } from "../views/LandingView";
import { PublisherEditView } from "../views/PublisherEditView";
import { UnconfirmedView } from "../views/UnconfirmedView";
import { TermsView } from "../views/TermsView";
import { PrivacyView } from "../views/PrivacyView";
import { PromoView } from "../views/PromoView";
import { DeletedRequestsView } from "../views/DeletedRequestsView";
import { DeveloperRequestsView } from "../views/DeveloperRequestsView";
import { HelpView } from "../views/HelpView";
import { ProDashboardView } from "views/ProDashboardView";
import { DeletionView } from "../views/DeletionView";
import { GameInfoView } from "../views/GameInfoView";

export type TRoute =
	| "/game-landing/:requestId"
	| "/pro/game-:gameId"
	| "/pro"
	| "/help"
	| "/signup"
	| "/unconfirmed"
	| "/publisher"
	| "/request-express"
	| "/promo-:promoId"
	| "/request-create"
	| "/request-:requestId/edit"
	| "/request-:requestId"
	| "/developer-:developerId"
	| "/developer/edit"
	| "/requests-:developerId"
	| "/profile"
	| "/deleted-requests"
	| "/terms-of-use"
	| "/privacy"
	| "/data-deletion"
	| "/under-construction"
	| "/";

export const staticRoutes: {
	path: TRoute | TRoute[];
	component: (props?: unknown) => JSX.Element;
}[] = [
	{
		path: "/game-landing/:requestId",
		component: CtrLandingView,
	},
	{
		path: "/pro/game-:gameId",
		component: GameInfoView,
	},
	{
		path: "/pro",
		component: ProDashboardView,
	},
	{
		path: "/help",
		component: HelpView,
	},
	{
		path: "/signup",
		component: SignUp,
	},
	{
		path: "/unconfirmed",
		component: UnconfirmedView,
	},
	{
		path: "/publisher",
		component: PublisherEditView,
	},
	{
		path: "/request-express",
		component: ExpressRegisterView,
	},
	{
		path: "/promo-:promoId",
		component: PromoView,
	},
	{
		path: "/request-create",
		component: CreateRequest,
	},
	{
		path: "/request-:requestId/edit",
		component: CreateRequest,
	},
	{
		path: "/request-:requestId",
		component: CreateRequest,
	},
	{
		path: "/requests-:developerId",
		component: DeveloperRequestsView,
	},
	{
		path: "/developer-:developerId",
		component: DeveloperView,
	},
	{
		path: "/developer/edit",
		component: DeveloperEdit,
	},
	{
		path: "/profile",
		component: HomeView,
	},
	{
		path: "/deleted-requests",
		component: DeletedRequestsView,
	},
	{
		path: "/terms-of-use",
		component: TermsView,
	},
	{
		path: "/privacy",
		component: PrivacyView,
	},
	{
		path: "/data-deletion",
		component: DeletionView,
	},
	{
		path: "/under-construction",
		component: LandingPlaceholder,
	},
];

import React from "react";
import { Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@material-ui/core";

const OFFER_FILE_PATH = "/assets/publishing_offer.pdf";
const OFFER_FILE_DOWNLOAD_NAME = "Espresso Publishing Offer v1_1 2021-02-17";

export const OfferFull = () => {
	return (
		<div className="doc-view">
			<Typography variant="body2">(version 1.1 as of February 17, 2021)</Typography>
			<Typography variant="h5">RECITALS</Typography>
			<Typography variant="body1">
				This Offer governs relations between the COMPANY and the Developer pertaining to their participation in
				the PROGRAMME.
			</Typography>
			<Typography variant="body1">
				Unless the context otherwise requires, reference to the present Offer shall include (a) the contract
				that may be formed if the Developer accepts this Offer by sending the signed Application to the COMPANY;
				(b) other documents, pages, schedules, policies, guidelines, specifications, manuals, and supporting
				materials that the COMPANY may make available to the Developer.
			</Typography>
			<ol>
				<Typography className="header" variant="h5" component="li">
					TERMS AND DEFINITIONS
					<Typography variant="body1">
						In this Offer, as well as in any correspondence regarding the PROGRAMME, the Parties agreed on
						the unambiguous interpretation of the following terms and definitions. All terms and definitions
						provided for herein can be used both singular and plural without prejudice to their meaning.
					</Typography>
					<Typography variant="body1">
						<b>AD LTV</b> – a marketability metric measurable via the Tracking System representing the
						advertising life time value.
					</Typography>
					<Typography variant="body1">
						<b>Advance</b> – as defined in Clause 5.2.1
					</Typography>
					<Typography variant="body1">
						<b>Affiliate</b> – in relation to either Party each and any subsidiary or holding company of
						that Party and each and any subsidiary of a holding company of that Party or any business entity
						from time to time controlling, controlled by, or under common control with, either Party. A
						business entity will be deemed to “control” another business entity if it owns, directly or
						indirectly, in excess of 50% of the outstanding voting securities or capital stock of such
						business entity or any other comparable equity or ownership interest with respect to a business
						entity other than a corporation.
					</Typography>
					<Typography variant="body1">
						<b>Application</b> – a formal request made by the Developer to participate in the PROGRAMME,
						which constitutes an unconditional acceptance of all the terms and conditions of this Offer. The
						Application form to be completed by the Developer and submitted to the Company in accordance
						with Clause 3.1, is attached as Appendix No.1 hereto.
					</Typography>
					<Typography variant="body1">
						<b>Chargeback</b> – a request that user files directly or indirectly with his or her
						credit/debit card company or credit/debit card issuing bank to invalidate a payment.{" "}
					</Typography>
					<Typography variant="body1">
						<b>COMPANY</b> – DROMAXCO SOLUTIONS LTD, a legal entity duly established and operating under the
						laws of the Republic of Cyprus, with its registered office at: 13 Kypranoros str., EVI BUILDING,
						2nd Floor, Office 201, 1061 Nicosia, the Republic of Cyprus, Trade register number: HE 409401.
					</Typography>
					<Typography variant="body1">
						<b>Confidential Information</b> - any and all information owned, created, or possessed by the
						Disclosing Party that the Disclosing Party desires to protect as confidential against
						unrestricted disclosure or improper competitive use, including, but not limited to, discoveries,
						ideas, concepts, know-how, techniques, designs (including minor details), specifications,
						drawings, blueprints, tracings, diagrams, models, samples, flow charts, data, computer programs
						and documentation, disks, diskettes, tapes, marketing plans, customer names, lists, or
						proposals, patent applications, business information, strategic and development plans, employee
						lists, business manuals, marketing plans, technical or financial information, or sketches,
						roadmaps whether conveyed as communications or data in oral, written, graphic, or
						electromagnetic form or otherwise, and all other information which may be disclosed by the
						Disclosing Party or an Affiliate or to which the Recipient may be provided access to by My.com
						or others in accordance with this Offer, which is not generally available to the public,
						regardless of the form of the information (tangible or intangible) and the disclosure (oral or
						written) and regardless of whether the information was marked as confidential.
					</Typography>
					<Typography variant="body1">
						<b>Confirmation Milestone</b> – a milestone relating to the Game reaching the confirmation stage
						as set out in the table in Section 5.
					</Typography>
					<Typography variant="body1">
						<b>CPI</b> – either CPI GP or CPI iOS, as applicable.
					</Typography>
					<Typography variant="body1">
						<b>CPI GP</b> – user acquisition cost of Google Play users, also referred to as CPL (Cost Per
						Lead) in the Tracking System.
					</Typography>
					<Typography variant="body1">
						<b>CPI iOS</b> – user acquisition cost of Apple App Store users, also referred to as CPL (Cost
						Per Lead) in the Tracking System.
					</Typography>
					<Typography variant="body1">
						<b>Developer</b> – a natural person or legal entity that participates in the PROGRAMME and
						desires to enter into the LA with the COMPANY or an Affiliate.
					</Typography>
					<Typography variant="body1">
						<b>Development Milestone</b> – a milestone relating to the Game reaching the development stage
						as set out in the table in Section 5.
					</Typography>
					<Typography variant="body1">
						<b>Disclosing Party</b> – as defined in Clause 7.1.
					</Typography>
					<Typography variant="body1">
						<b>DPT</b> – Daily Play Time, a marketability metric measurable via the Tracking System
						representing the total amount of time users spend in the Game per day.
					</Typography>
					<Typography variant="body1">
						<b>Evaluation Milestone</b> – a milestone relating to the Game reaching the evaluation stage as
						set out in the table in Section 5.
					</Typography>
					<Typography variant="body1">
						<b>Evaluation Test</b> – actions of the COMPANY aimed at the evaluation of the Game.
					</Typography>
					<Typography variant="body1">
						<b>Fraud</b> – payments made by the users, arisen for reason of fraudulent or other illegal
						actions of third parties and withdrawn (cancelled) by a bank or a relevant payment system, which
						cannot be controlled, foreseen or prevented by COMPANY.
					</Typography>
					<Typography variant="body1">
						<b>Game</b> – the Developer’s Hypercasual game for mobile devices, as specified in the
						Application.
					</Typography>
					<Typography variant="body1">
						<b>Game Title</b> – the Game title(s), whether created prior to or after the date of the
						contract formation on the basis of this Offer.
					</Typography>
					<Typography variant="body1">
						<b>Game User Information</b> – any end user information (including, without limitation,
						aggregated data, personal information, non-personal information, mobile device information,
						browser data, location data, etc.) that may be collected by either Party in connection with a
						particular Game.
					</Typography>
					<Typography variant="body1">
						<b>Global Launch</b> – the official launch of the Game via a Mobile Platform.
					</Typography>
					<Typography variant="body1">
						<b>Gross Revenues</b> – any and all revenue and other income directly or indirectly generated by
						the COMPANY’s or an Affiliates’ account from the sale, distribution and operation of the Game in
						the Territory.
					</Typography>
					<Typography variant="body1">
						<b>Indemnified Party</b> – means any Party or any of that Party’s Affiliates, successors and
						assigns, directors, officers, employees and agents, claiming indemnification under Clause 10.4.
					</Typography>
					<Typography variant="body1">
						<b>Indemnifying Party</b> – means any Party against whom a claim for indemnification is being
						asserted under Clause 10.4.
					</Typography>
					<Typography variant="body1">
						<b>LA</b> – a LICENSE AGREEMENT under which the Developer shall grant, and the COMPANY or an
						Affiliate shall accept, the rights and licenses as set forth in Section 5, which will
						incorporate the principles set forth herein and determine the terms and conditions of the
						cooperation between the Developer and the COMPANY, in the case the Game be selected by the
						COMPANY and successfully achieves the expected results of the Evaluation Test as provided for
						herein.
					</Typography>
					<Typography variant="body1">
						<b>License Term</b> – a period of three (3) years, and any further extension term, unless
						otherwise is agreed upon between the Parties in the LA.
					</Typography>
					<Typography variant="body1">
						<b>License Territory</b> – worldwide, unless otherwise is agreed upon between the Parties in the
						LA.
					</Typography>
					<Typography variant="body1">
						<b>Materials</b> – any materials related to the Game and/or the Developer, including,
						trademarks, trade names, logos, designs, artwork, and other digital assets owned by the
						Developer.
					</Typography>
					<Typography variant="body1">
						<b>Mobile Platform</b> – means the “App Store” platform operated by Apple Inc. and the “Google
						Play Store” operated by Google LLC where end users may access and download games dedicated to
						mobile devices.
					</Typography>
					<Typography variant="body1">
						<b>Net Profit</b> – the Gross Revenues less operating expenses, incurred both before and after
						the global launch, including but not limited to platform and payment systems fees, Chargeback
						and Fraud, marketing expenses, outsource, expenditure on contractors engaged for the Game
						publishing, taxes and other expenses that can be associated to the Game directly.
					</Typography>
					<Typography variant="body1">
						<b>Offer</b> – this HYPERCASUAL PUBLISHING PROGRAMME OFFER, which following the signed
						Application submission by the Developer to the COMPANY constitutes a legally binding document
						determining the terms and conditions and procedures for the Developer’s participation in the
						PROGRAMME.
					</Typography>
					<Typography variant="body1">
						<b>Party</b> – the COMPANY and the Developer referred to jointly or individually.
					</Typography>
					<Typography variant="body1">
						<b>PROGRAMME</b> – the COMPANY’s HYPERCASUAL PUBLISHING PROGRAMME aimed at the evaluation of the
						desirability of the Parties to enter into the LA for mutually advantageous cooperation.
					</Typography>
					<Typography variant="body1">
						<b>Promotional</b> Material – any product or work (including, but not limited to, all art, text,
						code, videos, and other materials in whatever form) created by or on behalf of the Developer and
						based on or utilising any of the Materials for the purpose of the participation in the
						PROGRAMME.
					</Typography>
					<Typography variant="body1">
						<b>Recipient</b> – as defined in Clause 7.1.
					</Typography>
					<Typography variant="body1">
						<b>Recoupment</b> - as defined in Clause 5.2.3.
					</Typography>
					<Typography variant="body1">
						<b>Representatives</b> – as defined in Clause 7.1.
					</Typography>
					<Typography variant="body1">
						<b>Royalties</b> – as defined in Clause 5.2.2.
					</Typography>
					<Typography variant="body1">
						<b>R1</b> – user retention of the first day, also referred to as Ret1d in the Tracking System.
					</Typography>
					<Typography variant="body1">
						<b>R7</b> – user retention of the seventh day, also referred to as Ret7d in the Tracking System.
					</Typography>
					<Typography variant="body1">
						<b>SDK</b> – software library intended to partly implement the functionality of the specific
						Tracking System in the Game.
					</Typography>
					<Typography variant="body1">
						<b>Tracking System</b> – Facebook Analytics and/or other tracking systems agreed by the Parties,
						if applicable, that make it possible to collect and process statistics in regard to software
						applications (in particular, user acquisition, user retention and payment statistics).
					</Typography>
					<Typography variant="body1">
						<b>Unacceptable Content</b> – means any kind of content that is either illegal or unacceptable
						under the generally accepted moral rules, including, without limitation, the following examples:
						(a) engaging in or contributing to any illegal activity or activity that violates others’
						rights; (b) content that is or could be reasonably viewed as unlawful, harmful, harassing,
						defamatory, libellous, obscene, or otherwise objectionable and unacceptable; (c) providing
						information that is false, misleading, or inaccurate; (d) disclosing any personal or proprietary
						information about any other person, or otherwise invading another person’s privacy; (e) abuse,
						harassment, stalking, threats, faming, or intimidation of any person or organisation; (f)
						profanity or the use of derogatory, discriminatory, hate, or excessively graphic language; (g)
						any content that may harm minors; (h) disseminating or advocating in any way hate, intolerance,
						discrimination, harm, racial or ethnic hatred, violence, crime, or war; (i) offensive, vulgar,
						sexually explicit or pornographic content; (j) promoting the use of alcohol, tobacco, any
						narcotic or illegal substances, or firearms; (k) transmitting software viruses, worms, or any
						other kind of harmful software; (l) unsolicited or unauthorised advertising, promotional
						materials, “junk mail”, “spam”, “chain letters”, “pyramid schemes”, or any other form of
						solicitation; (m) hacking; (n) infringing any intellectual property rights or unlawful
						provision/disclosure of information (insider information, confidential information, or other
						proprietary information); (o) other unacceptable content.
					</Typography>
					<Typography variant="body1">
						In this Offer references to sections, clauses or Appendices are to sections, clauses or
						Appendices of this Offer.
					</Typography>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					PROGRAMME OVERVIEW
					<ol>
						<Typography variant="body1" component="li">
							<u>Purpose:</u> The purpose of the PROGRAMME is to provide the Developer with the
							opportunity to publish the Game and enter into the LA with the COMPANY or an Affiliate,
							therewith to work jointly in order to increase the total number of end users of the Game,
							the in-game payments (if applicable), and revenue from the Game, in accordance with the
							terms and conditions and subject to the limitations specified in the present Offer and
							applicable law.
						</Typography>
						<Typography variant="body1" component="li">
							Both natural persons and legal entities are entitled to participate in the PROGRAMME.
						</Typography>
						<Typography variant="body1" component="li">
							During participation in the PROGRAMME, the Developer is obliged to strictly observe the
							terms and conditions of this Offer, applicable law, and the terms of use of the Mobile
							Platform(s) where the Game is to be distributed.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					ACCEPTANCE OF THE OFFER AND ENROLMENT PROCESS
					<ol>
						<Typography variant="body1" component="li">
							<u>Application:</u> To participate in the PROGRAMME, the Developer must send a duly
							completed and signed Application to the COMPANY. By sending the Application, the Developer
							(a) acknowledges that it has read, understood, and unconditionally accepted the terms and
							conditions of this Offer, and guarantees that the Developer will observe them during the
							entire duration of the PROGRAMME; (b) acknowledges and agrees that it has independently
							evaluated the desirability of participating in the PROGRAMME and is not relying on any
							representation, guarantee, or statement other than as expressly set forth herein; and (c)
							acknowledges that all the Developer’s representations and warranties contained in this Offer
							are true, correct, and valid; (d) represents and warrants that the Developer is and will
							remain in compliance with the Offer, inter alia, that the Developer will fully observe the
							confidentiality provisions thereof and will enter into the LA in the event that the Game
							successfully achieves the expected results of the Evaluation Test. Otherwise, participation
							in the PROGRAMME is prohibited.
							<Typography variant="body1">
								The Developer undertakes to fill in all the fields of the Application with valid and
								accurate information and provide the COMPANY with any additional information requested
								by the COMPANY and which is reasonably necessary for the Developer’s participation in
								the PROGRAMME.
							</Typography>
							<Typography variant="body1">
								The Developer is entitled to send as many Applications to the COMPANY as it considers
								reasonable, provided that each Application shall be filed regarding different Games.
							</Typography>
						</Typography>
						<Typography variant="body1" component="li">
							<u>Preliminary evaluation:</u> After receiving the Application, the COMPANY shall assess
							whether the Developer and the Game satisfy all the requirements indicated in this Offer. If
							the requirements are satisfied, the Parties will prepare for the Evaluation Test which may
							include experimental launch of the Game.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Tracking System:</u> During the course of preparation for the Evaluation Test, the
							Developer undertakes to integrate the SDK of the Tracking System in the Game within fourteen
							(14) calendar days upon receipt of notification from the COMPANY and to inform the COMPANY
							upon completion of such integration. The COMPANY undertakes to provide the Developer with
							access to the Tracking System after it is integrated into the Game. Participation by the
							Developer in the PROGRAMME will only be possible after integration of the Tracking System
							into the Game.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Promotional Materials:</u> During the course of preparation for the Evaluation Test, the
							Developer undertakes to provide within a reasonable time all the Promotional Materials
							necessary at the COMPANY’s sole discretion for the participation by the Developer in the
							PROGRAMME, as well as all the information and authorisations in regard to the Promotional
							Materials and their use by the COMPANY. The Developer grants to the COMPANY a non-exclusive,
							worldwide, royalty-free licence for duration of the Offer to use the Promotional Materials
							provided by the Developer in any manner reasonably necessary for the performance of its
							obligations hereunder. The COMPANY has the right not to accept the Promotional Materials if
							they do not comply with the applicable laws or the terms of this Offer.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Mobile Platform upload:</u> The Developer shall upload the Game on the Mobile Platform(s)
							using its own account. The Developer is responsible for reviewing the applicable terms and
							conditions of the specific Mobile Platform, which may provide for certain additional
							requirements applicable to downloading the Game through that Mobile Platform, its
							installation, and use.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					EVALUATION TEST
					<ol>
						<Typography variant="body1" component="li">
							<u>Evaluation stages:</u>
							<Typography variant="body1">
								For the Purpose of evaluating the Game, after each stage of the Game development, the
								COMPANY may carry out the Evaluation Test. The Evaluation Test may comprise the
								COMPANY’s actions dedicated to marketing and promoting the Game via the Internet by: (a)
								an initial marketing analysis, (b) brand positioning, (c) providing a proposal for a
								marketing campaign, (d) placing Promotional Materials or otherwise attracting end users
								in accordance with this Offer.
							</Typography>
						</Typography>
						<Typography variant="body1" component="li">
							The cost of the Evaluation Test shall be paid by the COMPANY and shall be determined at the
							COMPANY’s discretion. In no circumstances shall the cost of the Evaluation Test exceed $1000
							(one thousand) US Dollars, unless the Parties agree otherwise.
						</Typography>
						<Typography variant="body1" component="li">
							The Evaluation Test’s target territory shall be limited to the USA, unless the Parties agree
							otherwise.
						</Typography>
						<Typography variant="body1" component="li">
							The COMPANY shall be solely entitled, in its absolute discretion, to determine the method
							and manner in which the Evaluation Test is carried out, so long as those methods and manners
							are intended in good faith and subject to the terms of this Offer.
						</Typography>
						<Typography variant="body1" component="li">
							The results of the Evaluation Test are gathered using the Tracking System. The unique
							Tracking System identifiers of the Game are to be sent by the Developer to the COMPANY using
							the e-mail addresses indicated in Clause 12.1.
						</Typography>
						<Typography variant="body1" component="li">
							The Evaluation Test is conducted by the COMPANY and/or its subcontractors. The COMPANY may
							carry out the Evaluation Test through its subcontractors, and shall at all times be
							responsible for their actions and for its own actions to the extent such actions are related
							hereto.
						</Typography>
						<Typography variant="body1" component="li">
							The Parties agree that the following Game Evaluation Test results shall be treated as
							successful:
							<ul>
								<Typography variant="body1" component="li">
									<b>CPI iOS Test:</b>
									<Typography variant="body1">
										{
											"CPI < $0.40 per user acquired via advertising campaign executed by the COMPANY; as well as"
										}
									</Typography>
									<Typography variant="body1">
										R1 after the first launch of the Game per user, acquired during the COMPANY-run
										advertising campaign, is equal to or greater than 30%; AND/OR
									</Typography>
									<Typography variant="body1">
										DPT per user, acquired during the COMPANY-run advertising campaign is equal to
										or greater than 600 seconds.
									</Typography>
								</Typography>
								<Typography variant="body1" component="li">
									<b>CPI GP Test:</b>
									<Typography variant="body1">
										{
											"CPI < $0.32 per user acquired via advertising campaign executed by the COMPANY; as well as"
										}
									</Typography>
									<Typography variant="body1">
										R1 after the first launch of the Game per user, acquired during the COMPANY-run
										advertising campaign, is equal to or greater than 30%; AND/OR
									</Typography>
									<Typography variant="body1">
										DPT per user, acquired during the COMPANY-run advertising campaign is equal to
										or greater than 600 seconds.
									</Typography>
								</Typography>
							</ul>
						</Typography>
						<Typography variant="body1" component="li">
							In most cases, the advertising campaigns for the purposes of the Evaluation Tests being
							carried out shall take place on the Unity Ads, Google Ads, TikTok, Snapchat and Facebook
							advertising platforms in the USA territory on the relevant Mobile Platform. The results of
							the Evaluation Tests will be determined by the COMPANY via a unique ID of the Game,
							implemented by the Developer using Facebook SDK software and sent to the COMPANY via email.
						</Typography>
						<Typography variant="body1" component="li">
							Should the Game not meet the applicable test results set out in Clause 4.8, the Evaluation
							Test shall be treated as unsuccessful, and Clause 11.1.1 shall be applied.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					LA
					<ol>
						<Typography variant="body1" component="li">
							Where the Game has been selected by the COMPANY and has passed the Evaluation Test, the
							Developer shall enter into a LA with the COMPANY or an Affiliate within thirty (30) calendar
							days after the COMPANY has notified the Developer via e-mail that the Game has passed the
							Evaluation Test. The Developer shall be entitled to receive the relevant Evaluation
							Milestone Advance (set out in the table below) following signature of the LA. For the
							avoidance of doubt, the relevant Evaluation Milestone Advance payments shall not be paid if
							the Developer does not enter into the LA with the COMPANY.
						</Typography>
						<Typography variant="body1" component="li">
							Under the terms of the LA, the Developer, in consideration for the license fee payment,
							shall grant to the COMPANY or an Affiliate, inter alia, the following rights and licenses
							during the License Term and in the License Territory:
							<ol className="alpha-list">
								<Typography variant="body1" component="li">
									an exclusive right and license to use the Game and any Materials in any manner
									reasonably required by the COMPANY acting in accordance with the terms and
									conditions of the LA (such as, for instance, market, operate, provide any respective
									services, promote, copy, store, display, use, distribute, reproduce and otherwise
									utilize);{" "}
								</Typography>
								<Typography variant="body1" component="li">
									an exclusive right and license to copy, display, and use any Promotional Materials
									for the purpose promoting or advertising the Game;
								</Typography>
								<Typography variant="body1" component="li">
									an exclusive right to allow end users to download, install, and use the Game;
								</Typography>
								<Typography variant="body1" component="li">
									an exclusive right to produce marketing materials for the marketing and promotion of
									the Game;
								</Typography>
								<Typography variant="body1" component="li">
									any and all rights, titles, and interest in and to the Game Title and the Game User
									Information.
								</Typography>
								<Typography variant="body1">
									In consideration of the grant of the license to the Game, the COMPANY will pay to
									the Developer consideration of (a) a recoupable <b>Advance</b> against Royalties,
									(pursuant to Clause 5.2.1), as well as (b) <b>Royalties</b> (pursuant to Clause
									5.2.2).
								</Typography>
								<Typography variant="body1">
									For the avoidance of doubt, the Advance is payable only if all the corresponding
									targets are reached unless otherwise specified and only one Advance per Milestone is
									payable. In the event that the Developer develops a game for both iOS and Google
									Play and the Developer meets the targets for both only one Advance will be payable
									for that particular Milestone.
								</Typography>
								<Table size="small">
									<TableHead>
										<TableRow>
											<TableCell rowSpan={2} align="center">
												Milestone
											</TableCell>
											<TableCell rowSpan={2} align="center">
												Advance
											</TableCell>
											<TableCell colSpan={6} align="center">
												Targets
											</TableCell>
											<TableCell rowSpan={2} align="center">
												Royalties
											</TableCell>
										</TableRow>
										<TableRow>
											<TableCell align="center">CPI iOS</TableCell>
											<TableCell align="center">CPI GP</TableCell>
											<TableCell align="center">R1</TableCell>
											<TableCell align="center">DPT</TableCell>
											<TableCell align="center">R7</TableCell>
											<TableCell align="center">AD LTV</TableCell>
										</TableRow>
									</TableHead>
									<TableBody>
										<TableRow>
											<TableCell rowSpan={3}>Evaluation</TableCell>
											<TableCell>$5 000</TableCell>
											<TableCell>{"<$0.40"}</TableCell>
											<TableCell>{"<$0.32"}</TableCell>
											<TableCell colSpan={2}>{"R1>30% and/or DPT≥600 sec."}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>-</TableCell>
											<TableCell rowSpan={3}>-</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>$7 000</TableCell>
											<TableCell>{"<$0.30"}</TableCell>
											<TableCell>{"<$0.24"}</TableCell>
											<TableCell colSpan={2}>{"R1>30% and/or DPT≥600 sec."}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>-</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>$10 000</TableCell>
											<TableCell>{"<$0.20"}</TableCell>
											<TableCell>{"<$0.16"}</TableCell>
											<TableCell colSpan={2}>{"R1>30% and/or DPT≥600 sec."}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>-</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Development</TableCell>
											<TableCell>$10 000</TableCell>
											<TableCell>{"<$0.25"}</TableCell>
											<TableCell>{"<$0.20"}</TableCell>
											<TableCell>{">43%"}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>{">13%"}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>-</TableCell>
										</TableRow>
										<TableRow>
											<TableCell rowSpan={4}>Confirmation</TableCell>
											<TableCell>$10 000</TableCell>
											<TableCell>{"<$0.40"}</TableCell>
											<TableCell>{"<$0.32"}</TableCell>
											<TableCell>{"<43%"}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>{"<13%"}</TableCell>
											<TableCell>105% of CPI</TableCell>
											<TableCell rowSpan={4}>-</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>$20 000</TableCell>
											<TableCell>{"<$0.25"}</TableCell>
											<TableCell>{"<$0.20"}</TableCell>
											<TableCell>{"<43%"}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>{"<13%"}</TableCell>
											<TableCell>130% of CPI</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>$20 000</TableCell>
											<TableCell>{"<$0.25"}</TableCell>
											<TableCell>{"<$0.20"}</TableCell>
											<TableCell>{">43%"}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>{">13%"}</TableCell>
											<TableCell>105% of CPI</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>$30 000</TableCell>
											<TableCell>{"<$0.25"}</TableCell>
											<TableCell>{"<$0.20"}</TableCell>
											<TableCell>{">43%"}</TableCell>
											<TableCell>-</TableCell>
											<TableCell>{">13%"}</TableCell>
											<TableCell>130% of CPI</TableCell>
										</TableRow>
										<TableRow>
											<TableCell>Global Launch</TableCell>
											<TableCell>-</TableCell>
											<TableCell colSpan={6}>-</TableCell>
											<TableCell>60% of the Net Profit</TableCell>
										</TableRow>
									</TableBody>
								</Table>
								<Typography variant="body1">
									The Parties are at liberty to agree in good faith on other terms of the LA, inter
									alia, on other Advance amounts or the Royalties rates.
								</Typography>
								<Typography variant="body1">
									The COMPANY shall rely upon the targets listed below, however, the COMPANY is not
									bound by them and may alter these product and marketing metrics at its reasonable
									discretion.
								</Typography>
								<ol>
									<Typography variant="body1" component="li">
										<u>Advance.</u> The COMPANY shall pay to the Developer a recoupable Advance
										against Royalties, if the Game reaches the targets (key performance indicators)
										as set out in the table above and the Developer performs all of its obligations
										under the corresponding milestone (the <b>“Advance”</b>). No more than one
										Advance will be made to the Developer for each milestone. All the milestones as
										well as the Parties obligations at each stage of the Game development and
										publishing will be indicated and described in detail in the LA.
									</Typography>
									<Typography variant="body1" component="li">
										<u>Royalties.</u> In further consideration of the rights granted to the COMPANY,
										the COMPANY shall pay to the Developer from the date of Global Launch and after
										recoupment pursuant to Clause 5.2.3 below, for each reporting quarter, a royalty
										fee equal to Sixty percent (60 %) of the Net Profit (<b>“Royalties”</b>).
									</Typography>
									<Typography variant="body1" component="li">
										<u>Recoupment.</u> COMPANY’s payments of the Advance shall be recouped from the
										Royalties due to the Developer(<b>“Recoupment”</b>). For the avoidance of doubt,
										Royalties are subject to accrual and payment only after the cumulative Net
										Profit is greater than zero.
									</Typography>
								</ol>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							If the Developer does not wish to enter into the LA, the COMPANY may use any materials
							created for, and/or any information gathered before, during or after the marketing campaign
							conducted for the purposes of the Evaluation Test in the COMPANY's future business
							activities, including sharing such materials and information with third parties. Where such
							materials and information may be classed as Confidential Information under Section 7,
							COMPANY shall not be subject to the confidentiality provisions in Section 7 and any use of
							such materials and information by COMPANY shall not be a breach of the confidentiality
							provisions in Section 7.
						</Typography>
						<Typography variant="body1" component="li">
							THE DEVELOPER ACKNOWLEDGES THAT IT WISHES TO BE BOUND BY THE TERMS AND CONDITIONS OF THIS
							OFFER, SHOULD IT BE ACCEPTED, AND REPRESENTS AND WARRANTS THAT THE DEVELOPER’S OBLIGATION ON
							ENTERING INTO THE LA SHALL NOT BE AFFECTED BY UNCERTAINTY OF THE PRESENT OFFER (IF ANY).
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					TAXES
					<ol>
						<Typography variant="body1" component="li">
							Each Party shall be solely responsible for its own taxes and fees applicable to each Party’s
							performance under the LA and the contract that may be formed if the Developer accepts this
							Offer by sending the signed Application to the COMPANY.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					CONFIDENTIALITY
					<ol>
						<Typography variant="body1" component="li">
							A Party (the <b>“Disclosing Party”</b>) may disclose or grant access to the Confidential
							Information to the other Party (the <b>“Recipient”</b>), in accordance with this Section 7.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							The Recipient shall use the Confidential Information solely for the purpose of this Offer
							including for the purpose of evaluation of the Game and the negotiation of the LA and,
							subject to Clause 7.6, shall not disclose or permit access to Confidential Information other
							than to its employees, officers, directors, partners, agents, attorneys, accountants, and
							financial advisors (collectively, its <b>“Representatives”</b>) who:
							<ol>
								<Typography variant="body1" component="li">
									need to know such Confidential Information for the purpose of evaluation of the Game
									and the negotiation of the LA;
								</Typography>
								<Typography variant="body1" component="li">
									know of the existence and terms of the confidentiality obligations set out in this
									Section 7, and
								</Typography>
								<Typography variant="body1" component="li">
									are bound by confidentiality agreements or obligations no less protective of the
									Confidential Information than the terms contained in this Section 7.{" "}
								</Typography>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							The Recipient shall safeguard the Confidential Information from unauthorized use, access or
							disclosure using at least the degree of care it uses to protect its own Confidential
							Information and no less than a commercially reasonable degree of care.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							The Recipient shall immediately notify the Disclosing Party of any unauthorized use or
							disclosure of Confidential Information, and shall use commercially reasonable efforts to
							prevent further use or disclosure.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							The Recipient will be responsible for any breach of this Agreement caused by its
							Representatives.
						</Typography>
						<Typography variant="body1" component="li">
							Confidential Information does not include information, technical data or know-how which:
							<ol>
								<Typography variant="body1" component="li">
									is in the possession of the Recipient at the time of disclosure as shown by the
									Recipient's files and records immediately prior to the time of disclosure; or
								</Typography>
								<Typography variant="body1" component="li">
									prior or after the time of disclosure becomes part of the public knowledge or
									literature, not as a result of any inaction or action of the Recipient; or{" "}
								</Typography>
								<Typography variant="body1" component="li">
									is approved for release by the Disclosing Party in writing; or{" "}
								</Typography>
								<Typography variant="body1" component="li">
									has been received from a third party without any non-disclosure and/or
									confidentiality obligation; or{" "}
								</Typography>
								<Typography variant="body1" component="li">
									is furnished after the date of this Offer by the Disclosing Party to a third party
									without restriction; or
								</Typography>
								<Typography variant="body1" component="li">
									was independently developed by the Recipient or an Affiliate without the benefit of
									data received from the Disclosing Party hereof.{" "}
								</Typography>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							Each Party agrees to notify the other Party in writing of any misuse or misappropriation of
							such Confidential Information of the other Party that may come to its attention.
						</Typography>
						<Typography variant="body1" component="li">
							In the event that either Party is requested or required by law to disclose any of the
							Confidential Information of the other Party, such Party shall give prompt notice thereof to
							the other Party. The Party required to make such disclosure shall disclose only that portion
							of the Confidential Information that is legally required to disclose.
						</Typography>
						<Typography variant="body1" component="li">
							The Recipient shall promptly return or destroy, in accordance with the Disclosing Party’s
							direction, all Confidential Information with all copies thereof at the termination or
							expiration of this Offer, or upon request of the Disclosing Party.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							In the case of destruction, the Recipient shall prove the destruction of the Confidential
							Information by sending written evidence to the Disclosing Party within ten (10) days of such
							destruction.
						</Typography>
						<Typography variant="body1" component="li">
							Each Party warrants that it has the right to disclose to the other Party all Confidential
							Information which it discloses under this Section 7, provided however, all Confidential
							Information provided to the other Party is provided “AS IS” and without warranty.
						</Typography>
						<Typography variant="body1" component="li">
							The obligations of the Parties shall survive for a period of three (3) years after the
							expiration or termination of this Offer.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					ANTI CORRUPTION
					<ol>
						<Typography variant="body1" component="li">
							The Parties hereby acknowledge and confirm that they have adopted a policy of zero tolerance
							to bribery and corruption, envisaging a total ban of any corrupt practices and on any
							facilitation payments, the purpose of which is to obtain an unlawful financial or other
							advantage, simplification of formalities in connection with the business activity, impact on
							decisions in relation to whatever issues.
						</Typography>
						<Typography variant="body1" component="li">
							The Parties agree that, at all times in connection with and throughout the course of this
							Offer and thereafter, they will comply with and that they will take reasonable measures to
							ensure that their Affiliates, employees, subcontractors, agents or other third parties,
							subject to their control or determining influence, will comply with all applicable laws,
							statutes, regulations, and codes relating to anti-bribery and anti-corruption including but
							not limited to Bribery Act 2010 (England and Wales) and the United States Foreign Corrupt
							Practices Act of 1977, any laws intended to implement the OECD Convention on Combating
							Bribery of Foreign Public Officials in International Business Transactions (the{" "}
							<b>“Anti-Corruption Laws”</b>).
						</Typography>
						<Typography variant="body1" component="li">
							{" "}
							The Parties undertake not to take any actions that may constitute a breach of the
							Anti-Corruption Laws and, among other things, guarantee that they, their Affiliates,
							employees or subcontractors, agents or other third parties, subject to their control or
							determining influence (who are engaged in the performance of this Offer):
							<ol>
								<Typography variant="body1" component="li">
									shall not offer promise, give, authorise or accept of any undue pecuniary or other
									advantage to, by or for any public official at the international, national or local
									level, a political party, party official or candidate to political office, and a
									director, officer or employee of a Party or for anyone else in order to obtain or
									retain a business or other improper advantage, whether directly or indirectly;
								</Typography>
								<Typography variant="body1" component="li">
									shall not perform any actions qualified by the Anti-Corruption Laws as giving /
									receiving a bribe or commercial bribery of any of the persons listed in Clause 8.3.1
									above.
								</Typography>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							Each Party hereby undertakes itself, its directors, officers or employees have not make any
							funds or economic resources available, directly or indirectly, to or for the benefit of any
							person or entity that is currently targeted as a restricted party under any applicable trade
							or economic sanctions, export control, embargo or similar laws, regulations, rules,
							measures, restrictions, restricted or designated party lists, orders, or requirements,
							including without limit those of the EU, the UK, the US and the UN (the{" "}
							<b>“Sanctions Rules”</b>) (including any funds or economic resources paid by the Party on
							behalf of the other Party or received by the Party from the other Party in accordance with
							this Agreement), unless authorised by a relevant authority to the satisfaction of the
							relevant Party.
						</Typography>
						<Typography variant="body1" component="li">
							Neither Party shall be obliged to perform any obligation under this Offer, and shall not be
							liable for damages or costs of any kind (including but not limited to penalties) for any
							delay or non-performance, if that Party in its sole discretion determines that such
							performance would be in violation of, inconsistent with, or could expose it to any negative
							consequences under Sanctions Rules and/or Anti-Corruption Laws.
						</Typography>
						<Typography variant="body1" component="li">
							Either Party may terminate this Agreement immediately upon written notice to the other Party
							where that Party determines in its sole discretion that the other Party has breached this
							Section 8, and the other Party shall indemnify such Party from all damages, penalties, fines
							and/or costs of any kind arising from, or relating to, any breach of this Section 8.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							This Section 8 shall survive any termination of this Offer.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					USERS’ DATA PROTECTION
					<ol>
						<Typography variant="body1" component="li">
							For the purposes of this Section 9, the following defined terms shall have the meanings set
							out:
							<ol>
								<Typography variant="body1" component="li">
									<b>
										Controller, Processor, Data Subject, Personal Data, Personal Data Breach,
										processing and appropriate technical and organisational measures:
									</b>{" "}
									as defined in the Data Protection Legislation.
								</Typography>
								<Typography variant="body1" component="li">
									<b>Data Protection Legislation:</b> the UK Data Protection Legislation and any other
									European Union legislation relating to personal data and all other legislation and
									regulatory requirements in force from time to time which apply to a party relating
									to the use of Personal Data (including, without limitation, the privacy of
									electronic communications); and the guidance and codes of practice issued by the
									relevant data protection or supervisory authority and applicable to a party.
								</Typography>
								<Typography variant="body1" component="li">
									<b>UK Data Protection Legislation:</b> all applicable data protection and privacy
									legislation in force from time to time in the UK including the General Data
									Protection Regulation ((EU) 2016/679); the Data Protection Act 2018; the Privacy and
									Electronic Communications Directive 2002/58/EC (as updated by Directive 2009/136/EC)
									and the Privacy and Electronic Communications Regulations 2003 (SI 2003/2426) as
									amended.
								</Typography>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							The Developer shall:
							<ol>
								<Typography variant="body1" component="li">
									procure the eligibility of collection and use of end users’ data via the Tracking
									System for the purposes of this Offer, in accordance with all applicable
									requirements of the Data Protection Legislation;
								</Typography>
								<Typography variant="body1" component="li">
									inform the end users of the Game in a privacy policy or in a similar document, and,
									in accordance with all applicable requirements of the Data Protection Legislation,
									that: (i) the SDK of the Tracking System is embedded in the Game, which may collect
									information about end users (including IP addresses, the device ID, and other
									information) , (ii) end users’ data may be transferred to, stored, and processed in
									the territory of different jurisdictions, (iii) the Developer may share end users’
									data with the COMPANY for the purposes of this Offer;
								</Typography>
								<Typography variant="body1" component="li">
									inform end users about their ability to reject the processing of their data; provide
									such a refusal by selecting the appropriate settings in the mobile application;
									immediately inform the COMPANY about any cases when end users of the Game withdraw
									their consent to processing of their data.
								</Typography>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							Both Parties will comply with all applicable requirements of the Data Protection
							Legislation. This Section 9 is in addition to, and does not relieve, remove or replace, a
							Party's obligations or rights under the Data Protection Legislation. In this Section 9,
							Applicable Laws means (for so long as and to the extent that they apply to either Party) the
							law of the European Union, the law of any member state of the European Union and/or Domestic
							UK Law; and Domestic UK Law means the UK Data Protection Legislation and any other law that
							applies in the UK.
						</Typography>
						<Typography variant="body1" component="li">
							The Parties explicitly indicate that personal data of the European Union’s data subjects
							shall not be possessed until the Parties implement a data processing agreement that complies
							with the requirements of the Data Protection Legislation.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							The Developer shall immediately, on demand, fully indemnify the COMPANY and keep it fully
							and effectively indemnified against all costs, claims, demands, expenses (including legal
							costs and disbursements on a full indemnity basis), losses (including indirect losses and
							loss of profits), actions, proceedings, and liabilities of whatsoever nature arising from or
							incurred by the COMPANY in connection with any failure of the Developer or any third party
							appointed by the Developer to comply with the provisions of this Section 9.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					LIABILITY, WARRANTIES AND INDEMNIFICATION
					<ol>
						<Typography variant="body1" component="li">
							<u>Mutual representations and warranties:</u> Each Party represents and warrants to the
							other Party that (a) it is, as the case may be, either a fully legally capable natural
							person, or a legal entity duly organised, validly existing, and in good standing under the
							laws of its state of residence with the full right and authority to enter into this Offer
							and to perform the acts required of it hereunder, (b) this Offer has been executed by its
							duly authorized representative, or by the Developer in person, should it be a natural
							person, (c) the execution of this Offer by it, and the performance by it of its obligations
							and duties hereunder, do not and shall not violate any agreement to which it is a party or
							by which it is otherwise bound.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Representations and warranties of the Developer:</u> On an ongoing basis, the Developer
							represents and warrants that (a) it has all the necessary rights, title and interests in the
							Game, the Materials and the Promotional Materials; (b) it has the full right, power, legal
							capacity, ability and authority to accept this Offer; (c) neither the Game on the whole, nor
							any of its part contains Unacceptable Content, violates of will violate any applicable laws,
							property rights, copyright, ancillary, patent, trademark, personal, civil, contractual or
							any other rights of third parties or damage their honour, dignity or business reputation is
							not in violation of this Offer, any applicable laws and regulations, or third party rights,
							and that the Developer has permission from any third party, whose personal information or
							intellectual property is incorporated in the Game; (d) it has all the rights to grant the
							licences under the LA (as set out in Clause 5.2); performance of the COMPANY’s obligations
							in accordance with the Offer will not violate applicable legislation and third parties’
							rights.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Limitation of liability:</u> Except for any liability for death or personal injury or any
							acts of fraud, gross negligence, or wilful misconduct, in no event will either Party be
							liable to the other for any loss of profits, loss of use, loss of revenue, loss of goodwill,
							any interruption of business, or for any indirect, special, incidental, exemplary, punitive,
							or consequential damages of any kind arising out of or in connection with this Offer,
							regardless of the form of action, whether in contract, tort, strict liability or otherwise,
							even if such Party has been advised or is otherwise aware of the possibility of such
							damages. Neither Party makes any representation, conditions, or warranties of any kind and
							all implied representations or warranties are excluded to the maximum extent permissible by
							law.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							The Indemnifying Party shall indemnify, defend, and hold harmless the Indemnified Party from
							and against any and all claims, liabilities, losses, damages, costs, and expenses
							(including, but not limited to, reasonable legal fees), arising out of or associated with
							any third party claims arising out of a breach of the warranties and representations under
							this Offer. The Indemnified Party agrees to give the Indemnifying Party prompt written
							notice of any claim subject to indemnification. The Indemnifying Party will have the right
							to defend against any such claim with counsel of its own choosing and to settle such claim
							as the Indemnifying Party deems appropriate, provided that the Indemnifying Party will not
							enter into any settlement that adversely affects the Indemnified Party’s rights without the
							Indemnified Party’s prior written consent. The Indemnified Party agrees to reasonably
							cooperate with the Indemnifying Party in the defence and settlement of any such claim, at
							the Indemnifying Party’s expense. All claims and causes of action by the Parties under or
							related to this Offer or otherwise related to the subject matter of this Offer, including,
							without limitation, claims for breach of contract and claims in tort, must follow the
							process set out in Clause 12.2.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					TERM AND TERMINATION
					<ol>
						<Typography variant="body1" component="li">
							A legally binding contract shall come into force on the date of submission of the signed
							Application by the Developer to the COMPANY and terminate immediately on the earlier of:
							<ol>
								<Typography variant="body1" component="li">
									the date on which the COMPANY informs the Developer that the Evaluation Test was
									unsuccessful;
								</Typography>
								<Typography variant="body1" component="li">
									the specific time and date that the Developer and the COMPANY enter into the LA; and
								</Typography>
								<Typography variant="body1" component="li">
									service of a notice by the COMPANY on the Developer under Clause 11.2.
								</Typography>
							</ol>
						</Typography>
						<Typography variant="body1" component="li">
							The COMPANY reserves the right to suspend or cancel, at any time, all or a portion of the
							PROGRAMME, without payment of any compensation to the Developer. In the event of early
							termination or suspension of the PROGRAMME, the COMPANY will notify the Developer by sending
							a notification to the e-mail address specified by the Developer in the Application. The
							COMPANY is not responsible for any direct, indirect, or consequential damages, lost profits,
							and/or damages of Developers related to early termination or suspension of the PROGRAMME.{" "}
						</Typography>
						<Typography variant="body1" component="li">
							The COMPANY has the right to change, modify, or update this Offer from time to time, and the
							revised Offer will be made available via the COMPANY website. The Developer, by continuing
							to participate in the PROGRAMME, shall be deemed to have accepted any changes, modifications
							or updates made to the Offer.
						</Typography>
					</ol>
				</Typography>
				<Typography className="header" variant="h5" component="li">
					MISCELLANEOUS
					<ol>
						<Typography variant="body1" component="li">
							<u>Notices:</u> All notices under this Offer shall be sent to the COMPANY through mail to
							y.vesnina@espresso-pub.com and/or at the following address: 13 Kypranoros str., EVI
							BUILDING, 2nd Floor, Office 201, 1061 Nicosia, the Republic of Cyprus, or to such other
							address as the COMPANY shall have notified the Developer. All such notices so addressed
							shall be deemed duly given (a) upon delivery, if delivered by courier, by hand (against
							receipt), or confirmed facsimile or e-mail; or (b) three (3) days after posting, if sent by
							certified or registered mail, return receipt requested. All notices under this Offer shall
							be sent to the Developer using the contact information indicated by the Developer in the
							Application or to such other address as the Developer shall have notified the COMPANY. The
							Parties agree that exchange of messages via e-mail and documents attached thereto using the
							foregoing e-mail address of the COMPANY and the Developer’s e-mail address indicated in the
							Application is deemed valid and shall have legal force.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Disputes, Governing Law, and Jurisdiction:</u> This Offer shall be governed and construed
							by the laws of England and Wales, without regard to the conflicts of law provisions thereof.
							All disputes arising in connection with this Offer are subject to mandatory pre-trial
							settlement by the Parties. In case of failure to reach agreement during the pre-trial
							settlement of the dispute, it is to be submitted for final settlement to the arbitration
							under the LCIA Rules, which Rules are deemed to be incorporated by reference into this
							Clause 12.2. The number of arbitrators shall be one. The seat, or legal place, of
							arbitration shall be London, UK. The language to be used in the arbitral proceedings shall
							be English.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Severability:</u> If any court or competent authority finds that any provision of this
							Offer (or part of any provision) is invalid, illegal, or unenforceable, that provision or
							part-provision will, to the extent required, be deemed to be deleted, and the validity and
							enforceability of the other provisions of this Offer will not be affected. If any invalid,
							unenforceable, or illegal provision of this Offer would be valid, enforceable, and legal if
							some part of it were deleted, the parties will negotiate in good faith to amend such
							provision so that, after it is amended, it is legal, valid, and enforceable, and, to the
							greatest extent possible, achieves the Parties’ original commercial intention.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Force Majeure Events:</u> Neither the COMPANY nor the Developer shall be liable for any
							failure to perform any of its obligations under this Offer that results from acts of God,
							the elements, fire, flood, riot, insurrection, industrial dispute, accident, war, embargoes,
							legal restrictions, or any other cause beyond the control of the Party.
						</Typography>
						<Typography variant="body1" component="li">
							<u>Assignment:</u> The COMPANY may assign, novate, delegate, transfer, and subcontract any
							and all of its rights, titles, benefits, interests and obligations in and under this Offer
							without obligation of giving any notice to the Developer. The Developer shall not assign,
							novate, sub-licence, transfer, charge, or deal in any other manner with this Offer or any
							rights under it without the prior written consent of the COMPANY (without which any such
							purported steps will confer no rights), except as part of a legal entity amalgamation,
							reorganisation, reconstruction, or rearrangement (where, for the avoidance of doubt, no such
							prior written consent of the COMPANY is required).{" "}
						</Typography>
						<Typography variant="body1" component="li">
							<u>No Agency or Partnership:</u> Nothing in this Offer is intended to, or shall be deemed
							to, establish any partnership, joint venture between the Parties, constitute any Party the
							agent of the other Party, or authorise a Party to make or enter into any commitments for or
							on behalf of the other Party.
						</Typography>
					</ol>
				</Typography>
				<Typography variant="body1">
					<i>
						Only the English version of this document shall have legal effect. Any translations of this
						document into other languages are provided for your convenience only.{" "}
					</i>
				</Typography>
				<Typography variant="body1">Version 1.1 as of February 17, 2021 </Typography>
			</ol>
			<a className="link" href={OFFER_FILE_PATH} download={OFFER_FILE_DOWNLOAD_NAME}>
				<i className="icon icon-download" />
				<b>Download the Publishing Offer</b>
			</a>
		</div>
	);
};

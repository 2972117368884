import React, { useCallback, useEffect, useState } from "react";
import _ from "lodash";
import { ConfirmModal, FullScreenPlaceholder, StandartView } from "../../../components";
import { PlaceholderView } from "components/common/PlaceholderView";
import withAuth from "../../../helpers/withAuth";
import { Backdrop, Box, CircularProgress, Grid, Hidden } from "@material-ui/core";
import {
	defaultUpsertRequestInput,
	useGetOneRequestLazyQuery,
	useUpsertRequestMutation,
	RequestFullFragment,
	ERequestStatus,
	GetManyRequestsDocument,
	UpsertRequestInput,
} from "@espresso/protocol";
import { CreateRequestFormInitial } from "./CreateRequestFormInitial";
import { CreateRequestFormNew } from "./CreateRequestFormNew";
import { useHistory, useParams } from "react-router-dom";
import { RequestStepper } from "../RequestStepper";
import { REQUEST_ITEMS_PER_PAGE } from "../../../helpers/constants";
import { CreateRequestFormInfoFacebook } from "./CreateRequestFormInfoFacebook";
import { CreateRequestFormSetSdk } from "./CreateRequestFormSetSdk";
import { RequestView } from "..";
import { CreateRequestFormPreValidation } from "./CreateRequestFormPreValidation";
import { TCreateRequestFormProps } from "./types";
import { CreateRequestFormPrivate } from "./CreateRequestFormPrivate";
import strings from "../../../helpers/strings";
import { useLoadingSignal } from "contexts/LoadingContext";

type TCreateRequestParams = {
	requestId: string;
};

export const CreateRequest = withAuth(() => {
	const [currentStepStatus, setCurrentStepStatus] = useState<ERequestStatus>();
	const [currentStepIndex, setCurrentStepIndex] = useState<number>();
	const [confirmVisible, setConfirmVisible] = useState(false);

	const [upsertRequest, { loading }] = useUpsertRequestMutation();
	useLoadingSignal(loading);

	const { requestId } = useParams<TCreateRequestParams>();
	const history = useHistory();

	const [getRequest, { data, loading: fetchRequestLoading }] = useGetOneRequestLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	useLoadingSignal(fetchRequestLoading);

	useEffect(() => {
		if (requestId) {
			// Проверяем currentStepStatus если он установлен, а requestId поменялся
			// значит пришли из другой заявки нужно скинуть в undefined
			if (currentStepStatus) {
				setCurrentStepStatus(undefined);
			}
			getRequest({ variables: { id: requestId } });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getRequest, requestId]);
	const request = data?.getOneRequest;

	useEffect(() => {
		if (!request) {
			return;
		}

		// 1. Кейс с нажатием кнопки "Проверить" на экране фейсбук. Происходит рефеч и не нужно менять экран
		// 2. После перехода на статус Подключение сдк и дальше с возвращением на экран для получения иконки со стора
		//		Для того чтобы не улететь на экрна актуального request.Status после получения данных
		if (
			currentStepStatus &&
			(currentStepStatus === ERequestStatus.InfoFacebook ||
				request.Status === ERequestStatus.PreValidation ||
				(request.Status === ERequestStatus.Returned && currentStepStatus !== ERequestStatus.Validation) ||
				(request.Status === ERequestStatus.InfoFacebook && currentStepStatus === ERequestStatus.New))
		) {
			return;
		}
		setCurrentStepStatus(request.Status);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	const submit = useCallback(
		async (data: UpsertRequestInput, callbackFn?: (upsertedRequestId: string) => Promise<void> | void) => {
			/**
			 * Инпут-параметр для мутации, содержащий только извествные свойства типа UpsertRequestInput
			 */
			const upsertRequestInput = _.pick(data, Object.keys(defaultUpsertRequestInput));
			if (requestId) {
				upsertRequestInput.Id = requestId;
			}

			const needSave = Object.entries(upsertRequestInput).some(([key, value]) => {
				const requestKey = key as keyof RequestFullFragment;
				const requestValue = request?.[requestKey];
				if (request && typeof request[requestKey] === "undefined") {
					return false;
				}
				if (!requestValue && !value) {
					return false;
				}
				return requestValue !== value;
			});

			if (!needSave) {
				if (request) {
					await callbackFn?.(request.Id);
				}
				return request;
			}

			const res = await upsertRequest({
				variables: { data: upsertRequestInput },
				refetchQueries: [
					{
						query: GetManyRequestsDocument,
						variables: { input: { Pagination: { Limit: REQUEST_ITEMS_PER_PAGE, Offset: 0 } } },
					},
				],
			});
			const upsertedRequest = res.data?.upsertRequest.data?.Request;
			const upsertedRequestId = upsertedRequest?.Id;

			if (upsertedRequestId) {
				await callbackFn?.(upsertedRequestId);
				if (!requestId) {
					history.push(`/request-${upsertedRequestId}/edit`);
					return upsertedRequest;
				}
				getRequest({ variables: { id: upsertedRequestId } });
			}
			return upsertedRequest;
		},
		[getRequest, history, request, requestId, upsertRequest],
	);

	const showConfirmModal = () => setConfirmVisible(true);

	const changeStepIndex = (index: number) => {
		if (index <= 0) {
			return;
		}
		setCurrentStepIndex(index);
	};

	if (request === null && !fetchRequestLoading) {
		return (
			<StandartView disableNotifications>
				<Grid item xs={12}>
					<PlaceholderView
						heading={strings.notFound}
						imageSrc="/images/placeholder.png"
						text={strings.request_placeholderText}
						buttonProps={{
							className: "button-width-medium",
							color: "success",
							children: strings.request_placeholderButton,
							onClick: () => {
								history.push("/profile");
							},
						}}
					/>
				</Grid>
			</StandartView>
		);
	}

	if (requestId && (!request || !currentStepStatus)) {
		return (
			<StandartView disableNotifications>
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="request_emptyTitle" messageKey="request_emptyMessage" />
				</Grid>
			</StandartView>
		);
	}

	const removeRequest = async () => {
		if (!request) {
			return;
		}
		await upsertRequest({
			variables: {
				data: { Id: request.Id, Status: request.Status, PublisherId: request.Publisher.Id, Deleted: true },
			},
		});
		history.push("/profile");
	};

	const restoreRequest = async () => {
		if (!request) {
			return;
		}
		await upsertRequest({
			variables: {
				data: { Id: request.Id, Status: request.Status, PublisherId: request.Publisher.Id, Deleted: false },
			},
		});
		getRequest({ variables: { id: request.Id } });
	};

	let CurrentStepComponent: ((props: TCreateRequestFormProps) => JSX.Element) | null = null;
	switch (currentStepStatus) {
		case undefined:
		case ERequestStatus.Initial:
			CurrentStepComponent = CreateRequestFormInitial;
			break;
		case ERequestStatus.Private:
			CurrentStepComponent = CreateRequestFormPrivate;
			break;
		case ERequestStatus.New:
			CurrentStepComponent = CreateRequestFormNew;
			break;
		case ERequestStatus.InfoFacebook:
			CurrentStepComponent = CreateRequestFormInfoFacebook;
			break;
		case ERequestStatus.SetSDK:
			CurrentStepComponent = CreateRequestFormSetSdk;
			break;
		case ERequestStatus.PreValidation:
		case ERequestStatus.Returned:
			CurrentStepComponent = CreateRequestFormPreValidation;
			break;
	}

	if (!CurrentStepComponent) {
		return <RequestView setCurrentStepStatus={setCurrentStepStatus} />;
	}

	return (
		<StandartView disableNotifications>
			<Box className="request-create">
				<Hidden smDown>
					<RequestStepper
						request={request}
						currentStepStatus={currentStepStatus}
						changeStepIndex={changeStepIndex}
					/>
				</Hidden>
				<CurrentStepComponent
					request={request}
					currentStepIndex={currentStepIndex}
					refetch={(id) => getRequest({ variables: { id: id || requestId } })}
					loading={loading || fetchRequestLoading}
					submit={submit}
					setCurrentStepStatus={setCurrentStepStatus}
					handleClickRemove={showConfirmModal}
					handleRestoreRequest={restoreRequest}
				/>
			</Box>
			<Backdrop open={fetchRequestLoading}>
				<CircularProgress />
			</Backdrop>
			<ConfirmModal
				visible={confirmVisible}
				title={strings.request_removeTitle}
				onClose={() => setConfirmVisible(false)}
				onConfirm={removeRequest}
				text={strings.request_removeText}
				confirmButtonLabel={strings.delete}
				confirmButtonColor="danger"
			/>
		</StandartView>
	);
});

import React, { useMemo } from "react";
import {
	StandartView,
	StandartButton,
	InfoArea,
	StandartParallaxContent,
	IStandartButtonProps,
} from "../../components";
import { Grid, Typography } from "@material-ui/core";
import { LandingCard } from "./LandingCard";
import strings from "../../helpers/strings";
import { useHistory } from "react-router-dom";
import { PARALLAX_LANDING_MEDIA_CONTENT } from "../../helpers/constants";
import useAppContext from "../../contexts/AppContext";

export const LandingView = () => {
	const history = useHistory();

	// хак, чтобы экран перерендерировался при смене языка на лету
	const { login } = useAppContext();

	const publicButtonProps = useMemo<IStandartButtonProps>(
		() => ({
			color: "success",
			onClick: () => history.push(login ? "/profile" : "/request-express"),
			size: "large",
		}),
		[history, login],
	);

	return (
		<StandartView
			disableNotifications
			title=""
			parallax={{
				...PARALLAX_LANDING_MEDIA_CONTENT,
				changeColorOnScroll: 80,
				content: (
					<StandartParallaxContent
						title="landing_title"
						description="landing_description"
						action={{ label: "landing_action", props: publicButtonProps }}
					/>
				),
			}}
		>
			<div className="landing-view">
				<Grid container direction="column" alignItems="center" justify="center">
					<Grid item md={12} lg={10}>
						<LandingCard
							inverted
							imageSrc="/images/landing-1.png"
							title={strings.landing_card1_title}
							subtitle={strings.landing_card1_desctiption}
						/>
					</Grid>
					<Grid item md={12} lg={10}>
						<LandingCard
							imageSrc="/images/landing-2.png"
							title={strings.landing_card2_title}
							subtitle={strings.landing_card2_desctiption}
						/>
					</Grid>
					<Grid item md={12} lg={10}>
						<LandingCard
							inverted
							imageSrc="/images/landing-3.png"
							title={strings.landing_card3_title}
							subtitle={strings.landing_card3_desctiption}
						/>
					</Grid>
					<Grid item md={12} lg={10}>
						<LandingCard
							imageSrc="/images/landing-4.png"
							title={strings.landing_card4_title}
							subtitle={strings.landing_card4_desctiption}
						/>
					</Grid>
					<Grid item md={11} lg={9}>
						<Typography variant="h5">{strings.landing_infoTitle}</Typography>
						<Grid container className="info-container">
							<Grid item sm={12} md={4}>
								<InfoArea
									title={strings.landing_info1_title}
									description={strings.landing_info1_desctiption}
									descriptionVariant="subtitle2"
									icon="website-1"
									iconColor="success"
									vertical
								/>
							</Grid>
							<Grid item sm={12} md={4}>
								<InfoArea
									title={strings.landing_info2_title}
									description={strings.landing_info2_desctiption}
									descriptionVariant="subtitle2"
									icon="analyze-1"
									iconColor="success"
									vertical
								/>
							</Grid>
							<Grid item sm={12} md={4}>
								<InfoArea
									title={strings.landing_info3_title}
									description={strings.landing_info3_desctiption}
									descriptionVariant="subtitle2"
									icon="website-2"
									iconColor="success"
									vertical
								/>
							</Grid>
						</Grid>
					</Grid>
					<StandartButton {...publicButtonProps}>{strings.landing_action}</StandartButton>
				</Grid>
			</div>
		</StandartView>
	);
};

import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";

export const GetRobustaSdk = () => {
	const sdkEndpoint = "https://github.com/espresso-pub/robusta#readme";

	return (
		<>
			<Typography variant="body1">
				{strings.help_faqGetRobustaSdkP1}
				<br />
				<a className="link" href={sdkEndpoint} target="_blank" rel="noreferrer">
					<i className="icon icon-link" />
					<b>{sdkEndpoint}</b>
				</a>
			</Typography>
			<Typography variant="body1">{strings.help_faqGetRobustaSdkP2}</Typography>
		</>
	);
};

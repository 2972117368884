import React from "react";
import { Grid } from "@material-ui/core";

const Splash = () => {
	return (
		<Grid container justify="center" alignItems="center">
			<Grid item sm={6}>
				<img src="/images/logo.png" width={256} alt="logo" />
			</Grid>
		</Grid>
	);
};

export default Splash;

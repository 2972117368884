import React, { useCallback, useEffect } from "react";
import { Box, CircularProgress, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import strings from "../../helpers/strings";
import { StandartButton, StandartTextInputController } from "../../components";
import { TEnterPasswordModalState } from "./ExpressRegisterView";
import { useStandartForm } from "../../helpers/useStandartForm";

type TEnterPasswordModalProps = {
	visible: boolean;
	loading?: boolean;
	errorMessage?: string;
	setEnterPasswordModalState: (value: TEnterPasswordModalState) => void;
	text?: string;
};

export const EnterPasswordModal = (props: TEnterPasswordModalProps) => {
	const {
		visible,
		loading,
		errorMessage,
		setEnterPasswordModalState,
		text = strings.auth_enterPasswordExpressRegister,
	} = props;

	const form = useStandartForm<{ password: string }>({
		mode: "onBlur",
		reValidateMode: "onChange",
		defaultValues: { password: "" },
	});
	const { handleSubmit, errors, control } = form;

	useEffect(() => {
		if (!errorMessage) {
			return;
		}
		form.setError("password", { message: errorMessage });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [errorMessage]);

	const submit = useCallback(
		(data: { password: string }) => {
			setEnterPasswordModalState({ visible: true, password: data.password, loading: true });
		},
		[setEnterPasswordModalState],
	);

	return (
		<Dialog className="withCloseButton" open={visible}>
			<IconButton className="closeButton" onClick={() => setEnterPasswordModalState({ visible: false })}>
				<i className="icon icon-close" />
			</IconButton>
			<DialogTitle disableTypography>
				<Typography variant="h5" align="center">
					{strings.auth_differentCredential}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<form onSubmit={handleSubmit(submit)}>
					<Box display="flex" flexDirection="column" alignItems="center">
						<Typography variant="body1">{text}</Typography>
						<StandartTextInputController
							fullWidth
							name="password"
							type="password"
							control={control}
							rules={{ required: strings.required }}
							errorMessage={errors.password?.message}
							label={strings.auth_password}
							margin="normal"
						/>

						<StandartButton
							type="submit"
							color="success"
							variant="contained"
							endIcon={loading && <CircularProgress size={20} color="inherit" />}
						>
							{strings.web_menuLogIn}
						</StandartButton>
					</Box>
				</form>
			</DialogContent>
		</Dialog>
	);
};

import React, { useMemo } from "react";
import { Typography } from "@material-ui/core";

import { DeveloperRequestFragment } from "@espresso/protocol";
import { metricsConfig, TMetricName, getMetric } from "helpers/metrics";
import useAppContext from "contexts/AppContext";

interface IProps {
	request: Pick<DeveloperRequestFragment, "CampaignType" | "Metrics">;
	metric: TMetricName;
	attachmentId: string;
}

export const AttachmentMetric = ({ request, metric: metricName, attachmentId }: IProps) => {
	const { lang } = useAppContext();
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const { title, unit, getGrade } = useMemo(() => metricsConfig(), [lang])[metricName];
	const metricValue = getMetric(metricName, request, attachmentId);

	if (!metricValue) {
		return null;
	}

	const resultClass = getGrade(metricValue);
	return (
		<div className="attachment-metric">
			<Typography className="metric-title" variant="caption">
				{title}
			</Typography>
			<div className="metric-value-container">
				<Typography className={`metric-value ${resultClass}`} component="span">
					{metricValue}
				</Typography>
				{unit.length && (
					<Typography className="metric-unit" variant="body2" component="span">
						{unit}
					</Typography>
				)}
			</div>
		</div>
	);
};

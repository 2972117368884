import React, { useRef, useState } from "react";

import "./App.scss";

import { ApolloProvider, ApolloClient, NormalizedCacheObject } from "@apollo/client";
import createApolloClient from "./helpers/apollo";
import Splash from "./views/Splash";
import { UseAppContextProvider } from "./contexts/AppContext";
import Router from "./Router";
import { IConfig, loadConfig } from "@espresso/shared-config";
import { ThemeProvider } from "@material-ui/core";
import { theme } from "./helpers/appTheme";
import strings from "./helpers/strings";
import moment from "moment";
import "moment/locale/ru";
import "moment/locale/zh-cn";
import "moment/locale/tr";
import { useCookies } from "react-cookie";
import { SkeletonTheme } from "react-loading-skeleton";
import { BrowserRouter } from "react-router-dom";
import { MuiPickersProvider } from "contexts/MuiPickersContext";
import { useAnalytics } from "helpers/ga";
import ReactDOM from "react-dom";
import { StayOrLeaveModal } from "./components";
import * as History from "history";

const currentLocale = strings.getLanguage();
moment.locale(currentLocale);

const App = () => {
	const [cookies, setCookie, removeCookie] = useCookies();

	const [client, setClient] = useState<ApolloClient<NormalizedCacheObject>>();
	const authToken = useRef<string | null>(cookies.token);
	const errorCode = useRef<{ code: string; data?: { [key: string]: string } } | null>();
	const [, setErrorState] = useState<{ code: string; data?: { [key: string]: string } } | null>();
	const [sharedConfig] = useState<IConfig>(loadConfig(process.env.REACT_APP_CHANNEL || "local"));
	const { initialize: initGa } = useAnalytics();

	const getAuthToken = () => {
		return authToken.current;
	};

	const updateAuthToken = async (token: string | null) => {
		authToken.current = token;
		if (token) {
			setCookie("token", token, { path: "/", expires: moment().add(2, "weeks").toDate() });
		} else {
			await logout();
		}
	};

	const updateErrorCode = (code?: { code: string; data?: { [key: string]: string } } | null) => {
		errorCode.current = code;
		setErrorState(code);
	};

	const getErrorCode = () => {
		return errorCode.current;
	};

	const logout = async () => {
		removeCookie("token", { path: "/" });

		if (client != null) {
			await client.clearStore();
			await client.cache.reset();
		}
	};

	if (!client) {
		setClient(
			createApolloClient({
				sharedConfig,
				onErrorLogout: logout,
				getAuthToken: getAuthToken,
				setErrorCode: updateErrorCode,
			}),
		);

		return <Splash />;
	}

	const showConfirm = (message: string, callback: (ok: boolean) => void) => {
		const confirmSavePrompt = document.getElementById("confirm-save-prompt");
		if (!confirmSavePrompt) {
			return;
		}
		let data:
			| {
					currentLocation: History.Location;
					historyAction: History.Action;
					message?: string;
					locationNext: History.Location;
			  }
			| undefined;
		try {
			data = JSON.parse(message);
		} catch (err) {
			console.warn("Error parsing confirm dialog message data: ", message);
			return;
		}

		if (!data || data.currentLocation.key === data.locationNext.key) {
			return;
		}

		const cleanUp = (result: boolean) => {
			ReactDOM.unmountComponentAtNode(confirmSavePrompt);
			callback(result);

			// Fix for https://github.com/remix-run/react-router/issues/5405
			if (!result && data?.historyAction === "POP") {
				window.history.forward();
			}
		};

		ReactDOM.render(
			<StayOrLeaveModal
				visible={true}
				onClose={() => cleanUp(false)}
				onConfirm={() => {
					cleanUp(true);
				}}
				closeOnConfirm={false}
				text={data?.message}
			/>,
			confirmSavePrompt,
		);
	};

	initGa(sharedConfig.ga.trackerId);

	return (
		<ApolloProvider client={client}>
			<BrowserRouter getUserConfirmation={showConfirm}>
				<UseAppContextProvider
					token={authToken.current}
					updateAuthToken={(token) => updateAuthToken(token)}
					updateErrorCode={(code) => updateErrorCode(code)}
					getErrorCode={getErrorCode}
				>
					<ThemeProvider theme={theme}>
						<SkeletonTheme color="#f3f3f7" highlightColor="#ffffff">
							<MuiPickersProvider>
								<Router />
							</MuiPickersProvider>
						</SkeletonTheme>
					</ThemeProvider>
				</UseAppContextProvider>
			</BrowserRouter>
		</ApolloProvider>
	);
};

export default App;

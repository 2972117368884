import React from "react";
import { Typography } from "@material-ui/core";

import { ELang } from "@espresso/protocol";
import useAppContext from "contexts/AppContext";
import strings from "helpers/strings";

import { InfoBox } from "components";

const imgSources: { [key in ELang]: string[] } = {
	Ru: [
		"/images/help/checkFbSdk/Ru0.png",
		"/images/help/checkFbSdk/Ru1.png",
		"/images/help/checkFbSdk/Ru2.png",
		"/images/help/checkFbSdk/Ru3.png",
		"/images/help/checkFbSdk/Ru4.png",
		"/images/help/checkFbSdk/Ru5.png",
		"/images/help/checkFbSdk/Ru6.png",
		"/images/help/checkFbSdk/Ru7.png",
	],
	En: [
		"/images/help/checkFbSdk/En0.png",
		"/images/help/checkFbSdk/En1.png",
		"/images/help/checkFbSdk/En2.png",
		"/images/help/checkFbSdk/En3.png",
		"/images/help/checkFbSdk/En4.png",
		"/images/help/checkFbSdk/En5.png",
		"/images/help/checkFbSdk/En6.png",
		"/images/help/checkFbSdk/En7.png",
	],
	Ch: [
		"/images/help/checkFbSdk/Ch0.png",
		"/images/help/checkFbSdk/Ch1.png",
		"/images/help/checkFbSdk/Ch2.png",
		"/images/help/checkFbSdk/Ch3.png",
		"/images/help/checkFbSdk/Ch4.png",
		"/images/help/checkFbSdk/Ch5.png",
		"/images/help/checkFbSdk/Ch6.png",
		"/images/help/checkFbSdk/Ch7.png",
	],
	Tr: [
		"/images/help/checkFbSdk/En0.png",
		"/images/help/checkFbSdk/En1.png",
		"/images/help/checkFbSdk/En2.png",
		"/images/help/checkFbSdk/En3.png",
		"/images/help/checkFbSdk/En4.png",
		"/images/help/checkFbSdk/En5.png",
		"/images/help/checkFbSdk/En6.png",
		"/images/help/checkFbSdk/En7.png",
	],
};

export const CheckFbSdk = () => {
	const { lang } = useAppContext();

	return (
		<>
			<Typography variant="body1">{strings.help_faqCheckFbSdkDescription}</Typography>
			<ol className="MuiTypography-body1">
				<li>
					{strings.formatString(strings.help_faqCheckFbSdkGuideStep1, {
						link: (
							<a className="link" href="https://business.facebook.com" target="_blank" rel="noreferrer">
								<i className="icon icon-link" />
								<b>{strings.help_faqCheckFbSdkGuideStep1Link}</b>
							</a>
						),
					})}
				</li>
				<li>
					{strings.formatString(strings.help_faqCheckFbSdkGuideStep2, {
						eventsManager: (
							<b>
								<q>{strings.help_faqCheckFbSdkGuideStep2Bold}</q>
							</b>
						),
					})}
					<br />
					<br />
					<img src={imgSources[lang][0]} alt="" />
				</li>
				<li>
					{strings.formatString(strings.help_faqCheckFbSdkGuideStep3, {
						dataSources: (
							<b>
								<q>{strings.help_faqCheckFbSdkGuideStep3Bold1}</q>
							</b>
						),
						overview: (
							<b>
								<q>{strings.help_faqCheckFbSdkGuideStep3Bold2}</q>
							</b>
						),
					})}
					<br />
					<br />
					<img src={imgSources[lang][1]} alt="" />
					<p>{strings.help_faqCheckFbSdkGuideStep3CheckDates}</p>
					<br />
					<img src={imgSources[lang][2]} alt="" />
				</li>
				<li>
					{strings.formatString(strings.help_faqCheckFbSdkGuideStep4, {
						activateApp: (
							<b>
								<q>{strings.help_faqCheckFbSdkGuideStep4Bold}</q>
							</b>
						),
					})}
					<ul>
						<li>{strings.help_faqCheckFbSdkGuideStep4Step1}</li>
						<li>
							{strings.formatString(strings.help_faqCheckFbSdkGuideStep4Step2, {
								recentActivity: (
									<b>
										<q>{strings.help_faqCheckFbSdkGuideStep4Step2Bold}</q>
									</b>
								),
							})}
						</li>
					</ul>
					<img src={imgSources[lang][3]} alt="" />
				</li>
				<li>
					{strings.formatString(strings.help_faqCheckFbSdkGuideStep5, {
						allActivates: (
							<b>
								<q>{strings.help_faqCheckFbSdkGuideStep5Bold}</q>
							</b>
						),
					})}
					<br />
					<br />
					<img src={imgSources[lang][4]} alt="" />
					<p>{strings.help_faqCheckFbSdkGuideStep5HasData}</p>
					<br />
					<img src={imgSources[lang][5]} alt="" />
					<p>{strings.help_faqCheckFbSdkGuideStep5NoData}</p>
					<br />
					<img src={imgSources[lang][6]} alt="" />
					<InfoBox icon="attention" color="warning">
						<Typography variant="caption">{strings.help_faqCheckFbSdkGuideStep5Info}</Typography>
					</InfoBox>
				</li>
				<li>
					{strings.formatString(strings.help_faqCheckFbSdkGuideStep6, {
						allInstalls: (
							<b>
								<q>{strings.help_faqCheckFbSdkGuideStep6Bold}</q>
							</b>
						),
					})}
					<ul>
						<li>
							{strings.formatString(strings.help_faqCheckFbSdkGuideStep6Step1, {
								appInstalls: (
									<b>
										<q>{strings.help_faqCheckFbSdkGuideStep6Step1Bold}</q>
									</b>
								),
							})}
						</li>
						<li>
							{strings.formatString(strings.help_faqCheckFbSdkGuideStep6Step2, {
								recentActivity: (
									<b>
										<q>{strings.help_faqCheckFbSdkGuideStep4Step2Bold}</q>
									</b>
								),
							})}
						</li>
						<li>{strings.help_faqCheckFbSdkGuideStep6Step3}</li>
					</ul>
					<img src={imgSources[lang][7]} alt="" />
					<InfoBox icon="attention" color="warning">
						<Typography variant="caption">{strings.help_faqCheckFbSdkGuideStep6Info}</Typography>
					</InfoBox>
				</li>
			</ol>
		</>
	);
};

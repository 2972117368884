import { Button, ButtonProps } from "@material-ui/core";
import classNames from "classnames";
import React from "react";
import { TColor } from "..";

export interface IStandartButtonProps extends Omit<ButtonProps, "color"> {
	color?: TColor;
	round?: boolean;
	justIcon?: boolean;
}

export const StandartButton = React.forwardRef<HTMLButtonElement, IStandartButtonProps>(
	(props: IStandartButtonProps, ref) => {
		const { color, round, children, justIcon, className, ...rest } = props;

		const btnClasses = classNames(
			"standart-button",
			color,
			{
				round: round,
				justIcon: justIcon,
			},
			className,
		);

		return (
			<Button {...rest} ref={ref} className={btnClasses}>
				{children}
			</Button>
		);
	},
);

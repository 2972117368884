import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";

export const Ctr = () => {
	return (
		<>
			<Typography variant="body1">{strings.help_testingCtrP1}</Typography>
			<Typography variant="body1">{strings.help_testingYouWillNeed}</Typography>
			<ol className="MuiTypography-body1">
				<li>{strings.help_testingCtrLi1}</li>
				<li>
					{strings.help_testingCtrLi2}
					<ul>
						<li>{strings.help_testingCtrLi2Li1}</li>
						<li>{strings.help_testingCtrLi2Li2}</li>
					</ul>
				</li>
				<li>
					{strings.help_testingCtrLi3}
					<ul>
						<li>{strings.help_testingVideoRequirementsLi1}</li>
						<li>{strings.help_testingVideoRequirementsLi2}</li>
						<li>{strings.help_testingVideoRequirementsLi3}</li>
						<li>{strings.help_testingVideoRequirementsLi4}</li>
					</ul>
				</li>
			</ol>
			<Typography variant="body1">{strings.help_testingCtrP3}</Typography>
		</>
	);
};

import { THelpSection } from "views/HelpView/types";
import { PrivacyViewContent } from "views/PrivacyView";
import { TermsViewContent } from "views/TermsView";
import { OfferInfoGraphic } from "./OfferInfoGraphic";
import { OfferFull } from "./OfferFull";

export const docs: THelpSection = {
	titleKey: "help_docsTitle",
	path: "docs",
	topics: [
		{ titleKey: "help_docsTermsTitle", Content: TermsViewContent, path: "terms-of-use" },
		{ titleKey: "help_docsPrivacyTitle", Content: PrivacyViewContent, path: "privacy-policy" },
		{ titleKey: "help_docsOfferSummaryTitle", Content: OfferInfoGraphic, path: "publishing-offer-summary" },
		{ titleKey: "help_docsOfferFullTitle", Content: OfferFull, path: "publishing-offer-full" },
	],
};

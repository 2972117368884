import { Grid, Tooltip, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { QueueTasksCountResult } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { MonitoringText } from "./MonitoringText";
import { TLocale } from "@espresso/shared-config";

const thresholds: [number, number] = [3, 10];
const apiThreshold: [number, number] = [50, 80];

type TProps = {
	tasksCountData: QueueTasksCountResult | null | undefined;
};

export const MonitoringView = ({ tasksCountData }: TProps) => {
	const resultConfig = useMemo<{
		labelKey: keyof TLocale;
		tooltipKey: keyof TLocale;
		iconName: string;
		iconClass: string;
	}>(() => {
		if (
			(tasksCountData?.CampaignTasksCount || 0) >= thresholds[1] ||
			(tasksCountData?.MetricsTasksCount || 0) >= thresholds[1] ||
			(tasksCountData?.ApiUsage || 0) >= apiThreshold[1] ||
			(tasksCountData?.VideoTasksCount || 0) >= thresholds[1]
		) {
			return {
				labelKey: "publisher_tasksResultNegative",
				tooltipKey: "publisher_tasksResultNegativeTooltip",
				iconName: "analytics-statys",
				iconClass: "not-valid",
			};
		}
		if (
			(tasksCountData?.CampaignTasksCount || 0) > thresholds[0] ||
			(tasksCountData?.MetricsTasksCount || 0) > thresholds[0] ||
			(tasksCountData?.ApiUsage || 0) > apiThreshold[0] ||
			(tasksCountData?.VideoTasksCount || 0) > thresholds[0]
		) {
			return {
				labelKey: "publisher_tasksResultNeutral",
				tooltipKey: "publisher_tasksResultNeutralTooltip",
				iconName: "analytics-statys",
				iconClass: "checking",
			};
		}
		return {
			labelKey: "publisher_tasksResultPositive",
			tooltipKey: "publisher_tasksResultPositiveTooltip",
			iconName: "analytics-acesses",
			iconClass: "valid",
		};
	}, [tasksCountData]);

	return (
		<Grid className="monitoring-container" item md={6} sm={12}>
			<Typography variant="subtitle1">{strings.publisher_Monitoring}</Typography>
			<div className="status-container monitoring-text-container">
				<Typography variant="subtitle2">{strings[resultConfig.labelKey]}</Typography>
				<Tooltip title={strings[resultConfig.tooltipKey]} placement="top" arrow>
					<i className={`icon icon-${resultConfig.iconName} ${resultConfig.iconClass}`} />
				</Tooltip>
			</div>
			<MonitoringText
				label={strings.publisher_apiUsage}
				value={tasksCountData?.ApiUsage}
				thresholds={apiThreshold}
			/>
			<MonitoringText
				label={strings.publisher_tasksVideo}
				value={tasksCountData?.VideoTasksCount}
				thresholds={thresholds}
			/>
			<MonitoringText
				label={strings.publisher_tasksCampaigns}
				value={tasksCountData?.CampaignTasksCount}
				thresholds={thresholds}
			/>
			<MonitoringText
				label={strings.publisher_tasksMetrics}
				value={tasksCountData?.MetricsTasksCount}
				thresholds={thresholds}
			/>
		</Grid>
	);
};

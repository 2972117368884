import { Box, Typography } from "@material-ui/core";
import React from "react";
import { ELoginRole, ERequestStatus, RequestFullFragment, DeveloperRequestFragment } from "@espresso/protocol";
import { Maybe } from "graphql/jsutils/Maybe";
import strings from "../../helpers/strings";
import { StandartTextCopyButton } from "../../components";
import useAppContext from "../../contexts/AppContext";

export interface IRequestIdInfoProps {
	request?: Maybe<RequestFullFragment | DeveloperRequestFragment>;
}

export const RequestIdInfo = ({ request }: IRequestIdInfoProps) => {
	const { login } = useAppContext();
	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	if (!request) {
		return null;
	}

	const requestIdText = `#${request?.Id}`;
	const notFinal =
		request.Status !== ERequestStatus.Finished &&
		request.Status !== ERequestStatus.Rejected &&
		request.Status !== ERequestStatus.RunFailed;

	return (
		<Box className="requestid-content-root">
			<Typography variant="subtitle2" component="span">
				{requestIdText}&nbsp;
				{request.Deleted && <span className="request-deleted-text">({strings.requestStatus_Removed})</span>}
				{request.Status === ERequestStatus.Returned && !request.Deleted && (
					<span className="request-deleted-text">({strings.requestStatus_Returned})</span>
				)}
			</Typography>
			{isManagerOrPublisher && notFinal && (
				<StandartTextCopyButton color="secondary" valueToCopy={requestIdText} />
			)}
		</Box>
	);
};

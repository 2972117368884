import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
// @material-ui/icons
import { TThemeColor } from "../..";
import { TLocale } from "@espresso/shared-config";
import { AppBar, Button, Drawer, Hidden, IconButton, Toolbar, LinearProgress } from "@material-ui/core";
import Menu from "@material-ui/icons/Menu";
import Close from "@material-ui/icons/Close";
import strings from "../../../helpers/strings";
import { AppLink } from "..";
import HeaderLinks from "./HeaderLinks";

export interface IHeaderProps {
	changeColorOnScroll?: { height: number; color: TThemeColor };
	color?: TThemeColor;
	brand: keyof TLocale;
	fixed?: boolean;
	absolute?: boolean;
	loading?: boolean;
	hideMenu?: boolean;
}

export default function Header(props: IHeaderProps) {
	const [mobileOpen, setMobileOpen] = React.useState(false);

	React.useEffect(() => {
		if (props.changeColorOnScroll) {
			window.addEventListener("scroll", headerColorChange);
			headerColorChange();
		}
		return function cleanup() {
			if (props.changeColorOnScroll) {
				window.removeEventListener("scroll", headerColorChange);
			}
		};
	});

	const handleDrawerToggle = () => {
		setMobileOpen(!mobileOpen);
	};

	const headerColorChange = () => {
		const { color, changeColorOnScroll } = props;

		if (!changeColorOnScroll) {
			return;
		}
		const windowsScrollTop = window.pageYOffset;
		if (windowsScrollTop > changeColorOnScroll.height) {
			color && document.body.getElementsByTagName("header")[0].classList.remove(color);
			document.body.getElementsByTagName("header")[0].classList.add(changeColorOnScroll.color);
		} else {
			color && document.body.getElementsByTagName("header")[0].classList.add(color);
			document.body.getElementsByTagName("header")[0].classList.remove(changeColorOnScroll.color);
		}
	};

	const { color, brand, fixed, absolute } = props;

	const appBarClasses = classNames(
		"appBar",
		{
			absolute: absolute,
			fixed: fixed,
		},
		color,
	);

	return (
		<AppBar className={appBarClasses}>
			<Toolbar className="links-container" disableGutters>
				<AppLink to="/">
					<Button className="title" variant="text">
						<img src="/images/logo.svg" alt={strings[brand]} />
					</Button>
				</AppLink>
				{!props.hideMenu && (
					<>
						<Hidden mdDown implementation="css">
							<div className="collapse">{<HeaderLinks />}</div>
						</Hidden>
						<Hidden lgUp>
							<IconButton color="inherit" aria-label="open drawer" onClick={handleDrawerToggle}>
								<Menu />
							</IconButton>
						</Hidden>
					</>
				)}
			</Toolbar>
			{!props.hideMenu && (
				<Hidden lgUp implementation="js">
					<Drawer
						variant="temporary"
						anchor={"right"}
						open={mobileOpen}
						classes={{
							paper: "drawerPaper",
						}}
						onClose={handleDrawerToggle}
					>
						<IconButton aria-label="open drawer" onClick={handleDrawerToggle} className="closeButtonDrawer">
							<Close />
						</IconButton>
						<div className="appResponsive">{<HeaderLinks isDrawer />}</div>
					</Drawer>
				</Hidden>
			)}
			{props.loading && <LinearProgress className="loading-progress" />}
		</AppBar>
	);
}

import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";
import { FacebookIdModalContent } from "views/RequestView/CreateRequest/CreateRequestFormInfoFacebook/SdkWizard/ModalContent";

export const FbAppId = () => {
	return (
		<>
			<Typography variant="body1">{strings.request_createRequestInfoFacebookAccordionAppIdSummary}</Typography>
			<FacebookIdModalContent />
		</>
	);
};

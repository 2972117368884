import { Paper, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { DeveloperRequestFragment, ECampaignType, ERequestStatus } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { MetricsTableRow } from "./MetricsTableRow";
import { getMetric, metricsConfig, TMetricName } from "../../helpers/metrics";
import useAppContext from "../../contexts/AppContext";

export interface IMetricsTableProps {
	request?: DeveloperRequestFragment;
}

const metricTableRows: {
	[key in ECampaignType]: {
		main: Array<TMetricName>;
		details: Array<TMetricName>;
		additional: Array<TMetricName>;
	};
} = {
	R1: {
		main: ["cpi", "r1", "mt"],
		details: ["cpm", "ctr", "ir", "ipm", "msl"],
		additional: ["r7", "cost", "impression", "click", "install"],
	},
	Cpi: {
		main: ["cpi"],
		details: ["cpm", "ctr", "ir", "ipm"],
		additional: ["cost", "impression", "click", "install"],
	},
	Ctr: {
		main: ["ctr"],
		details: ["cpm"],
		additional: ["cost", "impression", "click"],
	},
};

export const MetricsTable = (props: IMetricsTableProps) => {
	const { request } = props;
	const { lang } = useAppContext();

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const metrics = useMemo(() => metricsConfig(), [lang]);

	const metricsValue = useMemo<{ [key in TMetricName]?: number | null | undefined } | null>(() => {
		if (!request?.Metrics.length) {
			return null;
		}

		return {
			cost: getMetric("cost", request),
			impression: getMetric("impression", request),
			click: getMetric("click", request),
			install: getMetric("install", request),
			cpi: getMetric("cpi", request),
			r1: getMetric("r1", request),
			r7: getMetric("r7", request),
			mt: getMetric("mt", request),
			cpm: getMetric("cpm", request),
			ctr: getMetric("ctr", request),
			ir: getMetric("ir", request),
			ipm: getMetric("ipm", request),
			msl: getMetric("msl", request),
		};
	}, [request]);

	if (!request?.Metrics.length) {
		return null;
	}

	return (
		<TableContainer className="result-table" component={Paper}>
			<Table>
				<TableHead>
					<TableRow>
						<TableCell className="cell-fill" component="th" />
						<TableCell className="cell-title" component="th">
							{strings.request_metricName}
						</TableCell>
						<TableCell className="cell-text" component="th">
							{strings.request_metricValue}
						</TableCell>
						<TableCell className="cell-info" component="th">
							{strings.request_metricTarget}
						</TableCell>
						<TableCell className="cell-icon" component="th" />
					</TableRow>
				</TableHead>
				<TableBody>
					{metricTableRows[request.CampaignType].main.map((metricName) => (
						<MetricsTableRow
							key={metricName}
							metricName={metricName}
							request={request}
							mainRow
							value={metricsValue?.[metricName]}
							{...metrics[metricName]}
						/>
					))}
					<TableRow className="moreInfo-row">
						{!!metricTableRows[request.CampaignType].details.length && (
							<TableCell colSpan={5}>
								<Typography className="moreInfo-label" variant="subtitle2">
									{strings.details}
								</Typography>
							</TableCell>
						)}
					</TableRow>
					{metricTableRows[request.CampaignType].details.map((metricName) => (
						<MetricsTableRow
							detailsRow
							key={metricName}
							request={request}
							value={metricsValue?.[metricName]}
							{...metrics[metricName]}
						/>
					))}
					<TableRow className="moreInfo-row">
						{!!metricTableRows[request.CampaignType].additional.length && (
							<TableCell colSpan={5}>
								<Typography className="moreInfo-label" variant="subtitle2">
									{strings.additionally}
								</Typography>
							</TableCell>
						)}
					</TableRow>
					{metricTableRows[request.CampaignType].additional.map((metricName) => (
						<MetricsTableRow
							additionalRow
							key={metricName}
							request={request}
							value={metricsValue?.[metricName]}
							{...metrics[metricName]}
						/>
					))}
					{request.Status !== ERequestStatus.Finished &&
						request.Status !== ERequestStatus.Rejected &&
						request.Status !== ERequestStatus.RunFailed && (
							<TableRow className="moreInfo-row">
								<TableCell colSpan={5}>
									<Typography className="statusInfo-label" variant="body2">
										<i className="icon icon-info" />
										{strings.request_refreshMetricsInfo}
									</Typography>
								</TableCell>
							</TableRow>
						)}
				</TableBody>
			</Table>
		</TableContainer>
	);
};

import React, { useEffect, useRef, useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import strings from "../../../helpers/strings";
import {
	AttachmentFullFragment,
	EAttachmentType,
	EFileType,
	EGenre,
	ELoginRole,
	ERequestStatus,
	useClearDraftAttachmentsMutation,
} from "@espresso/protocol";
import { TCreateRequestFormProps } from "./types";
import { useStandartForm } from "../../../helpers/useStandartForm";
import { Gallery, StandartTextInputController, GoBackButton, GenreList, StayOrLeaveModal } from "../../../components";
import { Controller, FieldError, FormProvider } from "react-hook-form";
import { OtherRequestsList } from "../OtherRequestsList";
import _ from "lodash";
import useAppContext from "../../../contexts/AppContext";
import { CreateRequestButtons } from "./CreateRequestButtons";
import { INPUT_MAX_LENGTH_APP_NAME } from "../../../helpers/constants";
import { RequestIdInfo } from "../RequestIdInfo";
import { validateAttachments } from "../../../helpers/request";
import { useAnalytics } from "helpers/ga";
import { RequestInfo } from "../RequestInfo";
import { Prompt, useHistory } from "react-router-dom";

type TFormData = {
	AppName: string;
	attachments: AttachmentFullFragment[];
	InternalComment: string;
	Genre: EGenre | null;
};

export const CreateRequestFormPrivate = (props: TCreateRequestFormProps) => {
	const { request, submit, setCurrentStepStatus, handleClickRemove, handleRestoreRequest } = props;
	const attachmentsProcessingRef = useRef<string[]>();
	const [loadingAttachment, setLoadingAttachment] = useState(false);
	const [isConfirmOpen, setIsConfirmOpen] = useState(false);

	const { login, sharedConfig, showExitPrompt, setShowExitPrompt } = useAppContext();
	const history = useHistory();
	const { analyticsEvents } = useAnalytics();

	const [clearAttachments] = useClearDraftAttachmentsMutation({ variables: { type: EAttachmentType.Request } });
	useEffect(() => {
		clearAttachments();
	}, [clearAttachments]);

	useEffect(() => {
		setShowExitPrompt(loadingAttachment);
	}, [loadingAttachment, setShowExitPrompt]);

	const form = useStandartForm<TFormData>({
		mode: "onChange",
		reValidateMode: "onChange",
		defaultValues: {
			AppName: request?.AppName || "",
			attachments: [],
			InternalComment: request?.InternalComment || "",
			Genre: request?.Genre || null,
		},
	});
	const { handleSubmit, errors, control } = form;

	useEffect(() => {
		if (!request) {
			return;
		}
		if (request.AppName !== form.getValues("AppName")) {
			form.setValue("AppName", request.AppName || "");
		}
		if (request.InternalComment && request.InternalComment !== form.getValues("InternalComment")) {
			form.setValue("InternalComment", request.InternalComment || "");
		}
		if (request.Genre && request.Genre !== form.getValues("Genre")) {
			form.setValue("Genre", request.Genre);
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	const attachmentsError = ((errors.attachments as unknown) as FieldError | undefined)?.message;
	const formState = control.formStateRef.current;

	useEffect(() => {
		const attachmentsValue = form.getValues("attachments");

		if (!attachmentsError || attachmentsValue?.length || formState.isSubmitted) {
			return;
		}
		form.clearErrors("attachments");
	}, [attachmentsError, formState.isSubmitted, form]);

	const back = () => setCurrentStepStatus(ERequestStatus.Initial);
	const handleBackClick = () => {
		if (showExitPrompt) {
			return setIsConfirmOpen(true);
		}
		back();
	};

	const next = (data: TFormData) => {
		submit({
			...data,
			Status: request?.Status === ERequestStatus.Private ? ERequestStatus.PreValidation : request?.Status,
			AttachmentId: data.attachments.map((a) => a.Id),
		});
		setCurrentStepStatus(ERequestStatus.PreValidation);
		analyticsEvents.advanceRequestCreation(request, ERequestStatus.Private, ERequestStatus.PreValidation);
	};

	const save = (e: React.FormEvent<HTMLFormElement>) => {
		e.preventDefault();

		const data = form.getValues();

		submit({
			...data,
			AttachmentId: data.attachments.map((a) => a.Id),
		});
	};

	const onUpdateAttachments = (newAttachments: AttachmentFullFragment[]) =>
		form.setValue("attachments", newAttachments, { shouldValidate: true });

	const onUpdateCropIds = (ids: string[] | undefined) => {
		attachmentsProcessingRef.current = ids;
		if (control.isFormDirty("attachments") || !_.isEmpty(ids)) {
			form.trigger("attachments");
		}
	};

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
	const disabledNext = !!errors.attachments || loadingAttachment;

	return (
		<FormProvider {...form}>
			<form noValidate className="content" onSubmit={isManagerOrPublisher ? save : handleSubmit(next)}>
				<Grid container>
					<Grid item xs={12} className="requestid-grid-root">
						<RequestIdInfo request={request} />
					</Grid>
					<Grid item xs={12}>
						<Box className="title-container">
							<GoBackButton to="/profile" />
							<Typography variant="h5">{strings.request_createRequestPrivateTitle}</Typography>
						</Box>
						{request?.Status === ERequestStatus.Returned && !request.Deleted && (
							<RequestInfo request={request} />
						)}
					</Grid>
					<Grid item xs={12} className="app-name-link-container">
						<Box>
							<Controller
								name="Genre"
								control={control}
								render={({ onChange, value }) => (
									<GenreList
										fullWidth
										labelKey="genre_label"
										selectedKeys={[value]}
										onSelectedChange={(v) => onChange(v[0])}
									/>
								)}
							/>
							<StandartTextInputController
								required
								disabled={!!request?.Deleted}
								name="AppName"
								control={control}
								rules={{
									required: strings.required,
								}}
								inputProps={{
									maxLength: INPUT_MAX_LENGTH_APP_NAME,
								}}
								errorMessage={errors.AppName?.message}
								label={strings.request_appName}
								placeholder={strings.request_appNamePlaceholder}
								margin="normal"
							/>
						</Box>
						<Gallery
							simple
							verticalItems
							disabled={!!request?.Deleted}
							attachmentType={EAttachmentType.Request}
							fileType={EFileType.Icon}
							maxItems={1}
							acceptedFiles={["image/*"]}
							addFileTip="request_addIcon"
							maxFileSize={5242880}
							objectId={request?.Id}
							onLoadingAttachmentsChange={setLoadingAttachment}
						/>
					</Grid>
					<Grid item xs={12}>
						<Controller
							name="attachments"
							control={control}
							rules={{
								required: strings.required,
								validate: (attachments) =>
									validateAttachments(attachments, attachmentsProcessingRef.current),
							}}
							render={() => (
								<Box>
									<Gallery
										required
										verticalItems
										hasDelete
										disabled={!!request?.Deleted}
										attachmentType={EAttachmentType.Request}
										fileType={EFileType.Video}
										maxItems={sharedConfig.request.maxVideos}
										acceptedFiles={["video/mp4", "video/x-matroska", "video/quicktime"]}
										onUpdateAttachments={onUpdateAttachments}
										onUpdateCropIds={onUpdateCropIds}
										onLoadingAttachmentsChange={setLoadingAttachment}
										objectId={request?.Id}
										additionalRequirementsText="request_videoRequirements"
										addFileTip="request_addVideo"
										label="request_videoLabel"
										errorMessage={attachmentsError}
										request={request}
									/>
								</Box>
							)}
						/>
						{isManagerOrPublisher && (
							<Grid item xs={12} className="MuiFormControl-marginNormal">
								<Typography variant="h5">{strings.request_internalComment}</Typography>
								<Box className="request-info">
									<StandartTextInputController
										control={control}
										name="InternalComment"
										label={strings.request_internalCommentLabel}
									/>
								</Box>
							</Grid>
						)}
						<Box marginTop={5}>
							<Typography className="required-fields-text">
								<span className="asterisk-error">*</span>
								<span className="required-info">{strings.standartFormat(strings.requiredFields)}</span>
							</Typography>
						</Box>
					</Grid>
					<OtherRequestsList request={request} />
					<CreateRequestButtons
						request={request}
						disabledSave={loadingAttachment}
						disabledNext={disabledNext}
						currentStepIndex={props.currentStepIndex}
						handleBackToStep={handleBackClick}
						handleClickRemove={handleClickRemove}
						handleRestoreRequest={handleRestoreRequest}
						onNext={isManagerOrPublisher ? () => handleSubmit(next)() : undefined}
					/>
				</Grid>
				<Prompt
					when={showExitPrompt}
					message={(locationNext, historyAction) => {
						return JSON.stringify({ historyAction, locationNext, currentLocation: history.location });
					}}
				/>
				<StayOrLeaveModal
					visible={isConfirmOpen}
					onClose={() => {
						setIsConfirmOpen(false);
					}}
					onConfirm={() => {
						setIsConfirmOpen(false);
						back();
					}}
				/>
			</form>
		</FormProvider>
	);
};

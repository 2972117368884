import React from "react";
import { Typography } from "@material-ui/core";
import useAppContext from "contexts/AppContext";
import strings from "helpers/strings";
import { InfoDialog } from "components";
import { OfferInfoGraphic } from "views/HelpView/sections/docs/OfferInfoGraphic";

interface IProps {
	open: boolean;
}

export const AgreementDialog = (props: IProps) => {
	const { open } = props;
	const { setIsAgreementDialogOpen } = useAppContext();
	return (
		<InfoDialog isOpen={open} onClose={() => setIsAgreementDialogOpen(false)}>
			<Typography variant="h5">{strings.help_docsOfferSummaryTitle}</Typography>
			<OfferInfoGraphic />
		</InfoDialog>
	);
};

import React from "react";

import classNames from "classnames";

import Icon from "@material-ui/core/Icon";

import { TColor } from "..";
import { Typography, TypographyVariant } from "@material-ui/core";

export interface IInfoAreaProps {
	className?: string;
	iconWrapStyle?: string;
	title?: string;
	titleVariant?: TypographyVariant;
	description?: string;
	descriptionVariant?: TypographyVariant;
	iconColor?: TColor;
	vertical?: boolean;
	icon?: React.ReactNode | string;
	children?: React.ReactNode;
}
export function InfoArea(props: IInfoAreaProps) {
	const {
		title,
		description,
		vertical,
		className,
		iconWrapStyle,
		children,
		iconColor = "transparent",
		titleVariant = "subtitle1",
		descriptionVariant = "body1",
	} = props;
	const iconWrapper = classNames("iconWrapper", { vertical: vertical }, `text-${iconColor}`, iconWrapStyle);
	const iconClasses = classNames({ vertical: vertical });
	const infoAreaClasses = classNames("infoArea", { vertical: vertical }, className);
	let icon = null;

	switch (typeof props.icon) {
		case "string":
			icon = (
				<Icon className={iconClasses}>
					<i className={`icon icon-${props.icon}`} />
				</Icon>
			);
			break;
		default:
			icon = <Icon className={iconClasses}>{props.icon}</Icon>;
			break;
	}

	return (
		<div className={infoAreaClasses}>
			<div className={iconWrapper}>{icon}</div>
			<div className="descriptionWrapper">
				{title && (
					<Typography variant={titleVariant} className="title">
						{title}
					</Typography>
				)}
				{description && (
					<div className="description">
						<Typography variant={descriptionVariant}>{description}</Typography>
					</div>
				)}
			</div>
			{children}
		</div>
	);
}

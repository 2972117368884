import { Typography } from "@material-ui/core";
import React from "react";
import strings from "../../helpers/strings";
import classNames from "classnames";

type TProps = {
	label: string;
	value?: number;
	thresholds?: [number, number];
};

export const MonitoringText = React.memo(({ label, value, thresholds = [3, 10] }: TProps) => {
	const textValueClassName = classNames({
		positive: value != null && value <= thresholds[0],
		neutral: value != null && value > thresholds[0] && value < thresholds[1],
		negative: value == null || value >= thresholds[1],
	});

	return (
		<div className="monitoring-text-container">
			<Typography variant="subtitle2">{label}</Typography>
			<Typography className={textValueClassName} variant="subtitle1">
				{value ?? strings.unknown}
			</Typography>
		</div>
	);
});

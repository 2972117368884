import { IconButton, IconButtonProps, Tooltip } from "@material-ui/core";
import React, { useState } from "react";
import strings from "../../helpers/strings";
import copy from "copy-to-clipboard";

export type TStandartTextCopyButtonProps = Pick<IconButtonProps, "color" | "size"> & {
	valueToCopy: string;
};

export const StandartTextCopyButton = (props: TStandartTextCopyButtonProps) => {
	const { valueToCopy, ...iconProps } = props;
	const [clipboardCopied, setClipboardCopied] = useState(false);

	return (
		<Tooltip open={clipboardCopied} title={strings.copiedToClipboard} placement="top">
			<IconButton
				{...iconProps}
				onClick={() => {
					copy(valueToCopy);
					setTimeout(() => setClipboardCopied(true), 100);
					setTimeout(() => setClipboardCopied(false), 1000);
				}}
			>
				<i className="icon icon-copy" />
			</IconButton>
		</Tooltip>
	);
};

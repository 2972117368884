import { Observable, ServerError } from "@apollo/client";
import { ErrorHandler } from "@apollo/client/link/error";

export interface IOnErrorHanderCreatorParams {
	logout: () => Promise<void>;
	setErrorCode: (value: { code: string; data?: { [key: string]: string } } | null) => void;
}

const onErrorHandler = function onErrorHandlerCreator({
	logout,
	setErrorCode,
}: IOnErrorHanderCreatorParams): ErrorHandler {
	return function errorHandler({ operation, networkError, graphQLErrors, forward }) {
		let loggedOut = false;
		let fileLimit = false;
		if (networkError && (networkError as ServerError).statusCode === 401) {
			loggedOut = true;
		}

		if (networkError && (networkError as ServerError).statusCode === 413) {
			fileLimit = true;
		}
		if (!loggedOut && !fileLimit && graphQLErrors != null) {
			for (const err of graphQLErrors) {
				if (err.extensions == null) {
					continue;
				}
				// handle errors differently based on its error code
				switch (err.extensions.code) {
					case "UNAUTHENTICATED":
						loggedOut = true;
						// Now, pass the modified operation to the next link
						// in the chain. This effectively intercepts the old
						// failed request, and retries it with a new token
						// return forward(operation);
						break;
					case "INTERNAL_SERVER_ERROR":
						if (err.message.includes("File truncated as it exceeds the")) {
							fileLimit = true;
						}
					// handle other errors
					// case "ANOTHER_ERROR_CODE":
					// ...
				}
			}
		}

		if (loggedOut) {
			return new Observable(() => {
				logout()
					.then(() => {
						forward(operation).map(() => {
							return { errors: "Logged out" };
						});
					})
					.catch(() => {
						forward(operation).map(() => {
							return { errors: "Logged out" };
						});
					});
			});
		}

		if (fileLimit) {
			setErrorCode({ code: "network_413" });
			return new Observable(() => {
				forward(operation).map(() => ({ errors: "File too large" }));
			});
		}

		return forward(operation);
	};
};
export default onErrorHandler;

import { Box, Typography } from "@material-ui/core";
import React from "react";

type TProps = {
	type?: "email" | "whatsapp" | "telegram" | "skype";
	label?: string;
	value?: string | (string | React.ReactNode)[] | JSX.Element | null | undefined;
	urlString?: string;
	margin?: "normal" | "none";
};

export function StandartTextField(props: TProps): JSX.Element {
	const { type, label, value, margin = "normal" } = props;

	let urlString = props.urlString || "";

	switch (type) {
		case "email":
			urlString = `mailto:${value}`;
			break;
		case "telegram":
			if (value && typeof value === "string") {
				urlString = `https://t.me/${value.replace(/@/g, "")}`;
			}
			break;
		case "whatsapp":
			if (value && typeof value === "string") {
				urlString = `https://wa.me/${value.replace(/[^\d;]/g, "")}`;
			}
			break;

		case "skype":
			if (value && typeof value === "string") {
				urlString = `skype:${value.replace(/live:/g, "")}?chat`;
			}
			break;
		default:
			break;
	}

	return (
		<Box
			className={margin === "normal" ? "MuiFormControl-marginNormal" : ""}
			onClick={(event) => {
				event.stopPropagation();
			}}
		>
			{!!label && <Typography className="MuiInputLabel-shrink">{label}</Typography>}
			{!!urlString ? (
				<a className="url-text" href={urlString} target="_blank" rel="noreferrer">
					<Typography>{value || "-"}</Typography>
					<i className="icon icon-link" />
				</a>
			) : (
				<Typography>{value || "-"}</Typography>
			)}
		</Box>
	);
}

import { Typography } from "@material-ui/core";
import React from "react";
import { StandartButton, IStandartButtonProps } from "./StandartButton";

export interface IPlaceholderViewProps {
	heading?: string;
	imageSrc?: string;
	title?: string | (string | JSX.Element)[];
	text?: string;
	buttonProps?: IStandartButtonProps;
	to?: string;
}

export const PlaceholderView = (props: IPlaceholderViewProps) => {
	return (
		<div className="placeholder-view">
			{props.heading && (
				<Typography variant="h5" className="heading">
					{props.heading}
				</Typography>
			)}
			{!!props.imageSrc && <img src={props.imageSrc} alt="..." />}
			{props.title && <Typography variant="h6">{props.title}</Typography>}
			{props.text && <Typography variant="subtitle2">{props.text}</Typography>}
			{props.buttonProps && !props.to && <StandartButton {...props.buttonProps} />}
			{props.to && props.buttonProps && (
				<a href={props.to} target="_blank" rel="noreferrer">
					<StandartButton {...props.buttonProps} />
				</a>
			)}
		</div>
	);
};

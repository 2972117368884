import React, { useMemo } from "react";
import {
	EFileType,
	AttachmentFullFragment,
	useRemoveAttachmentMutation,
	ETaskStatus,
	RequestMetricsFullFragment,
	useCreateFbAdMutation,
	GetOneRequestDocument,
	DeveloperRequestFragment,
} from "@espresso/protocol";
import strings from "../../helpers/strings";
import {
	Avatar,
	Box,
	CircularProgress,
	Icon,
	IconButton,
	ListItem,
	ListItemAvatar,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { AttachmentStatus } from "./AttachmentStatus";
import { checkVideoDimensionsValid, checkVideoLengthValid, TLocale } from "@espresso/shared-config";
import { CircularProgressWithLabel, IStandartButtonProps, StandartButton } from "../common";
import { AttachmentMetric } from "./AttachmentMetric";
import { isRequestRunningOrFinished } from "helpers/request";
import { getFbVideoStatus } from "helpers/metrics";

interface IGalleryAttachmentItemProps {
	attachment?: AttachmentFullFragment;
	attachmentMetrics?: RequestMetricsFullFragment;
	disabled?: boolean;
	readonly?: boolean;
	hasDelete?: boolean;
	hasCreateAd?: boolean;
	afterDelete?: (id: string) => void;
	openFull?: (attachment: AttachmentFullFragment) => void;
	openCrop?: (attachment: AttachmentFullFragment | undefined) => void;
	simple?: boolean;
	task?: { Id: string; Status?: ETaskStatus | null };
	progress?: number;
	fileName?: string;
	onAbortClick?: () => void;
	request?: Pick<DeveloperRequestFragment, "Status" | "CampaignType" | "Metrics"> | null;
}

export const GalleryAttachmentItem = (props: IGalleryAttachmentItemProps) => {
	const [removeAttachment, removeAttachmentState] = useRemoveAttachmentMutation();
	const [createFBAd, createFBAdState] = useCreateFbAdMutation();

	const isVideoDimensionsValid = useMemo(
		() => checkVideoDimensionsValid(props.attachment?.Metadata?.Height, props.attachment?.Metadata?.Width),
		[props.attachment],
	);
	const isVideoLengthValid = useMemo(() => checkVideoLengthValid(props.attachment?.Metadata?.Length), [
		props.attachment,
	]);

	const attachmentStatus = useMemo(() => {
		if (props.task) {
			return props.task.Status;
		}
		if (!isVideoDimensionsValid && !isVideoLengthValid) {
			return "DimensionsLengthInvalid";
		}
		if (!isVideoDimensionsValid) {
			return "DimensionsInvalid";
		}
		if (!isVideoLengthValid) {
			return "LengthInvalid";
		}
		if (props.attachmentMetrics) {
			const fbVideoStatus = getFbVideoStatus(props.attachmentMetrics);
			if (fbVideoStatus) {
				return fbVideoStatus;
			}
		}
		if (
			props.attachment &&
			!props.attachment.PreviewUrl &&
			(props.attachment.FileType === EFileType.Video || props.attachment.FileType === EFileType.Image)
		) {
			return ETaskStatus.Failed;
		}
		return ETaskStatus.Done;
	}, [isVideoDimensionsValid, isVideoLengthValid, props.attachment, props.attachmentMetrics, props.task]);

	const disabledActions =
		props.disabled ||
		!attachmentStatus ||
		attachmentStatus === ETaskStatus.Processing ||
		attachmentStatus === "FacebookProcessing";

	const removeFile = async () => {
		if (props.attachment) {
			await removeAttachment({ variables: { id: props.attachment.Id } });
			props.afterDelete && props.afterDelete(props.attachment.Id);
		}
	};

	const createAd = async () => {
		if (props.attachment?.ObjectId) {
			await createFBAd({
				variables: { id: props.attachment.ObjectId, attachId: props.attachment.Id },
				refetchQueries: [{ query: GetOneRequestDocument, variables: { id: props.attachment.ObjectId } }],
			});
		}
	};

	const actionsConfig = useMemo(() => {
		const configs: Array<{
			buttonConfig: Pick<IStandartButtonProps, "color" | "onClick" | "href" | "disabled" | "className">;
			icon: string;
			tooltipKey: keyof TLocale;
		}> = [];
		if (props.simple) {
			return [];
		}

		if (!props.readonly && props.hasDelete) {
			configs.push({
				buttonConfig: {
					color: "danger",
					onClick: removeFile,
					className: attachmentStatus === ETaskStatus.Failed ? "pulse" : undefined,
				},
				icon: "delit",
				tooltipKey: "delete",
			});
		}
		if (props.attachment?.Url.length) {
			configs.push({
				buttonConfig: { color: "secondary", href: props.attachment.Url },
				icon: "download",
				tooltipKey: "download",
			});
		}
		if (!props.readonly) {
			if (props.openCrop) {
				configs.push({
					buttonConfig: {
						color: "secondary",
						onClick: () => props.openCrop?.(props.attachment),
						disabled:
							!props.attachment?.PreviewUrl ||
							attachmentStatus === "FacebookAccepted" ||
							attachmentStatus === "FacebookReviewing",
						className:
							attachmentStatus === "DimensionsLengthInvalid" ||
							attachmentStatus === "DimensionsInvalid" ||
							attachmentStatus === "LengthInvalid"
								? "pulse"
								: undefined,
					},
					icon: "edit-video",
					tooltipKey: "request_cropVideoTitle",
				});
			}
			if (props.hasCreateAd) {
				configs.push({
					buttonConfig: {
						color: "secondary",
						onClick: createAd,
						disabled:
							!props.attachment?.PreviewUrl ||
							(attachmentStatus !== ETaskStatus.Done && attachmentStatus !== "FacebookRejected"),
						className:
							attachmentStatus === ETaskStatus.Done || attachmentStatus === "FacebookRejected"
								? "pulse"
								: undefined,
					},
					icon: "create-ad-strok",
					tooltipKey: "request_createFacebookAdTooltip",
				});
			}
		}
		return configs;
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [props.simple, props.attachment, props.hasDelete, props.hasCreateAd, props.attachmentMetrics, attachmentStatus]);

	if (!props.attachment?.Url.length || removeAttachmentState.loading || createFBAdState.loading) {
		const getProgressIndicatorText = () => {
			if (
				(props.progress !== undefined && props.progress >= 100) ||
				(props.attachment && !props.attachment.Url.length)
			) {
				return strings.processing;
			}
			if (removeAttachmentState.loading) {
				return strings.deleting;
			}
			return strings.loading;
		};

		if (props.simple) {
			return (
				<ListItem className="placeholder simple" onClick={(event) => event.stopPropagation()}>
					<ListItemAvatar>
						<>
							{props.progress !== undefined && props.progress < 100 ? (
								<CircularProgressWithLabel size={40} value={props.progress} color="secondary" />
							) : (
								<CircularProgress size={40} color="secondary" />
							)}
						</>
					</ListItemAvatar>
				</ListItem>
			);
		}
		return (
			<ListItem className="placeholder" onClick={(event) => event.stopPropagation()}>
				{(props.attachment?.FileName || props.fileName) && (
					<Box className="filename-root">
						<Typography className="text-ellipsis" component="span" variant="body2">
							{props.attachment?.FileName || props.fileName}
						</Typography>
					</Box>
				)}
				<Box className="fileinfo-root">
					<ListItemAvatar>
						<>
							{props.progress !== undefined && props.progress < 100 ? (
								<CircularProgressWithLabel size={40} value={props.progress} color="secondary" />
							) : (
								<CircularProgress size={40} color="secondary" />
							)}
						</>
					</ListItemAvatar>
					<Box className="description">
						<Box className="metadata-root">
							<Typography>{getProgressIndicatorText()}</Typography>
						</Box>
						{!removeAttachmentState.loading && !createFBAdState.loading && (
							<Box className="control-buttons">
								<StandartButton
									variant="outlined"
									color="secondary"
									onClick={props.onAbortClick || removeFile}
								>
									{strings.cancel}
								</StandartButton>
							</Box>
						)}
					</Box>
				</Box>
			</ListItem>
		);
	}

	if (props.simple) {
		return (
			<ListItem className="simpleGallery" onClick={(event) => event.stopPropagation()}>
				<Box className="fileinfo-root">
					<ListItemAvatar>
						{props.attachment.PreviewUrl ? (
							<Box>
								<Avatar alt={props.attachment.FileName} src={props.attachment.PreviewUrl} />
								{!props.readonly && (
									<IconButton
										disabled={props.disabled}
										edge="end"
										aria-label="delete"
										onClick={removeFile}
									>
										<i className="icon icon-delit" />
									</IconButton>
								)}
							</Box>
						) : (
							<Icon>
								<i className="icon icon-file_apk" />
							</Icon>
						)}
					</ListItemAvatar>
				</Box>
			</ListItem>
		);
	}

	return (
		<ListItem onClick={(event) => event.stopPropagation()}>
			<Box className="filename-root">
				<Typography className="text-ellipsis" component="span" variant="body2">
					{props.attachment.FileName}
				</Typography>
				<div className="status-container">
					<AttachmentStatus status={attachmentStatus} />
				</div>
			</Box>
			<Box className="fileinfo-root">
				<ListItemAvatar>
					{props.attachment.PreviewUrl ? (
						<Box>
							<Avatar alt={props.attachment.FileName} src={props.attachment.PreviewUrl} />
							<IconButton
								onClick={() =>
									props.attachment?.FileType === EFileType.Video && props.openFull?.(props.attachment)
								}
								disabled={
									props.disabled ||
									!props.attachment?.Url ||
									props.attachment?.FileType !== EFileType.Video ||
									!props.openFull
								}
							>
								<i className="icon icon-play" />
							</IconButton>
						</Box>
					) : (
						<Icon>
							<i
								className={`icon icon-file_apk ${
									props.attachment.FileType === EFileType.Video ||
									props.attachment.FileType === EFileType.Image
										? "danger"
										: ""
								}`}
							/>
						</Icon>
					)}
				</ListItemAvatar>
				<Box className="description">
					{(props.attachment.Metadata?.Width ||
						props.attachment.Metadata?.Height ||
						props.attachment.Metadata?.Length) && (
						<Box className="metadata-root">
							{props.attachment.Metadata?.Width && props.attachment.Metadata?.Height && (
								<Typography
									component="span"
									variant="body2"
									color={isVideoDimensionsValid ? undefined : "error"}
								>
									<i className={"icon icon-video_size"} />
									{props.attachment.Metadata?.Width}x{props.attachment.Metadata?.Height}
								</Typography>
							)}
							{props.attachment.Metadata?.Length && (
								// HACK: Бек возвращает видео слегка неверной длины, приходится округлять
								<Typography
									component="span"
									variant="body2"
									color={isVideoLengthValid ? undefined : "error"}
								>
									<i className="icon icon-chronometer-1" />
									{Math.round(props.attachment.Metadata?.Length)} {strings.sec}
								</Typography>
							)}
						</Box>
					)}
					<Box className="bottom-container">
						{actionsConfig.length && (
							<Box className="control-buttons">
								{actionsConfig.map((config, idx) =>
									disabledActions || config.buttonConfig.disabled ? (
										<StandartButton
											key={idx}
											variant="contained"
											justIcon
											{...config.buttonConfig}
											disabled
											className={undefined}
										>
											<i className={`icon icon-${config.icon}`} />
										</StandartButton>
									) : (
										<Tooltip key={idx} title={strings[config.tooltipKey]} placement="bottom" arrow>
											<StandartButton
												variant="contained"
												justIcon
												{...config.buttonConfig}
												disabled={disabledActions || config.buttonConfig.disabled}
											>
												<i className={`icon icon-${config.icon}`} />
											</StandartButton>
										</Tooltip>
									),
								)}
							</Box>
						)}
						{props.attachmentMetrics &&
							props.attachment.FileType === EFileType.Video &&
							props.request &&
							isRequestRunningOrFinished(props.request) && (
								<AttachmentMetric
									request={props.request}
									metric="ctr"
									attachmentId={props.attachment.Id}
								/>
							)}
					</Box>
				</Box>
			</Box>
		</ListItem>
	);
};

import React, { useState } from "react";
import {
	TableCell,
	Typography,
	Fade,
	Table,
	TableHead,
	TableRow,
	TableBody,
	TableFooter,
	TablePagination,
	IconButton,
	TablePaginationProps,
	Box,
} from "@material-ui/core";
import { KeyboardArrowLeft, KeyboardArrowRight } from "@material-ui/icons";
import moment from "moment";
import classNames from "classnames";

import { formatNumber, TLocale } from "@espresso/shared-config";
import { gameMetricsConfig } from "helpers/gameMetrics";
import { TenjinMetricsFullFragment, useGetGameTenjinMetricsQuery } from "@espresso/protocol";
import strings from "helpers/strings";
import { getPaddingTableRows } from "helpers/table";
import { useLoadingSignal } from "contexts/LoadingContext";

import { PlaceholderView } from "components";
import { TLocationState } from "./types";

type GameStatsColumnKey = keyof Omit<TenjinMetricsFullFragment, "CreatedAt" | "UpdatedAt" | "Id" | "__typename">;

interface IGameStatsColumn {
	key: GameStatsColumnKey;
	orderField?: GameStatsColumnKey;
	orderDirection?: "asc" | "desc";
	titleKey?: keyof TLocale;
	Cell: (props: { dayStats: TenjinMetricsFullFragment }) => JSX.Element;
}

const gameStatsColumns: IGameStatsColumn[] = [
	{
		key: "ReportDate",
		titleKey: "date",
		Cell: (props) => (
			<TableCell>
				<Typography variant="subtitle2">{moment(props.dayStats.ReportDate).format("DD.MM.YYYY")}</Typography>
			</TableCell>
		),
	},
	{
		key: "Spend",
		titleKey: "proDashboard_gameMetricSpend",
		Cell: (props) => <MetricCell {...props} metricName="Spend" />,
	},
	{
		key: "AdRevenue",
		titleKey: "proDashboard_gameMetricAdRevenue",
		Cell: (props) => <MetricCell {...props} metricName="AdRevenue" />,
	},
	{
		key: "Roas",
		titleKey: "proDashboard_gameMetricRoas",
		Cell: (props) => <MetricCell {...props} metricName="Roas" />,
	},
	{
		key: "RetentionD1",
		titleKey: "proDashboard_gameMetricRetentionD1",
		Cell: (props) => <MetricCell {...props} metricName="RetentionD1" />,
	},
];

export const GameStatsTable = (props: { rowsPerPage: number; gameId?: string; highlightedPeriod?: TLocationState }) => {
	const { rowsPerPage, gameId, highlightedPeriod } = props;

	//#region Internal State
	const [reportPeriod, setReportPeriod] = useState({
		from: moment().utc().startOf("week"),
		to: moment().utc().endOf("week").startOf("day"),
	});
	const [page, setPage] = useState(0);
	//#endregion

	//#region Data Fetching
	const { data: gameStatsData, loading } = useGetGameTenjinMetricsQuery({
		fetchPolicy: "cache-and-network",
		skip: !gameId,
		variables: {
			id: gameId as string,
			from: moment(reportPeriod.from).subtract(1, "week").toISOString(),
			to: reportPeriod.to.toISOString(),
		},
	});
	const gameStats: TenjinMetricsFullFragment[] = gameStatsData?.getGameTenjinMetrics ?? [];
	useLoadingSignal(loading);
	//#endregion

	const handlePageChange = (e: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
		setPage(newPage);
		setReportPeriod({
			from: moment().utc().add(newPage, "weeks").startOf("week"),
			to: moment().utc().add(newPage, "weeks").endOf("week").startOf("day"),
		});
	};

	const statsForWeek = gameStats.filter((m) =>
		moment(m.ReportDate).isBetween(reportPeriod.from, reportPeriod.to, undefined, "[]"),
	);

	return (
		<>
			{statsForWeek.length === 0 && <GameStatsTablePlaceholder />}
			<Fade in={statsForWeek.length > 0} appear>
				<Table>
					<TableHead>
						<TableRow>
							{gameStatsColumns.map((c) => (
								<TableCell key={c.key} className={c.key}>
									<Typography variant="subtitle1">
										{c.titleKey ? strings.formatString(strings[c.titleKey], " ") : ""}
									</Typography>
								</TableCell>
							))}
						</TableRow>
					</TableHead>
					<TableBody>
						{statsForWeek.map((s, index) => (
							<GameStatsTableRow key={index} dayStats={s} highlightedPeriod={highlightedPeriod} />
						))}
						{statsForWeek.length === 0 && getPaddingTableRows(rowsPerPage, gameStatsColumns.length)}
					</TableBody>
					<TableFooter>
						<TableRow>
							<TableCell colSpan={gameStatsColumns.length}>
								<Box className="table-actions">
									<TablePagination
										count={-1}
										page={page}
										onChangePage={handlePageChange}
										rowsPerPage={rowsPerPage}
										rowsPerPageOptions={[]}
										component="div"
										ActionsComponent={GameTablePaginationActions}
										labelDisplayedRows={() =>
											`${reportPeriod.from.format("DD.MM.YYYY")} - ${reportPeriod.to.format(
												"DD.MM.YYYY",
											)}`
										}
									/>
								</Box>
							</TableCell>
						</TableRow>
					</TableFooter>
				</Table>
			</Fade>
		</>
	);
};

export const GameStatsTablePlaceholder = () => {
	return <PlaceholderView imageSrc="/images/placeholder.png" title={strings.loadingMessage} />;
};

const GameStatsTableRow = (props: { dayStats: TenjinMetricsFullFragment; highlightedPeriod?: TLocationState }) => {
	const { dayStats, highlightedPeriod } = props;

	const isHighlighted =
		highlightedPeriod &&
		moment(dayStats.ReportDate).isBetween(highlightedPeriod.from, highlightedPeriod.to, "day", "[]");

	return (
		<TableRow className={isHighlighted ? "highlighted" : undefined}>
			{gameStatsColumns.map((c) => (
				<c.Cell key={c.key} dayStats={dayStats} />
			))}
		</TableRow>
	);
};

const MetricCell = (props: {
	dayStats: TenjinMetricsFullFragment;
	metricName: Exclude<GameStatsColumnKey, "ReportDate">;
}) => {
	const { dayStats, metricName } = props;
	const metricValue = dayStats[metricName] ?? null;
	const checkFn = gameMetricsConfig[metricName]?.check;
	const checkResult = checkFn && metricValue !== null ? checkFn(metricValue) : null;

	const metricString =
		metricValue !== null ? formatNumber(metricValue?.toFixed(2), gameMetricsConfig[metricName]?.unit) : "—";

	const metricClassnames = classNames("metric-text", {
		positive: checkResult,
		negative: checkResult !== null ? !checkResult : undefined,
	});
	return (
		<TableCell className="metric-cell">
			<Typography variant={checkResult !== null ? "subtitle1" : "body1"} className={metricClassnames}>
				{metricString || "-"}
			</Typography>
		</TableCell>
	);
};

const GameTablePaginationActions = (props: TablePaginationProps) => {
	const { page, onChangePage } = props;

	const handleBackButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page - 1);
	};

	const handleNextButtonClick = (event: React.MouseEvent<HTMLButtonElement>) => {
		onChangePage(event, page + 1);
	};

	return (
		<div className="MuiTablePagination-actions">
			<IconButton onClick={handleBackButtonClick}>
				<KeyboardArrowLeft />
			</IconButton>
			<IconButton onClick={handleNextButtonClick} disabled={page === 0}>
				<KeyboardArrowRight />
			</IconButton>
		</div>
	);
};

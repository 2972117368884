import React from "react";
// nodejs library that concatenates classes
import classNames from "classnames";

export interface IParallaxProps {
	filter?: boolean;
	className?: string;
	image?: string;
	video?: {
		h264?: string;
		h265?: string;
		vp8?: string;
	};
	small?: boolean;
}

export function Parallax(props: React.PropsWithChildren<IParallaxProps>) {
	let windowScrollTop;
	if (window.innerWidth >= 768) {
		windowScrollTop = window.pageYOffset / 3;
	} else {
		windowScrollTop = 0;
	}
	const [transform, setTransform] = React.useState("translate3d(0," + windowScrollTop + "px,0)");
	React.useEffect(() => {
		if (window.innerWidth >= 768) {
			window.addEventListener("scroll", resetTransform);
		}
		return function cleanup() {
			if (window.innerWidth >= 768) {
				window.removeEventListener("scroll", resetTransform);
			}
		};
	});

	const resetTransform = () => {
		var windowScrollTop = window.pageYOffset / 3;
		setTransform("translate3d(0," + windowScrollTop + "px,0)");
	};
	const { filter, className, children, image, video, small } = props;

	const parallaxClasses = classNames(
		"parallax",
		{
			filter: filter,
			small: small,
		},
		className,
	);

	const videoClasses =
		video &&
		classNames("video", {
			filter: filter,
		});

	return (
		<div
			id="videoContainer"
			className={parallaxClasses}
			style={{
				backgroundImage: image && "url(" + image + ")",
				transform: transform,
			}}
		>
			{video && (
				<div className={videoClasses}>
					<video autoPlay loop muted playsInline>
						{video.h264 && <source src={video.h264} type="video/mp4" />}
						{video.h265 && <source src={video.h265} type="video/mp4" />}
						{video.vp8 && <source src={video.vp8} type="video/webm" />}
					</video>
				</div>
			)}
			{children}
		</div>
	);
}

import { TLocale } from "@espresso/shared-config";
import { Box, Icon, Typography } from "@material-ui/core";
import { TColor } from "components/types";
import strings from "helpers/strings";
import React, { PropsWithChildren } from "react";

export interface IInfoBoxProps {
	icon: string;
	color?: TColor;
	localeKeys?: Array<keyof TLocale>;
	className?: string;
}

export const InfoBox = ({
	icon,
	color = "info",
	localeKeys,
	className,
	children,
}: PropsWithChildren<IInfoBoxProps>) => {
	return (
		<Box className={`info-box ${color} ${className}`}>
			<Icon fontSize="large" className={`icon icon-${icon}`} />
			<Box className="text-container">
				{localeKeys?.map((key, i) => (
					<Typography key={`info-text-${i}`} variant="caption">
						{strings[key]}
					</Typography>
				))}
				{children}
			</Box>
		</Box>
	);
};

import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import { RequestList } from "./RequestList";
import { StandartButton } from "../common";
import useAppContext, { IRequestsListParams } from "../../contexts/AppContext";
import { Box, CircularProgress, Grid, Tooltip } from "@material-ui/core";
import { TColor } from "../types";
import strings from "../../helpers/strings";
import {
	ERequestStatus,
	StatusPresetInput,
	useUpsertPresetMutation,
	PresetSettingsFullFragment,
	GetMyLoginDocument,
	useGetRequestsPresetsCountersLazyQuery,
	GetRequestsPresetsCountersDocument,
	defaultUpsertPresetSettingsInput,
	useTouchPresetMutation,
	EPresetConfig,
} from "@espresso/protocol";
import { PresetsSettingsDialog } from "./PresetsSettingsDialog";
import { mergeKeepShape, TLocale } from "@espresso/shared-config";
import moment from "moment";
import { useLoadingSignal } from "contexts/LoadingContext";

export const RequestsListWithPresets = () => {
	const { login, requestsListParams, setRequestsListParams } = useAppContext();
	const initActivePresetRef = useRef<boolean>(false);
	const [activePreset, setActivePreset] = useState<PresetSettingsFullFragment>();

	const slidersConfig: { [key in ERequestStatus]?: number } | undefined = useMemo(() => {
		if (activePreset?.Readonly) {
			return;
		}
		return activePreset?.Filters?.PresetStatuses?.reduce<{ [key in ERequestStatus]?: number }>((res, item) => {
			if (item.DayToOffset != null) {
				res[item.Status] = item.DayToOffset;
			}
			return res;
		}, {});
	}, [activePreset]);

	const [getPresetsWithCount, { data, loading }] = useGetRequestsPresetsCountersLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	useLoadingSignal(loading);

	const [touchPreset] = useTouchPresetMutation({
		refetchQueries: [{ query: GetMyLoginDocument }],
	});

	const presets = login?.Presets;
	const counters = useMemo(() => new Map(data?.getRequestsPresetsCounters.map((c) => [c.Id, c])), [
		data?.getRequestsPresetsCounters,
	]);

	useEffect(() => {
		getPresetsWithCount();
	}, [activePreset, getPresetsWithCount]);

	useEffect(() => {
		if (!presets?.length) {
			return;
		}

		if (activePreset) {
			const newPreset = presets.find((item) => item.Id === activePreset.Id);
			if (newPreset) {
				setActivePreset(newPreset);
			}
			return;
		}

		if (requestsListParams.presetId) {
			const newPreset = presets.find((item) => item.Id === requestsListParams.presetId);
			if (newPreset) {
				touchPreset({ variables: { id: newPreset.Id } });
				setActivePreset(newPreset);
				return;
			}
		}

		touchPreset({ variables: { id: presets[0].Id } });
		setActivePreset(presets[0]);
	}, [activePreset, presets, requestsListParams.presetId, touchPreset]);

	const setPreset = useCallback(
		(preset: PresetSettingsFullFragment) => {
			if (activePreset?.Id !== preset.Id) {
				touchPreset({ variables: { id: preset.Id } });
				setActivePreset(preset);
				setRequestsListParams({ presetId: preset.Id });
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[activePreset?.Id, touchPreset, requestsListParams],
	);

	useEffect(() => {
		if (
			!presets?.length ||
			!counters.size ||
			!activePreset ||
			activePreset.Config !== EPresetConfig.Attention ||
			initActivePresetRef.current
		) {
			return;
		}

		const attentionPresetCount = counters.get(activePreset.Id);

		if (!attentionPresetCount || attentionPresetCount.Count > 0) {
			return;
		}

		const validationPreset = presets.find((item) => item.Config === EPresetConfig.Validation);

		if (!validationPreset) {
			return;
		}

		const validationPresetCount = counters.get(validationPreset.Id);

		if (!validationPresetCount || validationPresetCount.Count <= 0) {
			return;
		}

		initActivePresetRef.current = !initActivePresetRef.current;
		setPreset(validationPreset);
	}, [presets, activePreset, counters, setPreset]);

	const setPresetAll = useCallback(() => {
		const presetAll = presets?.find((preset) => preset.Config === EPresetConfig.All);
		setActivePreset(presetAll);
		setRequestsListParams({ ...requestsListParams, presetId: presetAll?.Id });
	}, [presets, requestsListParams, setRequestsListParams]);

	const [presetDialogOpen, setPresetDialogOpen] = useState(false);

	const [upsertPresets] = useUpsertPresetMutation({
		refetchQueries: [{ query: GetMyLoginDocument }, { query: GetRequestsPresetsCountersDocument }],
	});

	const queryParams: IRequestsListParams = useMemo(() => {
		const presetStatuses = (activePreset?.Filters?.PresetStatuses || []).map((item) => {
			const { __typename, ...presetToInput } = item;
			return presetToInput;
		});
		const statuses = presetStatuses.length ? presetStatuses : requestsListParams.statuses;

		return {
			...requestsListParams,
			statuses,
		};
	}, [activePreset?.Filters?.PresetStatuses, requestsListParams]);

	const onSavePresets = (slidersConfig: { [key in ERequestStatus]?: number }) => {
		if (!activePreset) {
			return;
		}

		const statusesKeys = Object.keys(slidersConfig);
		const statusesToUpsert: StatusPresetInput[] = statusesKeys.map((key) => {
			const statusKey = key as ERequestStatus;
			const value = slidersConfig[statusKey];

			return {
				Status: statusKey,
				DayToOffset: value,
			};
		});

		const isAddPrivateStatus =
			statusesKeys.includes(ERequestStatus.New) && !statusesKeys.includes(ERequestStatus.Private);

		if (isAddPrivateStatus) {
			statusesToUpsert.push({
				Status: ERequestStatus.Private,
				DayToOffset: slidersConfig.New,
			});
		}

		const activePresetInput = mergeKeepShape(defaultUpsertPresetSettingsInput, {
			...activePreset,
			Filters: {
				PresetStatuses: statusesToUpsert,
			},
		});
		upsertPresets({
			variables: {
				data: activePresetInput,
			},
		});
	};

	const withStatusFilter = activePreset?.Config === EPresetConfig.All;

	return (
		<Grid container>
			<Box className="presets-container">
				<Box className="items-container">
					{presets?.map((preset) => {
						const counter = counters.get(preset.Id);
						const hasNewRequests = moment(preset.UpdatedAt).isBefore(counter?.LastRequest);
						if (preset.Id === activePreset?.Id) {
							return (
								<StandartButton key={preset.Id} round color={preset.Color as TColor}>
									{preset.Name ||
										strings[
											(preset.NameLocaleKey as keyof TLocale | null | undefined) || "unknown"
										]}
									{!!counter?.Count && (
										<div className={`badge text-${preset.Color} active`}>{counter.Count}</div>
									)}
								</StandartButton>
							);
						}
						return (
							<StandartButton key={preset.Id} round variant="text" onClick={() => setPreset(preset)}>
								{preset.Name ||
									strings[(preset.NameLocaleKey as keyof TLocale | null | undefined) || "unknown"]}
								{!!counter?.Count && (
									<div className={`badge bg-${hasNewRequests ? preset.Color : "gray"}`}>
										{counter.Count}
									</div>
								)}
							</StandartButton>
						);
					})}
				</Box>

				<Tooltip
					title={
						activePreset?.Tooltip ||
						(activePreset?.TooltipLocaleKey && strings[activePreset.TooltipLocaleKey as keyof TLocale]) ||
						""
					}
					placement="bottom"
				>
					<div>
						<StandartButton
							disabled={activePreset?.Readonly || false}
							round
							justIcon
							variant="text"
							onClick={() => setPresetDialogOpen(true)}
						>
							<i className="icon icon-settings" />
						</StandartButton>
					</div>
				</Tooltip>
				{slidersConfig && (
					<PresetsSettingsDialog
						slidersConfig={slidersConfig}
						onSavePresets={onSavePresets}
						open={presetDialogOpen}
						close={() => setPresetDialogOpen(false)}
						presetName={
							activePreset?.Name ||
							strings[(activePreset?.NameLocaleKey as keyof TLocale | null | undefined) || "unknown"]
						}
					/>
				)}
			</Box>
			{loading && !presets ? (
				<Grid item xs={12}>
					<Box display="flex" justifyContent="center">
						<CircularProgress />
					</Box>
				</Grid>
			) : (
				<RequestList
					requestsListParams={queryParams}
					setRequestsListParams={setRequestsListParams}
					withStatusFilter={withStatusFilter}
					setPresetAll={setPresetAll}
					counters={counters}
					activePreset={activePreset}
					rememberLocation
					disableAllRequestsButton
				/>
			)}
		</Grid>
	);
};

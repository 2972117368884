import { Step, StepIconProps, StepLabel, Stepper, Typography } from "@material-ui/core";
import classNames from "classnames";
import React, { useEffect, useState } from "react";
import { ERequestStatus, RequestFullFragment, ECampaignType } from "@espresso/protocol";
import strings from "../../helpers/strings";
import moment, { Moment } from "moment";
import { ctrRequestSteps, cpiRequestSteps, r1RequestSteps } from "../../helpers/constants";
import { useRequestStatusHistory } from "helpers/request";

export interface IRequestStepperProps {
	request?: RequestFullFragment | null;
	currentStepStatus?: ERequestStatus;
	changeStepIndex?: (index: number) => void;
}

const RequestStepIcon = (props: StepIconProps) => {
	const { active, completed, icon } = props;

	return (
		<div
			className={classNames("MuiStepIcon-root", {
				"MuiStepIcon-active": active,
				"MuiStepIcon-completed": completed,
			})}
		>
			{completed ? <i className="icon icon-do" /> : icon}
		</div>
	);
};

export const RequestStepper = (props: IRequestStepperProps) => {
	const { changeStepIndex } = props;
	const [activeStep, setActiveStep] = useState(0);
	const statusHistory = useRequestStatusHistory(props.request);

	let steps = ctrRequestSteps;
	switch (props.request?.CampaignType) {
		case ECampaignType.R1:
			steps = r1RequestSteps;
			break;
		case ECampaignType.Cpi:
			steps = cpiRequestSteps;
			break;
	}

	useEffect(() => {
		const currentStatus = props.currentStepStatus || props.request?.Status;
		if (!steps || !currentStatus) {
			return;
		}
		const activeIndex = steps.findIndex((step) => step?.statuses.includes(currentStatus));

		setActiveStep(activeIndex);
		changeStepIndex?.(activeIndex);
	}, [props.currentStepStatus, props.request, changeStepIndex, steps]);

	if (!steps) {
		return null;
	}

	return (
		<Stepper className="request-create-stepper" activeStep={activeStep} alternativeLabel>
			{steps.map((step, index) => {
				if (!step) {
					return null;
				}
				const dates =
					statusHistory &&
					step.statuses.reduce<Array<Moment>>((res, item) => {
						statusHistory[item] && res.push(moment(statusHistory[item]));
						return res;
					}, []);

				const date = statusHistory && dates?.length ? moment.max(dates).format("DD.MM.YYYY") : "";
				return (
					<Step completed={index < activeStep} key={`step-${index}`}>
						<StepLabel StepIconComponent={RequestStepIcon}>
							{date}
							<Typography className="top-label" variant="caption">
								{strings[step.labelkey]}
							</Typography>
						</StepLabel>
					</Step>
				);
			})}
		</Stepper>
	);
};

import { createMuiTheme } from "@material-ui/core";

export const theme = createMuiTheme({
	breakpoints: {
		values: {
			xs: 0,
			sm: 576,
			md: 769,
			lg: 992,
			xl: 1100,
		},
	},
	props: {
		MuiButton: {
			variant: "contained",
			color: "primary",
		},
		MuiPaper: {
			elevation: 0,
		},
		MuiButtonGroup: {
			variant: "outlined",
			color: "primary",
			disableElevation: true,
		},
		MuiTextField: {
			color: "secondary",
		},
	},
});

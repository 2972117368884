import React from "react";
import { Typography } from "@material-ui/core";
import _ from "lodash";

import { IPlaceholderViewProps, PlaceholderView } from "components/common/PlaceholderView";
import strings from "helpers/strings";

interface IProps {
	placeholderButtonCallback: () => void;
	loading: boolean;
	hiddenRequestsIds?: string[];
}

export const RequestListTablePlaceholder = (props: IProps) => {
	const { placeholderButtonCallback, hiddenRequestsIds, loading } = props;

	const placeholderConfig: IPlaceholderViewProps = loading
		? {
				imageSrc: "/images/placeholder.png",
				title: strings.loadingMessage,
		  }
		: {
				imageSrc: "/images/placeholder.png",
				title: strings.formatString(strings.request_placeholderTitle, <br />),
				text: strings.request_placeholderText,
				buttonProps: {
					className: "button-width-medium",
					color: "success",
					onClick: placeholderButtonCallback,
					children: strings.request_placeholderButton,
				},
		  };

	return (
		<>
			{_.isEmpty(hiddenRequestsIds) ? (
				<PlaceholderView {...placeholderConfig} />
			) : (
				<div className="table-placeholder">
					<Typography variant="body2">{strings.request_otherListEmpty}</Typography>
				</div>
			)}
		</>
	);
};

import React, { useState } from "react";
import { ConfirmModal, StandartButton, StandartTextCopyButton } from "../../components";
import { Paper, Typography, Box } from "@material-ui/core";
import { DeveloperFullFragment, GetMyDeveloperDocument, useGenerateApiKeyMutation } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { useLoadingSignal } from "contexts/LoadingContext";

type TDeveloperEditProps = {
	developer?: DeveloperFullFragment | null;
};

export const UpdateApiKey = (props: TDeveloperEditProps) => {
	const { developer } = props;

	const [generateKey, { loading }] = useGenerateApiKeyMutation({
		refetchQueries: [{ query: GetMyDeveloperDocument }],
	});
	useLoadingSignal(loading);

	const [confirmVisible, setConfirmVisible] = useState(false);

	if (!developer) {
		return null;
	}

	const refreshKey = () => {
		generateKey();
	};

	return (
		<Paper className="update-api-key">
			<Typography variant="subtitle2">{strings.developer_apiKeyTitle}</Typography>
			<Typography variant="subtitle1" color="secondary">
				{developer.ApiKey}
				<StandartTextCopyButton valueToCopy={developer.ApiKey} color="secondary" />
			</Typography>
			<Box className="action-root">
				<Box className="info-root">
					<i className="icon icon-info1" />
					<Typography variant="body2">{strings.developer_apiKeyWarningText}</Typography>
				</Box>
				<StandartButton color="danger" variant="contained" onClick={() => setConfirmVisible(true)}>
					{strings.developer_apiKeyRefreshButton}
				</StandartButton>
			</Box>
			<ConfirmModal
				visible={confirmVisible}
				onClose={() => setConfirmVisible(false)}
				onConfirm={refreshKey}
				title={strings.developer_apiKeyDialogTitle}
				text={strings.developer_apiKeyDialogMessage}
				confirmButtonLabel={strings.developer_apiKeyDialogButton}
			/>
		</Paper>
	);
};

import React, { useEffect, useState } from "react";
import {
	Checkbox,
	FormControl,
	IconButton,
	Input,
	InputLabel,
	ListItemText,
	MenuItem,
	Select,
} from "@material-ui/core";
import { useGetPromoKeysQuery } from "@espresso/protocol";
import strings from "../../helpers/strings";

type TRequestItemStepperProps = {
	selectedKeys?: string[];
	onSelectedChange?: (selectedKeys: string[]) => void;
};

export const PromoList = (props: TRequestItemStepperProps) => {
	const { data } = useGetPromoKeysQuery({ fetchPolicy: "cache-and-network" });

	const [selectedKeys, setSelectedKeys] = useState<string[]>(props.selectedKeys || []);

	useEffect(() => {
		setSelectedKeys(props.selectedKeys || []);
	}, [props.selectedKeys]);

	if (!data) {
		return null;
	}

	const promoKeys = data.getPromoKeys;

	const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
		const selectedKeys = event.target.value as string[];
		setSelectedKeys(selectedKeys);
		if (props.onSelectedChange) {
			props.onSelectedChange(selectedKeys);
		}
	};

	const handleClear = (event: React.SyntheticEvent) => {
		event.stopPropagation();
		setSelectedKeys([]);
		if (props.onSelectedChange) {
			props.onSelectedChange([]);
		}
	};

	return (
		<FormControl className="promo-filter" margin="none" fullWidth>
			<InputLabel color="secondary">{strings.promoKey}</InputLabel>
			<Select
				color="secondary"
				MenuProps={{ variant: "menu" }}
				multiple
				value={selectedKeys}
				onChange={handleChange}
				input={
					<Input
						className="select-input"
						endAdornment={
							selectedKeys.length ? (
								<IconButton
									onClick={handleClear}
									size="small"
									color="secondary"
									className="clear-button"
								>
									<i className="icon icon-close" />
								</IconButton>
							) : undefined
						}
					/>
				}
				renderValue={(selected) => (selected as string[]).join(", ")}
			>
				{promoKeys.map((promoKey) => (
					<MenuItem key={promoKey} value={promoKey}>
						<ListItemText primary={promoKey} />
						<Checkbox
							checked={selectedKeys.includes(promoKey)}
							color="secondary"
							icon={<i className="icon icon-Checkboxes" />}
							checkedIcon={<i className="icon icon-Checkboxhec" />}
						/>
					</MenuItem>
				))}
			</Select>
		</FormControl>
	);
};

import React, { useRef, useMemo } from "react";
import { Grid, Box, Typography, CircularProgress } from "@material-ui/core";
import { ECheckStatus } from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";
import strings from "helpers/strings";

import { IStandartButtonProps, StandartButton, InfoButtonWithDialog, TColor } from "components";
import { RequestCheckStatus, TCheckField } from "views/RequestView/RequestCheckStatus";

interface IWizardStepAction extends IStandartButtonProps {
	title: keyof TLocale;
	onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

interface IActionableStepContentProps {
	content: string;
	icon?: string;
	urlString?: string;
	onClick?: () => void;
	disabled?: boolean;
	color?: TColor;
}

export interface ISdkWizardStepProps {
	status?: ECheckStatus;
	lastStatsDate?: string | null;
	fieldName?: TCheckField;
	title: keyof TLocale | JSX.Element;
	action: IWizardStepAction;
	descriptionKey?: keyof TLocale;
	isVerifying: boolean;
	totalSteps: number;
	subtitle?: keyof TLocale | JSX.Element;
	infoDialog?: {
		summaryKey?: keyof TLocale;
		content: JSX.Element;
	};
	actionableContent?: Omit<IActionableStepContentProps, "disabled">[];
	hidden?: boolean;
	disabled?: boolean;
}

const ActionableStepContent = (props: IActionableStepContentProps) => {
	const { content, icon, urlString, onClick, disabled, color = "secondary" } = props;

	const ButtonComponent = (
		<StandartButton
			variant="outlined"
			startIcon={icon && <i className={`icon icon-${icon}`} />}
			className={`actionable-content ${color} ${disabled ? "invisible" : ""}`}
			onClick={onClick}
			disabled={disabled}
		>
			<Typography component="span" color="secondary">
				<b>{content}</b>
			</Typography>
		</StandartButton>
	);

	if (!urlString) {
		return ButtonComponent;
	}
	return (
		<a href={urlString} target="_blank" rel="noreferrer">
			{ButtonComponent}
		</a>
	);
};

export const SdkWizardStep = ({
	status,
	fieldName,
	title,
	subtitle,
	action,
	descriptionKey,
	actionableContent,
	isVerifying,
	totalSteps,
	infoDialog,
	hidden,
	disabled,
	lastStatsDate,
}: ISdkWizardStepProps) => {
	const prevStepStatus = useRef<ECheckStatus | undefined>(status !== ECheckStatus.NotChecked ? status : undefined);

	const stepWidth = Math.floor(12 / totalSteps) as 1 | 2 | 3 | 4 | 5 | 6 | 7 | 8 | 9 | 10 | 11 | 12;
	const checkStatus = useMemo(() => {
		const result =
			((!disabled && prevStepStatus.current) || isVerifying) && status !== ECheckStatus.NotChecked
				? status
				: undefined;
		prevStepStatus.current = status;

		return result;
	}, [disabled, status, isVerifying]);

	const titleElement = typeof title === "string" ? strings[title] : title;
	const subtitleElement = typeof subtitle === "string" ? strings[subtitle] : subtitle;

	return (
		<Grid item lg={stepWidth} md={12} className={`wizard-step-container ${hidden ? "hidden" : ""}`}>
			<Box className={`wizard-step ${disabled ? "disabled" : ""}`}>
				<Box className="wizard-step-content">
					<Box className="wizard-step-title">
						<Box className="wizard-status-info">
							<RequestCheckStatus
								field={fieldName}
								inProgress={isVerifying}
								status={checkStatus}
								customExtraData={{ lastStatsDate }}
							>
								{titleElement}
							</RequestCheckStatus>
							{infoDialog && (
								<InfoButtonWithDialog dialogSummaryKey={infoDialog.summaryKey} disabled={disabled}>
									{infoDialog.content}
								</InfoButtonWithDialog>
							)}
						</Box>
						{subtitleElement}
					</Box>
					{descriptionKey && (
						<Typography variant="body2" color="secondary" className="description">
							{strings[descriptionKey]}
						</Typography>
					)}
					{actionableContent &&
						actionableContent.map((content, index) => (
							<ActionableStepContent key={`act-content-${index}`} {...content} disabled={disabled} />
						))}
				</Box>
				<StandartButton
					onClick={action.onClick}
					color={action.color}
					disabled={disabled || isVerifying}
					startIcon={isVerifying && <CircularProgress size={16} className="progress" />}
					className={disabled ? "invisible" : ""}
				>
					{strings[action.title]}
				</StandartButton>
			</Box>
		</Grid>
	);
};

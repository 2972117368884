import React from "react";
import { Typography } from "@material-ui/core";

import { ELang } from "@espresso/protocol";
import useAppContext from "contexts/AppContext";
import strings from "helpers/strings";

export const imgSources: { [key in ELang]: string } = {
	Ru: "/images/facebookAppIdRu1.png",
	En: "/images/facebookAppIdEn1.png",
	Ch: "/images/facebookAppIdCh1.png",
	Tr: "/images/facebookAppIdEn1.png",
};

export const AppLinkError = () => {
	const { lang } = useAppContext();

	return (
		<>
			<Typography>{strings.help_faqAppLinkErrorP1}</Typography>
			<ul className="MuiTypography-body1">
				<li>{strings.help_faqAppLinkErrorUl1Li1}</li>
				<li>{strings.help_faqAppLinkErrorUl1Li2}</li>
			</ul>
			<Typography>{strings.help_faqAppLinkErrorP2}</Typography>

			<Typography component="h6">
				<b>{strings.help_faqAppLinkErrorH1}</b>
			</Typography>

			<Typography>{strings.help_faqAppLinkErrorP3}</Typography>
			<ol className="MuiTypography-body1">
				<li>
					{strings.formatString(
						strings.help_faqAppLinkErrorOlLi1,
						<b>{strings.help_faqAppLinkErrorOlLi1Bold}</b>,
					)}
				</li>
				<li>
					{strings.formatString(
						strings.help_faqAppLinkErrorOlLi2,
						<b>{strings.help_faqAppLinkErrorOlLi2Bold}</b>,
					)}
				</li>
				<li>
					{strings.formatString(
						strings.help_faqAppLinkErrorOlLi3,
						<b>{strings.help_faqAppLinkErrorOlLi3Bold}</b>,
					)}
				</li>
				<li>
					{strings.formatString(
						strings.help_faqAppLinkErrorOlLi4,
						<b>{strings.help_faqAppLinkErrorOlLi4Bold1}</b>,
						<b>{strings.help_faqAppLinkErrorOlLi4Bold2}</b>,
					)}
				</li>
			</ol>
			<div className="img-wrap">
				<img src={imgSources[lang]} alt="" />
			</div>
			<Typography component="h6">
				<b>{strings.help_faqAppLinkErrorH2}</b>
			</Typography>
			<Typography>{strings.help_faqAppLinkErrorP4}</Typography>
			<ul className="MuiTypography-body1">
				<li>{strings.help_faqAppLinkErrorUl2Li1}</li>
				<li>{strings.help_faqAppLinkErrorUl2Li2}</li>
			</ul>
		</>
	);
};

import React from "react";
import { Grid, Typography } from "@material-ui/core";
import { Redirect } from "react-router-dom";

import { EDeveloperRank, ELoginRole } from "@espresso/protocol";
import { StandartView } from "components";
import strings from "helpers/strings";
import withAuth from "helpers/withAuth";
import useAppContext from "contexts/AppContext";
import { GamesList } from "./GamesList";

export const ProDashboardView = withAuth(() => {
	const { login } = useAppContext();

	const isProAvailable =
		login?.Developer?.Rank === EDeveloperRank.Trusted ||
		login?.Developer?.Rank === EDeveloperRank.ProViewAccess ||
		login?.Role === ELoginRole.Manager ||
		login?.Role === ELoginRole.Publisher;

	if (!login?.Role) {
		return null;
	}

	if (!isProAvailable) {
		return <Redirect to="/profile" />;
	}

	return (
		<StandartView fullWidth>
			<Grid container className="pro-dashboard">
				<Grid item sm={12} className="title-container">
					<Typography variant="h5">{strings.proDashboard_title}</Typography>
				</Grid>
				<GamesList />
			</Grid>
		</StandartView>
	);
});

import React from "react";
import { useRouteMatch } from "react-router-dom";
import { AppLink } from "components";
import { HelpPage } from "./HelpPage";
import strings from "helpers/strings";
import { helpSections } from "./sections";

export const ContentsPage = () => {
	const { path } = useRouteMatch();

	return (
		<HelpPage titleKey="help_contents">
			<ul className=" contents-list MuiTypography-subtitle1">
				{helpSections.map((section, index) => (
					<li key={`section-${index}`}>
						<AppLink to={`${path}/${section.path}`}>{strings[section.titleKey]}</AppLink>
						<ul className="topics-list MuiTypography-body1">
							{section.topics.map((topic, index) => (
								<li key={`topic-${index}`}>
									<AppLink to={`${path}/${section.path}/${topic.path}`}>
										{strings[topic.titleKey]}
									</AppLink>
								</li>
							))}
						</ul>
					</li>
				))}
			</ul>
		</HelpPage>
	);
};

import React, { ComponentType, PropsWithChildren } from "react";
import useAppContext from "../contexts/AppContext";
import { Redirect, useLocation } from "react-router-dom";
import { TLocale } from "@espresso/shared-config";

export interface IAuthLocation {
	authMessageKey?: keyof TLocale;
	successRedirect?: string;
}

const CheckAuth = (props: PropsWithChildren<IAuthLocation>) => {
	const { session } = useAppContext();
	const location = useLocation();

	if (!session) {
		const state: IAuthLocation = {
			authMessageKey: props.authMessageKey,
			successRedirect: props.successRedirect || location.pathname,
		};
		return <Redirect to={{ pathname: "/signup", state }} />;
	}
	return <>{props.children}</>;
};

function withAuth<T>(Component: ComponentType<T>, authMessageKey?: keyof TLocale, successRedirect?: string) {
	return (props: T) => (
		<CheckAuth authMessageKey={authMessageKey} successRedirect={successRedirect}>
			<Component {...props} />
		</CheckAuth>
	);
}
export default withAuth;

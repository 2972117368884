import React, { useState } from "react";
import { Box, Typography, Tooltip } from "@material-ui/core";
import { OptionCard } from "components";
import strings from "../../../helpers/strings";
import { IRequestCampaignTypeItem } from "../../../helpers/request";

export type TCreateGameRequestItemProps = IRequestCampaignTypeItem & {
	onClick: () => void;
	disabled?: boolean;
};

export const CreateGameRequestItem = React.memo((props: TCreateGameRequestItemProps) => {
	const { titleKey, tooltipInfoKey, onClick, iconName, listKeys, disabled } = props;
	const [loading, setLoading] = useState(false);

	return (
		<OptionCard
			onClick={() => {
				onClick();
				setLoading(true);
			}}
			className="item"
			disabled={disabled}
			loading={loading}
		>
			<Tooltip title={strings[tooltipInfoKey]} placement="top">
				<Box className="header">
					<Typography align="center">{strings[titleKey]}</Typography>
					<i className="icon icon-header icon-info-outline" />
				</Box>
			</Tooltip>
			<Box className="icon-root">
				<i className={`icon icon-${iconName}`} />
			</Box>
			{listKeys && (
				<Box marginBottom={1}>
					<Typography>{strings.request_titleListGame}</Typography>
					<ul>
						{listKeys.map((key, index) => (
							<li key={index}>
								<Typography display="inline">{strings[key]}</Typography>
							</li>
						))}
					</ul>
				</Box>
			)}
		</OptionCard>
	);
});

import { Box, Grid, Typography } from "@material-ui/core";
import React, { useState, useRef } from "react";
import strings from "../../helpers/strings";
import "firebase/auth";
import * as firebase from "firebase/app";
import { IBaseSignUpState } from "../types";
import { authStatusCode, mergeKeepShape } from "@espresso/shared-config";
import { defaultUpsertDeveloperInput, useSignUpMutation, useUpsertDeveloperMutation } from "@espresso/protocol";
import moment from "moment";
import useAppContext from "../../contexts/AppContext";
import { StandartButton } from ".";
import { useHistory, useLocation } from "react-router-dom";
import { Maybe } from "graphql/jsutils/Maybe";
import { IAuthLocation } from "helpers/withAuth";
import { useAnalytics } from "helpers/ga";
import { ConfirmModal } from "../../components/ConfirmModal";

interface IModalParams {
	title: string;
	text?: string;
}

interface IConfirmModalState extends IModalParams {
	visible: boolean;
}

export interface ISocialAuthProps<T extends IBaseSignUpState> {
	setSignUpState: React.Dispatch<React.SetStateAction<T>>;
	loading: boolean;
	getModalParams?: () => IModalParams | undefined;
}

const DEFAULT_CONFIRM_MODAL_STATE: IConfirmModalState = {
	visible: false,
	title: "",
};

export function SocialAuth<T extends IBaseSignUpState = IBaseSignUpState>(props: ISocialAuthProps<T>): JSX.Element {
	const { setSignUpState, loading, getModalParams } = props;
	const signInProviderRef = useRef<Maybe<firebase.auth.AuthProvider>>(null);
	const [confirmModalState, setConfirmModalState] = useState<IConfirmModalState>(DEFAULT_CONFIRM_MODAL_STATE);
	const [signUp] = useSignUpMutation();
	const [upsertDeveloper] = useUpsertDeveloperMutation();
	const { session, setSession, getErrorCode, promoKey, setShowExitPrompt } = useAppContext();
	const { analyticsEvents } = useAnalytics();
	const location = useLocation<Maybe<IAuthLocation>>();
	const history = useHistory();
	const redirectRoute = location.state?.successRedirect;

	const signIn = async (provider: firebase.auth.AuthProvider) => {
		setSignUpState((prevState) => ({ ...prevState, localeKey: "processing", error: false, loading: true }));
		try {
			const result = await firebase.auth().signInWithPopup(provider);
			const firebaseToken = await result.user?.getIdToken();

			const signUpResult = await signUp({
				variables: {
					data: {
						FirebaseToken: firebaseToken,
						Email: result.user?.email || undefined,
						PromoKey: promoKey,
					},
				},
			});
			const token = signUpResult.data?.signUp.data?.token;

			if (!token) {
				const errorCode = getErrorCode();
				if (errorCode?.code === "auth_2") {
					return;
				}
				setSignUpState((prevState) => ({
					...prevState,
					localeKey: "auth_unknown",
					error: true,
					loading: false,
				}));
				return;
			}
			const developer = signUpResult?.data?.signUp.data?.Login.Developer;
			if (!developer) {
				const upsertDeveloperInput = mergeKeepShape(defaultUpsertDeveloperInput, {
					ContactEmail: result.user?.email,
					TeamName: result.user?.displayName || undefined,
					ContactName: result.user?.displayName || undefined,
					TimezoneDelta: moment().utcOffset(),
				});
				await upsertDeveloper({
					variables: { data: upsertDeveloperInput },
					context: { headers: { Authorization: `Bearer ${token}` } },
				});
			}

			setSignUpState((prevState) => ({ ...prevState, localeKey: undefined, error: false, loading: false }));

			const id = signUpResult.data?.signUp.data?.authId;

			if (session?.auth?.id !== id || session?.auth?.token !== token) {
				setSession({ auth: { token, id } });
			}

			if (!developer) {
				analyticsEvents.signup("social");
			} else {
				analyticsEvents.login("social");
			}

			const route = redirectRoute || "/profile";
			history.push(route);
		} catch (error) {
			console.log(error);
			if (error.code) {
				const localeKey = authStatusCode.get(error.code)?.localeKey;
				if (localeKey === null) {
					// Если знаем код, но для него нет сообщения об ошибке - считаем это нормальной ситуацией и ошибку авторизации не показывааем
					setSignUpState((prevState) => ({
						...prevState,
						localeKey: undefined,
						error: false,
						loading: false,
					}));
				} else {
					setSignUpState((prevState) => ({
						...prevState,
						localeKey: localeKey || "auth_unknown",
						error: true,
						loading: false,
					}));
				}
			} else {
				setSignUpState((prevState) => ({
					...prevState,
					localeKey: "auth_unknown",
					error: true,
					loading: false,
				}));
			}
		}
	};

	const clickSocialAuth = (provider: firebase.auth.AuthProvider) => {
		const modalParams = getModalParams?.();

		if (!modalParams) {
			signIn(provider);
			return;
		}

		signInProviderRef.current = provider;
		setConfirmModalState((prevState) => ({
			...modalParams,
			visible: !prevState.visible,
		}));
	};

	const handleConfirmModal = () => {
		if (!signInProviderRef.current) {
			return;
		}
		signIn(signInProviderRef.current);
	};

	const handleCloseModal = () => {
		signInProviderRef.current = null;
		setConfirmModalState((prevState) => ({
			...prevState,
			visible: false,
		}));
		setShowExitPrompt(false);
	};

	return (
		<>
			{confirmModalState && (
				<ConfirmModal
					visible={confirmModalState.visible}
					title={confirmModalState.title}
					text={confirmModalState.text}
					confirmButtonLabel={strings.request_next}
					onConfirm={handleConfirmModal}
					onClose={handleCloseModal}
				/>
			)}
			<Box className="social-auth">
				<Typography align="center" variant="body1" color="secondary">
					{strings.auth_signInWith}
				</Typography>
				<Grid container spacing={2} justify="center" className="buttons">
					<Grid item>
						<StandartButton
							size="large"
							justIcon
							round
							color="google"
							onClick={() => clickSocialAuth(new firebase.auth.GoogleAuthProvider())}
							title={strings.auth_signInGoogle}
							disabled={loading}
						>
							<i className="icon icon-Google" />
						</StandartButton>
					</Grid>
					<Grid item>
						<StandartButton
							size="large"
							justIcon
							round
							color="facebook"
							onClick={() => clickSocialAuth(new firebase.auth.FacebookAuthProvider())}
							title={strings.auth_signInFacebook}
							disabled={loading}
						>
							<i className="icon icon-Facebook" />
						</StandartButton>
					</Grid>
					<Grid item>
						<StandartButton
							size="large"
							justIcon
							round
							color="apple"
							onClick={() => clickSocialAuth(new firebase.auth.OAuthProvider("apple.com"))}
							title={strings.auth_signInApple}
							disabled={loading}
						>
							<i className="icon icon-apple" />
						</StandartButton>
					</Grid>
				</Grid>
				<Typography align="center" variant="body1" color="secondary">
					{strings.auth_classicSignIn}
				</Typography>
			</Box>
		</>
	);
}

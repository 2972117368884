import React, { useCallback, useEffect, useRef } from "react";
import { Box, Tooltip } from "@material-ui/core";
import { EAttachmentType, EFileType, AttachmentFullFragment, ECampaignType } from "@espresso/protocol";
import strings from "../../helpers/strings";
import { FieldError, Controller, UseFormMethods } from "react-hook-form";
import {
	campaignTypeSwitchItems,
	SwitchBar,
	StandartTextInputController,
	IBaseSignUpState,
	Gallery,
	InfoBox,
} from "../../components";
import _ from "lodash";
import useAppContext from "../../contexts/AppContext";
import { TExpressRegisterFormData } from ".";
import { INPUT_MAX_LENGTH_APP_NAME } from "../../helpers/constants";
import { validateAttachments } from "../../helpers/request";

export type TExpressFormAppInfoData = {
	appName: string;
	attachments?: AttachmentFullFragment[];
	campaignType: ECampaignType;
};

export interface IExpressFormAppInfoProps {
	signUpState: IBaseSignUpState;
	form: UseFormMethods<TExpressRegisterFormData>;
	onLoadingAttachmentsChange?: (loading: boolean) => void;
	onProcessingTasksVideo?: (loading: boolean) => void;
}

export const ExpressFormAppInfo = (props: IExpressFormAppInfoProps) => {
	const { form, signUpState, onLoadingAttachmentsChange, onProcessingTasksVideo } = props;

	const { sharedConfig } = useAppContext();
	const attachmentsProcessingRef = useRef<string[]>();

	const { errors, control } = form;

	const appNameError = errors.appName?.message;
	const attachmentsError = ((errors.attachments as unknown) as FieldError | undefined)?.message;
	const formState = control.formStateRef.current;

	const isPublicGame = form.watch("campaignType") === ECampaignType.Cpi;

	const changeBarItem = (items: ECampaignType[]) => {
		control.setValue("campaignType", items[0], { shouldDirty: true });
	};

	useEffect(() => {
		const attachmentsValue = form.getValues("attachments");

		if (!attachmentsError || attachmentsValue?.length || formState.isSubmitted) {
			return;
		}
		form.clearErrors("attachments");
	}, [attachmentsError, formState.isSubmitted, form]);

	const onUpdateAttachments = useCallback(
		(newAttachments: AttachmentFullFragment[]) =>
			control.setValue("attachments", newAttachments, { shouldValidate: true, shouldDirty: true }),
		[control],
	);

	const onUpdateCropIds = useCallback(
		(ids?: string[]) => {
			attachmentsProcessingRef.current = ids;

			if (control.isFormDirty("attachments") || !_.isEmpty(ids)) {
				control.trigger("attachments");
			}
		},
		[control],
	);

	return (
		<div className="express-register-form">
			<div className="request-type-container">
				<Controller
					name="campaignType"
					control={control}
					render={({ value }) => (
						<SwitchBar
							items={campaignTypeSwitchItems}
							onItemsChange={changeBarItem}
							selectedItems={[value]}
							margin="normal"
							buttonColor="secondary"
						/>
					)}
				/>
				<Tooltip
					title={
						<>
							<span>{strings.request_expressTestHelp0}</span>
							<Box marginTop={1}>
								<span>{strings.request_expressTestHelp1}</span>
							</Box>
						</>
					}
					placement="top"
				>
					<i className="icon icon-info-outline" />
				</Tooltip>
			</div>
			<StandartTextInputController
				required
				className={isPublicGame ? "hidden" : "info-box-height"}
				type={isPublicGame ? "hidden" : undefined}
				hasScrollByAllow={!!appNameError}
				name="appName"
				control={control}
				rules={{
					required: !isPublicGame ? strings.required : undefined,
				}}
				inputProps={{
					maxLength: INPUT_MAX_LENGTH_APP_NAME,
				}}
				errorMessage={errors.appName?.message}
				label={strings.request_appName}
				placeholder={strings.request_appNamePlaceholder}
			/>
			<InfoBox
				className={!isPublicGame ? "hidden" : undefined}
				icon="attention"
				color="info"
				localeKeys={["request_expressCpiInfo0", "request_expressCpiInfo1"]}
			/>
			<Controller
				name="attachments"
				control={control}
				rules={{
					required: strings.required,
					validate: (attachments) => validateAttachments(attachments, attachmentsProcessingRef.current),
				}}
				render={() => (
					<Box className="MuiFormControl-marginNormal">
						<Gallery
							required
							verticalItems
							hasDelete
							attachmentType={EAttachmentType.Request}
							fileType={EFileType.Video}
							maxItems={sharedConfig.request.maxVideos}
							acceptedFiles={["video/mp4", "video/x-matroska", "video/quicktime"]}
							onUpdateAttachments={onUpdateAttachments}
							onUpdateCropIds={onUpdateCropIds}
							onLoadingAttachmentsChange={onLoadingAttachmentsChange}
							onProcessingTasksVideo={onProcessingTasksVideo}
							additionalRequirementsText="request_videoRequirements"
							addFileTip="request_addVideo"
							label="request_videoLabel"
							errorMessage={attachmentsError}
						/>
					</Box>
				)}
			/>
			<Box className={signUpState.error ? "error-text" : undefined}>
				{signUpState.localeKey ? strings[signUpState.localeKey] : ""}
			</Box>
		</div>
	);
};

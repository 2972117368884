import React from "react";
import { Box } from "@material-ui/core";
import { HelpPage } from "./HelpPage";
import { THelpTopic } from "./types";

export const TopicPage = (props: { topic: THelpTopic }) => {
	const { topic } = props;

	return (
		<HelpPage titleKey={topic.titleKey}>
			<Box className="help-topic">
				<topic.Content />
			</Box>
		</HelpPage>
	);
};

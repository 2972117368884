import { ECampaignType, ERequestStatus, RequestFullFragment } from "@espresso/protocol";
import useAppContext from "contexts/AppContext";
import { TSdkWizardStepName } from "views/RequestView/CreateRequest/CreateRequestFormInfoFacebook/SdkWizard";

type TRequestCreationActions = "skip_fb_app_id_help" | "show_fb_app_id_help";

/**
 * Зарегистрировать трекер по-умолчанию
 * @param trackerId Идентификатор ресурса Google Analytics
 */
const initialize = (trackerId: string) => {
	if (trackerId.length > 0) {
		gtag("config", trackerId);
	}
};

const getPersonalizedEvents = (espresso_user_id?: string) => {
	return {
		/**
		 * Начало оформления заявки
		 * @param test_type Тип теста в заявке
		 * @param creation_method Способ создания заявки (обычный | экспресс)
		 */
		createRequestOfType: (test_type?: ECampaignType | null, creation_method: "regular" | "express" = "regular") => {
			if (!test_type) {
				return;
			}
			gtag("event", "request_creation", { test_type, espresso_user_id, creation_method });
		},
		/**
		 * Отправка заявки на проверку
		 * @param request Заявка
		 */
		sendRequestToManager: (request?: Pick<RequestFullFragment, "CampaignType" | "Id"> | null) => {
			if (!request?.CampaignType) {
				return;
			}
			gtag("event", "request_sent_to_manager", {
				request_id: request.Id,
				test_type: request.CampaignType,
				espresso_user_id,
			});
		},
		/**
		 *
		 * Продвижение в процессе создания заявки
		 * @param request Заявка
		 * @param stepStatus Статус текущего шага процесса создания заявки
		 * @param newStatus Статус следующего шага процесса создания заявки
		 */
		advanceRequestCreation: (
			request: Pick<RequestFullFragment, "CampaignType" | "Status" | "Id"> | null | undefined,
			stepStatus: ERequestStatus,
			newStatus: ERequestStatus,
		) => {
			if (!request) {
				return;
			}
			if (request.Status === stepStatus) {
				gtag("event", "request_creation_advance", {
					request_id: request.Id,
					test_type: request.CampaignType,
					status: newStatus,
					espresso_user_id,
				});
			}
		},
		/**
		 * Совершение определенного действия в процессе создания заявки
		 * @param request Заявка
		 * @param action Название совершенного действия
		 */
		completeRequestCreationAction: (
			request: Pick<RequestFullFragment, "CampaignType" | "Id"> | null | undefined,
			action: TRequestCreationActions,
		) => {
			if (!request) {
				return;
			}
			gtag("event", "request_creation_action_completed", {
				request_id: request.Id,
				test_type: request.CampaignType,
				action,
				espresso_user_id,
			});
		},
		/**
		 * Успешное завершение действия в шаге мастера по подключению SDK
		 * @param request Заявка
		 * @param wizardStepName Название шага, в котором было успешно совершено действие
		 */
		completeSdkWizardAction: (
			request: Pick<RequestFullFragment, "CampaignType" | "Id"> | null | undefined,
			wizardStepName: TSdkWizardStepName,
		) => {
			if (!request) {
				return;
			}
			gtag("event", "sdk_wizard_action_completed", {
				request_id: request.Id,
				test_type: request.CampaignType,
				step: wizardStepName,
				espresso_user_id,
			});
		},
		/**
		 * Успешный вход в систему
		 * @param method Метод входа
		 */
		login: (method: "social" | "e-mail") => {
			gtag("event", "login", { method });
		},
		/**
		 * Успешная регистрация в системе
		 * @param method Метод регистрации
		 */
		signup: (method: "social" | "e-mail") => {
			gtag("event", "sign_up", { method });
		},
	};
};

export const useAnalytics = () => {
	const { login } = useAppContext();

	/**
	 * События для Google Analytics
	 */
	const analyticsEvents = getPersonalizedEvents(login?.Id);
	return { initialize, analyticsEvents };
};

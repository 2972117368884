import React, { useEffect } from "react";
import {
	booleanSwitchItems,
	FullScreenPlaceholder,
	platformSwitchItems,
	StandartTextField,
	StandartView,
	SwitchBar,
	LanguagePicker,
	StandartButton,
} from "../../components";
import { Box, Grid, Typography, Paper } from "@material-ui/core";
import {
	EDeveloperPlatform,
	ELoginRole,
	useGetMyDeveloperLazyQuery,
	useGetOneDeveloperLazyQuery,
} from "@espresso/protocol";
import useAppContext from "../../contexts/AppContext";
import { useParams, Redirect } from "react-router-dom";
import strings from "../../helpers/strings";
import withAuth from "../../helpers/withAuth";
import { useLoadingSignal } from "contexts/LoadingContext";

interface IDeveloperParams {
	developerId: string;
}

export const DeveloperView = withAuth(() => {
	const params = useParams<IDeveloperParams>();
	const { login } = useAppContext();

	const [getMyDeveloper, { data: dataMyDeveloper, loading: loadingMyDeveloper }] = useGetMyDeveloperLazyQuery();
	useLoadingSignal(loadingMyDeveloper);

	const [getDeveloper, { data: dataDeveloper, loading: loadingDeveloper }] = useGetOneDeveloperLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	useLoadingSignal(loadingDeveloper);

	useEffect(() => {
		if (params.developerId) {
			getDeveloper({ variables: { id: params.developerId } });
			return;
		}
		if (login?.Role === ELoginRole.User) {
			getMyDeveloper();
		}
	}, [getDeveloper, getMyDeveloper, login, params.developerId]);

	const developer = dataMyDeveloper?.getMyDeveloper || dataDeveloper?.getOneDeveloper;

	if (!login || (!developer && (loadingMyDeveloper || loadingDeveloper))) {
		return (
			<StandartView disableNotifications title="">
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="developer_emptyTitle" messageKey="developer_emptyMessage" />
				</Grid>
			</StandartView>
		);
	}

	if (login.Role === ELoginRole.User) {
		return <Redirect to="/developer/edit" />;
	}

	const portfolioUrl = getUrlSafe(developer?.Portfolio);

	return (
		<StandartView disableNotifications title="">
			<Box className="upsert-developer-form">
				<Grid container spacing={5}>
					<Grid item md={6} sm={12}>
						<LanguagePicker disabled value={developer?.Login.Lang} />
						<Typography className="developer-contacts-title" variant="h5">
							{strings.developer_contactsDeveloper}
						</Typography>
						<StandartTextField label={strings.developer_contactNameLabel} value={developer?.ContactName} />
						<StandartTextField
							label={strings.developer_contactSurnameLabel}
							value={developer?.ContactSurname}
						/>
						<StandartTextField
							type="email"
							label={strings.developer_contactEmailLabel}
							value={developer?.ContactEmail}
						/>
						<Typography className="inner-title" variant="h6">
							{strings.messanger}
						</Typography>
						<StandartTextField
							type="telegram"
							label={strings.developer_telegramId}
							value={developer?.TelegramId}
						/>
						<StandartTextField
							type="whatsapp"
							label={strings.developer_whatsAppId}
							value={developer?.WhatsAppId}
						/>
						<StandartTextField type="skype" label={strings.developer_skypeId} value={developer?.SkypeId} />
					</Grid>
					<Grid item md={6} sm={12}>
						<Typography variant="h5">{strings.developer_contactsTeam}</Typography>
						<StandartTextField label={strings.developer_studioNameLabel} value={developer?.TeamName} />
						<StandartTextField label={strings.developer_countryLabel} value={developer?.TeamLocation} />
						{portfolioUrl ? (
							<StandartTextField
								label={strings.developer_portfolioLinkLabel}
								urlString={portfolioUrl?.toString()}
								value={portfolioUrl?.toString()}
							/>
						) : (
							<StandartTextField
								label={strings.developer_portfolioLinkLabel}
								value={developer?.Portfolio}
							/>
						)}
						<StandartTextField label={strings.developer_technologiesLabel} value={developer?.Engine} />
						<StandartTextField
							label={strings.developer_teamSizeLabel}
							value={developer?.TeamSize.toString()}
						/>
						<SwitchBar
							disabled
							selectedItems={[developer?.PrevPublishers]}
							items={booleanSwitchItems}
							labelKey="developer_expPublisherLabel"
							buttonColor="secondary"
						/>
						<SwitchBar
							disabled
							selectedItems={[developer?.PrevSdk]}
							items={booleanSwitchItems}
							labelKey="developer_expSdkLabel"
							buttonColor="secondary"
						/>
						<SwitchBar
							disabled
							selectedItems={[developer?.Platform ?? EDeveloperPlatform.Android]}
							items={platformSwitchItems}
							labelKey="developer_platformLabel"
							buttonColor="secondary"
						/>
					</Grid>
					<Grid item xs={12} className="action-grid-root">
						<Paper className="action-root">
							<Box className="info-root">
								<i className="icon icon-request-list" />
								<Typography variant="subtitle2">{strings.request_allDeveloperRequests}</Typography>
							</Box>
							<a href={`/requests-${params.developerId}`} target="_blank" rel="noreferrer">
								<StandartButton variant="contained" color="secondary">
									{strings.developerDashboard_Show}
								</StandartButton>
							</a>
						</Paper>
					</Grid>
				</Grid>
			</Box>
		</StandartView>
	);
});

const getUrlSafe = (string?: string | null): URL | null => {
	if (!string) {
		return null;
	}
	try {
		return new URL(string);
	} catch (error) {
		return null;
	}
};

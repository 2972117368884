import React from "react";
import classNames from "classnames";
import { AppBar, Box, Toolbar, Typography } from "@material-ui/core";
import moment from "moment";
import strings from "../../../helpers/strings";
import { StandartButton } from "..";

export interface IFooterProps {
	theme?: "dark" | "white" | "transparent";
	big?: boolean;
	className?: string;
	hideMenu?: boolean;
}

export default function Footer(props: React.PropsWithChildren<IFooterProps>) {
	const { children, theme = "transparent", big, className } = props;

	const footerClasses = classNames(
		"footer",
		{
			[theme]: theme !== "transparent",
			big: big || children !== undefined,
		},
		className,
	);

	return (
		<AppBar position="relative" color="transparent" component="footer" elevation={0} className={footerClasses}>
			<Toolbar className="container" disableGutters>
				{!props.hideMenu && children}
				<Box className="social-copyright-container">
					{!props.hideMenu && (
						<span className="social">
							<a href="https://t.me/espressopub" target="_blank" rel="noreferrer">
								<StandartButton color="telegram" variant="contained" round justIcon>
									<i className="icon icon-telegram" />
								</StandartButton>
							</a>
							<a href="https://fb.me/espressopublishing" target="_blank" rel="noreferrer">
								<StandartButton color="facebook" variant="contained" round justIcon>
									<i className="icon icon-Facebook" />
								</StandartButton>
							</a>
						</span>
					)}
					<Typography color="secondary" variant="body2" className="copyrights">
						{strings.standartFormat(strings.web_copyright, { date: moment().format("YYYY") })}
					</Typography>
				</Box>
			</Toolbar>
		</AppBar>
	);
}

import { TLocale } from "@espresso/shared-config";
import { ERequestStatus, ERequestOrderField, EOrderDirection, EOrderNullPosition, EGenre } from "@espresso/protocol";
import { IRequestsListSortParam } from "../contexts/AppContext";
import { ISwitchBarItem } from "../components";

export const StatusesColors: {
	[key in ERequestStatus]: { className: string; localeKey: keyof TLocale; icon: string };
} = {
	Finished: {
		className: "requestStatus_FinishedBackgroundColor",
		localeKey: "requestStatus_Finished",
		icon: "completed",
	},
	New: { className: "requestStatus_NewBackgroundColor", localeKey: "requestStatus_New", icon: "pencil" },
	NotValid: {
		className: "requestStatus_NotValidBackgroundColor",
		localeKey: "requestStatus_NotValid",
		icon: "circle",
	},
	Rejected: {
		className: "requestStatus_RejectedBackgroundColor",
		localeKey: "requestStatus_Rejected",
		icon: "value",
	},
	RunFailed: {
		className: "requestStatus_RunFailedBackgroundColor",
		localeKey: "requestStatus_RunFailed",
		icon: "cancel",
	},
	Running: {
		className: "requestStatus_RunningBackgroundColor",
		localeKey: "requestStatus_Running",
		icon: "testing",
	},
	CampaignFinished: {
		className: "requestStatus_CampaignFinishedBackgroundColor",
		localeKey: "requestStatus_CampaignFinished",
		icon: "stoped",
	},
	Valid: {
		className: "requestStatus_ValidBackgroundColor",
		localeKey: "requestStatus_Valid",
		icon: "circle",
	},
	Validation: {
		className: "requestStatus_ValidationBackgroundColor",
		localeKey: "requestStatus_Validation",
		icon: "check",
	},
	Initial: {
		className: "requestStatus_InitialBackgroundColor",
		localeKey: "requestStatus_Initial",
		icon: "new",
	},
	Private: {
		className: "requestStatus_NewBackgroundColor",
		localeKey: "requestStatus_Private",
		icon: "pencil",
	},
	SetSDK: {
		className: "requestStatus_SetSDKBackgroundColor",
		localeKey: "requestStatus_SetSDK",
		icon: "connection",
	},
	InfoFacebook: {
		className: "requestStatus_InfoFacebookBackgroundColor",
		localeKey: "requestStatus_InfoFacebook",
		icon: "settingsdk1",
	},
	PreValidation: {
		className: "requestStatus_PreValidationBackgroundColor",
		localeKey: "requestStatus_PreValidation",
		icon: "training",
	},
	Returned: {
		className: "requestStatus_ReturnedBackgroundColor",
		localeKey: "requestStatus_Returned",
		icon: "return",
	},
};

export const stepsConfig: { [key: string]: { labelkey: keyof TLocale; statuses: ERequestStatus[] } } = {
	Initial: { labelkey: "request_userStepInitial", statuses: [ERequestStatus.Initial] },
	New: { labelkey: "request_userStepNew", statuses: [ERequestStatus.New] },
	Private: { labelkey: "request_userStepNew", statuses: [ERequestStatus.Private] },
	SetSDK: { labelkey: "request_userStepSetSDK", statuses: [ERequestStatus.SetSDK] },
	InfoFacebook: {
		labelkey: "request_userStepInfoFacebook",
		statuses: [ERequestStatus.InfoFacebook],
	},
	PreValidation: {
		labelkey: "requestStatus_PreValidation",
		statuses: [ERequestStatus.PreValidation, ERequestStatus.Returned],
	},
	Check: {
		labelkey: "request_stepCheck",
		statuses: [ERequestStatus.Validation, ERequestStatus.Valid, ERequestStatus.NotValid],
	},
	Test: { labelkey: "request_stepTest", statuses: [ERequestStatus.Running] },
	TestFinished: { labelkey: "request_stepTestFinished", statuses: [ERequestStatus.CampaignFinished] },
	Result: {
		labelkey: "request_stepResult",
		statuses: [ERequestStatus.Finished, ERequestStatus.Rejected, ERequestStatus.RunFailed],
	},
};

export const ctrRequestSteps = [
	stepsConfig.Initial,
	stepsConfig.Private,
	stepsConfig.PreValidation,
	stepsConfig.Check,
	stepsConfig.Test,
	stepsConfig.TestFinished,
	stepsConfig.Result,
];

export const cpiRequestSteps = [
	stepsConfig.Initial,
	stepsConfig.InfoFacebook,
	stepsConfig.New,
	stepsConfig.PreValidation,
	stepsConfig.Check,
	stepsConfig.Test,
	stepsConfig.TestFinished,
	stepsConfig.Result,
];

export const r1RequestSteps = [
	stepsConfig.Initial,
	stepsConfig.SetSDK,
	stepsConfig.InfoFacebook,
	stepsConfig.New,
	stepsConfig.PreValidation,
	stepsConfig.Check,
	stepsConfig.Test,
	stepsConfig.TestFinished,
	stepsConfig.Result,
];

export const requestSortParamDefault: IRequestsListSortParam = {
	field: ERequestOrderField.CurrentStatusAt,
	order: EOrderDirection.DESC,
	nullsPosition: EOrderNullPosition.LAST,
};

export const REQUEST_ITEMS_PER_PAGE = 50;

export const INPUT_MAX_LENGTH_APP_NAME = 50;

export const PARALLAX_LANDING_MEDIA_CONTENT = {
	image: "/images/xpresso.jpg",
	video: {
		h264: "/videos/xpresso-h264.m4v",
		h265: "/videos/xpresso-h265.m4v",
		vp8: "/videos/xpresso-vp8.webm",
	},
};

export const GENRE_LIST_ITEMS: {
	[key in EGenre]: ISwitchBarItem<EGenre> & {
		label: keyof TLocale;
		description?: keyof TLocale;
		typicalGame?: string;
		typicalGameUrl?: string;
		typicalStudio?: string;
	};
} = {
	IO: {
		label: "genre_IO",
		value: EGenre.IO,
		description: "genre_IODescription",
		typicalGame: "Agar.io",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.miniclip.agar.io",
		typicalStudio: "Miniclip",
	},
	Dexterity: {
		label: "genre_Dexterity",
		value: EGenre.Dexterity,
		description: "genre_DexterityDescription",
		typicalGame: "Timberman",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.dm.timber",
		typicalStudio: "Digital Melody",
	},
	Idle: {
		label: "genre_Idle",
		value: EGenre.Idle,
		description: "genre_IdleDescription",
		typicalGame: "Adventure Capitalist",
		typicalGameUrl:
			"https://play.google.com/store/apps/details?id=com.kongregate.mobile.adventurecapitalist.google",
		typicalStudio: "Hyper Hippo",
	},
	Merge: {
		label: "genre_Merge",
		value: EGenre.Merge,
		description: "genre_MergeDescription",
		typicalGame: "Merge Dogs",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.zepni.dog",
		typicalStudio: "Zepni Ltd",
	},
	Puzzle: {
		label: "genre_Puzzle",
		value: EGenre.Puzzle,
		description: "genre_PuzzleDescription",
		typicalGame: "2048",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.ketchapp.play2048",
		typicalStudio: "Ketchapp",
	},
	RisingFalling: {
		label: "genre_RisingFalling",
		value: EGenre.RisingFalling,
		description: "genre_RisingFallingDescription",
		typicalGame: "Helix Jump",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.h8games.helixjump",
		typicalStudio: "Voodoo",
	},
	Stacking: {
		label: "genre_Stacking",
		value: EGenre.Stacking,
		description: "genre_StackingDescription",
		typicalGame: "The Tower",
		typicalGameUrl: "https://apps.apple.com/us/app/the-tower/id905408749",
		typicalStudio: "Ketchapp",
	},
	Swerve: {
		label: "genre_Swerve",
		value: EGenre.Swerve,
		description: "genre_SwerveDescription",
		typicalGame: "Aquapark.io",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.cassette.aquapark",
		typicalStudio: "Voodoo",
	},
	TapTiming: {
		label: "genre_TapTiming",
		value: EGenre.TapTiming,
		description: "genre_TapTimingDescription",
		typicalGame: "Baseball Boy!",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.itchmedia.ta3",
		typicalStudio: "Voodoo",
	},
	Turning: {
		label: "genre_Turning",
		value: EGenre.Turning,
		description: "genre_TurningDescription",
		typicalGame: "Skiddy Car",
		typicalGameUrl: "https://play.google.com/store/apps/details?id=com.kwalee.skiddycar",
		typicalStudio: "Kwalee",
	},
	Other: { label: "genre_Other", value: EGenre.Other, description: "genre_OtherDescription" },
};

import { TLocale } from "@espresso/shared-config";
import { FormControlProps } from "@material-ui/core";
import { TColor } from "../../types";

export type TSwitchBarProps<ItemT> = FormControlProps & {
	labelKey?: keyof TLocale;
	onItemsChange?: (items: ItemT[]) => void;
	items: ISwitchBarItem<ItemT>[];
	multiSelect?: boolean;
	selectedIndex?: number[];
	selectedItems?: ItemT[];
	errorKey?: keyof TLocale;
	buttonColor?: TColor;
};

export interface ISwitchBarItem<T> {
	label?: keyof TLocale;
	value: T;
	disabled?: boolean;
	label_params?: { [key: string]: string };
	icon?: string;
	tooltipKey?: keyof TLocale;
}

export enum EMessagerType {
	Skype = "Skype",
	Telegram = "Telegram",
	WhatsApp = "WhatsApp",
}

import React from "react";
import { useParams, Redirect } from "react-router-dom";
import { Button, Typography } from "@material-ui/core";
import moment from "moment";

import { ELang, useGetPublicRequestInfoQuery } from "@espresso/protocol";
import strings from "helpers/strings";
import useAppContext from "contexts/AppContext";

const GOOGLE_PLAY_ESPRESSO_URL = "https://play.google.com/store/apps/dev?id=7355355068514312133";
const APP_STORE_ESPRESSO_URL = "https://apps.apple.com/us/developer/dromaxco-solutions-ltd/id1570631540";

export const CtrLandingView = () => {
	const { lang } = useAppContext();
	const { requestId } = useParams<{ requestId: string }>();
	const { data, error } = useGetPublicRequestInfoQuery({ variables: { id: requestId } });

	if (data?.getPublicRequestInfo === null || error) {
		return <Redirect to="/not-found" />;
	}
	const space = lang === ELang.Ch ? "" : " ";

	const gameTitleEl = data?.getPublicRequestInfo?.AppName ? (
		<>
			{" "}
			<q>{data.getPublicRequestInfo.AppName}</q>{" "}
		</>
	) : (
		space
	);

	return (
		<div className="game-landing">
			<div className="bg" />
			<main>
				<div className="game-card">
					<div className="content">
						<div className="game-icon">
							<img
								src={data?.getPublicRequestInfo?.IconPreviewUrl ?? "/images/game-icon-default.png"}
								alt="Game Icon"
							/>
						</div>
						<div className="text">
							<Typography variant="h3">
								{strings.formatString(strings.web_ctrLandingGameCardTitle, gameTitleEl)}
							</Typography>
							<Typography variant="h6">{strings.web_ctrLandingTryOurHits}</Typography>
						</div>
					</div>
					<div className="actions">
						<Button
							startIcon={<i className="icon icon-apple" />}
							disableElevation
							href={APP_STORE_ESPRESSO_URL}
						>
							App Store
						</Button>
						<Button
							startIcon={<i className="icon icon-google-play" />}
							disableElevation
							href={GOOGLE_PLAY_ESPRESSO_URL}
						>
							Google Play
						</Button>
					</div>
					<img className="rings" src="/images/rings-2.png" alt="" />
				</div>
			</main>
			<footer>
				<Typography className="copyrights" variant="body2">
					{strings.standartFormat(strings.web_copyright, { date: moment().format("YYYY") })}
				</Typography>
			</footer>
		</div>
	);
};

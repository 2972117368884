import React from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import strings from "../../../../helpers/strings";
import { FullScreenPlaceholder, GoBackButton } from "../../../../components";
import { TCreateRequestFormProps } from "../types";
import { ERequestStatus } from "@espresso/protocol";
import { CreateRequestButtons } from "../CreateRequestButtons";
import { RequestIdInfo } from "../../RequestIdInfo";
import { StatsMethodOptions } from "./StatsMethodOptions";
import { RequestInfo } from "../../RequestInfo";

export const CreateRequestFormSetSdk = (props: TCreateRequestFormProps) => {
	const { request, setCurrentStepStatus, handleClickRemove, handleRestoreRequest } = props;

	const back = () => setCurrentStepStatus(ERequestStatus.Initial);

	if (!request) {
		return (
			<>
				<Grid item xs={8}>
					<FullScreenPlaceholder titleKey="request_emptyTitle" messageKey="request_emptyMessage" />
				</Grid>
			</>
		);
	}

	return (
		<Box className="content">
			<Grid container className="facebook-info-container">
				<Grid item xs={12} className="requestid-grid-root">
					<RequestIdInfo request={request} />
				</Grid>
				<Grid item xs={12}>
					<Box className="title-container">
						<GoBackButton to="/profile" />
						<Typography variant="h5">{strings.request_createRequestSetSDKTitle}</Typography>
					</Box>
					{request?.Status === ERequestStatus.Returned && !request.Deleted && (
						<RequestInfo request={request} />
					)}
				</Grid>
				<StatsMethodOptions {...props} />
				<CreateRequestButtons
					showNext={false}
					request={request}
					currentStepIndex={props.currentStepIndex}
					handleBackToStep={back}
					handleClickRemove={handleClickRemove}
					handleRestoreRequest={handleRestoreRequest}
				/>
			</Grid>
		</Box>
	);
};

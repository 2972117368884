import React, { useCallback, useEffect, useMemo, useState, useRef } from "react";
import {
	AppLink,
	FullScreenPlaceholder,
	StandartButton,
	StandartTextCopyButton,
	StandartTextField,
	StandartTextInputController,
	StandartView,
	Gallery,
	GoBackButton,
	GenreList,
} from "../../components";
import strings from "../../helpers/strings";
import {
	defaultUpsertRequestInput,
	EAttachmentType,
	ECheckStatus,
	EDeveloperPlatform,
	EFileType,
	ELoginRole,
	ERequestStatus,
	ETaskStatus,
	GetManyRequestsDocument,
	GetOneRequestDocument,
	useCreateFbCampaignMutation,
	useGetOneRequestQuery,
	useUpsertRequestMutation,
	AttachmentFullFragment,
	ECampaignType,
	useGetApiUsageLazyQuery,
	GetApiUsageDocument,
	useCopyRequestMutation,
	UpsertRequestInput,
	EGenre,
	useGetFbAdAccountInfoLazyQuery,
} from "@espresso/protocol";
import { Prompt, useHistory, useParams } from "react-router-dom";
import {
	Box,
	CircularProgress,
	Dialog,
	DialogContent,
	DialogTitle,
	Grid,
	Hidden,
	IconButton,
	Tooltip,
	Typography,
} from "@material-ui/core";
import { useStandartForm } from "../../helpers/useStandartForm";
import { TUpsertRequestFormData } from "./types";
import useAppContext from "../../contexts/AppContext";
import { Controller, FieldError, FormProvider } from "react-hook-form";
import { mergeKeepShape, TLocale, generateTestCampaignName } from "@espresso/shared-config";
import { RequestStepper } from "./RequestStepper";
import { RequestActions } from "./RequestActions";
import { MetricsTable } from "./MetricsTable";
import { RequestCheckStatus } from "./RequestCheckStatus";
import moment from "moment";
import { ResetCampaign } from "./ResetCampaign";
import withAuth from "../../helpers/withAuth";
import { GENRE_LIST_ITEMS, REQUEST_ITEMS_PER_PAGE } from "../../helpers/constants";
import { RequestInfo } from "./RequestInfo";
import { OtherRequestsList } from "./OtherRequestsList";
import { RequestAdAccountUsage } from "./RequestAdAccountUsage";
import { formatUrlApp } from "../../helpers/formatUrlApp";
import _ from "lodash";
import { formatDeveloperName } from "../../helpers/formatDeveloperName";
import { RequestIdInfo } from "./RequestIdInfo";
import { VideoMetricsBlock } from "./VideoMetricsBlock";
import { analyticsStatus, requestTypeConfig, validateAttachments } from "../../helpers/request";
import { RobustaStatsBlock } from "./RobustaStatsBlock";
import { useLoadingSignal } from "contexts/LoadingContext";

export interface IRequestParams {
	requestId: string;
}

const campaignTypeStrings: { [key in ECampaignType]: keyof TLocale } = {
	Cpi: "request_metricCPI",
	Ctr: "request_metricCTR",
	R1: "request_metricR1",
};
interface IRequestViewProps {
	setCurrentStepStatus: React.Dispatch<React.SetStateAction<ERequestStatus | undefined>>;
}
export const RequestView = withAuth(
	(props: IRequestViewProps) => {
		const params = useParams<IRequestParams>();
		const { login, sharedConfig, showExitPrompt, setShowExitPrompt } = useAppContext();
		const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
		const history = useHistory();

		const attachmentsProcessingRef = useRef<string[]>();

		const [loadingTasksVideo, setLoadingTasksVideo] = useState(false);
		const [loadingAttachment, setLoadingAttachment] = useState(false);
		const [copyRequestOpened, setCopyRequestOpened] = useState(false);
		const [disabledAdAccount, setDisabledAdAccount] = useState(false);
		const [copyRequestInput, setCopyRequestInput] = useState<UpsertRequestInput>({});

		const { data, loading, refetch } = useGetOneRequestQuery({ variables: { id: params.requestId } });
		const [getFbAdAccountInfo, { data: fbAdAccountsData }] = useGetFbAdAccountInfoLazyQuery({
			fetchPolicy: "cache-first",
		});
		const request = data?.getOneRequest;

		const [getApiUsage, { data: apiUsageData }] = useGetApiUsageLazyQuery({
			fetchPolicy: "cache-and-network",
		});

		const [upsertRequest, { loading: upserting }] = useUpsertRequestMutation({
			refetchQueries: [
				{ query: GetOneRequestDocument, variables: { id: params.requestId } },
				{
					query: GetManyRequestsDocument,
					variables: { input: { Pagination: { Limit: REQUEST_ITEMS_PER_PAGE, Offset: 0 } } },
				},
			],
		});
		const [copyRequest, { loading: copyingRequest }] = useCopyRequestMutation();

		const [createFbCampaign, { loading: creating }] = useCreateFbCampaignMutation();

		const isLoading = loading || upserting || copyingRequest;
		useLoadingSignal(isLoading);

		const generatedCampaignName = useMemo(() => {
			if (!request) {
				return "";
			}

			return generateTestCampaignName(
				request.Id,
				request.AppName || "UnnamedGame",
				request.CampaignType,
				(request.AppStoreUrl && EDeveloperPlatform.Ios) ||
					(request.GooglePlayUrl && EDeveloperPlatform.Android) ||
					"PlatformNotSelected",
			);
		}, [request]);

		const form = useStandartForm<TUpsertRequestFormData>({
			mode: "onBlur",
			reValidateMode: "onChange",
			defaultValues: {
				Status: request?.Status || ERequestStatus.New,
				CampaignName: request?.CampaignName || generatedCampaignName || "",
				FacebookToken: request?.FacebookToken || "",
				FacebookId: request?.FacebookId || "",
				AdvertId: request?.AdvertId || "",
				Comment: request?.Comment || "",
				Geos: request?.Geos || "",
				Genre: request?.Genre || EGenre.Other,
				InternalComment: request?.InternalComment || "",
				Attachments: [],
			},
		});
		const { control, errors } = form;

		const allCheckValid = useCallback(() => {
			if (!request) {
				return false;
			}
			const metric = request.Metrics.find((m) => !m.AttachmentId);
			if (request.AppStoreUrl || request.GooglePlayUrl) {
				return (
					request.AdvertIdStatus === ECheckStatus.Valid &&
					request.FacebookIdStatus === ECheckStatus.Valid &&
					request.FacebookTokenStatus === ECheckStatus.Valid &&
					!!metric &&
					!!metric.FBCampaignId?.length
				);
			} else {
				return !!metric && !!metric.FBCampaignId?.length;
			}
		}, [request]);

		const { urlText, urlString } = useMemo(() => formatUrlApp(request), [request]);
		const developerNameText = useMemo(() => formatDeveloperName(request), [request]);

		useEffect(() => {
			setShowExitPrompt(loadingAttachment);
		}, [loadingAttachment, setShowExitPrompt]);

		useEffect(() => {
			if (!isManagerOrPublisher || !request || request.Status !== ERequestStatus.Validation || apiUsageData) {
				return;
			}

			const metric = request.Metrics.find((m) => !m.AttachmentId);

			if (metric?.FBCampaignId) {
				return;
			}
			getApiUsage({
				variables: {
					input: {
						AdvertId: request.AdvertId,
					},
				},
			});
		}, [isManagerOrPublisher, request, apiUsageData, getApiUsage]);

		useEffect(() => {
			if (!request) {
				return;
			}
			form.setValue("Status", request.Status);
			form.setValue("CampaignName", request.CampaignName || generatedCampaignName);
			form.setValue("FacebookToken", request.FacebookToken || "");
			form.setValue("FacebookId", request.FacebookId || "");
			form.setValue("AdvertId", request.AdvertId || "");
			form.setValue("Comment", request.Comment || "");
			form.trigger("Attachments");
			const typeConfig = requestTypeConfig.find((conf) => request.CampaignType === conf.input.CampaignType);
			setCopyRequestInput({ ...typeConfig?.input, Id: request.Id, CampaignType: request.CampaignType });
			// eslint-disable-next-line react-hooks/exhaustive-deps
		}, [generatedCampaignName, request, login]);

		useEffect(() => {
			if (isManagerOrPublisher) {
				getFbAdAccountInfo();
			}
		}, [getFbAdAccountInfo, isManagerOrPublisher]);

		useEffect(() => {
			if (fbAdAccountsData?.getFbAdAccountInfo.length) {
				const disabledAcc = fbAdAccountsData.getFbAdAccountInfo.find(
					(acc) => acc.Id === request?.AdvertId && !acc.Enabled,
				);
				if (!disabledAcc) {
					setDisabledAdAccount(false);
					return;
				}
				setDisabledAdAccount(true);
			}
		}, [fbAdAccountsData?.getFbAdAccountInfo, request?.AdvertId]);

		const submit = useCallback(
			async (data: TUpsertRequestFormData) => {
				if (!request) {
					return;
				}
				const upsertRequestInput = mergeKeepShape(defaultUpsertRequestInput, {
					...data,
					CampaignName: data.CampaignName !== generatedCampaignName ? data.CampaignName : undefined,
					Id: request.Id,
				});
				await upsertRequest({
					variables: { data: upsertRequestInput },
				});
				props.setCurrentStepStatus(data.Status);
			},
			[generatedCampaignName, props, request, upsertRequest],
		);
		const createNewRequest = () => {
			history.push("/request-create");
		};

		const copyToNewRequest = async () => {
			if (!request) {
				return;
			}

			const result = await copyRequest({ variables: { input: copyRequestInput } });
			if (result.data?.copyRequest.success && result.data?.copyRequest.data?.Request) {
				history.push(`/request-${result.data.copyRequest.data.Request.Id}/edit`);
			}
			closeCopyRequest();
		};

		const save = (e: React.FormEvent<HTMLFormElement>) => {
			e.preventDefault();

			const data = form.getValues();
			submit(data);
		};

		if (!request) {
			return (
				<StandartView disableNotifications title={strings.request_title}>
					<Grid container className="profile-view">
						<FullScreenPlaceholder titleKey="request_emptyTitle" messageKey="request_emptyMessage" />
					</Grid>
				</StandartView>
			);
		}

		const isPublished = request.AppStoreUrl || request.GooglePlayUrl;
		const isR1Request = request.CampaignType === ECampaignType.R1;
		const metric = request.Metrics.find((m) => !m.AttachmentId);

		const setStatus = (newStatus: ERequestStatus, comment?: string) => {
			form.setValue("Status", newStatus, { shouldDirty: true, shouldValidate: true });
			if (comment?.length) {
				form.setValue("Comment", comment, { shouldDirty: true, shouldValidate: true });
			}

			const data = form.getValues();
			submit(data);
		};

		const createCampaign = () => {
			createFbCampaign({
				variables: { id: request.Id },
				refetchQueries: [
					{ query: GetOneRequestDocument, variables: { id: request.Id } },
					{
						query: GetApiUsageDocument,
						variables: {
							input: {
								AdvertId: request.AdvertId,
							},
						},
					},
				],
			});
		};

		const onUpdateAttachments = (newAttachments: AttachmentFullFragment[]) =>
			form.setValue("Attachments", newAttachments, { shouldValidate: true });

		const onUpdateCropIds = (ids: string[] | undefined) => {
			attachmentsProcessingRef.current = ids;
			if (control.isFormDirty("Attachments") || !_.isEmpty(ids)) {
				form.trigger("Attachments");
			}
		};

		const notFinal =
			request.Status !== ERequestStatus.Finished &&
			request.Status !== ERequestStatus.Rejected &&
			request.Status !== ERequestStatus.RunFailed;
		const advertId = request.AdvertId || request.Publisher.AdvertId || sharedConfig.facebook.advertId;
		const attachmentsError = ((errors.Attachments as unknown) as FieldError | undefined)?.message;
		const isOwner = request.Developer.Id === login?.Developer?.Id;

		let metricsStatusClasses = "icon icon-analytics-acesses valid";
		let metricsStatusKey: keyof TLocale = "request_metricsStatusDone";

		if (advertId && metric) {
			switch (metric.Status) {
				case ETaskStatus.Done:
					if (metric.LastSuccessAt) {
						metricsStatusClasses = "icon icon-analytics-acesses valid";
						metricsStatusKey = "request_metricsStatusDone";
					} else {
						//Костыли для случая, когда еще ни разу не получили данные
						metricsStatusClasses = "icon icon-analytics-statys checking";
						metricsStatusKey = "request_metricsStatusWaiting";
					}
					break;
				case ETaskStatus.Failed:
					metricsStatusClasses = "icon icon-analytics-statys not-valid";
					metricsStatusKey = "request_metricsStatusFailed";
					break;
				case ETaskStatus.Processing:
					metricsStatusClasses = "icon icon-analytics-statys checking";
					metricsStatusKey = "request_metricsStatusProcessing";
					break;
			}
		}

		const removeRequest = async () => {
			if (!request) {
				return;
			}
			await upsertRequest({
				variables: {
					data: { Id: request.Id, Status: request.Status, PublisherId: request.Publisher.Id, Deleted: true },
				},
			});
			history.push("/profile");
		};

		const restoreRequest = async () => {
			if (!request) {
				return;
			}
			await upsertRequest({
				variables: {
					data: { Id: request.Id, Status: request.Status, PublisherId: request.Publisher.Id, Deleted: false },
				},
				refetchQueries: [{ query: GetOneRequestDocument, variables: { id: request.Id } }],
			});
		};

		const appNameText = request?.AppName || "Default App Name";

		const creatingCampaign =
			creating || (metric && !metric.FBCampaignId && metric.Status === ETaskStatus.Processing);
		const showRequestInfo =
			request.Deleted || request.Status !== ERequestStatus.Validation || login?.Role === ELoginRole.User;
		const showMetricsTable = !!metric && request.Status !== ERequestStatus.Validation;

		const createCampaignDisabled =
			request.Deleted ||
			!notFinal ||
			loadingTasksVideo ||
			loadingAttachment ||
			creatingCampaign ||
			!!validateAttachments(form.getValues("Attachments") || [], attachmentsProcessingRef.current) ||
			disabledAdAccount;

		const openCopyRequest = () => setCopyRequestOpened(true);
		const closeCopyRequest = () => setCopyRequestOpened(false);

		const selectedRequestTypeConfig = requestTypeConfig.find(
			(conf) => copyRequestInput.CampaignType === conf.input.CampaignType,
		);

		return (
			<StandartView disableNotifications>
				<FormProvider {...form}>
					<div className="request-view">
						<form className="request-edit" onSubmit={save}>
							<Controller
								name="Status"
								defaultValue={null}
								control={control}
								render={(_props) => <></>}
							/>
							<Controller
								name="Comment"
								defaultValue={null}
								control={control}
								render={(_props) => <></>}
							/>
							<Grid container justify="center">
								<Grid item md={12}>
									<Hidden smDown>
										<RequestStepper request={request} />
									</Hidden>
								</Grid>
								<Grid item xs={12}>
									<RequestIdInfo request={request} />
								</Grid>
								<Grid item xs={12} className="header">
									<Box className="info-root">
										<GoBackButton to="/profile" />
										<Typography title={appNameText} className="text-ellipsis" variant="h5">
											{appNameText}
										</Typography>
									</Box>
									{isManagerOrPublisher &&
										request?.Status === ERequestStatus.Validation &&
										request.Comment && <RequestInfo request={request} />}
								</Grid>
								<Grid className="multi-columns" container direction="row">
									<Grid item md={2} xs={12}>
										<Gallery
											simple
											verticalItems
											readonly={request.Deleted || request.Status !== ERequestStatus.New}
											attachmentType={EAttachmentType.Request}
											fileType={EFileType.Icon}
											objectId={request.Id}
											maxItems={1}
											acceptedFiles={["image/*"]}
											maxFileSize={5242880}
										/>
										<Typography
											className="MuiFormControl-marginNormal MuiInputLabel-shrink"
											variant="body2"
										>
											{strings.request_campaignType}
										</Typography>
										<Typography>{strings[campaignTypeStrings[request.CampaignType]]}</Typography>
										{isManagerOrPublisher && notFinal ? (
											<StandartTextInputController
												disabled={!!request.Deleted}
												control={control}
												name="Geos"
												label={strings.request_Geos}
												margin="normal"
											/>
										) : (
											<>
												<Typography className="MuiFormControl-marginNormal" variant="body2">
													{strings.request_Geos}
												</Typography>
												<Typography>{request.Geos || "---"}</Typography>
											</>
										)}
									</Grid>
									<Grid item md={5} xs={12}>
										{isManagerOrPublisher ? (
											<Controller
												name="Genre"
												control={control}
												render={({ onChange, value }) => (
													<GenreList
														fullWidth
														className="MuiFormControl-marginNormal"
														disabled={!isManagerOrPublisher}
														labelKey="genre_label"
														selectedKeys={[value]}
														onSelectedChange={(v) => onChange(v[0])}
														disableInfoButton
													/>
												)}
											/>
										) : (
											<Box className="MuiFormControl-marginNormal">
												<Typography className="MuiInputLabel-shrink" variant="body2">
													{strings.genre_label}
												</Typography>
												<Typography>
													{strings[GENRE_LIST_ITEMS[request.Genre].label]}
												</Typography>
											</Box>
										)}
										{urlString && (
											<StandartTextField
												label={strings.link}
												value={urlText}
												urlString={urlString}
												margin="none"
											/>
										)}
										<Box className="MuiFormControl-marginNormal">
											<Typography className="MuiInputLabel-shrink">
												{strings.developer_developerNameLabel}
											</Typography>
											<AppLink
												className="url-text"
												to={isOwner ? "/developer/edit" : `/developer-${request.Developer.Id}`}
											>
												<Typography className="status-text" variant="body1" color="inherit">
													{developerNameText}
												</Typography>
											</AppLink>
										</Box>
									</Grid>
									<Grid item md={5} xs={12}>
										{isPublished && (
											<>
												<RequestCheckStatus
													className="MuiFormControl-marginNormal"
													status={request.FacebookIdStatus}
													field="FacebookIdStatus"
												>
													<Typography className="status-text">
														{`${strings.request_facebookAppId} (${request.FacebookId})`}
													</Typography>
												</RequestCheckStatus>
												<RequestCheckStatus
													className="MuiFormControl-marginNormal"
													status={request.AdvertIdStatus}
													field="AdvertIdStatus"
												>
													<Typography className="status-text">
														{strings.request_advertId}
													</Typography>
												</RequestCheckStatus>
												{isR1Request && (
													<RequestCheckStatus
														className="MuiFormControl-marginNormal"
														status={analyticsStatus(request)}
														field="FacebookAnalyticsStatus"
														customExtraData={{ lastStatsDate: request.LastStatsAt }}
													>
														<Typography className="status-text">
															{strings.request_checkAnalyticsAccess}
														</Typography>
													</RequestCheckStatus>
												)}
											</>
										)}
										{isManagerOrPublisher &&
											(!metric?.FBCampaignId ? (
												<Box>
													<Box className="status-row">
														<StandartTextInputController
															control={control}
															name="CampaignName"
															label={strings.request_campaignName}
															disabled={createCampaignDisabled}
														/>
														<Box className="status-container">
															<StandartTextCopyButton
																valueToCopy={form.watch("CampaignName")}
															/>
														</Box>
													</Box>
													<Box className="create-root">
														<StandartButton
															disabled={createCampaignDisabled}
															onClick={createCampaign}
															color="success"
															fullWidth
															endIcon={
																creatingCampaign ? (
																	<CircularProgress color="inherit" size={20} />
																) : undefined
															}
														>
															{strings.request_createCampaign}
														</StandartButton>
														<RequestAdAccountUsage
															fbAccountId={request?.AdvertId}
															fbAccountUsage={apiUsageData?.getApiUsage || 0}
														/>
													</Box>
												</Box>
											) : (
												<Box>
													<Typography className="MuiInputLabel-shrink">
														{strings.request_campaignName}
													</Typography>
													{advertId && metric?.FBCampaignId ? (
														<Box className="status-row">
															<a
																className="url-text"
																href={`https://business.facebook.com/adsmanager/manage/campaigns?act=${advertId}&filter_set=SEARCH_BY_CAMPAIGN_GROUP_ID-STRING%1EEQUAL%1E"${metric.FBCampaignId}"&selected_campaign_ids=${metric.FBCampaignId}`}
																target="_blank"
																rel="noreferrer"
															>
																<i className="icon icon-link" />
																<Typography>
																	{metric.FBCampaignName || metric.FBCampaignId}
																</Typography>
															</a>
															<ResetCampaign request={request} />
														</Box>
													) : (
														<Typography className="status-text">
															{strings.request_campaignUnlinked}
														</Typography>
													)}
													{isManagerOrPublisher && metric?.StartTime && (
														<Box className="MuiFormControl-marginNormal">
															<Typography
																className="MuiInputLabel-shrink"
																variant="body2"
															>
																{strings.request_testStartDateLabel}
															</Typography>
															<Typography>
																{moment(metric.StartTime).format("D MMMM YYYY")}
															</Typography>
														</Box>
													)}
													{isManagerOrPublisher && metric?.StopTime && (
														<Box className="MuiFormControl-marginNormal">
															<Typography
																className="MuiInputLabel-shrink"
																variant="body2"
															>
																{strings.request_testStopDateLabel}
															</Typography>
															<Typography>
																{moment(metric.StopTime).format("D MMMM YYYY")}
															</Typography>
														</Box>
													)}
												</Box>
											))}
									</Grid>
								</Grid>
							</Grid>
							<Grid className="video-gallery" item xs={12}>
								<Controller
									name="Attachments"
									control={control}
									rules={{
										required: strings.required,
										validate: (attachments) =>
											validateAttachments(
												attachments,
												attachmentsProcessingRef.current,
												request.Metrics,
											),
									}}
									render={() => (
										<Box>
											<Gallery
												required
												verticalItems
												disabled={!!request.Deleted}
												disableItemsActions={creating}
												hasDelete={
													isManagerOrPublisher && request.Status === ERequestStatus.Validation
												}
												hasCreateAd={
													isManagerOrPublisher &&
													request.Status === ERequestStatus.Validation &&
													!!advertId &&
													!!metric?.FBCampaignId
												}
												readonly={
													request.Deleted || isManagerOrPublisher
														? request.Status !== ERequestStatus.Validation
														: request.Status !== ERequestStatus.New
												}
												attachmentType={EAttachmentType.Request}
												fileType={EFileType.Video}
												objectId={request.Id}
												maxItems={sharedConfig.request.maxVideos}
												acceptedFiles={["video/mp4", "video/x-matroska", "video/quicktime"]}
												addFileTip="request_addVideo"
												onUpdateAttachments={onUpdateAttachments}
												onUpdateCropIds={onUpdateCropIds}
												onLoadingAttachmentsChange={setLoadingAttachment}
												onProcessingTasksVideo={setLoadingTasksVideo}
												errorMessage={attachmentsError}
												requestMetrics={request.Metrics}
												refetchRequest={refetch}
												request={request}
											/>
										</Box>
									)}
								/>
							</Grid>
							{request.Status !== ERequestStatus.Validation && <VideoMetricsBlock request={request} />}
							{(showRequestInfo || showMetricsTable) && (
								<Grid className="result-block" item xs={12}>
									{showRequestInfo && (
										<>
											<Typography variant="h5">{strings.request_stepResult}</Typography>
											<RequestInfo request={request} />
										</>
									)}
									{showMetricsTable && <MetricsTable request={request} />}
								</Grid>
							)}
							<Box display="flex" flexDirection="column" alignItems="center">
								{request.Status === ERequestStatus.Running && (
									<Typography className="MuiFormControl-marginNormal metrics-info" variant="body2">
										{metric?.LastSuccessAt &&
											strings.standartFormat(strings.request_actualDataInfo, {
												updatedAt: moment(metric.LastSuccessAt).format("HH:mm DD.MM.YYYY"),
											})}
										&nbsp;{strings[metricsStatusKey]}
										<Tooltip
											title={
												!!metric?.FBCampaignId
													? moment(metric.NextUpdateAt).isAfter(moment())
														? strings.standartFormat(strings.request_nextUpdateDataInfo, {
																nextUpdateAt: moment(metric.NextUpdateAt).format(
																	"HH.mm DD.MM.YYYY",
																),
														  })
														: strings.request_nextUpdateNearestTime
													: strings.standartFormat(strings.request_nextUpdateDataInfo, {
															nextUpdateAt: strings.request_nextUpdateUnavailable,
													  })
											}
											placement="top"
										>
											<i className={metricsStatusClasses} />
										</Tooltip>
									</Typography>
								)}
							</Box>
							{request.Status !== ERequestStatus.Validation && isR1Request && (
								<RobustaStatsBlock request={request} />
							)}
							{isManagerOrPublisher && (
								<Grid className="MuiFormControl-marginNormal" item md={12}>
									<Typography variant="h5">{strings.request_internalComment}</Typography>
									<Box className="request-info">
										<StandartTextInputController
											control={control}
											name="InternalComment"
											label={strings.request_internalCommentLabel}
											disabled={!!request.Deleted}
										/>
									</Box>
								</Grid>
							)}
							<OtherRequestsList request={request} />
							{upserting && (
								<Box
									className="MuiFormControl-marginNormal"
									display="flex"
									alignItems="center"
									justifyContent="center"
								>
									<Typography variant="body2">{strings.request_statusUpdate}</Typography>
									<CircularProgress size={18} className="MuiTypography-colorTextSecondary" />
								</Box>
							)}
							<RequestActions
								request={request}
								disabled={notFinal && !!errors.Attachments}
								loading={isLoading || loadingAttachment}
								onChangeStatus={setStatus}
								allCheckValid={allCheckValid}
								onRemove={isManagerOrPublisher && !metric?.FBCampaignId ? removeRequest : undefined}
								onRestore={isManagerOrPublisher ? restoreRequest : undefined}
								preActions={
									!notFinal && (isManagerOrPublisher || isOwner)
										? [
												{
													color: "warning",
													labelKey: "request_createNew",
													onClick: createNewRequest,
												},
										  ]
										: undefined
								}
								postActions={
									!notFinal && (isManagerOrPublisher || isOwner)
										? [
												{
													color: "success",
													labelKey: "request_copyToNew",
													onClick: openCopyRequest,
												},
										  ]
										: undefined
								}
							/>
							<Prompt
								when={showExitPrompt}
								message={(locationNext, historyAction) => {
									return JSON.stringify({
										historyAction,
										locationNext,
										currentLocation: history.location,
									});
								}}
							/>
						</form>
					</div>
				</FormProvider>
				<Dialog className="withCloseButton copy-request" open={copyRequestOpened} onClose={closeCopyRequest}>
					<IconButton className="closeButton" onClick={closeCopyRequest}>
						<i className="icon icon-close" />
					</IconButton>
					<DialogTitle disableTypography>
						<Typography variant="h5" align="center">
							{strings.request_createRequestInitialTitle}
						</Typography>
					</DialogTitle>
					<DialogContent>
						<Box className="test-type-selector">
							{requestTypeConfig?.map((conf, idx) => {
								return (
									<StandartButton
										key={idx}
										variant="contained"
										color={
											copyRequestInput.CampaignType === conf.input.CampaignType
												? "secondary"
												: "white"
										}
										disableElevation
										onClick={() => {
											setCopyRequestInput((prevValue) => ({
												...conf.input,
												Id: prevValue.Id,
											}));
										}}
									>
										<Box display="flex" flexDirection="column">
											<i className={`icon icon-${conf.itemProps.iconName || ""}`} />
											{strings[conf.itemProps.titleKey]}
										</Box>
									</StandartButton>
								);
							})}
						</Box>
						{selectedRequestTypeConfig && (
							<Box className="test-type-description">
								<i className="icon icon-info" />
								<Typography variant="body2" color="secondary">
									{strings[selectedRequestTypeConfig.itemProps.tooltipInfoKey]}
								</Typography>
							</Box>
						)}
						<StandartButton
							color="success"
							variant="contained"
							onClick={copyToNewRequest}
							disabled={copyingRequest}
						>
							{strings.request_copyToNew}
						</StandartButton>
					</DialogContent>
				</Dialog>
			</StandartView>
		);
	},
	undefined,
	window.location.pathname,
);

import { Icon, Typography } from "@material-ui/core";
import React, { useMemo } from "react";
import { StandartButton } from "../../../components";
import { getMetric, metricsConfig, TMetricName } from "../../../helpers/metrics";
import classNames from "classnames";
import { StatusesColors } from "../../../helpers/constants";
import strings from "../../../helpers/strings";
import moment from "moment";
import { RequestForListFragment } from "@espresso/protocol";
import { Link } from "react-router-dom";
import useAppContext from "../../../contexts/AppContext";
import { formatNumber } from "@espresso/shared-config";

type TProps = {
	row: RequestForListFragment;
	metricName: TMetricName;
	mainRow?: boolean;
};

export const MetricsTableExpandBlockRow = (props: TProps) => {
	const { row, metricName, mainRow } = props;
	const { lang } = useAppContext();

	const metricValue = getMetric(metricName, row);

	// eslint-disable-next-line react-hooks/exhaustive-deps
	const metrics = useMemo(() => metricsConfig(), [lang]);

	const conf = metrics[metricName];
	const checkValueResult = metricValue != null && conf.getGrade(metricValue);

	let metricText = "—";

	if (metricValue != null) {
		metricText = formatNumber(metricValue.toFixed(2), conf.unit);
	}

	const rowClassnames = classNames("metrics-expand-row", { mainRow });

	const metricClassnames = classNames("metric-text", checkValueResult);

	const content = (
		<div className={rowClassnames}>
			{row.IconPreviewUrl ? (
				<img src={row.IconPreviewUrl} className="app-icon" alt={row.AppName || undefined} />
			) : (
				<Icon className="appNameIcon" fontSize="large">
					<i className="icon icon-puzzle" />
				</Icon>
			)}
			<i
				className={`icon icon-${StatusesColors[row.Status].icon} status ${
					StatusesColors[row.Status].className
				}`}
			/>
			<Typography className="appName" variant={mainRow ? "subtitle1" : "subtitle2"}>
				{row.AppName || strings.request_appNameEmpty}
			</Typography>

			<Typography className="date" variant="body1">
				{mainRow ? <b>{moment(row.CreatedAt).format("DD.MM.YY")}</b> : moment(row.CreatedAt).format("DD.MM.YY")}
			</Typography>
			<div className="metric-container-text">
				<Typography
					variant={mainRow ? "subtitle1" : "subtitle2"}
					color="textSecondary"
				>{`${metricName.toUpperCase()}\u00a0=\u00a0`}</Typography>
				<Typography className={metricClassnames} variant={mainRow ? "subtitle1" : "subtitle2"}>
					{metricText}
				</Typography>
			</div>
			{mainRow ? (
				<div className="button-placeholder" />
			) : (
				<StandartButton variant="text" color="success" justIcon>
					<i className="icon icon-arrow-1" />
				</StandartButton>
			)}
		</div>
	);
	if (mainRow) {
		return content;
	}

	return (
		<Link to={`/request-${row.Id}`} target="_blank">
			{content}
		</Link>
	);
};

import { Tooltip, Typography, Box } from "@material-ui/core";
import React, { useMemo } from "react";
import { Maybe } from "graphql/jsutils/Maybe";
import strings from "../../helpers/strings";

const apiThreshold: [number, number] = [50, 80];

interface IRequestAdAccountUsageProps {
	fbAccountUsage: number;
	fbAccountId?: Maybe<string>;
}

export const RequestAdAccountUsage = (props: IRequestAdAccountUsageProps) => {
	const { fbAccountId, fbAccountUsage } = props;
	const usageConfig = useMemo(() => {
		if (fbAccountUsage >= apiThreshold[1]) {
			return {
				iconName: "Red",
				iconClass: "not-valid",
			};
		}
		if (fbAccountUsage > apiThreshold[0]) {
			return {
				iconName: "Yellow",
				iconClass: "secondary",
			};
		}
		return {
			iconName: "Green",
			iconClass: "valid",
		};
	}, [fbAccountUsage]);

	return (
		<Box className="adaccount-usage-root">
			<Tooltip
				title={
					<Box className="tooltip-center">
						{fbAccountId && (
							<Typography variant="body2" component="span">
								{strings.formatString(strings.request_adAccountTitle, fbAccountId)}
							</Typography>
						)}
						<Box>
							<Typography variant="body2" component="span">
								{strings.formatString(strings.request_adAccountUseText, <b>{fbAccountUsage}</b>)}
							</Typography>
						</Box>
					</Box>
				}
				placement="top"
			>
				<i className={`icon icon-${usageConfig.iconName} ${usageConfig.iconClass}`} />
			</Tooltip>
		</Box>
	);
};

import React, { PropsWithChildren } from "react";
import { Box, Typography, ButtonBase, Card, CircularProgress } from "@material-ui/core";

import { TLocale } from "@espresso/shared-config";
import strings from "helpers/strings";

interface IOptionCardProps {
	disabled?: boolean;
	buttonLabelKey?: keyof TLocale;
	onClick?: () => void;
	className?: string;
	loading?: boolean;
}

export const OptionCard = ({
	buttonLabelKey = "request_next",
	disabled,
	onClick,
	className,
	children,
	loading,
}: PropsWithChildren<IOptionCardProps>) => {
	return (
		<ButtonBase
			disabled={disabled}
			onClick={onClick}
			component={Card}
			focusRipple
			className={`option-card ${className ? className : ""}`}
		>
			<Box className="option-card-content">{children}</Box>
			<Box className="button-next button-width-medium">
				{loading && <CircularProgress color="inherit" size={20} />}
				<Typography component="span">{strings[buttonLabelKey]}</Typography>
			</Box>
		</ButtonBase>
	);
};

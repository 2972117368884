import { ApolloError } from "@apollo/client";
import React, { ReactElement, useContext, useEffect, useState, useCallback } from "react";
import JwtDecode from "jwt-decode";
import { IConfig, loadConfig, mergeKeepShape } from "@espresso/shared-config";
import {
	defaultUpsertDeveloperInput,
	ELang,
	MyLoginFragment,
	StatusPreset,
	useGetMyLoginLazyQuery,
	useSetLangMutation,
	useUpsertDeveloperMutation,
	ERequestOrderField,
	EOrderDirection,
	EOrderNullPosition,
	ECampaignType,
	EGenre,
	UpsertDeveloperInput,
	ELoginRole,
} from "@espresso/protocol";
import * as firebase from "firebase/app";
import { notificationSupported } from "../helpers/notificationSupported";
import { isLoginUser, isProfileFilled } from "../helpers/profileChecks";
import { INotificationCardProps } from "../components";
import strings, { langs } from "../helpers/strings";
import { useHistory } from "react-router-dom";
import { SnackbarProps } from "@material-ui/core";
import _ from "lodash";
import moment from "moment";
import { requestSortParamDefault } from "../helpers/constants";

interface IAppContextData {
	loginId: string;
}

export interface IRequestsListSortParam {
	field: ERequestOrderField;
	order: EOrderDirection;
	nullsPosition: EOrderNullPosition;
}

export interface IRequestsListParams {
	page: number;
	searchString: string;
	statuses: StatusPreset[];
	sort: IRequestsListSortParam;
	presetId?: string;
	campaignTypes: ECampaignType[];
	promoKey: string[];
	genre: EGenre[];
}
interface IAppContextProps {
	auth?: {
		token: string;
		id?: number;
		resetSessonNum?: boolean;
	};
	data?: IAppContextData;
	error?: ApolloError;
}

const lang = langs[strings.getInterfaceLanguage().slice(0, 2)] || ELang.En;

const sharedConfig = loadConfig(process.env.REACT_APP_CHANNEL || "local");
const firebaseApp = firebase.initializeApp(sharedConfig.firebaseWeb);

const notificationsPermission = notificationSupported() ? Notification.permission : undefined;

export interface ISessionContext {
	session?: IAppContextProps;
	setSession: (data: IAppContextProps, save?: boolean) => Promise<void>;
	clearSession: (navigateToHome?: boolean) => Promise<void>;
	logoutModalVisible: boolean;
	setLogoutModalVisible: (value: boolean) => void;
	infoSnackbarParams: SnackbarProps;
	setInfoSnackbarParams: (value: SnackbarProps) => void;
	setLogin: (login: MyLoginFragment) => void;
	updateErrorCode: (code?: { code: string; data?: { [key: string]: string } } | null) => void;
	sharedConfig: IConfig;
	firebaseApp: firebase.app.App;
	login?: MyLoginFragment | null;
	getErrorCode: () => { code: string; data?: { [key: string]: string } } | null | undefined;
	notificationsPermission?: "granted" | "denied" | "default";
	setNotificationsPermission: (notificationsPermission: "granted" | "denied" | "default") => void;
	notifications: INotificationCardProps[];
	pushNotification: (notification: Omit<INotificationCardProps, "onClose">) => void;
	unshiftNotification: (notification: Omit<INotificationCardProps, "onClose">) => void;
	closeNotification: (keyToClose?: string) => void;
	lang: ELang;
	setLang: (lang: ELang) => void;
	requestsListParams: IRequestsListParams;
	setRequestsListParams: (value: Partial<IRequestsListParams>) => void;
	promoKey?: string;
	setPromoKey: (promoKey: string) => void;
	showExitPrompt: boolean;
	setShowExitPrompt: (showExitPrompt: boolean) => void;
	isAgreementDialogOpen: boolean;
	setIsAgreementDialogOpen: (showExitPrompt: boolean) => void;
}

interface IUseAppContextProvider {
	children: ReactElement;
	updateAuthToken: (token: string | null) => Promise<void>;
	token: string | null;
	getErrorCode: () => { code: string; data?: { [key: string]: string } } | null | undefined;
	updateErrorCode: (code?: { code: string; data?: { [key: string]: string } } | null) => void;
}

const initBeforeUnLoad = (showExitPrompt: boolean) => {
	window.onbeforeunload = (event: any) => {
		if (showExitPrompt) {
			const e = event || window.event;
			e.preventDefault();
			if (e) {
				e.returnValue = "";
			}
			return "";
		}
		return;
	};
};

const AppContext = React.createContext<ISessionContext>({
	setSession: async () => undefined,
	clearSession: async () => undefined,
	logoutModalVisible: false,
	setLogoutModalVisible: () => undefined,
	infoSnackbarParams: {},
	setInfoSnackbarParams: () => undefined,
	setLogin: () => undefined,
	updateErrorCode: () => undefined,
	getErrorCode: () => undefined,
	sharedConfig,
	firebaseApp,
	notificationsPermission,
	setNotificationsPermission: () => undefined,
	notifications: [],
	pushNotification: () => undefined,
	unshiftNotification: () => undefined,
	closeNotification: () => undefined,
	lang,
	setLang: () => undefined,
	requestsListParams: {
		page: 0,
		searchString: "",
		statuses: [],
		promoKey: [],
		sort: requestSortParamDefault,
		campaignTypes: [],
		genre: [],
	},
	setRequestsListParams: () => undefined,
	setPromoKey: () => undefined,
	showExitPrompt: false,
	setShowExitPrompt: () => undefined,
	isAgreementDialogOpen: false,
	setIsAgreementDialogOpen: () => undefined,
});

const UseAppContextProvider = (props: IUseAppContextProvider) => {
	const { children, updateAuthToken, token, getErrorCode, updateErrorCode } = props;

	const [getLoginData, { data: loginData, loading: loadingLogin }] = useGetMyLoginLazyQuery();

	const [setLangMutation] = useSetLangMutation();
	const [upsertDeveloper] = useUpsertDeveloperMutation();

	const history = useHistory();

	let session: IAppContextProps | undefined;
	if (token) {
		const tokenData = JwtDecode<IAppContextData>(token);
		session = { auth: { token }, data: tokenData };
	}

	const setSession = async (data: IAppContextProps) => {
		if (data.auth) {
			await updateAuthToken(data.auth.token);
			data.data = JwtDecode<IAppContextData>(data.auth.token);
		}
		setSessionContext((prev) => ({ ...prev, session: data }));
	};

	const clearSession = async (navigateToHome = true) => {
		await updateAuthToken(null);
		await firebaseApp.auth().signOut();
		setSessionContext((prev) => ({ ...prev, session: undefined, login: undefined, notifications: [] }));
		if (navigateToHome) {
			window.location.href = "/";
		}
	};

	const setLogin = (login?: MyLoginFragment | null) => {
		setSessionContext((prev) => ({ ...prev, login }));
	};
	const setLogoutModalVisible = (value: boolean) => {
		setSessionContext((prev) => ({ ...prev, logoutModalVisible: value }));
	};
	const setInfoSnackbarParams = (value: SnackbarProps) => {
		setSessionContext((prev) => ({ ...prev, infoSnackbarParams: value }));
	};

	const setNotificationsPermission = (notificationsPermission: "granted" | "denied" | "default") => {
		setSessionContext((prev) => ({ ...prev, notificationsPermission }));
	};

	const closeNotification = (keyToClose?: string) => {
		setSessionContext((prev) => {
			if (keyToClose) {
				const notifications = prev.notifications.filter((n) => n.key !== keyToClose);
				return { ...prev, notifications };
			}
			prev.notifications.shift();
			return { ...prev };
		});
	};

	const notifications: INotificationCardProps[] = [];

	const pushNotification = (notification: Omit<INotificationCardProps, "onClose">) => {
		setSessionContext((prev) => {
			return { ...prev, notifications: [...prev.notifications, { ...notification }] };
		});
	};

	const unshiftNotification = (notification: Omit<INotificationCardProps, "onClose">) => {
		setSessionContext((prev) => {
			return { ...prev, notifications: [{ ...notification, ...prev.notifications }] };
		});
	};

	const setLang = (lang: ELang, needSave = true) => {
		const newLang = strings.getAvailableLanguages().includes(lang.toLowerCase()) ? lang : ELang.En;
		const langCode = newLang.toLowerCase();
		strings.setLanguage(langCode);
		moment.locale(newLang === ELang.Ch ? "zh-cn" : langCode);
		setSessionContext((prev) => {
			return { ...prev, lang: newLang };
		});

		if (sessionContext.session && needSave) {
			firebaseApp.auth().languageCode = langCode;
			setLangMutation({ variables: { lang: newLang } });
		}
	};

	const setRequestsListParams = (requestsListParams: Partial<IRequestsListParams>) => {
		setSessionContext((prev) => {
			const newRequestsListParams = { ...prev.requestsListParams, ...requestsListParams };
			return { ...prev, requestsListParams: newRequestsListParams };
		});
	};

	const setPromoKey = (promoKey: string) => setSessionContext((prev) => ({ ...prev, promoKey }));

	const setShowExitPrompt = (showExitPrompt: boolean) => setSessionContext((prev) => ({ ...prev, showExitPrompt }));

	const setIsAgreementDialogOpen = (isAgreementDialogOpen: boolean) =>
		setSessionContext((prev) => ({ ...prev, isAgreementDialogOpen }));

	const [sessionContext, setSessionContext] = useState<ISessionContext>({
		setSession,
		clearSession,
		logoutModalVisible: false,
		setLogoutModalVisible,
		infoSnackbarParams: {},
		setInfoSnackbarParams,
		setLogin,
		updateErrorCode,
		sharedConfig,
		session,
		firebaseApp,
		getErrorCode,
		notificationsPermission,
		setNotificationsPermission,
		notifications,
		pushNotification,
		unshiftNotification,
		closeNotification,
		lang,
		setLang,
		requestsListParams: {
			page: 0,
			searchString: "",
			statuses: [],
			promoKey: [],
			sort: requestSortParamDefault,
			campaignTypes: [],
			genre: [],
		},
		setRequestsListParams,
		setPromoKey,
		showExitPrompt: false,
		setShowExitPrompt,
		isAgreementDialogOpen: false,
		setIsAgreementDialogOpen,
	});

	useEffect(() => {
		if (loginData?.getMyLogin?.Lang && sessionContext.lang !== loginData.getMyLogin.Lang) {
			setLang(loginData.getMyLogin.Lang, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginData?.getMyLogin?.Lang]);

	useEffect(() => {
		if (sessionContext.login?.Id !== sessionContext?.session?.data?.loginId && !loadingLogin) {
			getLoginData();
		}
	}, [getLoginData, loadingLogin, sessionContext.login?.Id, sessionContext?.session?.data?.loginId]);

	useEffect(() => {
		if (!sessionContext.session || !loginData?.getMyLogin) {
			return;
		}
		if (sessionContext.login?.Id !== loginData.getMyLogin.Id && !loginData.getMyLogin.Developer) {
			const upsertDeveloperInput = mergeKeepShape(defaultUpsertDeveloperInput, {
				ContactEmail: loginData.getMyLogin.Email,
				TimezoneDelta: moment().utcOffset(),
			});
			upsertDeveloper({
				variables: { data: upsertDeveloperInput },
				update: (cache, mutationResult) => {
					if (mutationResult.data?.upsertDeveloper.success) {
						cache.modify({
							fields: {
								getMyLogin: (existing) => {
									return {
										...existing,
										Developer: mutationResult.data?.upsertDeveloper.data?.Developer,
									};
								},
							},
						});
					}
				},
			});
		}
		if (
			sessionContext.login?.Id !== loginData?.getMyLogin.Id ||
			!_.isEqual(sessionContext.login?.Presets, loginData?.getMyLogin.Presets) ||
			!_.isEqual(sessionContext.login?.Developer, loginData.getMyLogin.Developer)
		) {
			setSessionContext((prev) => ({
				...prev,
				login: loginData?.getMyLogin,
			}));
		}
	}, [loginData, sessionContext, upsertDeveloper]);

	const pushEmptyProfileNotification = useCallback(() => {
		const { login, notifications } = sessionContext;
		if (!login) {
			return;
		}
		if (!isLoginUser(login) || isProfileFilled(login.Developer)) {
			return;
		}
		if (notifications.some((n) => n.key === "emptyProfile")) {
			return;
		}

		const emptyProfileNotification: INotificationCardProps = {
			key: "emptyProfile",
			titleKey: "notification_emptyProfileTitle",
			messageKey: "notification_emptyProfileMessage",
			variant: "twitter",
			actions: [
				{
					title: "notification_emptyProfileActionText",
					onClick: () => {
						history.push("/developer/edit");
					},
					variant: "contained",
					color: "white",
				},
			],
			iconName: "user-6-1",
		};

		pushNotification(emptyProfileNotification);
	}, [history, sessionContext]);

	const pushAgreementNotification = useCallback(() => {
		const { login, notifications } = sessionContext;
		if (login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher) {
			return;
		}
		if (!login?.Developer) {
			return;
		}
		if (login.Developer.IsAgreementRead) {
			return;
		}
		if (notifications.some((n) => n.key === "agreement")) {
			return;
		}

		const agreementNotification: INotificationCardProps = {
			key: "agreement",
			titleKey: "notification_agreementTitle",
			messageKey: "notification_agreementMessage",
			variant: "warning",
			iconName: "agreement",
			actions: [
				{
					title: "notification_agreementActionText",
					onClick: () => {
						const data: UpsertDeveloperInput = {
							...mergeKeepShape(defaultUpsertDeveloperInput, { ...login.Developer }),
							IsAgreementRead: true,
						};
						upsertDeveloper({
							variables: { data },
							update: (cache, mutationResult) => {
								if (mutationResult.data?.upsertDeveloper.success) {
									cache.modify({
										fields: {
											getMyLogin: (existing) => {
												return {
													...existing,
													Developer: { ...existing.Developer, IsAgreementRead: true },
												};
											},
										},
									});
								}
							},
						});
						setIsAgreementDialogOpen(true);
					},
					variant: "contained",
					color: "white",
				},
			],
		};

		unshiftNotification(agreementNotification);
	}, [sessionContext, upsertDeveloper]);

	useEffect(() => {
		const { login, notifications } = sessionContext;
		if (
			login &&
			isLoginUser(login) &&
			isProfileFilled(login.Developer) &&
			notifications.some((n) => n.key === "emptyProfile")
		) {
			closeNotification("emptyProfile");
		}
		if (login?.Developer?.IsAgreementRead) {
			closeNotification("agreement");
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sessionContext.login?.Developer]);

	useEffect(() => {
		pushEmptyProfileNotification();
		pushAgreementNotification();
	}, [pushAgreementNotification, pushEmptyProfileNotification, sessionContext.login?.Id]);

	useEffect(() => {
		const { login } = sessionContext;

		if (login) {
			return;
		}

		if (strings.getLanguage() !== lang.toLowerCase()) {
			setLang(lang, false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	window.onload = function () {
		initBeforeUnLoad(sessionContext.showExitPrompt);
	};

	useEffect(() => {
		initBeforeUnLoad(sessionContext.showExitPrompt);
	}, [sessionContext.showExitPrompt]);

	return <AppContext.Provider value={sessionContext}>{children}</AppContext.Provider>;
};

const useAppContext = () => {
	const sessionContext = useContext(AppContext);

	return sessionContext;
};

export default useAppContext;
export { AppContext, UseAppContextProvider };

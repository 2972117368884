import React, { useContext, useEffect, useMemo, useReducer, useRef } from "react";

import { usePrevious } from "helpers/usePrevious";

interface ILoadingContextValue {
	loading: boolean;
	signalLoading: (type: "start" | "finish") => void;
}

const LoadingContext = React.createContext<ILoadingContextValue | undefined>(undefined);

const contextDefaultValue = {
	loading: false,
	signalLoading: (type: "start" | "finish") => undefined,
};

const loadingReducer = (state: number, action: "start" | "finish") => {
	switch (action) {
		case "start": {
			return state + 1;
		}
		case "finish": {
			return state > 0 ? state - 1 : 0;
		}
		default: {
			return state;
		}
	}
};

export const LoadingContextProvider = (props: React.PropsWithChildren<{}>) => {
	const [queriesLoading, signalLoading] = useReducer(loadingReducer, 0);
	const loadingContextValue = useMemo(() => {
		return {
			loading: queriesLoading > 0,
			signalLoading,
		};
	}, [queriesLoading, signalLoading]);
	return <LoadingContext.Provider value={loadingContextValue}>{props.children}</LoadingContext.Provider>;
};

export const useLoadingContext = (): ILoadingContextValue => {
	const contextValue = useContext(LoadingContext);
	if (!contextValue) {
		console.warn(
			"%cОтсутствует Provider для LoadingContext!",
			"font-weight: bold;",
			"Добавьте его выше в дереве компонентов",
		);
		return contextDefaultValue;
	}
	return contextValue;
};

/**
 * Сигнализировать в LoadingContext о загрузке, опираясь на значение переменной-индикатора.
 * @param value Индикатор загрузки (чаще всего loading, возвращаемый useQuery)
 */
export const useLoadingSignal = (value: boolean) => {
	const { signalLoading } = useLoadingContext();
	const prevValue = usePrevious(value);
	const signalSent = useRef(false);

	useEffect(() => {
		if (!prevValue && value) {
			signalLoading("start");
			signalSent.current = true;
		}
		if (prevValue && !value) {
			signalLoading("finish");
			signalSent.current = false;
		}
	}, [prevValue, signalLoading, value]);

	/**
	 * Если компонент, отправивший сигнал о загрузке, ан-маунтится до ее завершения - отправляем сингал о завершении
	 * загрузки.
	 */
	useEffect(() => {
		return () => {
			if (signalSent.current) {
				signalLoading("finish");
			}
		};
	}, [signalLoading]);
};

import React from "react";
import { Grid, Box, Typography } from "@material-ui/core";

import { OptionCard } from "components";
import strings from "helpers/strings";
import { useAnalytics } from "helpers/ga";
import { EStatsMethod, ERequestStatus } from "@espresso/protocol";
import { TCreateRequestFormProps } from "../types";

export const StatsMethodOptions = (props: TCreateRequestFormProps) => {
	const { loading, submit, setCurrentStepStatus, request } = props;
	const { analyticsEvents } = useAnalytics();

	const handleClick = async (statsMethod: EStatsMethod) => {
		// Оставляем статус "Отправка" или "Возвращена" если пользователь захотел сменить тип заявки
		// после завершения всех подготовительных шагов или возврата заявки менеджером для доработки
		if (request?.Status === ERequestStatus.PreValidation || request?.Status === ERequestStatus.Returned) {
			await submit({ StatsMethod: statsMethod });
		} else {
			await submit({ StatsMethod: statsMethod, Status: ERequestStatus.InfoFacebook });
		}
		setCurrentStepStatus(ERequestStatus.InfoFacebook);
		analyticsEvents.advanceRequestCreation(request, ERequestStatus.SetSDK, ERequestStatus.InfoFacebook);
	};
	const clickCardSDK = async () => {
		handleClick(EStatsMethod.Sdk);
	};

	const clickCardManual = async () => {
		handleClick(EStatsMethod.Manual);
	};

	return (
		<Grid container spacing={5} className="options-container">
			<Grid item lg={6} xs={12}>
				<OptionCard disabled={loading} onClick={clickCardSDK}>
					<Box className="header">
						<Typography variant="subtitle1">
							{strings.formatString(
								strings.request_createRequestInfoFacebookSdkOptionTitle,
								<i className="icon icon-Unity" />,
							)}
						</Typography>
						<Typography>{strings.request_createRequestInfoFacebookSdkOptionSubtitle}</Typography>
					</Box>
					<Box className="body">
						<Box>
							<i className="icon icon-sdk-1" />
							<Typography>{strings.request_createRequestInfoFacebookSdkOptionText1}</Typography>
						</Box>
						<Box>
							<i className="icon icon-video" />
							<Typography>{strings.request_createRequestInfoFacebookSdkOptionText2}</Typography>
						</Box>
						<Box>
							<i className="icon icon-statistics" />
							<Typography>{strings.request_createRequestInfoFacebookSdkOptionText3}</Typography>
						</Box>
					</Box>
				</OptionCard>
			</Grid>
			<Grid item lg={6} md={12}>
				<OptionCard disabled={loading} onClick={clickCardManual}>
					<Box className="header">
						<Typography variant="subtitle1">
							{strings.request_createRequestInfoFacebookManualOptionTitle}
						</Typography>
						<Typography>{strings.request_createRequestInfoFacebookManualOptionSubtitle}</Typography>
					</Box>
					<Box className="body">
						<Box>
							<i className="icon icon-icon-sdkstatistik" />
							<Typography>{strings.request_createRequestInfoFacebookManualOptionText}</Typography>
						</Box>
					</Box>
				</OptionCard>
			</Grid>
		</Grid>
	);
};

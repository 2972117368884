import React, { useEffect, useState } from "react";
import { Grid, Tooltip, IconButton, Menu, MenuItem, Box, Typography } from "@material-ui/core";
import { DatePicker } from "@material-ui/pickers";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import moment from "moment";
import classNames from "classnames";
import _ from "lodash";

import { ELoginRole, useGetGameTotalStatLazyQuery } from "@espresso/protocol";
import { TLocale } from "@espresso/shared-config";
import { useLoadingSignal } from "contexts/LoadingContext";
import useAppContext from "contexts/AppContext";
import strings from "helpers/strings";

import { ISwitchBarItem, StandartTextInput, SwitchBar } from "components";
import { IGamesListParams } from "./GamesList";

interface IProps {
	gamesListParams: IGamesListParams;
	onGamesListParamsChange: React.Dispatch<React.SetStateAction<IGamesListParams>>;
}

interface IReportSummaryProps {
	gamesListParams: IGamesListParams;
}

interface IReportSummaryItemProps {
	reportPeriodStart: MaterialUiPickersDate;
	reportPeriodEnd: MaterialUiPickersDate;
	labelKey: keyof TLocale;
	unit: string;
	value?: number;
	valueToMeet?: number;
	colored?: boolean;
	loading?: boolean;
}

const ReportSummaryItem = (props: IReportSummaryItemProps) => {
	const {
		reportPeriodStart: from,
		reportPeriodEnd: to,
		labelKey,
		value,
		unit,
		colored,
		loading,
		valueToMeet,
	} = props;

	const reportPeriodString = `(${from?.format("DD.MM.YY")} - ${to?.format("DD.MM.YY")})`;
	const checkResult = value && valueToMeet !== undefined ? value > valueToMeet : undefined;

	const classes = classNames("report-summary-item", {
		positive: checkResult,
		negative: !(checkResult ?? true),
		colored,
		disabled: loading,
	});

	return (
		<Box className={classes}>
			<Box className="label-container">
				<Typography variant="caption" component="p">
					<b>{strings.formatString(strings[labelKey], " ")}</b>
				</Typography>
				<Typography variant="caption" component="p" className="text-ellipsis">
					{reportPeriodString}
				</Typography>
			</Box>
			<Box className="value-container">
				<Typography variant="subtitle1" className="value">
					{value?.toFixed(2) ?? "—"}
				</Typography>
				<Typography variant="subtitle1" className="unit">
					{unit}
				</Typography>
			</Box>
		</Box>
	);
};

const ReportSummary = (props: IReportSummaryProps) => {
	const { gamesListParams } = props;

	const [getGameTotalStat, { data: totalStatsData, loading }] = useGetGameTotalStatLazyQuery({
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});
	const totalStats = totalStatsData?.getGameTotalStat;
	useLoadingSignal(loading);

	useEffect(() => {
		const Disabled = gamesListParams.Disabled?.[0];

		getGameTotalStat({
			variables: {
				input: {
					OrderField: gamesListParams.OrderField,
					OrderDirection: gamesListParams.OrderDirection,
					SearchString: gamesListParams.SearchString,
					From: gamesListParams.From?.toISOString(),
					To: gamesListParams.To?.toISOString(),
					Disabled,
				},
			},
		});
	}, [getGameTotalStat, gamesListParams]);

	return (
		<>
			<ReportSummaryItem
				reportPeriodStart={gamesListParams.From}
				reportPeriodEnd={gamesListParams.To}
				labelKey="proDashboard_gameMetricSpend"
				value={totalStats?.TenjinMetrics?.TotalSpend ?? undefined}
				unit="$"
				loading={loading}
			/>
			<ReportSummaryItem
				reportPeriodStart={gamesListParams.From}
				reportPeriodEnd={gamesListParams.To}
				labelKey="proDashboard_gameMetricAdRevenue"
				value={totalStats?.TenjinMetrics?.AdRevenue ?? undefined}
				unit="$"
				loading={loading}
			/>
			<ReportSummaryItem
				colored
				reportPeriodStart={gamesListParams.From}
				reportPeriodEnd={gamesListParams.To}
				labelKey="proDashboard_gameMetricGrossIncome"
				value={totalStats?.TenjinMetrics?.Income ?? undefined}
				valueToMeet={0}
				unit="$"
				loading={loading}
			/>
		</>
	);
};

const gameStatusSwitchItems: ISwitchBarItem<boolean>[] = [
	{ label: "proDashboard_gameStatusActive", value: false },
	{ label: "proDashboard_gameStatusDisabled", value: true },
];

export const FiltersAndReportSummary = (props: IProps) => {
	const { gamesListParams, onGamesListParamsChange } = props;

	const { login } = useAppContext();

	const [menuAnchorEl, setMenuAnchorEl] = useState<Element | null>(null);

	const handleQuickPeriodChange = (newFrom: moment.Moment, newTo: moment.Moment) => {
		onGamesListParamsChange((prev) => ({ ...prev, From: newFrom, To: newTo }));
		setMenuAnchorEl(null);
	};

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	return (
		<Grid container className="filters-container">
			<Grid item lg={5} xs={12} className="date-pickers-container">
				<DatePicker
					disableToolbar
					disableFuture
					maxDateMessage=" "
					minDateMessage=" "
					maxDate={gamesListParams.To ?? undefined}
					variant="inline"
					value={gamesListParams.From}
					onChange={(From) => onGamesListParamsChange((prev) => ({ ...prev, From }))}
					label={strings.proDashboard_reportPeriodFrom}
					format="DD/MM/YYYY"
					PopoverProps={{ className: "calendar-popover-root" }}
				/>
				<Box className="date-picker-with-button">
					<DatePicker
						disableToolbar
						disableFuture
						maxDateMessage=" "
						minDateMessage=" "
						variant="inline"
						value={gamesListParams.To}
						onChange={(To) => onGamesListParamsChange((prev) => ({ ...prev, To }))}
						label={strings.proDashboard_reportPeriodTo}
						format="DD/MM/YYYY"
						PopoverProps={{ className: "calendar-popover-root" }}
					/>
					<Tooltip title={strings.proDashboard_reportPeriodQuickSettingTooltip} placement="top-start">
						<IconButton onClick={(e) => setMenuAnchorEl(e.currentTarget)}>
							<i className="icon icon-calendar" />
						</IconButton>
					</Tooltip>
					<Menu
						open={!!menuAnchorEl}
						anchorEl={menuAnchorEl}
						onClose={() => setMenuAnchorEl(null)}
						getContentAnchorEl={null}
						anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
					>
						<MenuItem onClick={() => handleQuickPeriodChange(moment(), moment())}>
							{strings.capitalize(strings.today)}
						</MenuItem>
						<MenuItem
							onClick={() =>
								handleQuickPeriodChange(moment().subtract(1, "days"), moment().subtract(1, "days"))
							}
						>
							{strings.capitalize(strings.yesterday)}
						</MenuItem>
						<MenuItem onClick={() => handleQuickPeriodChange(moment().subtract(7, "days"), moment())}>
							{strings.formatString(strings.lastNDays, 7)}
						</MenuItem>
						<MenuItem onClick={() => handleQuickPeriodChange(moment().subtract(14, "days"), moment())}>
							{strings.formatString(strings.lastNDays, 14)}
						</MenuItem>
						<MenuItem onClick={() => handleQuickPeriodChange(moment().startOf("month"), moment())}>
							{strings.thisMonth}
						</MenuItem>
						<MenuItem
							onClick={() =>
								handleQuickPeriodChange(
									moment().subtract(1, "month").startOf("month"),
									moment().subtract(1, "month").endOf("month"),
								)
							}
						>
							{strings.lastMonth}
						</MenuItem>
						<MenuItem onClick={() => handleQuickPeriodChange(moment().subtract(30, "days"), moment())}>
							{strings.formatString(strings.lastNDays, 30)}
						</MenuItem>
					</Menu>
				</Box>
			</Grid>
			<Grid item lg={7} className="report-summary-container">
				<ReportSummary gamesListParams={gamesListParams} />
			</Grid>
			<Grid item xs={12} className="search-and-switch-container">
				<StandartTextInput
					label={isManagerOrPublisher ? strings.search : strings.searchGameOnly}
					onChange={_.debounce(
						(SearchString) => onGamesListParamsChange((prev) => ({ ...prev, SearchString })),
						500,
					)}
				/>
				<SwitchBar
					labelKey="proDashboard_reportShowProjects"
					selectedItems={gamesListParams.Disabled}
					items={gameStatusSwitchItems}
					buttonColor="secondary"
					onItemsChange={(Disabled) => onGamesListParamsChange((prev) => ({ ...prev, Disabled }))}
				/>
			</Grid>
		</Grid>
	);
};

import React, { useState, useEffect, useRef } from "react";
import { UseFormMethods } from "react-hook-form";
import { Box, Fade, Grid, Typography } from "@material-ui/core";
import copy from "copy-to-clipboard";
import {
	DeveloperRequestFragment,
	ECheckStatus,
	EStatsMethod,
	useCheckRequsetMutation,
	UpsertRequestInput,
	CheckRequsetMutation,
	ECampaignType,
} from "@espresso/protocol";
import strings from "helpers/strings";
import { useAnalytics } from "helpers/ga";
import useAppContext from "contexts/AppContext";

import { InfoDialog, StandartButton, StandartTextInputController } from "components";
import { SdkWizardStep, ISdkWizardStepProps } from "./SdkWizardStep";
import { analyticsStatus } from "../../../../../helpers/request";
import { FetchResult } from "@apollo/client";
import { FbPublish } from "views/HelpView/sections/faq/FbPublish";

type TFormData = { FacebookId: string; InternalComment: string };
export interface ISdkWizardProps {
	request: DeveloperRequestFragment;
	form: UseFormMethods<TFormData>;
	setCompleted: React.Dispatch<React.SetStateAction<boolean>>;
	submit: (
		data: Partial<UpsertRequestInput>,
		callbackFn?: (upsertedRequestId: string) => Promise<void> | void,
	) => Promise<DeveloperRequestFragment | null | undefined>;
	refetch?: (id?: string) => void;
	loading?: boolean;
}

export type TSdkWizardStepName = "FacebookId" | "BaristaId" | "RobustaSdk" | "SetupSdk";

type TStatus = "FacebookIdStatus" | "AdvertIdStatus" | "FacebookAnalyticsStatus";

interface ISteps {
	name: TSdkWizardStepName;
	requestStatus?: TStatus;
	config: Omit<ISdkWizardStepProps, "totalSteps" | "fieldName">;
}

export const SdkWizard = ({ request, form, setCompleted, refetch, submit, loading }: ISdkWizardProps) => {
	const { sharedConfig, setInfoSnackbarParams } = useAppContext();
	const { analyticsEvents } = useAnalytics();

	const [isQuestionAnswered, setIsQuestionAnswered] = useState(!!request.FacebookId);

	const {
		control,
		formState: { errors },
		watch,
	} = form;

	const { Id: requestId } = request;

	const snackbarMessageRef = useRef<string | undefined>(undefined);

	const [checkFbAppId, { loading: loadingFbAppId }] = useCheckRequsetMutation({
		variables: { data: { requestId, checkFacebookId: true } },
	});

	const [checkAdvertId, { loading: loadingAdvertId }] = useCheckRequsetMutation({
		variables: { data: { requestId, checkAdvertId: true } },
	});

	const serverEndpoint = `${sharedConfig.endpoints.graphqlUseSSL ? "https" : "http"}://${
		sharedConfig.endpoints.graphqlHost
	}/api/v1/${request.Developer.ApiKey || "XXXXXXXX-XXXX-XXXX-XXXX-XXXXXXXXXXXX"}/stats-config/${
		request.FacebookId || "XXXXXXXXXXXXXXXX"
	}`;

	const completeStep = (index: number) => {
		if (index > lastStepIndex) {
			setLastStepIndex(index);
		}
	};

	const watchFacebookId = watch("FacebookId");
	const getFacebookIdStatus = () => {
		return watchFacebookId !== request.FacebookId ? ECheckStatus.NotChecked : request.FacebookIdStatus;
	};

	const saveRequest = async () => {
		if (watchFacebookId === request.FacebookId) {
			return request;
		}

		await form.trigger("FacebookId");
		if (!errors.FacebookId) {
			return await submit({ FacebookId: watchFacebookId });
		}

		return;
	};

	const openSnackbar = (copyMessage: string, snackbarMessage: string) => {
		snackbarMessageRef.current = snackbarMessage;

		copy(copyMessage);
		setInfoSnackbarParams({
			open: true,
			message: snackbarMessage,
			autoHideDuration: 2000,
		});
	};

	const DEFAULT_STEPS: ISteps[] = [
		{
			name: "FacebookId",
			requestStatus: "FacebookIdStatus",
			config: {
				title: "request_facebookAppId",
				subtitle: (
					<StandartTextInputController
						name="FacebookId"
						control={control}
						rules={{ required: strings.requiredShort }}
						errorMessage={errors?.FacebookId?.message}
					/>
				),
				status: getFacebookIdStatus(),
				action: {
					onClick: async () => {
						const res = await saveRequest();
						if (res) {
							const checked = await checkFbAppId();
							if (checked.data?.checkRequest.data?.Request.FacebookIdStatus === ECheckStatus.Valid) {
								completeStep(0);
								analyticsEvents.completeSdkWizardAction(request, "FacebookId");
							}
							refetch?.();
						}
					},
					title: "check",
					color: "warning",
				},
				isVerifying: loadingFbAppId,
			},
		},
		{
			name: "BaristaId",
			requestStatus: "AdvertIdStatus",
			config: {
				title: "request_facebookAdminIdShort",
				status: request.AdvertIdStatus,
				descriptionKey: "request_createRequestInfoFacebookWizardStepDescription2",
				actionableContent: [
					{
						icon: "copy",
						content: `id ${request.AdvertId || sharedConfig.facebook.advertId}`,
						onClick: () =>
							openSnackbar(
								request.AdvertId || sharedConfig.facebook.advertId,
								strings.request_createRequestBaristaIDCopied,
							),
					},
					{
						icon: "link",
						content: strings.request_createRequestInfoFacebookWizardHowToBarista,
						urlString: "/help/configuration/barista-id",
					},
				],
				action: {
					onClick: async () => {
						let checked:
							| FetchResult<CheckRequsetMutation, Record<string, any>, Record<string, any>>
							| undefined;
						if (getFacebookIdStatus() === ECheckStatus.NotChecked) {
							const res = await saveRequest();
							if (res) {
								checked = await checkAdvertId({
									variables: { data: { requestId, checkFacebookId: true, checkAdvertId: true } },
								});
							}
						} else {
							checked = await checkAdvertId();
						}
						if (checked?.data?.checkRequest.data?.Request.AdvertIdStatus === ECheckStatus.Valid) {
							completeStep(1);
							analyticsEvents.completeSdkWizardAction(request, "BaristaId");
						}
						refetch?.();
					},
					title: "check",
					color: "warning",
				},
				isVerifying: loadingAdvertId,
			},
		},
	];
	const SDK_STEPS: ISteps[] = [
		...DEFAULT_STEPS,
		{
			name: "RobustaSdk",
			config: {
				title: "request_robustaSdk",
				descriptionKey: "request_createRequestInfoFacebookWizardStepDescription3",
				actionableContent: [
					{
						icon: "link",
						content: strings.request_createRequestInfoFacebookWizardHowToConnectSdk,
						urlString: "/help/configuration/robusta-sdk",
					},
				],
				action: {
					onClick: () => {
						completeStep(2);
						window.open("https://github.com/espresso-pub/robusta#readme", "_blank");
						analyticsEvents.completeSdkWizardAction(request, "RobustaSdk");
					},
					title: "request_createRequestInfoStatsDownload",
					color: "warning",
				},
				isVerifying: false,
			},
		},
		{
			name: "SetupSdk",
			requestStatus: "FacebookAnalyticsStatus",
			config: {
				title: "request_createRequestInfoFacebookWizardStepTitleSetupSdk",
				status: analyticsStatus(request),
				lastStatsDate: request.LastStatsAt,
				descriptionKey: "request_createRequestInfoFacebookWizardStepDescription4",
				actionableContent: serverEndpoint.length
					? [
							{
								icon: "copy",
								content: strings.request_createRequestInfoStatsCopy,
								onClick: () =>
									openSnackbar(serverEndpoint, strings.request_createRequestSettingsLinkCopied),
							},
							{
								icon: "link",
								content: strings.request_createRequestInfoFacebookWizardHowToCheckSdk,
								urlString: "/help/faq/check-robusta-sdk",
							},
					  ]
					: undefined,
				action: {
					onClick: () => {
						refetch?.();
						analyticsEvents.completeSdkWizardAction(request, "SetupSdk");
					},
					title: "check",
					color: "warning",
				},
				isVerifying: !!loading,
			},
		},
	];
	const MANUAL_STEPS: ISteps[] = [
		...DEFAULT_STEPS,
		{
			name: "SetupSdk",
			requestStatus: "FacebookAnalyticsStatus",
			config: {
				title: "request_createRequestInfoFacebookWizardStepTitleAnalytics",
				status: analyticsStatus(request),
				lastStatsDate: request.LastStatsAt,
				descriptionKey: "request_createRequestInfoFacebookWizardStepDescription5",
				actionableContent: [
					{
						icon: "link",
						content: strings.request_createRequestInfoFacebookWizardHowToAnalytics,
						urlString: "/help/configuration/non-unity-games",
					},
				],
				action: {
					onClick: () => {
						refetch?.();
						analyticsEvents.completeSdkWizardAction(request, "SetupSdk");
					},
					title: "check",
					color: "warning",
				},
				isVerifying: !!loading,
			},
		},
	];

	const steps =
		request.CampaignType === ECampaignType.Cpi
			? DEFAULT_STEPS
			: request.StatsMethod === EStatsMethod.Manual
			? MANUAL_STEPS
			: SDK_STEPS;

	const getLastStepIndex = (request: DeveloperRequestFragment) => {
		let lastStepIndex = -1;
		for (const step of steps) {
			if (!step.requestStatus || request[step.requestStatus] === ECheckStatus.Valid) {
				lastStepIndex++;
				continue;
			}
			break;
		}
		return lastStepIndex;
	};
	const [lastStepIndex, setLastStepIndex] = useState<number>(getLastStepIndex(request));

	useEffect(() => {
		const newLastStepIndex = getLastStepIndex(request);
		if (newLastStepIndex > lastStepIndex) {
			setLastStepIndex(newLastStepIndex);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [request]);

	useEffect(() => {
		if (steps.every(({ config: { status } }) => !status || status !== ECheckStatus.NotChecked)) {
			setCompleted(true);
		}
	}, [steps, setCompleted]);

	useEffect(() => {
		return () => {
			setInfoSnackbarParams({ open: false, message: snackbarMessageRef.current });
		};
	}, [setInfoSnackbarParams]);

	return (
		<Grid container item className="wizard-container">
			<QuestionStep request={request} onQuestionAnswered={() => setIsQuestionAnswered(true)} />
			<Fade in={isQuestionAnswered} appear>
				<Grid container item xs={12}>
					{steps.map((step, index) => (
						<SdkWizardStep
							totalSteps={steps.length}
							key={step.name}
							disabled={index > lastStepIndex + 1}
							fieldName={step.requestStatus}
							{...step.config}
						/>
					))}
				</Grid>
			</Fade>
		</Grid>
	);
};

const QuestionStep = (props: { onQuestionAnswered: () => void; request: DeveloperRequestFragment }) => {
	const { onQuestionAnswered, request } = props;

	const { analyticsEvents } = useAnalytics();

	const [isInfoDialogOpen, setIsInfoDialogOpen] = useState(false);

	const handleSkipInfoDialog = () => {
		onQuestionAnswered();
		analyticsEvents.completeRequestCreationAction(request, "skip_fb_app_id_help");
	};

	const handleShowInfoDialog = () => {
		onQuestionAnswered();
		setIsInfoDialogOpen(true);
		analyticsEvents.completeRequestCreationAction(request, "show_fb_app_id_help");
	};

	return (
		<Grid item xs={12}>
			<Box className="wizard-step-container">
				<Box className="wizard-step question-step">
					<Box className="wizard-step-content">
						<Typography variant="subtitle2">
							{strings.request_createRequestInfoStatsIsGamePublished}
						</Typography>
					</Box>
					<Box className="actions">
						<StandartButton onClick={handleSkipInfoDialog} color="secondary">
							{strings.request_createRequestInfoStatsIsGamePublishedYes}
						</StandartButton>
						<StandartButton onClick={handleShowInfoDialog} color="warning">
							{strings.request_createRequestInfoStatsIsGamePublishedNo}
						</StandartButton>
						<InfoDialog isOpen={isInfoDialogOpen} onClose={() => setIsInfoDialogOpen(false)}>
							<FbPublish />
						</InfoDialog>
					</Box>
				</Box>
			</Box>
		</Grid>
	);
};

import React from "react";
import { StandartView } from "../components";
import { Typography } from "@material-ui/core";

export const DeletionView = () => {
	return (
		<StandartView disableNotifications hideHeadMenu hideFooterMenu title="">
			<div className="doc-view">
				<Typography variant="h3"> Facebook data deletion</Typography>
				<Typography variant="body1">
					We only store your facebook app specific id in the app. If you want to delete it for any reason
					please contact us with support e-mail.
				</Typography>
			</div>
		</StandartView>
	);
};

import { CircularProgress, Typography } from "@material-ui/core";
import React, { useEffect, useState, useMemo } from "react";
import {
	ELoginRole,
	ERequestOrderField,
	ERequestStatus,
	RequestForListFragment,
	useGetSimilarRequestsLazyQuery,
} from "@espresso/protocol";
import { ISwitchBarItem, SwitchBar } from "../../../components";
import { TMetricName } from "../../../helpers/metrics";
import { MetricsTableExpandBlockRow } from "./MetricsTableExpandBlockRow";
import { declRequests, TLocale } from "@espresso/shared-config";
import strings from "../../../helpers/strings";
import useAppContext from "../../../contexts/AppContext";

type TProps = {
	request: RequestForListFragment;
	metricName?: TMetricName;
	info?: string;
	visible: boolean;
};
const LIMITS = [3, 5];

const EmptyListPlaceholder = (props: { metricName: TMetricName; comparisonLabelKey: keyof TLocale }) => (
	<div className="empty-list">
		<i className="icon icon-info1" />
		<Typography variant="body2" color="secondary">
			{strings.formatString(strings.request_similarRequestsPlaceholder, {
				metricName: props.metricName.toUpperCase(),
				comparison: strings[props.comparisonLabelKey],
			})}
		</Typography>
	</div>
);

export const MetricsTableExpandBlock = (props: TProps) => {
	const { request, metricName, info, visible } = props;
	const { login, lang } = useAppContext();

	const limitSwitcherItems: ISwitchBarItem<number>[] = useMemo(() => {
		return LIMITS.map((v) => ({
			label: "request_limitSwitch",
			label_params: { num: v.toString(), rows: strings[declRequests(v)] },
			value: v,
		}));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);

	const [limit, setLimit] = useState<number>(limitSwitcherItems[0].value);

	const [getSimilarRequests, { data, loading, updateQuery }] = useGetSimilarRequestsLazyQuery({
		fetchPolicy: "no-cache",
	});

	useEffect(() => {
		if (!visible) {
			updateQuery?.(() => ({ getSimilarRequests: [[], []] }));
			return;
		}

		let field;
		switch (metricName) {
			case "cpi":
				field = ERequestOrderField.CPI;
				break;
			case "r1":
				field = ERequestOrderField.R1;
				break;
			case "mt":
				field = ERequestOrderField.MT;
				break;
			case "ctr":
				field = ERequestOrderField.CTR;
				break;
			default:
				break;
		}
		if (field) {
			getSimilarRequests({
				variables: {
					input: {
						RequestId: request.Id,
						Field: field,
						Limit: limit,
						Status: [
							{ Status: ERequestStatus.Finished },
							{ Status: ERequestStatus.Rejected },
							{ Status: ERequestStatus.RunFailed },
						],
					},
				},
			});
		}
	}, [visible, getSimilarRequests, limit, metricName, request.Id, updateQuery]);

	if (loading) {
		return <CircularProgress size={32} />;
	}

	if (!metricName || login?.Role === ELoginRole.Unconfirmed || login?.Role === ELoginRole.User) {
		return <Typography variant="subtitle2">{info}</Typography>;
	}

	return (
		<div className="metrics-expand-block">
			<div className="info-block">
				<Typography>{info}</Typography>
				<div className="switch-bar-container">
					<SwitchBar<number>
						items={limitSwitcherItems}
						onItemsChange={(items) => {
							if (limit !== items[0]) {
								setLimit(items[0]);
							}
						}}
						selectedItems={[limit]}
						size="small"
						required
						buttonColor="secondary"
					/>
				</div>
			</div>
			<div className="table-block">
				{data?.getSimilarRequests?.[0]?.length ? (
					data?.getSimilarRequests?.[0]
						.reverse()
						.map((item) => <MetricsTableExpandBlockRow key={item.Id} metricName={metricName} row={item} />)
				) : (
					<EmptyListPlaceholder metricName={metricName} comparisonLabelKey="less" />
				)}
				<MetricsTableExpandBlockRow mainRow metricName={metricName} row={request} />
				{data?.getSimilarRequests?.[1].length ? (
					data?.getSimilarRequests?.[1].map((item) => (
						<MetricsTableExpandBlockRow key={item.Id} metricName={metricName} row={item} />
					))
				) : (
					<EmptyListPlaceholder metricName={metricName} comparisonLabelKey="more" />
				)}
			</div>
		</div>
	);
};

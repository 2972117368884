import React from "react";
import { Dialog, DialogContent, DialogTitle, Typography } from "@material-ui/core";
import strings from "../helpers/strings";
import { useHistory } from "react-router-dom";
import { StandartButton, IAuthLocationState } from "../components";

type TEmailLinkSendedModalProps = {
	visible: boolean;
	setVisible: (value: boolean) => void;
	text1?: string;
	text2?: string;
	onButtonClick?: () => void;
	locationState?: IAuthLocationState;
};

export const EmailLinkSendedModal = (props: TEmailLinkSendedModalProps) => {
	const {
		visible,
		setVisible,
		onButtonClick,
		locationState,
		text1 = strings.auth_verifyEmailModalText1,
		text2 = strings.auth_verifyEmailModalText2,
	} = props;
	const history = useHistory();

	return (
		<Dialog onClose={() => setVisible(false)} open={visible} disableBackdropClick disableEscapeKeyDown>
			<DialogTitle disableTypography>
				<Typography variant="h5" align="center">
					{strings.auth_verifyEmailModalTitle}
				</Typography>
			</DialogTitle>
			<DialogContent>
				<Typography variant="body1">{text1}</Typography>
				<Typography variant="body1">{text2}</Typography>
				<StandartButton
					color="success"
					variant="contained"
					onClick={
						onButtonClick ||
						(() => {
							setVisible(false);
							history.push("/signup", locationState);
						})
					}
				>
					{strings.ok}
				</StandartButton>
			</DialogContent>
		</Dialog>
	);
};

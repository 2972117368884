import React from "react";
import { useRouteMatch } from "react-router-dom";
import { Box, Breadcrumbs, Typography } from "@material-ui/core";
import _ from "lodash";

import { TLocale } from "@espresso/shared-config";
import { AppLink, StandartView, GoBackButton } from "components";
import strings from "helpers/strings";
import { helpSections } from "./sections";

interface IHelpPageProps {
	titleKey: keyof TLocale;
}

interface IBreadcrumb {
	titleKey: keyof TLocale;
	path?: string;
}

export const HelpPage = (props: React.PropsWithChildren<IHelpPageProps>) => {
	const { children, titleKey } = props;
	const { url } = useRouteMatch();
	const urlParts = url.match(/\/[^/]+/g);

	if (!urlParts) {
		return null;
	}
	const section = urlParts[1] ? helpSections.find((s) => s.path === urlParts[1].substr(1)) : undefined;
	const topic = urlParts[2] && section ? section.topics.find((t) => t.path === urlParts[2].substr(1)) : undefined;

	const sectionPath = _.dropRight(urlParts).join("");

	const baseBc: IBreadcrumb =
		!section && !topic ? { titleKey: "help_contents" } : { titleKey: "help_contents", path: urlParts[0] };
	const sectionBc: IBreadcrumb | undefined =
		section && (!topic ? { titleKey: section.titleKey } : { titleKey: section.titleKey, path: sectionPath });
	const topicBc: IBreadcrumb | undefined = topic && { titleKey: topic.titleKey };
	const breadcrumbs: IBreadcrumb[] = [baseBc];
	if (sectionBc) {
		breadcrumbs.push(sectionBc);
	}
	if (topicBc) {
		breadcrumbs.push(topicBc);
	}

	return (
		<StandartView disableNotifications>
			<Box className="help-view">
				<Box className="breadcrumbs-container MuiPaper-rounded">
					<Breadcrumbs>
						{breadcrumbs.map((bc, index) =>
							bc.path ? (
								<AppLink key={`bc-${index}`} to={bc.path}>
									<Typography variant="caption">
										{index === 0 && <i className="icon icon-home" />}
										{strings[bc.titleKey]}
									</Typography>
								</AppLink>
							) : (
								<Typography key={`bc-${index}`} variant="caption">
									{index === 0 && <i className="icon icon-home" />}
									{strings[bc.titleKey]}
								</Typography>
							),
						)}
					</Breadcrumbs>
				</Box>
				<Box className="title-container">
					{urlParts.length > 1 && <GoBackButton to={urlParts[0]} tooltipKey="request_creationBack" />}
					<Typography variant="h5">{strings[titleKey]}</Typography>
				</Box>
				{children}
			</Box>
		</StandartView>
	);
};

import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { StandartView, StandartButton, RequestsListWithPresets } from "../../components";
import useAppContext from "../../contexts/AppContext";

import withAuth from "../../helpers/withAuth";
import strings from "../../helpers/strings";
import { useHistory } from "react-router-dom";

export const HomeView = withAuth(() => {
	const { login } = useAppContext();
	const history = useHistory();

	return (
		<StandartView title="" fullWidth>
			{login?.Role == null ? (
				<Box display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			) : (
				<Grid container className="home-view">
					<>
						<Grid item md={7} sm={12} className="title-container">
							<Typography variant="h5">{strings.request_listTitle}</Typography>
						</Grid>
						<Grid item md={5} sm={12} className="buttons-container">
							<StandartButton
								className="new-request-button"
								color="success"
								onClick={(evt) => {
									evt.preventDefault();
									history.push("/request-create");
								}}
								href="/request-create"
							>
								{strings.request_createNew}
							</StandartButton>
						</Grid>
						<RequestsListWithPresets />
					</>
				</Grid>
			)}
		</StandartView>
	);
});

import React from "react";
import { ETaskStatus } from "@espresso/protocol";
import { CircularProgress, Tooltip } from "@material-ui/core";
import strings from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";

export interface IAttachmentStatusProps {
	status?:
		| ETaskStatus
		| "DimensionsLengthInvalid"
		| "DimensionsInvalid"
		| "LengthInvalid"
		| "FacebookProcessing"
		| "FacebookReviewing"
		| "FacebookRejected"
		| "FacebookAccepted"
		| null
		| undefined;
}

export const AttachmentStatus = (props: IAttachmentStatusProps) => {
	const {
		sharedConfig: {
			attachments: { videoMaxHeight, videoMaxWidth, videoMinLength, videoMaxLength },
		},
	} = useAppContext();

	switch (props.status) {
		case ETaskStatus.Done:
			return <i className="icon icon-analytics-acesses success" />;
		case ETaskStatus.Failed:
			return (
				<Tooltip title={strings.request_cropFailedStatusText} placement="top" arrow>
					<i className="icon icon-analytics-statys danger" />
				</Tooltip>
			);
		case "DimensionsLengthInvalid":
			return (
				<Tooltip
					title={strings.formatString(
						strings.request_cropTrimNeedStatusText,
						videoMaxWidth,
						videoMaxHeight,
						videoMinLength,
						videoMaxLength,
					)}
					placement="top"
					arrow
				>
					<i className="icon icon-analytics-statys danger" />
				</Tooltip>
			);
		case "DimensionsInvalid":
			return (
				<Tooltip
					title={strings.formatString(strings.request_cropNeedStatusText, videoMaxWidth, videoMaxHeight)}
					placement="top"
					arrow
				>
					<i className="icon icon-analytics-statys danger" />
				</Tooltip>
			);
		case "LengthInvalid":
			return (
				<Tooltip
					title={strings.formatString(strings.request_trimNeedStatusText, videoMinLength, videoMaxLength)}
					placement="top"
					arrow
				>
					<i className="icon icon-analytics-statys danger" />
				</Tooltip>
			);
		case "FacebookProcessing":
			return (
				<Tooltip title={strings.request_facebookProcessingStatusText} placement="top" arrow>
					<CircularProgress size={20} color="secondary" />
				</Tooltip>
			);
		case "FacebookReviewing":
			return (
				<Tooltip title={strings.request_facebookReviewingStatusText} placement="top" arrow>
					<i className="icon icon-review warning" />
				</Tooltip>
			);
		case "FacebookRejected":
			return (
				<Tooltip title={strings.request_facebookRejectedStatusText} placement="top" arrow>
					<i className="icon icon-create-ad danger" />
				</Tooltip>
			);
		case "FacebookAccepted":
			return (
				<Tooltip title={strings.request_facebookAcceptedStatusText} placement="top" arrow>
					<i className="icon icon-create-ad success" />
				</Tooltip>
			);
		case ETaskStatus.Processing:
		case null:
		case undefined:
			return (
				<Tooltip title={strings.request_croppingStatusText} placement="top" arrow>
					<CircularProgress size={20} color="secondary" />
				</Tooltip>
			);
	}
};

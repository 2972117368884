import React from "react";
import { Grid } from "@material-ui/core";
import strings from "../../../helpers/strings";
import { StandartButton, AppLink } from "..";
import useAppContext from "../../../contexts/AppContext";
import { EDeveloperRank, ELoginRole } from "@espresso/protocol";

export default function FooterLinks() {
	const { login, setLogoutModalVisible } = useAppContext();

	const isProAvailable =
		login?.Developer?.Rank === EDeveloperRank.Trusted ||
		login?.Developer?.Rank === EDeveloperRank.ProViewAccess ||
		login?.Role === ELoginRole.Manager ||
		login?.Role === ELoginRole.Publisher;

	return (
		<Grid item className="button-container">
			<AppLink to="/">
				<StandartButton color="transparent" variant="text" round>
					{strings.about_title}
				</StandartButton>
			</AppLink>
			{login ? (
				<AppLink to="/profile">
					<StandartButton color="transparent" variant="text" round>
						{strings.request_list}
					</StandartButton>
				</AppLink>
			) : (
				<AppLink to="request-express">
					<StandartButton color="transparent" variant="text" round>
						{strings.request_title}
					</StandartButton>
				</AppLink>
			)}
			{login && isProAvailable && (
				<AppLink to="/pro">
					<StandartButton color="transparent" variant="text" round>
						{strings.proDashboard_link}
					</StandartButton>
				</AppLink>
			)}
			<AppLink to="/help" target="_blank">
				<StandartButton color="transparent" variant="text" round>
					{strings.web_helpCenterTitle}
				</StandartButton>
			</AppLink>
			{login ? (
				<StandartButton color="transparent" variant="text" onClick={() => setLogoutModalVisible(true)} round>
					{strings.web_menuLogOut}
				</StandartButton>
			) : (
				<AppLink to="/signup">
					<StandartButton color="transparent" variant="text" round>
						{strings.web_menuLogIn}
					</StandartButton>
				</AppLink>
			)}
		</Grid>
	);
}

import React from "react";
import { SignUpForm, StandartView } from "../components";
import useAppContext from "../contexts/AppContext";
import { Redirect, useLocation } from "react-router-dom";
import strings from "../helpers/strings";
import { IAuthLocation } from "../helpers/withAuth";

export const SignUp = () => {
	const state = useLocation<IAuthLocation>().state;

	const { session } = useAppContext();

	if (session) {
		if (state?.successRedirect) {
			return <Redirect to={state.successRedirect} />;
		}
		return <Redirect to="/" />;
	}

	return (
		<StandartView
			disableNotifications
			title={strings.auth_signIn}
			pageBreakpoints={{ xs: 12, sm: 8, md: 6, lg: 5, xl: 4 }}
			backgroundImage="/images/signin_background.png"
		>
			<SignUpForm authDefaultMessageKey={state?.authMessageKey} />
		</StandartView>
	);
};

import React from "react";
import { Box, Typography } from "@material-ui/core";

import strings from "helpers/strings";
import { StandartTextCopyButton } from "components";

interface IProps {
	manualStatsEndpoint: string;
	apiKey: string;
	facebookId?: string;
}

const code = `import UIKit

@main
class AppDelegate: UIResponder, UIApplicationDelegate {

	var window: UIWindow?



	func applicationDidEnterBackground(_ application: UIApplication) {
		// Use this method to release shared resources, save user data, invalidate timers, and store enough application state information to restore your application to its current state in case it is terminated later.
		sendRobustaStats(to:false,to: 1);
	}
	func applicationWillEnterForeground(_ application: UIApplication) {
		// Called as part of the transition from the background to the active state; here you can undo many of the changes made on entering the background.

	}
	func applicationDidBecomeActive(_ application: UIApplication) {
		// Restart any tasks that were paused (or not yet started) while the application was inactive. If the application was previously in the background, optionally refresh the user interface.
		sendRobustaStats(to:true,to: 1);
	}

	private var uid : String?; // user id, you may use your own, generate UUID and remember it
	private var sessionStartTime: TimeInterval = 0; // session stars
	private var lastStatActivity: TimeInterval = 0; // last time we've send any stats
	fileprivate func setupRobustaUID() { // generating and saving random user id
		if(uid==nil)
		{
			uid = UserDefaults.standard.string(forKey: "robusta_uid");
			if(uid==nil)
			{
				uid = UUID().uuidString;
				UserDefaults.standard.set(uid, forKey: "robusta_uid")
			}
		}
	}


	func sendRobustaStats(to appAwaking: Bool, to level:Int = 0 ) {

		setupRobustaUID()

		let currentTime = Date().timeIntervalSinceReferenceDate; // get current time
		if (
			sessionStartTime == 0 ||                // if this first start
			(appAwaking &&                          // or we are awiking
			currentTime - lastStatActivity > 3*60)  // and we've been in foreground longer than 3 minutes
			) // we can assume - this is new version
		{
			sessionStartTime = currentTime; // we reset session with current time
		}

		// generating URL string
		let url = URL(string: String("https://master.server.xpresso.games/api/v1/bc608bbe-7c24-4524-87a0-a9ba64537392/event/3242423234342/\\(uid!)/\\(Int(sessionStartTime))/\\(Int(currentTime-sessionStartTime))/\\(level)"))!
		// we are making request
		let task = URLSession.shared.dataTask(with: url) {(data, response, error) in
			guard let data = data else { return }
			print(String(data: data, encoding: .utf8)!) // printing result for debug. you should see {"success":true}
		}
		task.resume()
		lastStatActivity = currentTime; // remembering time
	}


}`;

export const SetupSdkManualModalContent = ({ manualStatsEndpoint, apiKey, facebookId }: IProps) => {
	return (
		<>
			<Typography>{strings.request_createRequestInfoStatsManualGuide1}</Typography>
			<ul>
				<li>
					<Typography>{strings.request_createRequestInfoStatsManualGuide1Li1}</Typography>
				</li>
				<li>
					<Typography>{strings.request_createRequestInfoStatsManualGuide1Li2}</Typography>
				</li>
				<li>
					<Typography>{strings.request_createRequestInfoStatsManualGuide1Li3}</Typography>
				</li>
			</ul>
			<Typography>{strings.request_createRequestInfoStatsManualGuide2}</Typography>
			<Box className="text-with-button">
				<StandartTextCopyButton
					valueToCopy={`${manualStatsEndpoint}/{userId}/{sessionStart}/{sessionLength}/{level}`}
				/>
				<Typography color="secondary">
					<b>{`${manualStatsEndpoint}/{userId}/{sessionStart}/{sessionLength}/{level}`}</b>
				</Typography>
			</Box>
			<Typography>{strings.request_createRequestInfoStatsManualGuide3}</Typography>
			<ul>
				<li>
					<Typography>
						{strings.formatString(
							strings.request_createRequestInfoStatsManualGuide3Li1,
							<b>{strings.request_createRequestInfoStatsManualGuide3Li1Bold}</b>,
						)}
					</Typography>
				</li>
				<li>
					<Typography>
						{strings.formatString(
							strings.request_createRequestInfoStatsManualGuide3Li2,
							<b>{strings.request_createRequestInfoStatsManualGuide3Li2Bold}</b>,
						)}
					</Typography>
				</li>
				<li>
					<Typography>
						{strings.formatString(
							strings.request_createRequestInfoStatsManualGuide3Li3,
							<b>{strings.request_createRequestInfoStatsManualGuide3Li3Bold}</b>,
						)}
					</Typography>
				</li>
				<li>
					<Typography>
						{strings.formatString(
							strings.request_createRequestInfoStatsManualGuide3Li4,
							<b>{strings.request_createRequestInfoStatsManualGuide3Li4Bold}</b>,
						)}
					</Typography>
				</li>
			</ul>
			<Typography>{strings.request_createRequestInfoStatsManualGuide4}</Typography>
			<ul>
				<li>
					<Typography>
						{strings.formatString(
							strings.request_createRequestInfoStatsManualGuide4Li1,
							<b>{strings.request_createRequestInfoStatsManualGuide4Li1Bold}</b>,
							<span>{apiKey}</span>,
						)}
					</Typography>
				</li>
				<li>
					<Typography>
						{strings.formatString(
							strings.request_createRequestInfoStatsManualGuide4Li2,
							<b>{strings.request_createRequestInfoStatsManualGuide4Li2Bold}</b>,
							<span>{facebookId || ""}</span>,
						)}
					</Typography>
				</li>
			</ul>
			<Typography>{strings.request_createRequestInfoStatsManualGuide5}</Typography>
			<Typography component="code" className="code">
				{code}
			</Typography>
		</>
	);
};

import { TLocale } from "@espresso/shared-config";
import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React, { useMemo, useState } from "react";
import { DeveloperRequestFragment, ELoginRole, ERequestStatus } from "@espresso/protocol";
import strings from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import { ConfirmModal, languagesSwitchItems, StandartButton, StandartTextInput, TColor } from "../../components";

export interface IAction {
	labelKey: keyof TLocale;
	color: TColor;
	onClick: () => void;
}
export interface IRequestActionsProps {
	disabled?: boolean;
	loading?: boolean;
	request?: DeveloperRequestFragment;
	onChangeStatus: (newStatus: ERequestStatus, comment?: string) => void;
	allCheckValid: () => boolean;
	onRemove?: () => void;
	onRestore?: () => void;
	preActions?: IAction[];
	postActions?: IAction[];
}

const actionsGraph: { [key in ERequestStatus]?: { [key in ELoginRole]?: ERequestStatus[] } } = {
	Private: {
		Manager: [ERequestStatus.Private],
		Publisher: [ERequestStatus.Private],
	},
	New: {
		User: [ERequestStatus.Validation],
		Manager: [ERequestStatus.Validation],
		Publisher: [ERequestStatus.Validation],
	},
	Validation: {
		Manager: [ERequestStatus.Validation, ERequestStatus.Running],
		Publisher: [ERequestStatus.Validation, ERequestStatus.Running],
	},
	Running: {
		Manager: [ERequestStatus.Running],
		Publisher: [ERequestStatus.Running],
	},
	CampaignFinished: {
		Manager: [ERequestStatus.CampaignFinished],
		Publisher: [ERequestStatus.CampaignFinished],
	},
	RunFailed: {
		Manager: [ERequestStatus.RunFailed],
		Publisher: [ERequestStatus.RunFailed],
	},
	Rejected: {
		Manager: [ERequestStatus.Rejected],
		Publisher: [ERequestStatus.Rejected],
	},
	Finished: {
		Manager: [ERequestStatus.Finished],
		Publisher: [ERequestStatus.Finished],
	},
};

const finalActionsGraph: { [key in ERequestStatus]?: { [key in ELoginRole]?: Array<ERequestStatus | "Divider"> } } = {
	Private: {
		Manager: [ERequestStatus.Rejected, ERequestStatus.Finished, ERequestStatus.RunFailed],
		Publisher: [ERequestStatus.Rejected, ERequestStatus.Finished, ERequestStatus.RunFailed],
	},
	Validation: {
		Manager: [
			ERequestStatus.Returned,
			"Divider",
			ERequestStatus.Finished,
			ERequestStatus.Rejected,
			ERequestStatus.RunFailed,
		],
		Publisher: [
			ERequestStatus.Returned,
			"Divider",
			ERequestStatus.Finished,
			ERequestStatus.Rejected,
			ERequestStatus.RunFailed,
		],
	},
	Valid: { Anonym: [], Unconfirmed: [], User: [], Manager: [], Publisher: [] },
	NotValid: { Anonym: [], Unconfirmed: [], User: [], Manager: [], Publisher: [] },
	Running: {
		Manager: [ERequestStatus.Finished, ERequestStatus.Rejected, ERequestStatus.RunFailed],
		Publisher: [ERequestStatus.Finished, ERequestStatus.Rejected, ERequestStatus.RunFailed],
	},
	CampaignFinished: {
		Manager: [ERequestStatus.Finished, ERequestStatus.Rejected, ERequestStatus.RunFailed],
		Publisher: [ERequestStatus.Finished, ERequestStatus.Rejected, ERequestStatus.RunFailed],
	},
};

const buttonsConfig: { [key in ERequestStatus]?: { localeKey: keyof TLocale; color: TColor } } = {
	New: { localeKey: "save", color: "success" },
	Private: { localeKey: "save", color: "warning" },
	Validation: { localeKey: "request_setValidation", color: "warning" },
	Valid: { localeKey: "save", color: "success" },
	NotValid: { localeKey: "save", color: "success" },
	Running: { localeKey: "request_setRunning", color: "success" },
	CampaignFinished: { localeKey: "save", color: "warning" },
	RunFailed: { localeKey: "request_setRunFailed", color: "success" },
	Rejected: { localeKey: "request_setRejected", color: "success" },
	Finished: { localeKey: "request_setFinished", color: "success" },
};

const finalStatusConfig: {
	[key in ERequestStatus]?: { localeKey: keyof TLocale; color: TColor; icon: string; descriptionKey: keyof TLocale };
} = {
	RunFailed: {
		localeKey: "request_setRunFailed",
		color: "rose",
		icon: "badly",
		descriptionKey: "request_finalStatusRunFailedDescription",
	},
	Rejected: {
		localeKey: "request_setRejected",
		color: "warning",
		icon: "satisfactorily",
		descriptionKey: "request_finalStatusRejectedDescription",
	},
	Finished: {
		localeKey: "request_setFinished",
		color: "success",
		icon: "good",
		descriptionKey: "request_finalStatusFinishedDescription",
	},
	Returned: {
		localeKey: "request_setReturned",
		color: "secondary",
		icon: "turn-left",
		descriptionKey: "request_finalStatusReturnedDescription",
	},
};

export const RequestActions = (props: IRequestActionsProps) => {
	const { login, lang } = useAppContext();

	const [finalStatusOpened, setFinalStatusOpened] = useState(false);
	const [comment, setComment] = useState(props.request?.Comment || "");
	const [finalStatus, setFinalStatus] = useState<ERequestStatus>();
	const [confirmVisible, setConfirmVisible] = useState(false);

	const developerLang = useMemo(() => {
		return (
			languagesSwitchItems.find((item) => item.value === props.request?.Developer.Login.Lang)?.label || "enLabel"
		);
	}, [props.request]);

	if (!props.request || !login) {
		return null;
	}

	if (props.request.Deleted && props.onRestore) {
		return (
			<Box className="actions-container">
				<StandartButton
					disabled={props.loading}
					color="success"
					onClick={props.onRestore}
					size="large"
					variant="outlined"
					startIcon={<i className="icon icon-file-5" />}
				>
					{strings.request_restoreTitle}
				</StandartButton>
			</Box>
		);
	}
	const openFinalStatus = () => setFinalStatusOpened(true);
	const closeFinalStatus = () => setFinalStatusOpened(false);

	const overrideButtonParams: {
		[key in ERequestStatus]?: {
			[key in ELoginRole]?: {
				[key in ERequestStatus]?: { disabled?: () => boolean; label?: keyof TLocale; color?: TColor };
			};
		};
	} = {
		Validation: {
			Manager: {
				[ERequestStatus.Running]: { disabled: () => !props.allCheckValid() },
			},
			Publisher: {
				[ERequestStatus.Running]: { disabled: () => !props.allCheckValid() },
			},
		},
	};
	const currentStatus = props.request.Status;

	const availableNextStatuses = actionsGraph[currentStatus]?.[login.Role];
	let availableFinalStatuses = finalActionsGraph[currentStatus]?.[login.Role];
	const metric = props.request?.Metrics.find((m) => !m.AttachmentId);
	if (metric?.FBCampaignId) {
		availableFinalStatuses = availableFinalStatuses?.filter((s) => s !== ERequestStatus.Returned);
	}
	const overrideButtonParamsStatuses = overrideButtonParams?.[currentStatus]?.[login.Role];
	const onlyButton =
		!availableFinalStatuses?.length &&
		availableNextStatuses &&
		availableNextStatuses.length === 1 &&
		!props.preActions?.length &&
		!props.postActions?.length;
	const selectedFinalStatusConfig = finalStatus && finalStatusConfig[finalStatus];

	return (
		<Box className="actions-container">
			{props.preActions?.map((action, idx) => {
				return (
					<StandartButton
						disabled={props.disabled || props.loading}
						key={`pre-${idx}`}
						color={action.color}
						onClick={action.onClick}
						size="large"
					>
						{strings[action.labelKey]}
					</StandartButton>
				);
			})}
			{!!availableFinalStatuses?.length && currentStatus === ERequestStatus.Validation && (
				<div>
					<StandartButton color="danger" size="large" onClick={openFinalStatus}>
						{strings.request_setFinalStatus}
					</StandartButton>
					{props.onRemove && (
						<StandartButton
							onClick={() => setConfirmVisible(true)}
							color="danger"
							variant="outlined"
							size="large"
							justIcon
						>
							<i className="icon icon-delit" />
						</StandartButton>
					)}
				</div>
			)}
			{availableNextStatuses?.map((status) => {
				const buttonConfig = buttonsConfig?.[status];
				if (!buttonConfig) {
					return null;
				}
				return (
					<StandartButton
						key={status}
						color={
							onlyButton
								? "success"
								: currentStatus === status
								? "secondary"
								: overrideButtonParamsStatuses?.[status]?.color || buttonConfig.color
						}
						onClick={() => props.onChangeStatus(status)}
						disabled={
							props.request?.Status === status
								? props.loading
								: props.disabled ||
								  props.loading ||
								  overrideButtonParamsStatuses?.[status]?.disabled?.()
						}
						size="large"
						type={onlyButton || currentStatus === status ? "submit" : "button"}
					>
						{
							strings[
								currentStatus === status
									? "save"
									: overrideButtonParamsStatuses?.[status]?.label || buttonConfig.localeKey
							]
						}
					</StandartButton>
				);
			})}
			{!!availableFinalStatuses?.length && currentStatus !== ERequestStatus.Validation && (
				<StandartButton color="success" onClick={openFinalStatus} size="large" disabled={props.disabled}>
					{strings.request_setFinalStatus}
				</StandartButton>
			)}
			{props.postActions?.map((action, idx) => {
				return (
					<StandartButton
						disabled={props.disabled || props.loading}
						key={`post-${idx}`}
						color={action.color}
						onClick={action.onClick}
						size="large"
					>
						{strings[action.labelKey]}
					</StandartButton>
				);
			})}
			<Dialog className="withCloseButton request-actions" open={finalStatusOpened} onClose={closeFinalStatus}>
				<IconButton className="closeButton" onClick={closeFinalStatus}>
					<i className="icon icon-close" />
				</IconButton>
				<DialogTitle disableTypography>
					<Typography variant="h5" align="center">
						{strings.request_finalStatusHeader}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Box display="flex" flexDirection="column">
						<Typography variant="body1">{strings.request_finalStatusDescription}</Typography>
						<Box className="status-selector">
							{availableFinalStatuses?.map((status, idx) => {
								if (status === "Divider") {
									return <div key={idx} className="divider" />;
								}
								const conf = finalStatusConfig[status];
								if (!conf) {
									return null;
								}
								return (
									<StandartButton
										key={status}
										variant={finalStatus === status ? "contained" : "text"}
										color={conf.color}
										onClick={() => {
											setFinalStatus(status);
											setComment("");
										}}
									>
										<Box display="flex" flexDirection="column">
											<i className={`icon icon-${conf.icon || ""}`} />
											<span>{strings[conf.localeKey]}</span>
										</Box>
									</StandartButton>
								);
							})}
						</Box>
						{selectedFinalStatusConfig ? (
							<Box className="status-description">
								<i className="icon icon-info" />
								<Typography variant="body2" color="secondary">
									{strings[selectedFinalStatusConfig.descriptionKey]}
								</Typography>
							</Box>
						) : (
							<Box className="lang-warning">
								<i className="icon icon-attention" />
								<Typography variant="body2">{strings.request_finalStatusNotSetDescription}</Typography>
							</Box>
						)}
						<StandartTextInput
							label={`${strings.request_finalCommentlabel} *`}
							value={comment}
							onChange={setComment}
							disabled={!finalStatus}
							placeholder={strings.getString(
								"request_actionsPlaceholder",
								props.request?.Developer.Login.Lang.toLowerCase(),
							)}
						/>
						{lang !== props.request?.Developer.Login.Lang && (
							<Box className="lang-warning">
								<i className="icon icon-attention" />
								<Typography variant="body2">
									{strings.standartFormat(strings.request_finalStatuslangInfo, {
										lang: strings[developerLang],
									})}
								</Typography>
							</Box>
						)}
						<StandartButton
							color="success"
							variant="contained"
							disabled={!comment.length || !finalStatus}
							onClick={() => {
								if (!finalStatus) {
									return;
								}
								props.onChangeStatus(finalStatus, comment || undefined);
								closeFinalStatus();
							}}
						>
							{strings.send}
						</StandartButton>
					</Box>
				</DialogContent>
			</Dialog>
			{props.onRemove && (
				<ConfirmModal
					visible={confirmVisible}
					title={strings.request_removeTitle}
					onClose={() => setConfirmVisible(false)}
					onConfirm={props.onRemove}
					text={strings.request_removeText}
					confirmButtonLabel={strings.delete}
					confirmButtonColor="danger"
				/>
			)}
		</Box>
	);
};

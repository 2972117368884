import React from "react";
import { MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";

import useAppContext from "contexts/AppContext";

export const MuiPickersProvider = ({ children }: React.PropsWithChildren<{}>) => {
	const { lang } = useAppContext();

	return (
		<MuiPickersUtilsProvider utils={MomentUtils} locale={lang.toLowerCase()}>
			{children}
		</MuiPickersUtilsProvider>
	);
};

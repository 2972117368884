import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";
import { ELang } from "@espresso/protocol";
import useAppContext from "../../../../contexts/AppContext";

export const imgSources: { [key in ELang]: string } = {
	Ru: "/images/help/facebookAdAccount/Ru1.png",
	En: "/images/help/facebookAdAccount/En1.png",
	Ch: "/images/help/facebookAdAccount/Ch1.png",
	Tr: "/images/help/facebookAdAccount/En1.png",
};

export const BaristaId = () => {
	const { lang } = useAppContext();
	return (
		<>
			<Typography variant="body1">{strings.help_adAccountIdSummary}</Typography>
			<ol className="MuiTypography-body1">
				<li>{strings.help_adAccountIdDetails1}</li>
				<li>{strings.help_adAccountIdDetails2}</li>
				<li>
					{strings.formatString(strings.help_adAccountIdDetails3, {
						bold: <b>{strings.help_adAccountIdDetails3Bold}</b>,
					})}
				</li>
				<li>
					{strings.formatString(strings.help_adAccountIdDetails4, {
						bold1: <b>{strings.help_adAccountIdDetails4Bold1}</b>,
						bold2: <b>{strings.help_adAccountIdDetails4Bold2}</b>,
					})}
				</li>
				<li>
					{strings.formatString(strings.help_adAccountIdDetails5, {
						bold1: <b>{strings.help_adAccountIdDetails5Bold1}</b>,
						bold2: <b>{strings.help_adAccountIdDetails5Bold2}</b>,
					})}
				</li>
			</ol>
			<div className="img-wrap">
				<img src={imgSources[lang]} alt="" />
			</div>
		</>
	);
};

import React, { useEffect, useCallback, useState } from "react";
import { NetworkStatus } from "@apollo/client";
import {
	Box,
	Fade,
	Table,
	TableBody,
	TableCell,
	TableCellProps,
	TableContainer,
	TableHead,
	TableRow,
	TableSortLabel,
	Typography,
} from "@material-ui/core";
import classNames from "classnames";
import moment from "moment";

import {
	EDeveloperPlatform,
	EGameStatOrderField,
	ELoginRole,
	EOrderDirection,
	EOrderNullPosition,
	GameForStatFragment,
	GetGameStatInput,
	useGetGamesStatQuery,
} from "@espresso/protocol";
import { formatNumber, TLocale } from "@espresso/shared-config";
import { MaterialUiPickersDate } from "@material-ui/pickers/typings/date";
import useAppContext from "../../contexts/AppContext";
import { useLoadingSignal } from "contexts/LoadingContext";
import { gameMetricsConfig, getGameMetric, TGameMetricNames } from "helpers/gameMetrics";
import strings from "helpers/strings";
import { getDeveloperName } from "helpers/formatDeveloperName";

import { AppLink, PlaceholderView, GameIcon } from "components";
import { FiltersAndReportSummary } from "./FiltersAndReportSummary";

interface IGameListRowProps {
	game: GameForStatFragment;
	columns: IColumn[];
	params: IGamesListParams;
}

interface ICellProps extends TableCellProps {
	game: GameForStatFragment;
	params: IGamesListParams;
}

interface IMetricCellProps extends ICellProps {
	metricName: TGameMetricNames;
}

export interface IGamesListParams extends Omit<GetGameStatInput, "From" | "To" | "Disabled"> {
	OrderField: EGameStatOrderField;
	OrderDirection: EOrderDirection;
	From: MaterialUiPickersDate;
	To: MaterialUiPickersDate;
	Disabled: boolean[];
}

export interface IColumn {
	key: "ProjectName" | "EditProject" | "RevenueSharing" | "DeveloperName" | TGameMetricNames;
	orderField?: EGameStatOrderField;
	orderDirection?: EOrderDirection;
	titleKey?: keyof TLocale;
	Cell: (props: ICellProps) => JSX.Element;
}

const SortIcon = ({ className }: { className: string }) => {
	return <i className={`icon icon-arrow-3 ${className}`} />;
};

const BaseCell = (props: React.PropsWithChildren<ICellProps>) => {
	const { game, children, params, ...rest } = props;
	return (
		<TableCell {...rest}>
			<AppLink
				to={{
					pathname: `/pro/game-${game.Id}`,
					state: { from: params.From?.toISOString(), to: params.To?.toISOString() },
				}}
			>
				{children}
			</AppLink>
		</TableCell>
	);
};

const HeaderCell = (props: {
	column: IColumn;
	direction: "asc" | "desc";
	sortField: EGameStatOrderField;
	onSortLabelClick: (sortField: EGameStatOrderField, defaultDirection: EOrderDirection) => void;
}) => {
	const {
		column: { titleKey, orderField, key, orderDirection = EOrderDirection.ASC },
		direction,
		sortField,
		onSortLabelClick,
	} = props;

	return (
		<TableCell className={key}>
			{titleKey && orderField && onSortLabelClick && (
				<TableSortLabel
					onClick={() => onSortLabelClick(orderField, orderDirection)}
					direction={direction}
					IconComponent={SortIcon}
					active={sortField === orderField}
				>
					<Box className="head-cell-content">
						<Typography variant="subtitle1">{strings.formatString(strings[titleKey], <br />)}</Typography>
					</Box>
				</TableSortLabel>
			)}
		</TableCell>
	);
};

const NameCell = (props: ICellProps) => {
	const { game, params } = props;
	return (
		<BaseCell className="name-cell" game={game} params={params}>
			<Typography component="div" variant="subtitle2" className="name-cell-content">
				<GameIcon iconUrl={game.IconUrl} tikTokId={game.TikTokId} />
				<span className="text-ellipsis">
					{game.Platform === EDeveloperPlatform.Android ? (
						<i className="icon icon-android" />
					) : (
						<i className="icon icon-apple" />
					)}
					{game.Name}
				</span>
			</Typography>
		</BaseCell>
	);
};

const MetricCell = (props: IMetricCellProps) => {
	const { game, metricName, params } = props;
	const metricValue = getGameMetric(game, metricName);
	const checkFn = gameMetricsConfig[metricName]?.check;
	const checkResult = checkFn && metricValue !== null ? checkFn(metricValue) : null;

	const metricString =
		metricValue !== null ? formatNumber(metricValue?.toFixed(2), gameMetricsConfig[metricName]?.unit) : "—";

	const metricClassnames = classNames("metric-text", {
		positive: checkResult,
		negative: checkResult !== null ? !checkResult : undefined,
	});
	return (
		<BaseCell className="metric-cell" game={game} params={params}>
			<Typography variant={checkResult !== null ? "subtitle1" : "body1"} className={metricClassnames}>
				{metricString || "-"}
			</Typography>
		</BaseCell>
	);
};

const GameListRow = (props: IGameListRowProps) => {
	const { game, columns, params } = props;
	return (
		<TableRow className="interactive">
			{columns.map((c) => (
				<c.Cell key={`${game.Id}-${c.key}`} game={game} params={params} />
			))}
		</TableRow>
	);
};

const сommonColumns: IColumn[] = [
	{
		key: "ProjectName",
		orderField: EGameStatOrderField.GameName,
		Cell: NameCell,
		titleKey: "proDashboard_projectName",
		orderDirection: EOrderDirection.ASC,
	},
	{
		key: "Spend",
		orderField: EGameStatOrderField.Spend,
		Cell: (props) => <MetricCell {...props} metricName="Spend" />,
		titleKey: gameMetricsConfig.Spend.titleKey,
		orderDirection: EOrderDirection.DESC,
	},
	{
		key: "TotalSpend",
		orderField: EGameStatOrderField.TotalSpend,
		Cell: (props) => <MetricCell {...props} metricName="TotalSpend" />,
		titleKey: gameMetricsConfig.TotalSpend.titleKey,
		orderDirection: EOrderDirection.DESC,
	},
	{
		key: "AdRevenue",
		orderField: EGameStatOrderField.Revenue,
		Cell: (props) => <MetricCell {...props} metricName="AdRevenue" />,
		titleKey: gameMetricsConfig.AdRevenue.titleKey,
		orderDirection: EOrderDirection.DESC,
	},
	{
		key: "Roas",
		orderField: EGameStatOrderField.Roas,
		Cell: (props) => <MetricCell {...props} metricName="Roas" />,
		titleKey: gameMetricsConfig.Roas.titleKey,
		orderDirection: EOrderDirection.DESC,
	},
	{
		key: "RevenueSharing",
		orderField: EGameStatOrderField.RevenueSharing,
		Cell: (props) => (
			<BaseCell {...props}>
				<Typography>{props.game.RevenueSharing}%</Typography>
			</BaseCell>
		),
		titleKey: "proDashboard_gameMetricRevShare",
		orderDirection: EOrderDirection.ASC,
	},
	{
		key: "Income",
		orderField: EGameStatOrderField.Income,
		Cell: (props) => <MetricCell {...props} metricName="Income" />,
		titleKey: gameMetricsConfig.Income.titleKey,
		orderDirection: EOrderDirection.DESC,
	},
	{
		key: "RetentionD1",
		orderField: EGameStatOrderField.RetentionD1,
		Cell: (props) => <MetricCell {...props} metricName="RetentionD1" />,
		titleKey: gameMetricsConfig.RetentionD1.titleKey,
		orderDirection: EOrderDirection.DESC,
	},
];

const managerColumns: IColumn[] = [
	...сommonColumns,
	{
		key: "DeveloperName",
		orderField: EGameStatOrderField.DeveloperName,
		Cell: (props) => (
			<BaseCell {...props}>
				<Typography className="text-ellipsis">
					{props.game.Developer ? getDeveloperName(props.game.Developer) : ""}
				</Typography>
			</BaseCell>
		),
		titleKey: "developer_developerNameLabel",
		orderDirection: EOrderDirection.ASC,
	},
	{
		key: "EditProject",
		Cell: (props) => (
			<BaseCell {...props} className="edit-project-cell">
				<i className="icon icon-icon-pencil" />
			</BaseCell>
		),
	},
];

const DEFAULT_GAMES_LIST_PARAMS = {
	OrderDirection: EOrderDirection.ASC,
	OrderField: EGameStatOrderField.GameName,
	SearchString: "",
	From: moment().subtract(30, "days"),
	To: moment(),
	Disabled: [false],
};

const flipOrderDirection = (direction: EOrderDirection) =>
	direction === EOrderDirection.ASC ? EOrderDirection.DESC : EOrderDirection.ASC;

const getNullsPosition = (orderField: EGameStatOrderField, direction: EOrderDirection) => {
	if (orderField === EGameStatOrderField.DeveloperName) {
		return EOrderNullPosition.LAST;
	}
	return direction === EOrderDirection.ASC ? EOrderNullPosition.FIRST : EOrderNullPosition.LAST;
};

const getGameStatsInputFromParams = (params: IGamesListParams) => {
	return {
		OrderDirection: params.OrderDirection,
		OrderField: params.OrderField,
		OrderNullPosition: getNullsPosition(params.OrderField, params.OrderDirection),
		SearchString: params.SearchString,
		From: params.From?.toISOString(),
		To: params.To?.toISOString(),
		Disabled: params.Disabled[0],
	};
};

export const GamesList = () => {
	//#region Internal state
	const [gamesListParams, setGamesListParams] = useState<IGamesListParams>(DEFAULT_GAMES_LIST_PARAMS);

	const { login } = useAppContext();
	//#endregion

	//#region Data fetching
	const { data, networkStatus, refetch, loading } = useGetGamesStatQuery({
		variables: {
			input: getGameStatsInputFromParams(gamesListParams),
		},
		notifyOnNetworkStatusChange: true,
		fetchPolicy: "cache-and-network",
	});
	const games = data?.getGamesStat ?? [];
	useLoadingSignal(loading);

	useEffect(() => {
		refetch?.({ input: getGameStatsInputFromParams(gamesListParams) });
	}, [gamesListParams, refetch]);
	//#endregion

	//#region Handlers
	const handleSortLabelClick = useCallback(
		(newSortField: EGameStatOrderField, defaultDirection: EOrderDirection) => {
			const isOrderFieldSame = newSortField === gamesListParams.OrderField;
			const newSortDirection = isOrderFieldSame
				? flipOrderDirection(gamesListParams.OrderDirection)
				: defaultDirection;
			setGamesListParams((prev) => ({ ...prev, OrderField: newSortField, OrderDirection: newSortDirection }));
		},
		[gamesListParams.OrderDirection, gamesListParams.OrderField],
	);
	//#endregion

	//#region Helpers
	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;
	const columnsConfig = isManagerOrPublisher ? managerColumns : сommonColumns;

	const getPlaceholderTitle = () => {
		if (
			networkStatus === NetworkStatus.loading ||
			networkStatus === NetworkStatus.refetch ||
			networkStatus === NetworkStatus.setVariables
		) {
			return strings.loadingMessage;
		}
		if (games.length === 0) {
			return strings.formatString(strings.proDashboard_placeholderTitle, <br />);
		}
		return undefined;
	};
	const showGames = games.length > 0;
	//#endregion

	return (
		<>
			<FiltersAndReportSummary gamesListParams={gamesListParams} onGamesListParamsChange={setGamesListParams} />
			<TableContainer className={`games-list ${!showGames ? "min-height" : ""}`}>
				<Fade in={showGames} appear>
					<Table>
						<TableHead>
							<TableRow>
								{columnsConfig.map((c) => (
									<HeaderCell
										key={c.key}
										column={c}
										direction={gamesListParams.OrderDirection.toLowerCase() as "asc" | "desc"}
										sortField={gamesListParams.OrderField}
										onSortLabelClick={handleSortLabelClick}
									/>
								))}
							</TableRow>
						</TableHead>
						<TableBody>
							{games.map((g) => (
								<GameListRow key={g.Id} game={g} columns={columnsConfig} params={gamesListParams} />
							))}
						</TableBody>
					</Table>
				</Fade>
				{!showGames && <PlaceholderView imageSrc="/images/placeholder.png" title={getPlaceholderTitle()} />}
			</TableContainer>
		</>
	);
};

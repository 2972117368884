import React from "react";
import CircularProgress, { CircularProgressProps } from "@material-ui/core/CircularProgress";
import Typography, { TypographyProps } from "@material-ui/core/Typography";
import Box from "@material-ui/core/Box";

export const CircularProgressWithLabel = (
	props: CircularProgressProps & { value: number } & { labelProps?: Omit<TypographyProps<"div">, "component"> },
) => {
	const { labelProps, ...circularProps } = props;
	return (
		<Box className="circular-with-label">
			<CircularProgress {...circularProps} variant="static" />
			<Box className="label">
				<Typography color="inherit" variant="caption" {...labelProps} component="div">{`${Math.round(
					props.value,
				)}%`}</Typography>
			</Box>
		</Box>
	);
};

import { useEffect } from "react";
import { FieldName, useForm, UseFormOptions } from "react-hook-form";
import useAppContext from "../contexts/AppContext";

export const useStandartForm = <T>(props: UseFormOptions<T>) => {
	const form = useForm<T>(props);
	const { lang } = useAppContext();

	useEffect(() => {
		Object.entries(form.errors).forEach(([key, value]) => form.trigger(key as FieldName<T>));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [lang]);
	return form;
};

import React, { HTMLAttributes, useEffect, useMemo, useState } from "react";
import { Prompt, Redirect, useHistory, useLocation, useParams } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import { Box, CircularProgress, Fade, Grid, ListItem, ListItemText, Tab, Tabs, Typography } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import Rating from "@material-ui/lab/Rating";
import _ from "lodash";

import {
	DeveloperForListFragment,
	EDeveloperPlatform,
	EDeveloperRank,
	ELoginRole,
	GameFullFragment,
	useGetDevelopersByPackageNameLazyQuery,
	useGetGameQuery,
	useGetManyDevelopersLazyQuery,
	useUpsertGameMutation,
} from "@espresso/protocol";
import useAppContext from "contexts/AppContext";
import { useLoadingSignal } from "contexts/LoadingContext";
import { getDeveloperName } from "helpers/formatDeveloperName";
import strings from "helpers/strings";
import withAuth from "helpers/withAuth";

import {
	FullScreenPlaceholder,
	GoBackButton,
	StandartButton,
	StandartTextInput,
	StandartView,
	GameIcon,
} from "components";
import { GameStatsTable } from "./GameStatsTable";
import { ExpensesTable } from "./ExpensesTable";
import { TLocationState } from "./types";

type TFormData = {
	developer: DeveloperForListFragment | null;
	revShare: number;
};

const getNewDevelopers = (
	developers: DeveloperForListFragment[] | null | undefined,
	selectedDeveloper: DeveloperForListFragment | null,
) => {
	const newDevelopers: DeveloperForListFragment[] = [];
	if (developers) {
		newDevelopers.push(...developers);
	}
	if (selectedDeveloper && !newDevelopers.some((d) => d.Id === selectedDeveloper.Id)) {
		newDevelopers.push(selectedDeveloper);
	}
	return newDevelopers;
};

const getAutocompleteOptions = (options: DeveloperForListFragment[], value: DeveloperForListFragment | null) => {
	if (value) {
		return [...options, value];
	}
	return options;
};

const PlatformIcon = ({ platform }: { platform?: EDeveloperPlatform }) => {
	if (platform === EDeveloperPlatform.Android) {
		return <i className="icon icon-android" />;
	}
	return <i className="icon icon-apple" />;
};

const GameTitle = (props: { loading: boolean; gameName?: string }) => {
	const { loading, gameName = "Unnamed Game" } = props;

	return (
		<Fade in={!loading}>
			<Grid item xs={12} className="title-container">
				<GoBackButton to="/pro" tooltipKey="proDashboard_backToProjects" />
				<Typography variant="h5">{gameName}</Typography>
			</Grid>
		</Fade>
	);
};

const GameInfoBlock = (props: { loading: boolean; game?: GameFullFragment }) => {
	const { game, loading } = props;

	const {
		sharedConfig: { tenjin },
		login,
	} = useAppContext();

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	return (
		<Fade in={!loading}>
			<Grid item container xs={12}>
				<Grid item className="game-info-container" sm={10}>
					<GameIcon iconUrl={game?.IconUrl} tikTokId={game?.TikTokId} />
					<Box className="rating-and-bundle-container">
						<Box className="rating">
							<Rating value={game?.Rating || 0} readOnly />
							<Typography color="textSecondary" component="span">{`(${game?.Rating || "—"})`}</Typography>
						</Box>
						<Typography variant="caption" color="textSecondary" className="bundle-id">
							<PlatformIcon platform={game?.Platform} />

							{strings.proDashboard_gameBundleId}
						</Typography>
						<Typography variant="body1">{game?.BundleId}</Typography>
					</Box>
				</Grid>
				{isManagerOrPublisher && (
					<Grid item className="game-links-container" sm={2}>
						<Typography variant="caption" color="textSecondary">
							{strings.proDashboard_goTo}
						</Typography>
						<a href={`${tenjin.appsUrl}${game?.TenjinId}`} target="_blank" rel="noreferrer">
							<StandartButton
								variant="outlined"
								color="secondary"
								startIcon={<i className="icon icon-tenjin" />}
							>
								{strings.proDashboard_tenjin}
							</StandartButton>
						</a>
					</Grid>
				)}
			</Grid>
		</Fade>
	);
};

const GameSettings = (props: { loading: boolean; game?: GameFullFragment }) => {
	const { game, loading } = props;

	const { setInfoSnackbarParams, login, showExitPrompt, setShowExitPrompt } = useAppContext();
	const history = useHistory();

	//#region Internal State
	const [loadedDevelopers, setLoadedDevelopers] = useState<DeveloperForListFragment[]>([]);

	const { control, handleSubmit, reset, formState, ...form } = useForm<TFormData>({
		defaultValues: { developer: null, revShare: 0 },
	});
	//#endregion

	//#region Data fetching
	const [getDevelopers, { data: developersData, loading: loadingDevelopers }] = useGetManyDevelopersLazyQuery({
		variables: { input: { Pagination: { Limit: 25 } } },
		fetchPolicy: "cache-and-network",
		notifyOnNetworkStatusChange: true,
	});
	const developers = developersData?.getManyDevelopers;
	useLoadingSignal(loadingDevelopers);

	useEffect(() => {
		if (!loadingDevelopers) {
			const selectedDeveloper = form.getValues("developer");
			const newDevelopers: DeveloperForListFragment[] = getNewDevelopers(developers, selectedDeveloper);
			setLoadedDevelopers(newDevelopers);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [developers, loadingDevelopers]);

	const [
		getDevelopersByBundle,
		{ data: developersByBundleData, loading: loadingDevelopersByBundle },
	] = useGetDevelopersByPackageNameLazyQuery({ fetchPolicy: "cache-and-network" });
	const developersByBundle = developersByBundleData?.getDevelopersByPackageName;
	useLoadingSignal(loadingDevelopersByBundle);

	useEffect(() => {
		if (!game || !game.BundleId) {
			return;
		}
		if (
			game.Developer?.Id !== form.getValues("developer")?.Id ||
			game.RevenueSharing !== form.getValues("revShare")
		) {
			reset({ developer: game.Developer, revShare: game.RevenueSharing });
		}
		getDevelopersByBundle({ variables: { packageName: game.BundleId } });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [game, getDevelopersByBundle]);

	const [upsertGame, { loading: upsertingGame }] = useUpsertGameMutation();
	useLoadingSignal(upsertingGame);
	//#endregion

	//#region Handlers
	const onSubmit = async (data: TFormData) => {
		if (!game) {
			return;
		}
		const res = await upsertGame({
			variables: {
				data: {
					Id: game.Id,
					RevenueSharing: data.revShare,
					DeveloperId: data.developer?.Id || null,
				},
			},
		});

		if (res.errors || !res.data?.upsertGame.success) {
			return;
		}

		setInfoSnackbarParams({ open: true, message: strings.changesSaved });
		reset({ ...data });
	};

	const handleDeveloperSearchStringChange = useMemo(
		() =>
			_.debounce((SearchString: string) => {
				if (SearchString.length > 2) {
					getDevelopers({ variables: { input: { SearchString, Pagination: { Limit: 25 } } } });
				}
				if (SearchString.length === 0) {
					setLoadedDevelopers(developersByBundle ?? []);
				}
			}, 500),
		[developersByBundle, getDevelopers],
	);

	useEffect(() => {
		return () => {
			setShowExitPrompt(false);
		};
	}, [setShowExitPrompt]);

	useEffect(() => {
		setShowExitPrompt(formState.isDirty);
	}, [formState.isDirty, setShowExitPrompt]);

	//#endregion

	const developersForAutocomplete =
		form.getValues("developer") || loadedDevelopers.length > 0 ? loadedDevelopers : developersByBundle ?? [];

	const isManagerOrPublisher = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	return (
		<Fade in={!loading}>
			<Grid
				item
				container
				className="settings-container"
				xs={12}
				component="form"
				onSubmit={handleSubmit(onSubmit)}
			>
				<Grid item xs={12}>
					<Typography variant="subtitle1">{strings.publisher_settings}</Typography>
				</Grid>
				<Grid item xs={12} sm={10}>
					<Controller
						name="developer"
						control={control}
						render={(field) => (
							<Autocomplete
								disabled={!isManagerOrPublisher}
								options={getAutocompleteOptions(developersForAutocomplete, field.value)}
								loading={loadingDevelopers || loadingDevelopersByBundle}
								loadingText={strings.loading}
								noOptionsText={strings.searchEmpty}
								clearOnBlur={false}
								filterSelectedOptions
								getOptionLabel={(developer: DeveloperForListFragment) => getDeveloperName(developer)}
								getOptionSelected={(option, value) => option?.Id === value?.Id}
								{...field}
								onOpen={() => {
									const selectedDeveloper = form.getValues("developer");
									if (selectedDeveloper) {
										handleDeveloperSearchStringChange(getDeveloperName(selectedDeveloper));
									}
								}}
								onChange={(_e, value, reason) => {
									if (reason === "clear") {
										setLoadedDevelopers([]);
										return field.onChange(null);
									}
									return field.onChange(value);
								}}
								onInputChange={(_e, value, reason) => {
									if (reason === "input") {
										handleDeveloperSearchStringChange(value);
									}
								}}
								renderOption={(option, state) => (
									<ListItem dense divider component="span">
										<ListItemText
											primary={getDeveloperName(option)}
											secondary={option.ContactEmail}
										/>
									</ListItem>
								)}
								renderInput={(params) => (
									<StandartTextInput
										{...params}
										label={strings.developer_developerNameLabel}
										onChange={() => undefined}
									/>
								)}
							/>
						)}
					/>
				</Grid>
				<Grid item xs={12} sm={2}>
					<Controller
						name="revShare"
						control={control}
						render={({ ref, onChange, ...rest }) => (
							<StandartTextInput
								disabled={!isManagerOrPublisher}
								type="number"
								inputMode="numeric"
								label={strings.formatString(strings.proDashboard_gameMetricRevShare, " ")}
								inputProps={{ min: 0, max: 100 }}
								{...rest}
								onChange={(v) => {
									const numericValue = parseInt(v);
									Number.isNaN(numericValue)
										? onChange(form.getValues("revShare"))
										: onChange(numericValue);
								}}
							/>
						)}
					/>
				</Grid>
				{isManagerOrPublisher && (
					<Grid item xs={5} sm={2} className="save-button-container">
						<StandartButton
							type="submit"
							color="success"
							disabled={upsertingGame}
							startIcon={upsertingGame && <CircularProgress color="inherit" size={16} />}
						>
							{strings.save}
						</StandartButton>
					</Grid>
				)}
				<Prompt
					when={showExitPrompt}
					message={(locationNext, historyAction) => {
						return JSON.stringify({ historyAction, locationNext, currentLocation: history.location });
					}}
				/>
			</Grid>
		</Fade>
	);
};

const GameStatsBlock = (props: { game?: GameFullFragment; loading: boolean }) => {
	const { game, loading } = props;

	const { state: periodFromDashboard } = useLocation<TLocationState | undefined>();

	const [tab, setTab] = useState<string>("stats");

	return (
		<Fade in={!loading}>
			<Grid item xs={12} className="game-stats-container">
				<Tabs centered value={tab} onChange={(_e, v) => setTab(v)}>
					<Tab label={strings.proDashboard_stats} value="stats" />
					<Tab label={strings.proDashboard_expenses} value="expenses" />
				</Tabs>
				<TabPanel value="stats" selectedTab={tab} className="stats-panel">
					<GameStatsTable gameId={game?.Id} rowsPerPage={7} highlightedPeriod={periodFromDashboard} />
				</TabPanel>
				<TabPanel value="expenses" selectedTab={tab} className="expenses-panel">
					<ExpensesTable gameId={game?.Id} rowsPerPage={7} highlightedPeriod={periodFromDashboard} />
				</TabPanel>
			</Grid>
		</Fade>
	);
};

const TabPanel = (
	props: React.PropsWithChildren<{ value: string; selectedTab: string } & HTMLAttributes<HTMLDivElement>>,
) => {
	const { children, value, selectedTab, ...other } = props;

	return (
		<div role="tabpanel" hidden={value !== selectedTab} {...other}>
			{value === selectedTab && children}
		</div>
	);
};

const GameInfoComponent = (props: { gameInfo?: GameFullFragment; initialLoading: boolean }) => {
	const { gameInfo, initialLoading } = props;

	return (
		<StandartView>
			<Grid container className="game-info">
				{initialLoading && <FullScreenPlaceholder titleKey="request_emptyTitle" messageKey="loadingMessage" />}
				<GameTitle gameName={gameInfo?.Name} loading={initialLoading} />
				<GameInfoBlock loading={initialLoading} game={gameInfo} />
				<GameSettings loading={initialLoading} game={gameInfo} />
				<GameStatsBlock loading={initialLoading} game={gameInfo} />
			</Grid>
		</StandartView>
	);
};

export const GameInfoView = withAuth(() => {
	const { login } = useAppContext();
	const { gameId } = useParams<{ gameId: string }>();
	const isProAvailable =
		login?.Developer?.Rank === EDeveloperRank.Trusted ||
		login?.Developer?.Rank === EDeveloperRank.ProViewAccess ||
		login?.Role === ELoginRole.Manager ||
		login?.Role === ELoginRole.Publisher;

	const { data: gameInfoData, loading } = useGetGameQuery({
		variables: { id: gameId },
		fetchPolicy: "cache-and-network",
	});
	const gameInfo = gameInfoData?.getGame;
	useLoadingSignal(loading);

	if (login === undefined) {
		return null;
	}

	if (!isProAvailable) {
		return <Redirect to="/profile" />;
	}

	if (!gameInfo && !loading) {
		return <Redirect to="/pro" />;
	}
	return <GameInfoComponent gameInfo={gameInfo} initialLoading={loading} />;
});

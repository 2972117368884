import React from "react";

import { DeveloperRequestFragment } from "@espresso/protocol";
import { VideoMetricsTable } from "./VideoMetricsTable";
import { StandartAccordion } from "components";

interface IProps {
	request: DeveloperRequestFragment;
}

export const VideoMetricsBlock = ({ request }: IProps) => {
	return (
		<StandartAccordion titleKey="request_videoMetricsLabel" className="video-metrics-accordion">
			<VideoMetricsTable request={request} />
		</StandartAccordion>
	);
};

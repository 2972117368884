import { TLocale } from "@espresso/shared-config";
import { Grid, Typography } from "@material-ui/core";
import React from "react";
import strings from "../../../helpers/strings";
import { IStandartButtonProps, StandartButton } from "../StandartButton";

export interface IStandartParallaxContentProps {
	title: keyof TLocale;
	description?: keyof TLocale;
	position?: "left" | "rigrt" | "center";
	action?: {
		label: keyof TLocale;
		props: IStandartButtonProps;
	};
}

export const StandartParallaxContent = (props: IStandartParallaxContentProps) => {
	return (
		<Grid container>
			<Grid item className={props.position || "left"}>
				<div className="content">
					<Typography variant="h1" className="title">
						{strings.standartFormat(strings[props.title])}
					</Typography>
					{props.description && (
						<Typography variant="h6" className="description">
							{strings[props.description]}
						</Typography>
					)}
					{props.action && (
						<StandartButton {...props.action.props}>{strings[props.action.label]}</StandartButton>
					)}
				</div>
			</Grid>
		</Grid>
	);
};

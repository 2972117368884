import React, { useState } from "react";
import { Box, Grid, Typography } from "@material-ui/core";
import strings from "../../../helpers/strings";
import { useAnalytics } from "helpers/ga";
import { CreateGameRequestItem } from "./CreateGameRequestItem";
import { TCreateRequestFormProps } from "./types";
import { ERequestStatus, UpsertRequestInput } from "@espresso/protocol";
import { GoBackButton } from "components";
import { RequestInfo } from "../RequestInfo";
import { requestTypeConfig } from "../../../helpers/request";

export const CreateRequestFormInitial = (props: TCreateRequestFormProps) => {
	const { setCurrentStepStatus, submit, request } = props;
	const [submitting, setSubmitting] = useState(false);

	const { analyticsEvents } = useAnalytics();

	const handleRequestCreate = async (input: UpsertRequestInput, nextStepStatus: ERequestStatus) => {
		setSubmitting(true);
		// Оставляем статус "Отправка" или "Возвращена" если пользователь захотел сменить тип заявки
		// после завершения всех подготовительных шагов или возврата заявки менеджером для доработки
		if (request?.Status === ERequestStatus.PreValidation || request?.Status === ERequestStatus.Returned) {
			await submit({ ...input, Status: request.Status });
		} else {
			await submit(input);
		}
		setCurrentStepStatus(nextStepStatus);
		analyticsEvents.createRequestOfType(input.CampaignType);
	};

	return (
		<Box className="content">
			<Grid container>
				<Grid item xs={12}>
					<Box className="title-container">
						<GoBackButton to="/profile" />
						<Typography variant="h5">{strings.request_createRequestInitialTitle}</Typography>
					</Box>
					{request?.Status === ERequestStatus.Returned && !request.Deleted && (
						<RequestInfo request={request} />
					)}
				</Grid>
			</Grid>
			<Grid container className="initial-items-container" spacing={3}>
				{requestTypeConfig.map((config, i) => (
					<Grid key={i} item lg={4} xs={12} className="item-container">
						<CreateGameRequestItem
							onClick={() => handleRequestCreate(config.input, config.nextStepStatus)}
							disabled={submitting}
							{...config.itemProps}
						/>
					</Grid>
				))}
			</Grid>
		</Box>
	);
};

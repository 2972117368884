import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";

export const FbAppIdInvalid = () => {
	return (
		<>
			<Typography variant="body1">{strings.help_faqFbAppIdInvalidP1}</Typography>
			<ul className="MuiTypography-body1">
				<li>{strings.help_faqFbAppIdInvalidLi1}</li>
				<li>{strings.help_faqFbAppIdInvalidLi2}</li>
				<li>{strings.help_faqFbAppIdInvalidLi3}</li>
			</ul>
			<Typography variant="body1">
				{strings.help_faqFbAppIdInvalidP2}
				<a className="link" href="/help/configuration/fb-app-id">
					<i className="icon icon-link" />
					<b>{strings.help_configurationFbAppIdTitle}</b>
				</a>
			</Typography>
		</>
	);
};

import { DeveloperFullFragment, RequestForListFragment, RequestFullFragment } from "@espresso/protocol";
import { Maybe } from "graphql/jsutils/Maybe";
import strings from "./strings";

type TRequestParam = RequestForListFragment | RequestFullFragment;

export const formatDeveloperName = (request?: Maybe<TRequestParam>) =>
	request?.Developer ? getDeveloperName(request.Developer) : strings.developer_developerNameLabel;

export const getDeveloperName = (
	developer: Pick<DeveloperFullFragment, "Id" | "TeamName" | "ContactName" | "ContactSurname">,
) => {
	if (developer.TeamName) {
		return developer.TeamName;
	}
	if (developer.ContactName) {
		return `${developer.ContactName} ${developer.ContactSurname || ""}`;
	}
	return `${strings.developer_developerNameLabel}-${developer.Id}`;
};

import React from "react";
import { Typography } from "@material-ui/core";

import strings from "helpers/strings";
import useAppContext from "contexts/AppContext";
import { RobustaSdkModalContent } from "../../../RequestView/CreateRequest/CreateRequestFormInfoFacebook/SdkWizard/ModalContent";

export const RobustaSdk = () => {
	const { login } = useAppContext();

	const apiKey = login?.Developer?.ApiKey;

	return (
		<>
			<Typography variant="body1">{strings.request_createRequestInfoStatsDescription}</Typography>
			<RobustaSdkModalContent apiKey={apiKey} />
		</>
	);
};

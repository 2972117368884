import { Box, Dialog, DialogContent, DialogTitle, IconButton, Typography } from "@material-ui/core";
import React, { useState } from "react";
import {
	ELoginRole,
	ERequestStatus,
	GetOneRequestDocument,
	RequestFullFragment,
	useResetRequestCampaignMutation,
} from "@espresso/protocol";
import strings from "../../helpers/strings";
import useAppContext from "../../contexts/AppContext";
import { StandartButton, StandartTextInput } from "../../components";

export interface IRequestActionsProps {
	request?: RequestFullFragment;
}

export const ResetCampaign = (props: IRequestActionsProps) => {
	const { login } = useAppContext();

	const [resetRequestCampaign, { loading }] = useResetRequestCampaignMutation();
	const [opened, setOpened] = useState(false);
	const [name, setName] = useState(props.request?.CampaignName || "");

	if (
		!props.request ||
		!login ||
		(login.Role !== ELoginRole.Manager && login.Role !== ELoginRole.Publisher) ||
		props.request.Status === ERequestStatus.Running ||
		props.request.Status === ERequestStatus.CampaignFinished ||
		props.request.Status === ERequestStatus.Finished ||
		props.request.Status === ERequestStatus.Rejected ||
		props.request.Status === ERequestStatus.RunFailed
	) {
		return null;
	}

	const openDialog = () => {
		setName(props.request?.CampaignName || "");
		setOpened(true);
	};
	const closeDialog = () => setOpened(false);

	const save = async () => {
		if (props.request) {
			await resetRequestCampaign({
				variables: { id: props.request.Id, name },
				refetchQueries: [{ query: GetOneRequestDocument, variables: { id: props.request.Id } }],
			});
		}
		setOpened(false);
	};

	return (
		<>
			<IconButton className="reset-campaign" onClick={openDialog}>
				<i className="icon icon-comment" />
			</IconButton>
			<Dialog className="withCloseButton" open={opened} onClose={closeDialog}>
				<IconButton className="closeButton" onClick={closeDialog}>
					<i className="icon icon-close" />
				</IconButton>
				<DialogTitle disableTypography>
					<Typography variant="h5" align="center">
						{strings.request_campaignLink}
					</Typography>
				</DialogTitle>
				<DialogContent>
					<Box display="flex" flexDirection="column" alignItems="center">
						<Typography variant="body1">{strings.request_resetCampaignDescription}</Typography>
						<StandartTextInput
							label={strings.request_campaignNameLabel}
							value={name}
							onChange={setName}
							placeholder={strings.request_campaignNamePlaceholder}
							margin="normal"
						/>
						<StandartButton
							color="success"
							variant="contained"
							disabled={loading}
							onClick={() => {
								save();
							}}
						>
							{strings.save}
						</StandartButton>
					</Box>
				</DialogContent>
			</Dialog>
		</>
	);
};

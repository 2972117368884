import { MyLoginFragment, ELoginRole, DeveloperFullFragment } from "@espresso/protocol";

export const isLoginUser = (login: MyLoginFragment) => {
	return login.Role === ELoginRole.User;
};
export const isProfileFilled = (Developer: DeveloperFullFragment | undefined | null) => {
	if (!Developer) {
		return false;
	}

	const isCommunicationsFilled = !!(Developer.SkypeId || Developer.TelegramId || Developer.WhatsAppId);
	const isTeamNameFilled = !!Developer.TeamName;
	const isTeamSizeFilled = Developer.TeamSize > 0;
	const isDeveloperNameFilled = !!Developer.ContactName;
	return isCommunicationsFilled && isTeamNameFilled && isTeamSizeFilled && isDeveloperNameFilled;
};

import React from "react";
import { DeveloperRequestFragment, EDeveloperPlatform } from "@espresso/protocol";
import { Maybe } from "graphql/jsutils/Maybe";

export const formatUrlApp = (request?: Maybe<DeveloperRequestFragment>) => {
	if (request?.AppStoreUrl && request?.Platform === EDeveloperPlatform.Ios) {
		return {
			urlString: request.AppStoreUrl,
			urlText: (
				<>
					<i className="icon icon-apple" />
					{request.AppStoreUrl}
				</>
			),
		};
	}
	if (request?.GooglePlayUrl && request?.Platform === EDeveloperPlatform.Android) {
		return {
			urlString: request.GooglePlayUrl,
			urlText: (
				<>
					<i className="icon icon-android" />
					{request.GooglePlayUrl}
				</>
			),
		};
	}
	if (request?.AppInfoUrl) {
		return {
			urlString: request.AppInfoUrl,
			urlText: (
				<>
					<i className="icon icon-puzzle" />
					{request.AppInfoUrl}
				</>
			),
		};
	}
	return {
		urlString: undefined,
		urlText: undefined,
	};
};

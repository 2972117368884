import moment from "moment";

export const getDaysBetweenDates = (from: moment.Moment, to: moment.Moment) => {
	const current = moment(from),
		days = [];

	while (current.isSameOrBefore(to)) {
		days.push(moment(current));
		current.add(1, "day");
	}
	return days;
};

import React from "react";
import { StandartCard } from "../../components";

type TProps = {
	imageSrc: string;
	title: string;
	subtitle: string;
	inverted?: boolean;
};

export const LandingCard = (props: TProps) => {
	const { imageSrc, title, subtitle, inverted } = props;

	return (
		<StandartCard
			profile
			plain
			inverted={inverted}
			headerProps={{ imageSrc }}
			bodyProps={{ className: "textLeft", title, subtitle }}
		/>
	);
};

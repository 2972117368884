import { Icon, TextField, TextFieldProps } from "@material-ui/core";
import React, { useMemo, useState, useLayoutEffect, useRef, useEffect, useCallback } from "react";
import InputMask, { Props } from "react-input-mask";
import { smoothScroll } from "../../helpers/smoothScroll";

export type TPhoneMaskInputProps = Omit<Props, "pattern">;

export type TStandartTextInputProps = Omit<TextFieldProps, "onChange" | "value"> & {
	onChange: (value: string, isValid: boolean) => void;
	mask?: string;
	maskChar?: string | null;
	pattern?: RegExp;
	errorMessage?: string;
	value?: string | null;
	hasScrollByAllow?: boolean;
};

const CYRYLLIC_FORMAT_CHARS = {
	"9": "[0-9]",
	a: "[A-Za-z]", // латинская буква а
	б: "[А-Яа-я]",
	"*": "[A-Za-z0-9А-Яа-я]",
};

export const StandartTextInput = (props: TStandartTextInputProps) => {
	const { onChange, mask, maskChar, pattern, errorMessage, hasScrollByAllow, ...otherProps } = props;
	const inputContainerRef = useRef<HTMLInputElement>(null);
	const [error, setError] = useState(false);

	const checkValue = useCallback(
		(value: string) => {
			let isValid = true;
			if (otherProps.required && value.trim().length === 0) {
				isValid = false;
				setError(!isValid);
			} else if (pattern) {
				isValid = pattern.test(value) || (!otherProps.required && value.length === 0);
				setError(!isValid);
			}

			if (isValid && error) {
				setError(false);
			}
			onChange(value, isValid);
		},
		[otherProps.required, pattern, error, onChange],
	);

	const onBlur = () => otherProps.value && checkValue(otherProps.value.trim());

	const InputComponent = useMemo(
		() => (inputComponentProps: any) => (
			<InputMask {...inputComponentProps} mask={mask} maskChar={maskChar} formatChars={CYRYLLIC_FORMAT_CHARS} />
		),
		[mask, maskChar],
	);

	const showError = error || otherProps.error;

	useEffect(() => {
		if (!error || !otherProps.value || otherProps.error) {
			return;
		}
		checkValue(otherProps.value.trim());
	}, [error, otherProps.error, otherProps.value, checkValue]);

	useLayoutEffect(() => {
		if (!hasScrollByAllow || !inputContainerRef.current) {
			return;
		}

		const bounds = inputContainerRef.current.getBoundingClientRect();

		const headerHeight = 96;

		const isInViewport =
			bounds.top >= headerHeight &&
			bounds.bottom <= (window.innerHeight || document.documentElement.clientHeight);

		if (!isInViewport) {
			smoothScroll(
				document.documentElement,
				window.scrollY +
					bounds.top -
					inputContainerRef.current.scrollHeight -
					inputContainerRef.current.offsetHeight,
				500,
				"scrollTop",
			);
		}
	}, [hasScrollByAllow]);

	return (
		<TextField
			ref={inputContainerRef}
			fullWidth
			variant={undefined}
			onBlur={onBlur}
			{...otherProps}
			error={showError}
			helperText={showError ? errorMessage : undefined}
			onChange={(e) => {
				const v = e.target.value;
				return checkValue(v);
			}}
			InputProps={{
				inputComponent: mask ? InputComponent : undefined,
				endAdornment: showError && <Icon className="icon icon--Attention" fontSize="small" color="error" />,
				...otherProps.InputProps,
			}}
			InputLabelProps={{ ...otherProps.InputLabelProps }}
		/>
	);
};

import { Box, CircularProgress, Grid, Typography } from "@material-ui/core";
import React from "react";
import { StandartView, RequestList } from "../../components";
import useAppContext from "../../contexts/AppContext";

import withAuth from "../../helpers/withAuth";
import strings from "../../helpers/strings";
import { GetRequestsPresetsInput } from "@espresso/protocol";

const INPUT: GetRequestsPresetsInput = { Deleted: true };
export const DeletedRequestsView = withAuth(() => {
	const { login } = useAppContext();

	return (
		<StandartView title="">
			{login?.Role == null ? (
				<Box display="flex" justifyContent="center">
					<CircularProgress />
				</Box>
			) : (
				<Grid container className="home-view">
					<>
						<Grid item sm={12} className="title-container">
							<Typography variant="h5">{strings.request_removedListTitle}</Typography>
						</Grid>
						<RequestList getRequestsQueryInput={INPUT} rememberLocation disableAllRequestsButton />
					</>
				</Grid>
			)}
		</StandartView>
	);
});

import { TLocale } from "@espresso/shared-config";
import React from "react";
import strings from "../../helpers/strings";

export interface IFullScreenPlaceholderProps {
	titleKey?: keyof TLocale;
	messageKey?: keyof TLocale;
	loading?: boolean;
}

export const FullScreenPlaceholder = (props: IFullScreenPlaceholderProps) => {
	return (
		<div className="placeholder-container">
			<div className="placeholder-content">
				<h1>{props.loading ? strings.loading : strings[props.titleKey || "defaultPlaceholderTitle"]}</h1>
				{props.loading ? (
					<p>{strings.loadingMessage}</p>
				) : (
					props.messageKey && <p>{strings[props.messageKey]}</p>
				)}
			</div>
		</div>
	);
};

import React from "react";
import { useHistory, useLocation } from "react-router-dom";

import { IconButton, Tooltip } from "@material-ui/core";

import { TLocale } from "@espresso/shared-config";
import strings from "../../helpers/strings";

export const GoBackButton = (props: { to: string; tooltipKey?: keyof TLocale }) => {
	const tooltipKey = props.tooltipKey || "request_backToProfileTooltip";
	let history = useHistory();
	const { state: locationState } = useLocation<{ prevPathname: string }>();

	return (
		<Tooltip title={strings[tooltipKey]}>
			<IconButton
				size="small"
				className="go-back-button"
				onClick={() => {
					history.push(locationState?.prevPathname ?? props.to);
				}}
			>
				<i className="icon icon-left" />
			</IconButton>
		</Tooltip>
	);
};

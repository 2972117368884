import React, { useCallback, useEffect, useMemo } from "react";

import {
	Box,
	Grid,
	Table,
	TableBody,
	TableContainer,
	TableHead,
	TablePagination,
	TableRow,
	Tooltip,
} from "@material-ui/core";
import {
	ELoginRole,
	GetManyRequestsDocument,
	GetRequestInput,
	StatusPreset,
	useGetManyRequestsLazyQuery,
	ERequestStatus,
	ECampaignType,
	PresetCounters,
	PresetSettingsFullFragment,
	EPresetConfig,
	EGenre,
} from "@espresso/protocol";
import strings from "../../helpers/strings";
import { REQUEST_ITEMS_PER_PAGE, requestSortParamDefault } from "../../helpers/constants";
import { StandartTextInput } from "../";
import { useHistory, useLocation } from "react-router-dom";
import withAuth from "../../helpers/withAuth";
import _ from "lodash";
import useAppContext, { IRequestsListParams, IRequestsListSortParam } from "../../contexts/AppContext";
import { useCookies } from "react-cookie";
import { columns } from "./RequestListCells";
import { GenreList, requestPrivateItems, StandartButton, SwitchBar } from "../common";
import { Maybe } from "graphql/jsutils/Maybe";
import RequestListTableHeadCell from "./RequestListTableHeadCell";
import { PromoList } from "./PromoList";
import { StatusList } from "./StatusList";
import { RequestListTablePlaceholder } from "./RequestListTablePlaceholder";
import { useLoadingSignal } from "contexts/LoadingContext";

export type TRequestListProps = {
	withSearch?: boolean;
	withStatusFilter?: boolean;
	withTableHeader?: boolean;
	withPagination?: boolean;
	maxRowsPerPage?: number;
	requestsListParams?: IRequestsListParams;
	setRequestsListParams?: (value: Partial<IRequestsListParams>) => void;
	getRequestsQueryInput?: GetRequestInput;
	hiddenRequestsIds?: string[];
	setPresetAll?: () => void;
	counters?: Map<string, PresetCounters>;
	activePreset?: PresetSettingsFullFragment;
	hideFilters?: boolean;
	rememberLocation?: boolean;
	disableAllRequestsButton?: boolean;
};

const RequestListComponent = (props: TRequestListProps) => {
	const {
		maxRowsPerPage,
		requestsListParams,
		setRequestsListParams,
		withPagination = true,
		withSearch = true,
		withStatusFilter = true,
		withTableHeader = true,
		getRequestsQueryInput,
		hiddenRequestsIds,
		setPresetAll,
		counters,
		activePreset,
		hideFilters,
		rememberLocation,
		disableAllRequestsButton,
	} = props;
	const location = useLocation<Maybe<{ isUserExists: Maybe<boolean> }>>();
	const [cookies, setCookie] = useCookies();

	const { login } = useAppContext();

	const itemsPerPage = maxRowsPerPage || REQUEST_ITEMS_PER_PAGE;
	const initialRowsPerPage = maxRowsPerPage ? maxRowsPerPage / 2 : +cookies.rowsPerPage || REQUEST_ITEMS_PER_PAGE / 2;

	const isPrivileged = login?.Role === ELoginRole.Manager || login?.Role === ELoginRole.Publisher;

	const history = useHistory();
	const [allFetched, setAllFetched] = React.useState(false);
	const [page, setPage] = React.useState(requestsListParams?.page || 0);
	const [rowsPerPage, setRowsPerPage] = React.useState(initialRowsPerPage);
	const [searchString, setSearchString] = React.useState<string>(requestsListParams?.searchString || "");
	const [statuses, setStatuses] = React.useState<StatusPreset[]>(requestsListParams?.statuses || []);
	const [selectedSort, setSelectedSort] = React.useState<IRequestsListSortParam>(
		requestsListParams?.sort || requestSortParamDefault,
	);
	const [campaignTypes, setCampaignTypes] = React.useState<ECampaignType[]>(requestsListParams?.campaignTypes || []);
	const [promoKey, setPromoKey] = React.useState<string[]>(requestsListParams?.promoKey || []);
	const [genre, setGenre] = React.useState<EGenre[]>(requestsListParams?.genre || []);

	const switchValue = useMemo(() => campaignTypes, [campaignTypes]);

	useEffect(() => {
		if (requestsListParams && requestsListParams.page !== page) {
			setPage(requestsListParams.page);
		}
		if (requestsListParams && requestsListParams.searchString !== searchString) {
			setSearchString(requestsListParams.searchString);
		}
		if (requestsListParams && !_.isEqual(requestsListParams.statuses, statuses)) {
			setStatuses(requestsListParams.statuses);
		}
		if (requestsListParams && !_.isEqual(requestsListParams.sort, selectedSort)) {
			setSelectedSort(requestsListParams.sort);
		}
		if (requestsListParams && !_.isEqual(requestsListParams.promoKey, promoKey)) {
			setPromoKey(requestsListParams.promoKey);
		}
		if (requestsListParams && requestsListParams.campaignTypes !== campaignTypes) {
			setCampaignTypes(requestsListParams.campaignTypes);
		}
		if (requestsListParams && requestsListParams.genre !== genre) {
			setGenre(requestsListParams.genre);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [requestsListParams]);

	const changeSearchString = (value: string) => {
		setSearchString(value);
		if (requestsListParams?.searchString !== value) {
			setRequestsListParams?.({ searchString: value });
		}
	};

	const [getRequests, { data, loading, fetchMore }] = useGetManyRequestsLazyQuery({
		fetchPolicy: "cache-and-network",
	});
	useLoadingSignal(loading);

	const showAllRequests = useCallback(() => {
		setPresetAll?.();
		setSelectedSort(requestSortParamDefault);
		handleResetFilters();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [setPresetAll]);

	const presetCount =
		activePreset && activePreset.Config !== EPresetConfig.All && counters
			? counters.get(activePreset.Id)
			: undefined;

	const getRequestList = useCallback(
		(input?: GetRequestInput) => {
			getRequests({
				variables: {
					input: {
						Pagination: { Limit: itemsPerPage, Offset: 0 },
						...getRequestsQueryInput,
						...input,
					},
				},
			});
		},
		[getRequests, getRequestsQueryInput, itemsPerPage],
	);

	const debouncedFetch = useMemo(
		() =>
			_.debounce(
				(
					search: string,
					statuses: StatusPreset[],
					sort: IRequestsListSortParam,
					promoKey: string[],
					campaignTypes: ECampaignType[] | undefined,
					genre: EGenre[] | undefined,
				) => {
					getRequestList({
						...(withSearch ? { SearchString: search } : undefined),
						...(statuses.length ? { Status: statuses } : undefined),
						...(promoKey.length ? { PromoKey: promoKey } : undefined),
						...(campaignTypes?.length ? { CampaignTypes: campaignTypes } : undefined),
						...(genre?.length ? { Genres: genre } : undefined),
						OrderField: sort.field,
						OrderDirection: sort.order,
						OrderNullPosition: sort.nullsPosition,
						Pagination: { Limit: itemsPerPage * 2, Offset: 0 },
					});
					setPage(0);
					setAllFetched(false);
					if (requestsListParams?.page !== 0) {
						setRequestsListParams?.({ page: 0 });
					}
				},
				500,
			),
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[getRequestList, statuses],
	);

	useEffect(() => {
		if (!data) {
			const firstItemOnPageIndex = (requestsListParams?.page ?? 0) * rowsPerPage;
			const pageIndex = Math.floor(firstItemOnPageIndex / itemsPerPage);
			const pagesToLoad = pageIndex > 0 ? 3 : 2;
			getRequestList({
				...(withSearch ? { SearchString: searchString } : undefined),
				...(statuses.length ? { Status: statuses } : undefined),
				...(promoKey.length ? { PromoKey: promoKey } : undefined),
				...(campaignTypes?.length ? { CampaignTypes: campaignTypes } : undefined),
				...(genre.length ? { Genres: genre } : undefined),
				OrderField: selectedSort.field,
				OrderDirection: selectedSort.order,
				OrderNullPosition: selectedSort.nullsPosition,
				Pagination: { Limit: itemsPerPage * pagesToLoad, Offset: Math.max(0, pageIndex - 1) * itemsPerPage },
			});
			return;
		}
		debouncedFetch(searchString, statuses, selectedSort, promoKey, campaignTypes, genre);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [statuses, searchString, selectedSort, campaignTypes, promoKey, genre]);

	useEffect(() => {
		if (!data?.getManyRequests) {
			return;
		}
		if (data.getManyRequests.length % itemsPerPage !== 0) {
			setAllFetched(true);
		}
	}, [data, itemsPerPage]);

	const developerRequests = useMemo(() => {
		if (presetCount?.Count === 0) {
			return [];
		}
		return hiddenRequestsIds
			? (data?.getManyRequests || []).filter((item) => !hiddenRequestsIds.includes(item.Id))
			: data?.getManyRequests || [];
	}, [hiddenRequestsIds, data?.getManyRequests, presetCount]);

	useEffect(() => {
		if (
			!developerRequests.length ||
			!location.state ||
			developerRequests.length > 1 ||
			location.state.isUserExists ||
			location.state.isUserExists === undefined
		) {
			return;
		}

		location.state.isUserExists = undefined;
		history.push(`/request-${developerRequests[0].Id}`);
	}, [developerRequests, history, location.state]);

	const handleChangePage = async (_event: unknown, newPage: number) => {
		setPage(newPage);
		setRequestsListParams?.({ page: newPage });
		if (!fetchMore) {
			return;
		}
		if (!allFetched && rowsPerPage * (newPage + 2) > (developerRequests?.length || 0)) {
			const { data } = await fetchMore({
				query: GetManyRequestsDocument,
				variables: {
					input: {
						...getRequestsQueryInput,
						SearchString: searchString,
						Status: statuses.length ? statuses : undefined,
						OrderField: selectedSort.field,
						OrderDirection: selectedSort.order,
						OrderNullPosition: selectedSort.nullsPosition,
						Pagination: {
							Offset: developerRequests?.length || 0,
							Limit: itemsPerPage,
						},
					},
				},
			});
			if (!data.getManyRequests || data.getManyRequests?.length < itemsPerPage) {
				setAllFetched(true);
			}
		}
	};

	const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
		const newRowsPerPage = +event.target.value;
		setRowsPerPage(newRowsPerPage);
		if (!maxRowsPerPage) {
			setCookie("rowsPerPage", newRowsPerPage);
		}
		setPage(0);
		if (requestsListParams?.page !== 0) {
			setRequestsListParams?.({ page: 0 });
		}
	};

	const handleChangeStatuses = (statuses: StatusPreset[]) => {
		let newStatus: StatusPreset | undefined;
		const statusKeys = statuses.map((item) => {
			if (item.Status === ERequestStatus.New) {
				newStatus = item;
			}
			return item.Status;
		});

		/*
			Т.к. New статус отвечает за этап "Ввод данных", 
			то также необходимо добавить Private статус для CTR заявок, 
			который будет аналогичен New
		*/
		if (newStatus && !statusKeys.includes(ERequestStatus.Private)) {
			statuses.push({
				...newStatus,
				Status: ERequestStatus.Private,
			});
		}

		setRequestsListParams?.({ statuses });
		setStatuses(statuses);
	};

	const handleChangePromoKey = (promoKey: string[]) => {
		setRequestsListParams?.({ promoKey });
		setPromoKey(promoKey);
	};

	const handleChangeCampaignTypes = (items: ECampaignType[]) => {
		setRequestsListParams?.({ campaignTypes: items });
		setCampaignTypes(items);
	};

	const handleChangeGenre = (genre: EGenre[]) => {
		setRequestsListParams?.({ genre });
		setGenre(genre);
	};

	const handleChangeColumnSort = (sort: IRequestsListSortParam) => {
		setRequestsListParams?.({ sort });
		setSelectedSort(sort);
	};

	const handleResetFilters = () => {
		setRequestsListParams?.({ searchString: "", promoKey: [], statuses: [], campaignTypes: [], genre: [] });
	};

	const isAnyFilterApplied =
		searchString !== "" ||
		!!promoKey?.length ||
		!!campaignTypes?.length ||
		!!genre?.length ||
		(withStatusFilter && !!statuses?.length);

	const showAllRequestsButton =
		!disableAllRequestsButton && !!getRequestsQueryInput?.DeveloperId && developerRequests.length > rowsPerPage;

	return (
		<Grid container className="requests-list">
			{!hideFilters && (
				<>
					<Grid container spacing={4}>
						{withSearch && (
							<Grid item xs={12} md={withStatusFilter ? 7 : 12}>
								<StandartTextInput
									value={searchString}
									onChange={changeSearchString}
									label={
										getRequestsQueryInput?.DeveloperId || !isPrivileged
											? strings.searchGameOnly
											: strings.search
									}
									margin="dense"
									className="search-input"
									fullWidth
								/>
							</Grid>
						)}
						{withStatusFilter && (
							<Grid item xs={12} md={5}>
								<StatusList selectedStatuses={statuses} onSelectedChange={handleChangeStatuses} />
							</Grid>
						)}
					</Grid>
					{isPrivileged && (
						<Grid container spacing={4} className="filters-container">
							<Grid item xs={12} lg={12} xl={4}>
								<PromoList selectedKeys={promoKey} onSelectedChange={handleChangePromoKey} />
							</Grid>
							<Grid item xs={12} lg={6} xl={4}>
								<GenreList
									multiple
									fullWidth
									labelKey="genre_label"
									selectedKeys={genre}
									onSelectedChange={handleChangeGenre}
									disableInfoButton
								/>
							</Grid>
							<Grid item xs={12} lg={6} xl={4} className="switchbar-container">
								<SwitchBar
									multiSelect
									selectedItems={switchValue}
									items={requestPrivateItems}
									labelKey="request_campaignType"
									buttonColor="secondary"
									onItemsChange={handleChangeCampaignTypes}
								/>
								<Tooltip title={isAnyFilterApplied ? strings.clearFilters : ""}>
									<div>
										<StandartButton
											justIcon
											round
											variant="text"
											onClick={handleResetFilters}
											disabled={!isAnyFilterApplied}
										>
											<i className="icon icon-filter" />
										</StandartButton>
									</div>
								</Tooltip>
							</Grid>
						</Grid>
					)}
				</>
			)}
			{developerRequests.length ? (
				<TableContainer className="requests-table">
					<Table size="small">
						{withTableHeader && (
							<TableHead>
								<TableRow>
									{columns.map((column) => (
										<RequestListTableHeadCell
											key={column.key}
											column={column}
											selectedSort={selectedSort}
											handleChangeColumnSort={handleChangeColumnSort}
										/>
									))}
								</TableRow>
							</TableHead>
						)}
						<TableBody>
							{developerRequests
								.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
								.map((row) => (
									<TableRow hover key={row.Id}>
										{columns.map((column) => (
											<column.Component
												key={column.key}
												column={column}
												row={row}
												rememberLocation={rememberLocation}
											/>
										))}
									</TableRow>
								))}
						</TableBody>
					</Table>
				</TableContainer>
			) : (
				<RequestListTablePlaceholder
					hiddenRequestsIds={hiddenRequestsIds}
					loading={loading}
					placeholderButtonCallback={showAllRequests}
				/>
			)}
			{!!developerRequests?.length && withPagination && (
				<Box className="table-pagination">
					<TablePagination
						rowsPerPageOptions={[itemsPerPage / 5, itemsPerPage / 2, itemsPerPage]}
						component="div"
						count={developerRequests.length}
						rowsPerPage={rowsPerPage}
						page={page}
						onChangePage={handleChangePage}
						onChangeRowsPerPage={handleChangeRowsPerPage}
						labelDisplayedRows={({ from, to, count }) =>
							`${from}-${to}${
								count !== -1
									? ` ${strings.fromPage} ${!allFetched ? `${strings.morePage} ${count}` : count}`
									: ""
							}`
						}
						labelRowsPerPage={strings.rowsPerPage}
						nextIconButtonText={strings.nextPage}
						backIconButtonText={strings.prevPage}
					/>
					{showAllRequestsButton && (
						<a href={`/requests-${getRequestsQueryInput?.DeveloperId}`} target="_blank" rel="noreferrer">
							<StandartButton variant="contained" color="secondary" disableElevation>
								{strings.request_placeholderButton}
							</StandartButton>
						</a>
					)}
				</Box>
			)}
		</Grid>
	);
};

export const RequestList = withAuth(RequestListComponent);
